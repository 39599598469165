import { func, string } from 'prop-types';
import React from 'react';
import Input from 'src/components/inputs';
import { categoriesForPublishing, genders, subCategoriesByCategory } from 'src/constants';
import LabelInput from 'src/components/labelInput';
import ErrorMessage from 'src/components/errorMessage';
import { Box, Grid } from '@material-ui/core';
import styles from './styles.module.css';

const GeneralInfo = ({ getInputProps, errorMessage, category }) => (
  <div className={styles.wrapper}>
    <ErrorMessage message={errorMessage} />
    <LabelInput label="Título" verticalPadding=".3em">
      <Input {...getInputProps('title')} maxLength={100} />
    </LabelInput>
    <Grid container wrap="nowrap">
      <LabelInput label="Precio" verticalPadding=".3em">
        <Input {...getInputProps('price')} onlyNumbers maxLength={6} />
      </LabelInput>
      <Box pr="1em" />
      <LabelInput label="Existencia" verticalPadding=".3em">
        <Input {...getInputProps('quantity')} onlyNumbers maxLength={6} />
      </LabelInput>
    </Grid>
    <LabelInput label="Categoría" verticalPadding=".3em">
      <Input {...getInputProps('category')} type="select" options={categoriesForPublishing} />
    </LabelInput>

    {!!subCategoriesByCategory[category] && (
      <>
        <LabelInput label="Subcategoría " verticalPadding=".3em">
          <Input {...getInputProps('subCategory')} type="select" options={subCategoriesByCategory[category]} />
        </LabelInput>
      </>
    )}

    <LabelInput label="Género" verticalPadding=".3em">
      <Input {...getInputProps('gender')} type="select" options={genders} />
    </LabelInput>

    <LabelInput label="Descripción" verticalPadding=".3em">
      <Input type="textarea" {...getInputProps('description')} />
    </LabelInput>
  </div>
);

GeneralInfo.defaultProps = {
  errorMessage: null,
  category: null,
};

GeneralInfo.propTypes = {
  getInputProps: func.isRequired,
  errorMessage: string,
  category: string,
};

export default GeneralInfo;
