import { Box, Grid } from '@material-ui/core';
import { bool, func, object, string } from 'prop-types';
import React from 'react';
import paypalSrc from 'src/assets/paypal.png';
// import tajetaSrc from 'src/assets/tarjeta.png';
// import store from 'src/assets/store.png';
// import spei from 'src/assets/spei.png';
import styles from './styles.module.css';
import ErrorMessage from '../../../../components/errorMessage';

const MethodPaySelectorAndSenInfo = (props) => {
  const getRadioInputProps = (value) => {
    const { onChange } = props.getInputProps();
    return {
      onClick: () => onChange({ target: { name: 'methodPay', value } }),
    };
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.group}>
        <div className={styles.flexSpacer}>
          <div className={styles.describe}>
            <span className={styles.gray}>Contacto:</span>
          </div>
          <div className={styles.describeValue}>{props.values.email}</div>
        </div>
      </div>
      <div className={styles.group}>
        <div className={styles.flexSpacer}>
          <div className={styles.describe}>
            <span className={styles.gray}>Enviar a:</span>
          </div>
          <div className={styles.describeValue}>
            {props.values.streetAndNumber}, {props.values.suburb}, {props.values.city}, {props.values.state}, C.P.:
            {props.values.postalCode}
          </div>
        </div>
      </div>
      <Grid container justify="flex-end">
        <div className={styles.button} onClick={props.onBack} role="button">
          Cambiar dirección
        </div>
      </Grid>
      {props.showMethoPay && (
        <>
          <Box fontSize="1.2em" fontWeight="800" margin="2em 0em 1em 0em">
            MÉTODO DE PAGO
          </Box>
          <ErrorMessage message={props.errorMessage} />
          <div className={styles.methodPayWrapper} {...getRadioInputProps('paypal')}>
            <div className={styles.flexSpacer}>
              <div className={styles.radio}>
                {props.values.methodPay === 'paypal' && <div className={styles.radioSelected} />}
              </div>
              <img className={styles.payIcon} src={paypalSrc} alt="icon" />
            </div>
          </div>
          <div className={styles.methodPayWrapper} {...getRadioInputProps('manual')}>
            <div className={styles.flexSpacer}>
              <div className={styles.radio}>
                {props.values.methodPay === 'manual' && <div className={styles.radioSelected} />}
              </div>
              Pago en efectivo o transferencia
            </div>
          </div>
          {/* <div className={styles.methodPayWrapper} {...getRadioInputProps('card')}>
            <div className={styles.flexSpacer}>
              <div className={styles.radio}>
                {props.values.methodPay === 'card' && <div className={styles.radioSelected} />}
              </div>
              Tarjeta
              <img className={styles.payIcon} src={tajetaSrc} alt="icon" />
            </div>
          </div>
          <div className={styles.methodPayWrapper} {...getRadioInputProps('store')}>
            <div className={styles.flexSpacer}>
              <div className={styles.radio}>
                {props.values.methodPay === 'store' && <div className={styles.radioSelected} />}
              </div>
              Depósitos en tiendas
              <img className={styles.payIcon} src={store} alt="icon" />
            </div>
          </div>
          <div className={styles.methodPayWrapper} {...getRadioInputProps('spei')}>
            <div className={styles.flexSpacer}>
              <div className={styles.radio}>
                {props.values.methodPay === 'spei' && <div className={styles.radioSelected} />}
              </div>
              Transferencia electrónica
              <img className={styles.payIcon} src={spei} alt="icon" />
            </div>
          </div> */}
        </>
      )}
    </div>
  );
};

MethodPaySelectorAndSenInfo.propTypes = {
  showMethoPay: bool.isRequired,
  values: object.isRequired,
  onBack: func.isRequired,
  getInputProps: func.isRequired,
  errorMessage: string.isRequired,
};

export default MethodPaySelectorAndSenInfo;
