import { Box, CircularProgress, Grid } from '@material-ui/core';
import React from 'react';
import Card from 'src/components/card';
import { useLocation } from 'react-router-dom';
import Input from 'src/components/inputs';
import LabelInput from 'src/components/labelInput';
import ErrorMessage from 'src/components/errorMessage';
import useCommunityEditor from 'src/hooks/useCommunityEditor';
import { Delete } from '@material-ui/icons';
import ButtonAdmin from '../../components/button';
import Container from '../../components/container';
import styles from './styles.module.css';
import getFilePreviewUrl from '../../../../helpers/normalizeImagesUrl';
import Dropzone from '../../../../components/dropzone';
import { colors } from '../../../../constants';

function ComunityEditor() {
  const { state } = useLocation();
  const { errorMessage, values, getInputProps, deletePicture, selectPicture, acept, loading } =
    useCommunityEditor(state);

  return (
    <Container className={styles.container}>
      <Card>
        {!loading && (
          <>
            <div className={styles.wrapper}>
              <Box fontWeight="bold" color="var(--black)" fontSize="1.2em" textAlign="center" paddingBottom="2em">
                {state ? 'Actualizar comunidad' : 'Crear nueva comunidad'}
              </Box>
              <ErrorMessage message={errorMessage} />
              <Box textAlign="center" fontSize="1em">
                FOTO
              </Box>
              <Box padding="1em 0em">
                {!values.picture && (
                  <Dropzone className={styles.dropzone} onChange={selectPicture}>
                    Seleccionar imagen
                  </Dropzone>
                )}
                {!!values.picture && (
                  <div className={styles.pictureListContainer}>
                    <div className={styles.pictureWrapper}>
                      <div className={styles.deleteIconWrapper} onClick={deletePicture} role="button">
                        <Delete className={styles.deleteIcon} />
                      </div>
                      <img className={styles.image} src={getFilePreviewUrl(values.picture)} alt="imagen" />
                    </div>
                  </div>
                )}
              </Box>
              <LabelInput label="Título" verticalPadding=".3em">
                <Input {...getInputProps('title')} maxLength={100} />
              </LabelInput>
              <LabelInput label="Correo" verticalPadding=".3em">
                <Input {...getInputProps('email')} maxLength={100} />
              </LabelInput>
              <LabelInput label="Teléfono" verticalPadding=".3em">
                <Input {...getInputProps('phone')} onlyNumbers maxLength={10} />
              </LabelInput>
              <LabelInput label="Whatsapp" verticalPadding=".3em">
                <Input {...getInputProps('whatsappNumber')} onlyNumbers maxLength={10} />
              </LabelInput>
              <LabelInput label="Sitio web (opcional)" verticalPadding=".3em">
                <Input {...getInputProps('web')} maxLength={100} />
              </LabelInput>
              <LabelInput label="Descripción" verticalPadding=".3em">
                <Input type="textarea" {...getInputProps('description')} maxLength={400} />
              </LabelInput>
              <Box marginTop="3em">
                <Grid container justify="flex-end">
                  <Box mr="1em" />
                  <ButtonAdmin primary onClick={acept}>
                    Guardar
                  </ButtonAdmin>
                </Grid>
              </Box>
            </div>
          </>
        )}

        {loading && (
          <div className={styles.loading}>
            <Box marginBottom="4em" textAlign="center" color={colors.cyan}>
              Guardando publicacion...
            </Box>
            <CircularProgress />
          </div>
        )}
      </Card>
    </Container>
  );
}

export default ComunityEditor;
