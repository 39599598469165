import { Box } from '@material-ui/core';
import { func, string } from 'prop-types';
import React from 'react';
import ErrorMessage from '../../../../components/errorMessage';
import Input from '../../../../components/inputs';
import { gendersBin } from '../../../../constants';
import styles from './styles.module.css';

const Information = (props) => (
  <div>
    <ErrorMessage message={props.errorMessage} />
    <div className={styles.group}>
      <Box fontWeight="800" marginBottom="1em" fontSize="1.2em" color="#222d69">
        Información de contacto
      </Box>
      <div className={styles.flexSpacer}>
        <Input
          className={styles.input}
          placeholder="Correo electrónico"
          maxLength={100}
          {...props.getInputProps('email')}
        />
      </div>
      <div className={styles.flexSpacer}>
        <Input className={styles.input} placeholder="Nombre" maxLength={100} {...props.getInputProps('name')} />
        <Box marginRight="1em" />
        <Input className={styles.input} placeholder="Apellidos" maxLength={100} {...props.getInputProps('lastName')} />
      </div>
      <div className={styles.flexSpacer}>
        <Input
          className={styles.input}
          placeholder="Fecha de nacimiento"
          maxLength={100}
          {...props.getInputProps('birthDate')}
        />
        <Box marginRight="1em" />
        <Input
          className={styles.input}
          placeholder="Género"
          type="select"
          options={gendersBin}
          {...props.getInputProps('gender')}
        />
      </div>
      <div className={styles.flexSpacer}>
        <Input
          className={styles.input}
          placeholder="Tel / Cel"
          maxLength={10}
          onlyNumbers
          {...props.getInputProps('phone')}
        />
      </div>
      <div className={styles.flexSpacer}>
        <Input
          className={styles.input}
          placeholder="Calle y número"
          maxLength={100}
          {...props.getInputProps('streetAndNumber')}
        />
      </div>
      <div className={styles.flexSpacer}>
        <Input className={styles.input} placeholder="Colonia" maxLength={100} {...props.getInputProps('suburb')} />
      </div>
      <div className={styles.flexSpacer}>
        <Input className={styles.input} placeholder="Ciudad" maxLength={100} {...props.getInputProps('city')} />
        <Box marginRight="1em" />
        <Input className={styles.input} placeholder="C.P." maxLength={5} {...props.getInputProps('postalCode')} />
      </div>
      <div className={styles.flexSpacer}>
        <Input className={styles.input} placeholder="Estado" maxLength={100} {...props.getInputProps('state')} />
        <Box marginRight="1em" />
        <Input className={styles.input} placeholder="País" maxLength={100} {...props.getInputProps('country')} />
      </div>
    </div>
  </div>
);

Information.propTypes = {
  getInputProps: func.isRequired,
  errorMessage: string.isRequired,
};

export default Information;
