// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_loading__210-M {\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  min-height: 30em;\r\n  flex-direction: column;\r\n}", "",{"version":3,"sources":["webpack://src/pages/dashboard/pages/createEvent/components/Loading/styles.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;EAChB,sBAAsB;AACxB","sourcesContent":[".loading {\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  min-height: 30em;\r\n  flex-direction: column;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loading": "styles_loading__210-M"
};
export default ___CSS_LOADER_EXPORT___;
