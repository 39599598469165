import React from 'react';
import Footer from 'src/components/privateFooter';
import Cover from 'src/components/privateCover';
import Header from 'src/components/header';
import { useSession } from 'src/modules/session';
import UserProfileBody from './components/profileBody';
import styles from './styles.module.css';

const MyProfile = () => {
  const session = useSession();

  return (
    <>
      <Header />
      <Cover />
      <div className={styles.container}>
        <UserProfileBody userId={session.data.id} />
      </div>
      <Footer />
    </>
  );
};

export default MyProfile;
