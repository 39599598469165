// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_group__6M4ZO {\n  background-color: #eee;\n  padding: 1.5em;\n  border-radius: .4em;\n  margin-bottom: 1em;\n}\n.styles_flexSpacer__1vgg3 {\n  display: flex;\n  flex-wrap: nowrap;\n}\n.styles_input__3A5iX {\n  height: 3.5em!important;\n}\n.styles_openpayAssets__3jpw4 {\n  width: 100%;\n}\n.styles_openpayLogo__JEJh- {\n  width: 10em;\n  display: block;\n  margin: auto;\n  margin-bottom: 2em;\n}", "",{"version":3,"sources":["webpack://src/pages/checkout/components/cardForm/styles.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,cAAc;EACd,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,iBAAiB;AACnB;AACA;EACE,uBAAuB;AACzB;AACA;EACE,WAAW;AACb;AACA;EACE,WAAW;EACX,cAAc;EACd,YAAY;EACZ,kBAAkB;AACpB","sourcesContent":[".group {\n  background-color: #eee;\n  padding: 1.5em;\n  border-radius: .4em;\n  margin-bottom: 1em;\n}\n.flexSpacer {\n  display: flex;\n  flex-wrap: nowrap;\n}\n.input {\n  height: 3.5em!important;\n}\n.openpayAssets {\n  width: 100%;\n}\n.openpayLogo {\n  width: 10em;\n  display: block;\n  margin: auto;\n  margin-bottom: 2em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"group": "styles_group__6M4ZO",
	"flexSpacer": "styles_flexSpacer__1vgg3",
	"input": "styles_input__3A5iX",
	"openpayAssets": "styles_openpayAssets__3jpw4",
	"openpayLogo": "styles_openpayLogo__JEJh-"
};
export default ___CSS_LOADER_EXPORT___;
