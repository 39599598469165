/* eslint-disable no-unused-vars */
import { Box, Grid, useMediaQuery } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { func, object } from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import AlternativeButton from 'src/components/alternativeButton';
import Breadcrumb from 'src/components/breadcrumb';
import Container from 'src/components/container';
import Footer from 'src/components/footer';
import Header from 'src/components/header';
import Picture from 'src/components/picture';
import formatPrice from 'src/helpers/formatPrice';
import neededSrc from 'src/assets/needed.svg';
import styles from './styles.module.css';

const MyCartDigitalView = (props) => {
  const isMobile = useMediaQuery('(max-width:700px)');

  return (
    <>
      <Header grayOptions />
      <Breadcrumb values={[{ label: 'TIENDA' }, { label: 'MI CARRITO' }]} />
      <Container small disabledVerticalPadding>
        <div className={styles.contentLimiter}>
          <>
            <div className={styles.listWrapper}>
              <div className={styles.productWrapperHeader}>
                <div className={styles.title}>PRODUCTO</div>
                <div className={styles.productPicture} />
                <div className={styles.quantityWrapper} />
                <div className={styles.price} />
                {/* <div className={styles.price}>PRECIO</div> */}
                <div className={styles.deleteIconWrapper} />
              </div>
              <div className={styles.productWrapper}>
                <Picture height={80} className={styles.productPicture} src={props.data.picture} alt="product" />
                <div className={styles.title}>
                  {!isMobile && (
                    <div>
                      <div>{props.data.title}</div>
                    </div>
                  )}
                  {isMobile && (
                    <>
                      <div>{props.data.title}</div>
                      <div className={styles.quantityMobile} />
                    </>
                  )}
                </div>
                <div className={styles.quantityWrapper} />
                {/* <div className={`${styles.price} ${styles.subprice}`} /> */}
                {/* <div className={styles.price}>${formatPrice(props.data.price)}</div> */}
                {/* <div className={styles.deleteIconWrapper}>
                  <Delete className={styles.iconDelete} onClick={props.onDelete} />
                </div> */}
              </div>
            </div>
            <div className={styles.sumaryWrapper}>
              <img className={styles.neededBar} src={neededSrc} alT="Nedded bar" />
              <div className={styles.sumaryDataWrapper}>
                {/* <div className={styles.summaryDataBox}>
                  <div>
                    <span>Subtotal:</span>${formatPrice(props.data.price)}
                  </div>
                  <div>
                    <span>Inscripción:</span>${formatPrice(props.data.inscription)}
                  </div>
                </div> */}
                <div className={styles.summaryTotalBox}>
                  <div>
                    Pago anual<span>${formatPrice(props.data.price)}</span>mxn
                  </div>
                  <div>
                    Pago semestral<span>${formatPrice(props.data.semesterPrice)}</span>mxn
                  </div>
                  <div>
                    Pago mensual <span>${formatPrice(props.data.monthlyPrice)}</span>mxn
                  </div>
                  <div>
                    Inscripción<span>${formatPrice(props.data.inscription)}</span>mxn
                  </div>
                </div>
              </div>
              <Box fontSize=".8em" textAlign="right" width="100%">
                *En el siguiente paso seleccionarás tu modo de pago.
              </Box>
            </div>
          </>

          <div className={styles.buttonWrapper}>
            <Grid container justify="space-between">
              <div />
              <Link to={{ pathname: '/checkout-digital', state: props.data }}>
                <AlternativeButton id={styles.button} filled>
                  CONTINUAR
                </AlternativeButton>
              </Link>
            </Grid>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
};

MyCartDigitalView.propTypes = {
  data: object.isRequired,
};

export default MyCartDigitalView;
