const limitText = (text, limit = 140) => {
  try {
    if (text.length <= limit) return text;
    return `${text.slice(0, limit)}...`;
  } catch (error) {
    return '';
  }
};

export default limitText;
