import { Box, CircularProgress } from '@material-ui/core';
import { bool } from 'prop-types';
import React from 'react';
import styles from './styles.module.css';

const ViewLoading = ({ isActive }) => {
  if (!isActive) return null;

  return (
    <div className={styles.wrapper}>
      <CircularProgress classes={{ circle: styles.circularProgres }} />
      <Box marginTop="4em" color="#fff" fontWeight="600">
        Cargando...
      </Box>
    </div>
  );
};

ViewLoading.propTypes = {
  isActive: bool.isRequired,
};

export default ViewLoading;
