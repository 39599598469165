import { Box, CircularProgress } from '@material-ui/core';
import { bool, func } from 'prop-types';
import React from 'react';
import Dropzone from 'src/components/dropzone';
import styles from './styles.module.css';

const UploadImplement = ({ onFileSelect, loading }) => (
  <>
    {!loading && (
      <div>
        <Dropzone
          onChange={onFileSelect}
          className={styles.dropzone}
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        >
          Cargar nuevo documento excel
        </Dropzone>
      </div>
    )}
    {loading && (
      <Box display="flex" alignItems="center" minHeight="10em" justifyContent="center">
        <CircularProgress />
      </Box>
    )}
  </>
);

UploadImplement.propTypes = {
  onFileSelect: func.isRequired,
  loading: bool.isRequired,
};

export default UploadImplement;
