export const colors = {
  black: '#333333',
  rose: '#fb5672',
  cyan: '#00eae2',
};

export const categories = {
  sportsPrograms: 'Programas deportivos',
  sportswear: 'Ropa deportiva',
  itemsOnRequest: 'Artículos sobre pedido',
  coursesAndConferences: 'Cursos y conferencias',
  eventsAndActivities: 'Eventos y actividades',
};

export const categoriesForPublishing = {
  // sportsPrograms: 'Programas deportivos',
  sportswear: 'Ropa deportiva',
  itemsOnRequest: 'Artículos sobre pedido',
  coursesAndConferences: 'Cursos y conferencias',
  eventsAndActivities: 'Eventos y actividades',
  others: 'Otros',
};

export const genders = {
  male: 'Hombre',
  female: 'Mujer',
  both: 'Ambos',
};

export const gendersBin = {
  male: 'Hombre',
  female: 'Mujer',
};

const wearSubCategories = {
  set: 'Conjunto',
  sportsShoes: 'Tenis',
  tShirt: 'Playeras',
  pants: 'Pants',
  others: 'Otros',
};

export const subCategoriesByCategory = {
  sportsPrograms: {
    sportsInitiation: 'Iniciasion depostiva',
    triathlon: 'Triatlón',
    individualSport: 'Deporte individual',
    routines: 'Rutinas',
  },
  sportswear: {
    ...wearSubCategories,
  },
  itemsOnRequest: {
    ...wearSubCategories,
  },
};

let allSubcategoriesCalculated = {};
Object.keys(subCategoriesByCategory).forEach((currentKeyname) => {
  allSubcategoriesCalculated = {
    ...allSubcategoriesCalculated,
    ...subCategoriesByCategory[currentKeyname],
  };
});

export const allSubcategories = allSubcategoriesCalculated;

export const contact = {
  email: 'feminnelife@gmail.com',
  facebook: 'https://www.facebook.com/feminne.life',
  instagram: 'https://www.instagram.com/feminne.life/',
  whatsappLink: 'https://api.whatsapp.com/send?phone=5214438572630',
  whatsapp: '(443) 857 2630',
  phone: '4525260852',
  phoneLabel: '(452) 526 0852',
};

export const survey = [
  {
    question: '¿Cuál es tu objetivo de hacer ejercicio?',
    options: [
      'INDICACIÓN MEDICA',
      'Crear hábitos saludables',
      'Perder peso',
      'Mejorar la condición fisica',
      'Mejorar la movilidad',
      'Dormir mejor',
      'Otro',
    ],
  },
  {
    question: '¿Alguna vez practicaste ejercicio o deporte?',
    options: ['Si', 'No', 'En la infancia', 'En la adolescencia', 'Una vez lo intente', 'Otro'],
  },
  {
    question: '¿Anteriormente intentaste hacer ejercicio?',
    options: ['Si', 'No'],
  },
  {
    question: '¿Cuando intentas hacer ejercicio que te ha hecho desistir ?',
    options: [
      'No me gusta',
      'No soy disciplinado',
      'Los ejercicios son difíciles',
      'Los ejercicios no son adecuados para mi',
      'Otro ',
    ],
  },
  {
    question: '¿Estas dispuesto a invertir 80 minutos diarios durante seis días de la semana?',
    options: ['si', 'no'],
  },
  {
    question: '¿Cómo te enteraste de nosotros?',
    options: ['Por recomendación', 'Facebook', 'Instagram', 'Página web', 'Otro'],
  },
];

export const payStatus = {
  approved: 'Pagado',
  pending: 'Pendiente',
};

export const methodPay = {
  paypal: 'Paypal',
  card: 'Tarjeta',
  store: 'Deposito en tienda',
  spei: 'Transferencia electronica SPEI',
  manual: 'Pago en efectivo o transferencia',
};

export const formsPay = {
  price: 'Una sola exhibición',
  semesterPrice: 'Semestral',
  monthlyPrice: 'Mensual',
};

export const videos = {
  home: 'Q_sS3uuRe2s',
  privateHome: 'hVflqeYISeQ',
};

export const TipsCategory = {
  training: 'Entrenamiento',
  nutrition: 'Nutrición',
  strong: 'Fuerza',
  performance: 'Rendimiento',
};

export const transferData = {
  fullName: 'IMELDA CABELLO LARIOS',
  clabe: '002528015378190531',
  account: '1537819053',
  sucursal: '153',
  bankName: 'Banamex',
};

export const defaultShippingCost = 0;
