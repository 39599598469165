import { ButtonBase } from '@material-ui/core';
import { number, element, oneOfType, string, bool } from 'prop-types';
import React from 'react';
import styles from './styles.module.css';

const AlternativeButton = ({ className, filled, ...props }) => {
  const composeStyle = `${styles.button} ${filled ? styles.filled : ''}`;

  return (
    <ButtonBase className={`${composeStyle} ${className}`} {...props}>
      {props.children}
    </ButtonBase>
  );
};

AlternativeButton.defaultProps = {
  className: '',
  filled: false,
};

AlternativeButton.propTypes = {
  children: oneOfType([string, element, number]).isRequired,
  className: string,
  filled: bool,
};

export default AlternativeButton;
