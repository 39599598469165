import { Box, ButtonBase, Hidden } from '@material-ui/core';
import { any, bool, func, number } from 'prop-types';
import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import facebookSrc from 'src/assets/facebook.svg';
import instagramSrc from 'src/assets/instagram.svg';
import bagSrc from 'src/assets/bag.svg';
import loginSrc from 'src/assets/login.svg';
import logoSrc from 'src/assets/logo.svg';
import MenuIcon from '@material-ui/icons/Menu';
import { colors, contact } from '../../../../constants';
import Container from '../../../container';
import styles from './styles.module.css';
import AlternativeButton from '../../../alternativeButton';

const HeaderDesktop = (props) => {
  const computedOptionsClassNames = props.grayOptions ? `${styles.option} ${styles.optionGray}` : styles.option;

  return (
    <header className={styles.header} style={props.getinlineStyle()} ref={props.headerRef} id="header">
      <Container disabledVerticalPadding>
        <div className={styles.colorLine} />
        <div className={styles.top}>
          <div className={styles.genericFlex}>
            <a href={contact.instagram} target="_blank" rel="noreferrer">
              <img src={instagramSrc} className={styles.icon} alt="icon" />
            </a>
            <a href={contact.facebook} target="_blank" rel="noreferrer">
              <img src={facebookSrc} className={styles.icon} alt="icon" />
            </a>
            <div className={styles.divider} />
            <Box fontSize="1.1em" color={colors.rose}>
              {contact.email}
            </Box>
          </div>

          {/* RIGHT OPTIONS */}
          <div className={styles.genericFlex}>
            <div className={styles.bagWrapper}>
              {!!props.numberItemsOnCart && <div className={styles.itemsOnCartCounter}>{props.numberItemsOnCart}</div>}

              <Link to="/mi-carrito">
                <img src={bagSrc} className={styles.icon} alt="icon" />
              </Link>
            </div>
            <div className={styles.divider} />
            <Link to="/auth/signin" className={styles.loginLink}>
              <img src={loginSrc} className={styles.icon} alt="icon" />
              <Box fontSize="1.1em" color={colors.rose} fontWeight="800">
                LOG IN
              </Box>
            </Link>
          </div>
        </div>
        <div className={styles.bottom}>
          <Link to="/home">
            <img className={styles.logo} src={logoSrc} alt="logo" />
          </Link>
          <Hidden smDown>
            <div className={styles.menuList}>
              <Link className={computedOptionsClassNames} to="/programa-de-entrenamiento">
                PROGRAMAS
              </Link>
              <Link className={computedOptionsClassNames} to="/articulos">
                TIENDA
              </Link>
              <Link className={computedOptionsClassNames} to="/events-and-activities">
                EVENTOS Y ACTIVIDADES
              </Link>
              <Link className={computedOptionsClassNames} to="/tips">
                TIPS
              </Link>
              <Link className={computedOptionsClassNames} to="/about" smooth>
                QUÉ ES FEMINNE LIFE
              </Link>
            </div>
          </Hidden>
          <div className={styles.action}>
            <ButtonBase className={styles.genericFlex} onClick={props.onMenuOpen}>
              <Box fontSize="1.1em" color="white" fontWeight="600">
                MENU
              </Box>
              <MenuIcon className={styles.menuIcon} />
            </ButtonBase>
            <Link to={{ pathname: '/articulos', state: { category: 'sportsPrograms' } }}>
              <AlternativeButton filled id={styles.buttonCustom}>
                COMPRAR AHORA
              </AlternativeButton>
            </Link>
          </div>
        </div>
      </Container>
    </header>
  );
};

HeaderDesktop.defaultProps = {
  grayOptions: false,
  numberItemsOnCart: 0,
};

HeaderDesktop.propTypes = {
  getinlineStyle: func.isRequired,
  headerRef: any.isRequired,
  onMenuOpen: func.isRequired,
  grayOptions: bool,
  numberItemsOnCart: number,
};

export default HeaderDesktop;
