/* eslint-disable no-param-reassign */
import { success } from '../../helpers/response';
import database from '../database';
import { getPurchaseCounter } from './counters';

export const savePurchase = async (data) => {
  const purchaseId = await getPurchaseCounter();
  const userInfo = { ...data.userInfo };
  delete userInfo.cardCode;
  delete userInfo.cardMonth;
  delete userInfo.cardName;
  delete userInfo.cardNumber;
  delete userInfo.cardYear;

  const response = await database.create('purchases', { ...data, userInfo }, purchaseId);
  return success(null, { id: response.id });
};

export const getListPaginated = () => {
  const fetcher = database.getList('purchases', 15, ['createdAt', 'desc'], null);
  return fetcher;
};

export const getList = async () => {
  const items = await database.getList('purchases', 1000, ['createdAt', 'desc'], null).next();
  return items;
};

export const getSingle = async (purchaseId) => {
  const data = await database.getDocument(`/purchases/${purchaseId}`);
  return data;
};

export const updateStatus = async (id, status) => {
  await database.update(`purchases/${id}`, { status });
  return success('Estado actualizado correctamente');
};

export const setAccountActivated = async (id) => {
  await database.update(`purchases/${id}`, { accountActivated: true });
  return success();
};

export const deletePurchase = async (purchaseId) => {
  await database.remove(`purchases/${purchaseId}`);
  return success('Registro eliminado correctamente');
};

const purchaseModel = {
  savePurchase,
  getListPaginated,
  getList,
  getSingle,
  updateStatus,
};

export default purchaseModel;
