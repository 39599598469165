// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_button__RWU8K {\r\n  border-style: solid!important;\r\n  border-width: 2px!important;\r\n  border-image: linear-gradient(90deg, var(--rose), var(--cyan)) 1!important;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n}", "",{"version":3,"sources":["webpack://src/components/button/styles.module.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,2BAA2B;EAC3B,0EAA0E;EAC1E,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB","sourcesContent":[".button {\r\n  border-style: solid!important;\r\n  border-width: 2px!important;\r\n  border-image: linear-gradient(90deg, var(--rose), var(--cyan)) 1!important;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "styles_button__RWU8K"
};
export default ___CSS_LOADER_EXPORT___;
