import { firebase, firestore } from 'src/core/firebase';
import database from '../database';
import snapShotParser from '../snapshotParser';
import { uploadPicture } from '../storage';

export const getChatId = async (advicerId, userId) => {
  let result = await database
    .getList('chats', null, null, [
      ['userId', '==', userId],
      ['advicerId', '==', advicerId],
    ])
    .next();

  // create if not exist;
  if (!result.length) {
    await database.create('chats', { advicerId, userId, content: [], advicerPending: 0, userPending: 0 });
    result = await database
      .getList('chats', null, null, [
        ['userId', '==', userId],
        ['advicerId', '==', advicerId],
      ])
      .next();
  }
  return result[0].id;
};

/**
 * create a new message
 * @param {String} chatId
 * @param {String} userId
 * @param {String|File} content
 * @param {('user'|'advicer')} notific set notification to user or advicer
 */
export const newMessage = async (chatId, userId, content, notific = null) => {
  const messageSchema = {
    userId,
    date: new Date(),
  };

  if (content instanceof File) {
    const url = await uploadPicture(content, `chats/${chatId}`);
    messageSchema.type = 'picture';
    messageSchema.content = url;
  } else {
    messageSchema.type = 'text';
    messageSchema.content = content;
  }

  await database.update(`chats/${chatId}`, {
    content: firebase.firestore.FieldValue.arrayUnion(messageSchema),
  });

  if (notific === 'advicer') {
    await database.update(`chats/${chatId}`, {
      advicerPending: firebase.firestore.FieldValue.increment(1),
    });
  }

  if (notific === 'user') {
    await database.update(`chats/${chatId}`, {
      userPending: firebase.firestore.FieldValue.increment(1),
    });
  }
};

/**
 * set notifications as viewed
 * @param {String} chatId
 * @param {('user'|'advicer')} userType
 */
export const setNotificationsViewed = async (chatId, userType) => {
  const { advicerPending, userPending } = await database.getDocument(`chats/${chatId}`);

  if (userType === 'advicer' && advicerPending) {
    await database.update(`chats/${chatId}`, {
      advicerPending: 0,
    });
  }
  if (userType === 'user' && userPending) {
    await database.update(`chats/${chatId}`, {
      userPending: 0,
    });
  }
};

export const onMessagesChange = (chatId, handler) => {
  const unsubscribe = firestore.doc(`chats/${chatId}`).onSnapshot((snap) => {
    try {
      const { content, advicerPending = 0, userPending = 0 } = snapShotParser(snap);
      handler(content, advicerPending, userPending);
    } catch (error) {
      handler([], 0, 0);
    }
  });
  return unsubscribe;
};

const chatModel = {
  getChatId,
  newMessage,
  onMessagesChange,
};

export default chatModel;
