import { Grid, Hidden, useMediaQuery } from '@material-ui/core';
import { WhatsApp } from '@material-ui/icons';
import React, { useState } from 'react';
import cover1 from 'src/assets/cover1.jpg';
import cover1M from 'src/assets/headerMobile.jpg';
import Button from 'src/components/button';
import Container from 'src/components/container';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { Link } from 'react-router-dom';
import { contact } from 'src/constants';
import styles from './styles.module.css';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const Cover = () => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const [activeStep, setActiveStep] = useState(0);

  return (
    <div className={styles.wrapper}>
      <AutoPlaySwipeableViews
        index={activeStep}
        onChangeIndex={setActiveStep}
        enableMouseEvents
        disableLazyLoading
        interval={13000}
        autoPlay={false}
      >
        <div className={styles.coverWrapper} style={{ backgroundImage: `url(${!isMobile ? cover1 : cover1M})` }}>
          <Container className={styles.container}>
            <Grid container justify="flex-start">
              <div className={styles.dataWrapper}>
                <h1>
                  POR UN ESTILO DE <br />
                  VIDA MÁS <br />
                  SALUDABLE.
                </h1>
                <h2>
                  Tenemos un programa especial para ti que aun sigues
                  <Hidden mdDown>
                    <br />
                  </Hidden>
                  inactiva físicamente
                </h2>
                <Link to={{ pathname: '/articulos', state: { category: 'sportsPrograms' } }}>
                  <Button fontWeight="600" fontSize="1em" padding="1.3em 2em" className={styles.button}>
                    COMPRAR AHORA
                  </Button>
                </Link>
              </div>
            </Grid>
          </Container>
        </div>
      </AutoPlaySwipeableViews>
      <a href={contact.whatsappLink} className={styles.whatsAppLink}>
        <WhatsApp />
      </a>
    </div>
  );
};

export default Cover;
