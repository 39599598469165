import React, { useState } from 'react';
import useEventsList from '../../hooks/useEventsList';
import EventsAndActivitiesView from './view';

const EventAndActivities = () => {
  const { loading, events } = useEventsList();
  const [currentView, setCurrentView] = useState('next'); // next || past

  // aplyFilters
  let eventsFiltered = [...events];
  const date = new Date();
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);

  if (currentView === 'past') eventsFiltered = eventsFiltered.filter((currentItem) => currentItem.date < date);
  if (currentView === 'next') eventsFiltered = eventsFiltered.filter((currentItem) => currentItem.date >= date);

  return (
    <EventsAndActivitiesView
      loading={loading}
      items={eventsFiltered}
      currentView={currentView}
      onViewChange={setCurrentView}
    />
  );
};

export default EventAndActivities;
