import firebase from 'firebase/app';
import { firebaseConfig, openPayCredential, paypalClientId } from 'src/runtime.config';
import { loadScript } from '@paypal/paypal-js';

firebase.initializeApp(firebaseConfig);

// load paypla scritp
loadScript({
  'client-id': paypalClientId,
  'disable-funding': 'credit,card',
  components: 'marks,buttons',
  currency: 'MXN',
})
  .then((paypal) => {
    window.paypal = paypal;
  })
  .catch(() => console.error('IMPORTANT PAYPAL ERROR'));

// openpay crendetials
try {
  window.OpenPay.setId(openPayCredential.id);
  window.OpenPay.setApiKey(openPayCredential.publickKey);
  window.OpenPay.setSandboxMode(true);
} catch (error) {
  console.error('error', error);
}
