import { Close } from '@material-ui/icons';
import { func } from 'prop-types';
import React from 'react';
import TrainingDetailCard from '../../../../../components/trainingDailyCard';
import styles from './styles.module.css';

const TraiiningDetail = ({ onClose, ...props }) => (
  <div className={styles.wrapper}>
    <div className={styles.body}>
      <Close className={styles.close} onClick={onClose} />
      <div className={styles.bodyLimmited}>
        <TrainingDetailCard {...props} />
      </div>
    </div>
  </div>
);

TraiiningDetail.propTypes = {
  onClose: func.isRequired,
};

export default TraiiningDetail;
