/**
 * get url preview if is a file
 * @param {Object||String} fileOrUrl
 * @returns {String} url preview
 */
const normalizeImagesUrl = (fileOrUrl) => {
  if (typeof fileOrUrl === 'string') return fileOrUrl;
  return URL.createObjectURL(fileOrUrl);
};

export default normalizeImagesUrl;
