import { survey } from 'src/constants';
import { success } from '../../helpers/response';
import database from '../database';

export const completeSurvey = async (userId, values) => {
  const surveyParsed = survey.map((currentSurvey, index) => {
    const answer = values[`question_${index}`];
    return { question: currentSurvey.question, answer };
  });
  await database.update(`/users/${userId}`, { survey: surveyParsed, surveyAnswered: true });
  return success('Perfil actualizado correctamente');
};

export const getUser = async (userId) => {
  const userData = await database.getDocument(`users/${userId}`);
  delete userData.password;
  return userData;
};

export default {
  completeSurvey,
  getSingle: getUser,
};
