import { useHistory } from 'react-router-dom';
import setPartialState from 'src/helpers/setPartialState';
import { useState } from 'react';
import programModel from '../core/models/program';
import useAlert from '../modules/alert/useAlert';
import useNotification from '../modules/notifications/useNotification';
import useFetch from './useFetch';

const useImplementList = (programId) => {
  const history = useHistory();
  const setAlert = useAlert();
  const setNotification = useNotification();
  const [state, setState] = useState({
    loading: true,
    items: [],
  });

  const setComposeState = setPartialState(setState);

  const handleFetch = async () => {
    setComposeState({ loading: true });
    const response = await programModel.getImplementsList(programId);
    setComposeState({ loading: false, items: response });
  };

  /**
   * get availabele options for item
   * @param {Object} item curent item
   * @returns list options
   */
  const handleGetOptions = (item) => {
    const options = [
      {
        label: 'Visualizar programa',
        handler: () => {
          const search = `?programId=${programId}&implementId=${item.id}`;
          history.push({ pathname: '/dashboard/training-view', search });
        },
      },
      {
        label: 'Visualizar tutoriales',
        handler: () => {
          const search = `?programId=${programId}&implementId=${item.id}`;
          history.push({ pathname: '/dashboard/tutorials-by-implement', search });
        },
      },
      {
        label: 'Eliminar',
        handler: () =>
          setAlert({
            title: '¿Seguro quieres eliminar esta implementacion?',
            action: async () => {
              const response = await programModel.deleteImplement(programId, item.id);
              if (response.success) await handleFetch();
              setNotification(response);
            },
          }),
      },
      {
        label: 'Descargar archivo',
        handler: () => {
          const link = document.createElement('a');
          link.download = `implement ${item.id}`;
          link.href = item.urlFile;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        },
      },
    ];
    if (!item.isActive)
      options.push({
        label: 'Activar',
        handler: () =>
          setAlert({
            title: '¿Seguro quieres activar esta implementacion?',
            action: async () => {
              const response = await programModel.activeImplement(programId, item.id);
              await handleFetch();
              setNotification(response);
            },
          }),
      });
    return options;
  };

  useFetch(handleFetch);

  return {
    items: state.items,
    loading: state.loading,
    refresh: handleFetch,
    getOptions: handleGetOptions,
  };
};

export default useImplementList;
