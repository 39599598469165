import { Box } from '@material-ui/core';
import React, { useState } from 'react';
import userSrc from 'src/assets/user.png';
import { getMemebers } from '../../../../core/models/groupChat';
import useFetch from '../../../../hooks/useFetch';
import styles from './styles.module.css';

const Members = () => {
  const [members, setMembers] = useState([]);

  useFetch(async () => {
    const membersResponse = await getMemebers();
    setMembers(membersResponse);
  });

  return (
    <>
      <div className={styles.titleWrapper}>
        <Box fontSize="1.3em" textAlign="center" fontWeight="600">
          Miembros
        </Box>
      </div>

      <div className={styles.members}>
        {members.map((member) => (
          <div className={styles.memberRow}>
            <img src={member.picture || userSrc} alt="avatar" className={styles.userAvatar} />
            <div className={styles.userName}>{member.name}</div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Members;
