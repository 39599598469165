// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_wrapper__3RMZr {\r\n  position: fixed;\r\n  width: 17em;\r\n  max-width: 80%;\r\n  z-index: 1;\r\n  bottom: 1em;\r\n  left: 1em;\r\n  padding: 1em;\r\n  border-radius: .5em;\r\n  color: #fff;\r\n  line-height: 1.3em;\r\n  display: flex;\r\n  align-items: center;\r\n  min-height: 2em;\r\n}\r\n\r\n.styles_success__141YQ {\r\n  background-color: var(--cyan);\r\n}\r\n\r\n.styles_error__3Gyp_ {\r\n  background-color: #ef4747;\r\n}\r\n\r\n\r\n@media screen and (max-width:600px) {\r\n  .styles_wrapper__3RMZr {\r\n    right: 0em;\r\n    left: 0em;\r\n    margin: auto;\r\n    font-size: .9em;\r\n  }\r\n}", "",{"version":3,"sources":["webpack://src/modules/notifications/styles.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,WAAW;EACX,cAAc;EACd,UAAU;EACV,WAAW;EACX,SAAS;EACT,YAAY;EACZ,mBAAmB;EACnB,WAAW;EACX,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,yBAAyB;AAC3B;;;AAGA;EACE;IACE,UAAU;IACV,SAAS;IACT,YAAY;IACZ,eAAe;EACjB;AACF","sourcesContent":[".wrapper {\r\n  position: fixed;\r\n  width: 17em;\r\n  max-width: 80%;\r\n  z-index: 1;\r\n  bottom: 1em;\r\n  left: 1em;\r\n  padding: 1em;\r\n  border-radius: .5em;\r\n  color: #fff;\r\n  line-height: 1.3em;\r\n  display: flex;\r\n  align-items: center;\r\n  min-height: 2em;\r\n}\r\n\r\n.success {\r\n  background-color: var(--cyan);\r\n}\r\n\r\n.error {\r\n  background-color: #ef4747;\r\n}\r\n\r\n\r\n@media screen and (max-width:600px) {\r\n  .wrapper {\r\n    right: 0em;\r\n    left: 0em;\r\n    margin: auto;\r\n    font-size: .9em;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "styles_wrapper__3RMZr",
	"success": "styles_success__141YQ",
	"error": "styles_error__3Gyp_"
};
export default ___CSS_LOADER_EXPORT___;
