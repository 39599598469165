import { Modal } from '@material-ui/core';
import { bool, func } from 'prop-types';
import { HashLink as Link } from 'react-router-hash-link';
import React from 'react';
import facebookSrc from 'src/assets/facebook.svg';
import instagramSrc from 'src/assets/instagram.svg';
import logoSrc from 'src/assets/logo.svg';
import { contact } from 'src/constants';
import { Close } from '@material-ui/icons';
import styles from './styles.module.css';
import Container from '../../components/container';

const ViewMenu = ({ open, onClose }) => (
  <Modal open={open} className={styles.modal}>
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <Container>
          <div className={styles.headerBody}>
            <img className={styles.logo} src={logoSrc} alt="logo" />
            <Close className={styles.close} onClick={onClose} />
          </div>
        </Container>
      </div>

      <div className={styles.body}>
        <Link className={styles.link} to="/programa-de-entrenamiento">
          PROGRAMAS
        </Link>
        <Link className={styles.link} to="/articulos">
          TIENDA
        </Link>
        <Link className={styles.link} to="/events-and-activities">
          EVENTOS Y ACTIVIDADES
        </Link>
        <Link className={styles.link} to="/tips">
          TIPS
        </Link>
        <Link className={styles.link} to="/about" smooth>
          QUÉ ES FEMINNE LIFE
        </Link>
        <Link className={styles.link} to="/asesores">
          ASESORES
        </Link>
        <Link className={styles.link} to="/comunidad">
          COMUNIDAD
        </Link>
        <Link className={styles.link} to="/">
          CLÍNICAS Y CONFERENCIAS
        </Link>
        <Link className={styles.link} to="/contacto">
          CONTACTO
        </Link>
        <div className={styles.divider} />
        <div className={styles.iconWrapper}>
          <a href={contact.instagram} target="_blank" rel="noreferrer">
            <img src={instagramSrc} className={styles.icon} alt="icon" />
          </a>
          <a href={contact.facebook} target="_blank" rel="noreferrer">
            <img src={facebookSrc} className={styles.icon} alt="icon" />
          </a>
        </div>
        <a className={styles.email} href={`mailto:${contact.email}`}>
          {contact.email}
        </a>
      </div>
    </div>
  </Modal>
);

ViewMenu.propTypes = {
  open: bool.isRequired,
  onClose: func.isRequired,
};

export default ViewMenu;
