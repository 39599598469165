import PropTypes, { any, func, number } from 'prop-types';
import { Search } from '@material-ui/icons';
import logoSrc from 'src/assets/logo.svg';
import bagSrc from 'src/assets/bag.svg';
import logInSrc from 'src/assets/login.svg';
import logOutSrc from 'src/assets/private/logout.svg';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Container from 'src/components/container';
import Input from 'src/components/inputs';
import styles from './styles.module.css';
import Notifications from '../notifications';

const PrivateDesktop = (props) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const [search, setSearch] = useState('');

  const handleInputChange = (event) => {
    setSearch(event.target.value);
  };

  const handleRedirectAndAplySearch = (event) => {
    if (event && event.preventDefault) event.preventDefault();
    setSearch('');
    if (pathname === '/articulos') history.replace({ pathname: '/articulos', state: { search } });
    else history.push({ pathname: '/articulos', state: { search } });
  };

  // autosearch when stop typing
  useEffect(() => {
    const timerId = setTimeout(() => {
      if (search) handleRedirectAndAplySearch();
    }, 2000);
    return () => clearTimeout(timerId);
  }, [search, pathname]);

  const handleKeyPress = (event) => {
    if (event.which === 13 && !event.shiftKey) {
      handleRedirectAndAplySearch();
      event.preventDefault();
    }
  };

  return (
    <div
      className={styles.fullWidthWrapper}
      style={props.getinlineStyle ? props.getinlineStyle() : {}}
      ref={props.headerRef}
      id="header"
    >
      <Container disabledVerticalPadding>
        <div className={styles.colorLine} />
        <div className={styles.body}>
          <div className={styles.primary}>
            <div className={styles.logoWrapper}>
              <img src={logoSrc} className={styles.logo} alt="logo" />
            </div>

            <div className={styles.profileWrapper}>
              <img className={styles.profilePicture} alt="profile" src={props.photo} />
              <div className={styles.profileName}>
                <span>Hola</span>
                {props.name}
              </div>
            </div>

            <div className={styles.actionsWrapper}>
              <Link to="/mi-carrito" className={styles.link}>
                {!!props.numItemsOnCart && <div className={styles.itemsOnCartCounter}>{props.numItemsOnCart}</div>}
                <img src={bagSrc} className={styles.icon} alt="icon" />
              </Link>
              <div className={styles.divider} />
              <Notifications className={styles.icon} />
              <div className={styles.divider} />
              <Link to="/private/my-profile">
                <img src={logInSrc} className={styles.icon} alt="icon" />
              </Link>
              <div className={styles.divider} />
              <div className={styles.logoutWrapper} onClick={props.logOut} role="button">
                <img className={styles.logOutImage} alt="icon" src={logOutSrc} />
                SALIR
              </div>
            </div>
          </div>

          <div className={styles.secondary}>
            <div className={styles.optionsWrapper}>
              <Link className={styles.option} to="/private/training">
                PROGRAMA DE ENTRENAMIENTO
              </Link>
              <Link className={styles.option} to="/articulos">
                TIENDA
              </Link>
              <Link className={styles.option} to="/private/tutorials">
                TUTORIALES
              </Link>
              <Link className={styles.option} to="/events-and-activities">
                EVENTOS Y ACTIVIDADES
              </Link>
              <Link className={styles.option} to="/group-chat">
                CHAT
              </Link>
            </div>
            <div className={styles.searcherWrapper}>
              <Search className={styles.searchIcon} onClick={handleRedirectAndAplySearch} />
              <Input
                placeholder="Buscar"
                className={styles.input}
                onChange={handleInputChange}
                value={search}
                onKeyPress={handleKeyPress}
              />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

PrivateDesktop.defaultProps = {
  getinlineStyle: null,
  headerRef: null,
};

PrivateDesktop.propTypes = {
  logOut: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  photo: PropTypes.string.isRequired,
  numItemsOnCart: number.isRequired,
  getinlineStyle: func,
  headerRef: any,
};

export default PrivateDesktop;
