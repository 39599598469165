import { Fade } from '@material-ui/core';
import { string } from 'prop-types';
import React from 'react';
import styles from './styles.module.css';

const ViewNotifications = ({ status, message }) => {
  const styleCOmputed =
    status === 'success' ? `${styles.wrapper} ${styles.success}` : `${styles.wrapper} ${styles.error}`;

  return (
    <Fade in={!!status && !!message}>
      <div className={styleCOmputed}>{message}</div>
    </Fade>
  );
};

ViewNotifications.defaultProps = {
  status: '',
  message: '',
};

ViewNotifications.propTypes = {
  status: string,
  message: string,
};

export default ViewNotifications;
