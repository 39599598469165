import { Box } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { array, bool, func, number, object, string } from 'prop-types';
import React, { useEffect } from 'react';
import AlternativeButton from 'src/components/alternativeButton';
import Picture from 'src/components/picture';
import formatPrice from 'src/helpers/formatPrice';
import styles from './styles.module.css';

const Summary = (props) => {
  useEffect(() => {
    if (props.renderPaypal) {
      const target = document.getElementById('paypal_tarjet');
      if (target) {
        target.innerHTML = '';
        window.paypal.Buttons(props.paypalConfig).render('#paypal_tarjet');
      }
    }
  }, [props.renderPaypal, props.paypalConfig]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.group}>
        {props.items.map((item) => (
          <div className={styles.productWrapper} key={item.id}>
            <div className={styles.imageProdutWRapper}>
              <Picture height={100} className={styles.imageProdut} src={item.picture} />
            </div>
            <div className={styles.title}>{item.title}</div>
            <div className={styles.price}>${formatPrice(item.price * item.quantity)}</div>
            <Delete className={styles.delete} onClick={() => props.onDelete(item)} />
          </div>
        ))}
      </div>
      <div className={styles.group}>
        <div className={styles.genericFlex}>
          <div className={`${styles.genericTitle} ${styles.gray}`}>Subtotal</div>
          <div className={`${styles.genericTitle}`}>
            <Box fontSize="1.1em">${formatPrice(props.subTotal)}</Box>
          </div>
        </div>
        <div className={styles.genericFlex}>
          <div className={`${styles.genericTitle} ${styles.gray}`}>Envío</div>
          <div className={`${styles.genericTitle}`}>
            <Box fontSize="1.1em">${formatPrice(props.shippingCost)}</Box>
          </div>
        </div>
      </div>
      <div className={styles.group}>
        <div className={styles.genericFlex}>
          <div className={`${styles.genericTitle} ${styles.gray}`}>Total</div>
          <div className={`${styles.genericTitle}`}>
            <Box fontSize="1.4em">${formatPrice(props.total)}</Box>
          </div>
        </div>
      </div>
      <div className={styles.group}>
        <div className={styles.genericFlex}>
          <Box width="100%">
            <AlternativeButton className={styles.buttton} onClick={props.onBack}>
              Regresar
            </AlternativeButton>
          </Box>
          <Box paddingRight="1.5em" />
          <div className={styles.buttonPaypalWrapper}>
            {props.renderPaypal && <div className={styles.paypalTarget} id="paypal_tarjet" />}
            <AlternativeButton className={styles.buttton} filled onClick={props.onAcept}>
              {props.buttonText}
            </AlternativeButton>
          </div>
        </div>
      </div>
    </div>
  );
};

Summary.propTypes = {
  items: array.isRequired,
  total: number.isRequired,
  subTotal: number.isRequired,
  shippingCost: number.isRequired,
  onDelete: func.isRequired,
  onAcept: func.isRequired,
  onBack: func.isRequired,
  paypalConfig: object.isRequired,
  renderPaypal: bool.isRequired,
  buttonText: string.isRequired,
};

export default Summary;
