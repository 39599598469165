import { Box, Grid } from '@material-ui/core';
import React, { useState } from 'react';
import Card from 'src/components/card';
import Stepper from 'src/components/stepper';
import useForm from 'src/hooks/useForm';
import storage, { uploadPicture } from 'src/core/storage';
import { useHistory, useLocation } from 'react-router';
import useErrorMessage from 'src/hooks/useErrorMessage';
import useNotification from 'src/modules/notifications/useNotification';
import ButtonAdmin from '../../components/button';
import Container from '../../components/container';
import GeneralInfo from './components/generalInfo';
import styles from './styles.module.css';
import rules from './inputRules';
import Multimedia from './components/multimedia';
import Loading from './components/Loading';
import computeInitialFormState from './computeInitialFormState';
import { createEvent, updateEvent } from '../../../../core/models/events';

function CreateEvent() {
  const setNotification = useNotification();
  const history = useHistory();
  const { state } = useLocation();
  const { errorMessage, setErrorMessage } = useErrorMessage();
  const [currentStep, setStep] = useState(0); // 0 generalInfo, 1 multimedia, 2 loading
  const { getInputProps, validateInputs, setValues, values } = useForm(computeInitialFormState(state));

  const handlePictureSelect = (picture) => {
    setValues((prevVals) => ({ ...prevVals, picture }));
  };

  const handlePicturesSelect = (pictures) => {
    setValues((prevVals) => ({
      ...prevVals,
      pictures: [...prevVals.pictures, ...pictures],
    }));
  };

  const handlePictureDelet = () => setValues((prevVals) => ({ ...prevVals, picture: null }));

  const handlePicturesDelete = (picture) => {
    setValues((prevValues) => ({
      ...prevValues,
      pictures: prevValues.pictures.filter((img) => img !== picture),
    }));
  };

  const handleValidateGeneralInfo = () => {
    const { firstErrorMessage } = validateInputs(rules.generalInfoRules);
    if (!firstErrorMessage) return true;
    setErrorMessage(firstErrorMessage);
    return false;
  };

  const handleValidateMultimedia = () => {
    const { firstErrorMessage } = validateInputs(rules.multimediaRules);
    if (!firstErrorMessage) return true;
    setErrorMessage(firstErrorMessage);
    return false;
  };

  const handleUpload = async () => {
    setStep((prevStep) => prevStep + 1);
    const { data } = await createEvent(values);
    const primaryUrl = await storage.uploadPicture(values.picture, `events/${data.id}`);
    const picturesUrl = await storage.uploadMultiplePicture(values.pictures, `events/${data.id}`);
    await updateEvent(data.id, {
      picture: primaryUrl,
      pictures: picturesUrl,
    });
    history.push('/dashboard/events');
    setNotification({
      status: 'success',
      message: 'Evento publicado correctamente',
    });
  };

  const handleUpdate = async () => {
    setStep((prevStep) => prevStep + 1);

    // handle primary picture
    let pictureUrl = values.picture;
    if (typeof pictureUrl !== 'string') {
      await storage.deletePicture(state.picture);
      pictureUrl = await uploadPicture(values.picture, `events/${state.id}`);
    }

    // handle other pictures
    const urlsToDelete = state.pictures.filter((currentPicture) => !values.pictures.includes(currentPicture));
    await Promise.all(urlsToDelete.map((d) => storage.deletePicture(d)));
    const picsToUpload = values.pictures.filter((currentPic) => typeof currentPic !== 'string');
    const newPicturesUrl = await storage.uploadMultiplePicture(picsToUpload, `events/${state.id}`);
    const composedPicturesUrl = [...values.pictures.filter((p) => typeof p === 'string'), ...newPicturesUrl];
    await updateEvent(state.id, {
      ...values,
      picture: pictureUrl,
      pictures: composedPicturesUrl,
    });
    history.push('/dashboard/events');
    setNotification({
      status: 'success',
      message: 'Evento actualizado correctamente',
    });
  };

  const handleAcept = () => {
    if (currentStep === 0 && handleValidateGeneralInfo()) setStep(1); // show multimedia
    if (currentStep === 1 && handleValidateMultimedia() && state) handleUpdate();
    if (currentStep === 1 && handleValidateMultimedia() && !state) handleUpload();
  };

  const handleBack = () => setStep((prevStep) => prevStep - 1);

  return (
    <Container className={styles.container}>
      <Card>
        <Stepper steps={['Datos generales', 'Multimedia']} activeStep={currentStep} />

        {currentStep === 0 && <GeneralInfo getInputProps={getInputProps} errorMessage={errorMessage} />}
        {currentStep === 1 && (
          <Multimedia
            errorMessage={errorMessage}
            onPicturesDelete={handlePicturesDelete}
            onPictureDelete={handlePictureDelet}
            pictures={values.pictures}
            picture={values.picture}
            onPictureSelect={handlePictureSelect}
            onPicturesSelect={handlePicturesSelect}
          />
        )}

        {currentStep === 2 && <Loading />}

        {currentStep <= 1 && (
          <Box marginTop="3em">
            <Grid container justify="flex-end">
              {!!currentStep && <ButtonAdmin onClick={handleBack}>Volver</ButtonAdmin>}
              <Box mr="1em" />
              <ButtonAdmin primary onClick={handleAcept}>
                {currentStep < 2 ? 'Siguente' : 'Guardar'}
              </ButtonAdmin>
            </Grid>
          </Box>
        )}
      </Card>
    </Container>
  );
}

export default CreateEvent;
