/* eslint-disable no-unused-vars */
import { Box, CircularProgress, createMuiTheme, Grid, ThemeProvider } from '@material-ui/core';
import { Close, Delete } from '@material-ui/icons';
import { func, string } from 'prop-types';
import React, { useState } from 'react';
import ErrorMessage from '../../../../../../components/errorMessage';
import Input from '../../../../../../components/inputs';
import {
  addBodyMeasurements,
  deleteBodyMeasurements,
  getHistoryBodyMeasurements,
} from '../../../../../../core/models/programUsers';
import { toLargeStringDate } from '../../../../../../helpers/dates';
import validaotors from '../../../../../../helpers/getValidators';
import useErrorMessage from '../../../../../../hooks/useErrorMessage';
import useFetch from '../../../../../../hooks/useFetch';
import useForm from '../../../../../../hooks/useForm';
import useAlert from '../../../../../../modules/alert/useAlert';
import useNotification from '../../../../../../modules/notifications/useNotification';
import { useSession } from '../../../../../../modules/session';
import ButtonAdmin from '../../../../components/button';
import styles from './styles.module.css';

const theme = createMuiTheme({
  spacing: (val) => `${val}em`,
});

const BodyMeasurementsModal = (props) => {
  const session = useSession();
  const { getInputProps, validateInputs, values, setValues } = useForm();
  const [isFormActive, setFormActive] = useState(false);
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);
  const { errorMessage, setErrorMessage } = useErrorMessage();
  const setNotification = useNotification();
  const alert = useAlert();

  const handleFetch = async () => {
    setLoading(true);
    const response = await getHistoryBodyMeasurements(props.userId);
    setItems(response);
    setLoading(false);
  };

  const handleSave = async () => {
    const { firstErrorMessage } = validateInputs({
      height: validaotors.input('Todos los campos son requeridos'),
      weight: validaotors.input('Todos los campos son requeridos'),
      chest: validaotors.input('Todos los campos son requeridos'),
      waist: validaotors.input('Todos los campos son requeridos'),
      hip: validaotors.input('Todos los campos son requeridos'),
      armL: validaotors.input('Todos los campos son requeridos'),
      armR: validaotors.input('Todos los campos son requeridos'),
      thighL: validaotors.input('Todos los campos son requeridos'),
      thighR: validaotors.input('Todos los campos son requeridos'),
    });

    if (firstErrorMessage) return setErrorMessage(firstErrorMessage);

    setLoading(true);
    const response = await addBodyMeasurements(props.userId, values);
    setFormActive(false);
    setValues({});
    await handleFetch();
    setNotification(response);
    return null;
  };

  const handleRegisterDelete = async (id) =>
    alert({
      title: '¿Seguro quieres eliminar este registro?',
      action: async () => {
        const response = await deleteBodyMeasurements(props.userId, id);
        setNotification(response);
        handleFetch();
      },
    });

  useFetch(handleFetch);

  return (
    <ThemeProvider theme={theme}>
      <div className={styles.modalWrapper}>
        <div className={styles.card}>
          <Close className={styles.closeButton} onClick={props.onClose} />
          {loading && (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="20em">
              <CircularProgress />
            </Box>
          )}
          {!loading && (
            <>
              {!isFormActive && !!session.data && session.data.type === 'admin' && (
                <Grid container justify="center">
                  <Box paddingTop="1em" paddingBottom="1em">
                    <ButtonAdmin primary onClick={() => setFormActive(true)}>
                      Agregar registro
                    </ButtonAdmin>
                  </Box>
                </Grid>
              )}
              {!isFormActive && !items.length && (
                <Box
                  textAlign="center"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  minHeight="20vh"
                  color="var(--cyan)"
                >
                  Aún no hay historial
                </Box>
              )}
              {!isFormActive && !!items.length && (
                <div className={styles.listWrapper}>
                  {items.map((currentItem) => (
                    <div className={styles.cardWrapper}>
                      <Box marginBottom=".5em">Fecha: {toLargeStringDate(currentItem.createdAt)}</Box>
                      <div className={styles.registerCard}>
                        {!!session.data && session.data.type === 'admin' && (
                          <Delete className={styles.delete} onClick={() => handleRegisterDelete(currentItem.id)} />
                        )}
                        <Grid container spacing={1}>
                          <Grid item xs={7}>
                            <Box textAlign="right">Estatura:</Box>
                          </Grid>
                          <Grid item xs={4}>
                            {currentItem.height}{' '}
                            <Box component="span" color="gray">
                              cm
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                          <Grid item xs={7}>
                            <Box textAlign="right">Peso:</Box>
                          </Grid>
                          <Grid item xs={4}>
                            {currentItem.weight}{' '}
                            <Box component="span" color="gray">
                              kg
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                          <Grid item xs={7}>
                            <Box textAlign="right">Contorno de pecho:</Box>
                          </Grid>
                          <Grid item xs={4}>
                            {currentItem.chest}{' '}
                            <Box component="span" color="gray">
                              cm
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                          <Grid item xs={7}>
                            <Box textAlign="right">Contorno de cintura:</Box>
                          </Grid>
                          <Grid item xs={4}>
                            {currentItem.waist}{' '}
                            <Box component="span" color="gray">
                              cm
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                          <Grid item xs={7}>
                            <Box textAlign="right">Contorno de cadera:</Box>
                          </Grid>
                          <Grid item xs={4}>
                            {currentItem.hip}{' '}
                            <Box component="span" color="gray">
                              cm
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                          <Grid item xs={7}>
                            <Box textAlign="right">Contorno brazo izquierdo:</Box>
                          </Grid>
                          <Grid item xs={4}>
                            {currentItem.armL}{' '}
                            <Box component="span" color="gray">
                              cm
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                          <Grid item xs={7}>
                            <Box textAlign="right">Contorno brazo derecho:</Box>
                          </Grid>
                          <Grid item xs={4}>
                            {currentItem.armR}{' '}
                            <Box component="span" color="gray">
                              cm
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                          <Grid item xs={7}>
                            <Box textAlign="right">Contorno muslo izquierdo:</Box>
                          </Grid>
                          <Grid item xs={4}>
                            {currentItem.thighL}{' '}
                            <Box component="span" color="gray">
                              cm
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                          <Grid item xs={7}>
                            <Box textAlign="right">Contorno muslo derecho:</Box>
                          </Grid>
                          <Grid item xs={4}>
                            {currentItem.thighR}{' '}
                            <Box component="span" color="gray">
                              cm
                            </Box>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {!!isFormActive && (
                <Box paddingTop="3em" paddingBottom="3em">
                  <ErrorMessage message={errorMessage} />
                  <Grid container justify="center" spacing="1" alignItems="center">
                    <Grid item xs={4}>
                      <Box textAlign="right">Estatura:</Box>
                    </Grid>
                    <Grid item xs={4} container wrap="nowrap" alignItems="center">
                      <Input onlyFloat maxLength={10} {...getInputProps('height')} />
                      <Box minWidth="2em" color="gray" textAlign="center">
                        cm
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container justify="center" spacing="1" alignItems="center">
                    <Grid item xs={4}>
                      <Box textAlign="right">Peso:</Box>
                    </Grid>
                    <Grid item xs={4} container wrap="nowrap" alignItems="center">
                      <Input onlyFloat maxLength={10} {...getInputProps('weight')} />
                      <Box minWidth="2em" color="gray" textAlign="center">
                        kg
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container justify="center" spacing="1" alignItems="center">
                    <Grid item xs={4}>
                      <Box textAlign="right">Contorno de pecho:</Box>
                    </Grid>
                    <Grid item xs={4} container wrap="nowrap" alignItems="center">
                      <Input onlyFloat maxLength={10} {...getInputProps('chest')} />
                      <Box minWidth="2em" color="gray" textAlign="center">
                        cm
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container justify="center" spacing="1" alignItems="center">
                    <Grid item xs={4}>
                      <Box textAlign="right">Contorno cintura:</Box>
                    </Grid>
                    <Grid item xs={4} container wrap="nowrap" alignItems="center">
                      <Input onlyFloat maxLength={10} {...getInputProps('waist')} />
                      <Box minWidth="2em" color="gray" textAlign="center">
                        cm
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container justify="center" spacing="1" alignItems="center">
                    <Grid item xs={4}>
                      <Box textAlign="right">Contorno cadera:</Box>
                    </Grid>
                    <Grid item xs={4} container wrap="nowrap" alignItems="center">
                      <Input onlyFloat maxLength={10} {...getInputProps('hip')} />
                      <Box minWidth="2em" color="gray" textAlign="center">
                        cm
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container justify="center" spacing="1" alignItems="center">
                    <Grid item xs={4}>
                      <Box textAlign="right">Contorno brazo izquierdo:</Box>
                    </Grid>
                    <Grid item xs={4} container wrap="nowrap" alignItems="center">
                      <Input onlyFloat maxLength={10} {...getInputProps('armL')} />
                      <Box minWidth="2em" color="gray" textAlign="center">
                        cm
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container justify="center" spacing="1" alignItems="center">
                    <Grid item xs={4}>
                      <Box textAlign="right">Contorno brazo derecho:</Box>
                    </Grid>
                    <Grid item xs={4} container wrap="nowrap" alignItems="center">
                      <Input onlyFloat maxLength={10} {...getInputProps('armR')} />
                      <Box minWidth="2em" color="gray" textAlign="center">
                        cm
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container justify="center" spacing="1" alignItems="center">
                    <Grid item xs={4}>
                      <Box textAlign="right">Contorno muslo izquierdo:</Box>
                    </Grid>
                    <Grid item xs={4} container wrap="nowrap" alignItems="center">
                      <Input onlyFloat maxLength={10} {...getInputProps('thighL')} />
                      <Box minWidth="2em" color="gray" textAlign="center">
                        cm
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container justify="center" spacing="1" alignItems="center">
                    <Grid item xs={4}>
                      <Box textAlign="right">Contorno muslo derecho:</Box>
                    </Grid>
                    <Grid item xs={4} container wrap="nowrap" alignItems="center">
                      <Input onlyFloat maxLength={10} {...getInputProps('thighR')} />
                      <Box minWidth="2em" color="gray" textAlign="center">
                        cm
                      </Box>
                    </Grid>
                  </Grid>
                  <Box paddingTop="2em">
                    <Grid container justify="center" spacing="1" alignItems="center">
                      <ButtonAdmin onClick={() => setFormActive(false)}>Carncelar</ButtonAdmin>
                      <Box paddingRight="1em" />
                      <ButtonAdmin primary onClick={handleSave}>
                        Guardar
                      </ButtonAdmin>
                    </Grid>
                  </Box>
                </Box>
              )}
            </>
          )}
        </div>
      </div>
    </ThemeProvider>
  );
};

BodyMeasurementsModal.propTypes = {
  userId: string.isRequired,
  onClose: func.isRequired,
};

export default BodyMeasurementsModal;
