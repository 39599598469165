import { convertFromRaw, EditorState } from 'draft-js';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import tipsModel from 'src/core/models/tips';
import validaotors from 'src/helpers/getValidators';
import useErrorMessage from 'src/hooks/useErrorMessage';
import useForm from 'src/hooks/useForm';
import useNotification from 'src/modules/notifications/useNotification';
import View from './view';

const TipsEditor = () => {
  const { state } = useLocation();
  const history = useHistory();
  const setNotification = useNotification();
  const [loading, setLoading] = useState(false);
  const { getInputProps, validateInputs, values, setValues } = useForm();
  const { errorMessage, setErrorMessage } = useErrorMessage();

  // fill form when editin
  useEffect(() => {
    if (state) {
      setValues({
        title: state.title,
        cover: state.cover,
        category: state.category,
        body: EditorState.createWithContent(convertFromRaw(state.raw)),
      });
    }
  }, []);

  const handleSave = async () => {
    const { firstErrorMessage } = validateInputs({
      title: validaotors.input('El titulo no es válido'),
      category: validaotors.input('La categoría es requerida'),
      cover: validaotors.input('La foto de portada es requerida'),
    });
    if (firstErrorMessage) {
      setErrorMessage(firstErrorMessage);
    } else {
      setLoading(true);
      const response = !state ? await tipsModel.createTip(values) : await tipsModel.update(state.id, values);
      if (response.success) {
        setNotification(response);
        history.replace('/dashboard/tips');
      } else {
        setErrorMessage(response.message);
        setLoading(false);
      }
    }
  };

  return <View getInputProps={getInputProps} errorMessage={errorMessage} onSave={handleSave} loading={loading} />;
};

export default TipsEditor;
