// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_viewPort__1kc3k {\n  padding: 1em;\n}\n.styles_itemList__3lDWt {\n  width: 70%;\n  margin: auto;\n  margin-top: 2em;\n  display: flex;\n  justify-content: flex-start;\n  align-items: stretch;\n  flex-wrap: wrap;\n}\n.styles_itemWrapper__3F43_ {\n  box-sizing: border-box;\n  width: 50%;\n  padding: .5em;\n}\n@media screen and (max-width:1200px) {\n  .styles_itemList__3lDWt {\n    width: 80%;\n  }\n}\n\n@media screen and (max-width:1000px) {\n  .styles_itemWrapper__3F43_ {\n    padding: .5em;\n  }\n  .styles_viewPort__1kc3k {\n    font-size: .9em;\n  }\n  .styles_itemList__3lDWt {\n    width: 100%;\n  }\n}\n\n@media screen and (max-width:800px) {\n  .styles_itemWrapper__3F43_ {\n    width: 100%;\n  }\n  .styles_viewPort__1kc3k {\n    font-size: .8em;\n    padding: 0em;\n  }\n}", "",{"version":3,"sources":["webpack://src/pages/dashboard/pages/users/styles.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;AACA;EACE,UAAU;EACV,YAAY;EACZ,eAAe;EACf,aAAa;EACb,2BAA2B;EAC3B,oBAAoB;EACpB,eAAe;AACjB;AACA;EACE,sBAAsB;EACtB,UAAU;EACV,aAAa;AACf;AACA;EACE;IACE,UAAU;EACZ;AACF;;AAEA;EACE;IACE,aAAa;EACf;EACA;IACE,eAAe;EACjB;EACA;IACE,WAAW;EACb;AACF;;AAEA;EACE;IACE,WAAW;EACb;EACA;IACE,eAAe;IACf,YAAY;EACd;AACF","sourcesContent":[".viewPort {\n  padding: 1em;\n}\n.itemList {\n  width: 70%;\n  margin: auto;\n  margin-top: 2em;\n  display: flex;\n  justify-content: flex-start;\n  align-items: stretch;\n  flex-wrap: wrap;\n}\n.itemWrapper {\n  box-sizing: border-box;\n  width: 50%;\n  padding: .5em;\n}\n@media screen and (max-width:1200px) {\n  .itemList {\n    width: 80%;\n  }\n}\n\n@media screen and (max-width:1000px) {\n  .itemWrapper {\n    padding: .5em;\n  }\n  .viewPort {\n    font-size: .9em;\n  }\n  .itemList {\n    width: 100%;\n  }\n}\n\n@media screen and (max-width:800px) {\n  .itemWrapper {\n    width: 100%;\n  }\n  .viewPort {\n    font-size: .8em;\n    padding: 0em;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"viewPort": "styles_viewPort__1kc3k",
	"itemList": "styles_itemList__3lDWt",
	"itemWrapper": "styles_itemWrapper__3F43_"
};
export default ___CSS_LOADER_EXPORT___;
