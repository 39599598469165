import React, { useState } from 'react';
import { getTutorialsOfCurrentImplement } from '../../../../core/models/program';
import useFetch from '../../../../hooks/useFetch';
import View from './view';
import filtersCreator from '../../../tutorials/filtetersComposed';

const TutorialsAdvicer = () => {
  const [loading, setLoading] = useState(true);
  const [tutorials, setTutorials] = useState([]);
  const [filters, setFilters] = useState({});
  const [program, setProgram] = useState('main');

  // fetch tutorials by implmenetId
  useFetch(async () => {
    setLoading(true);
    const response = await getTutorialsOfCurrentImplement(program);
    setLoading(false);
    setTutorials(response);
  }, [program]);

  const handleSetFilters = async (newFilters) => {
    window.scroll(0, 0);
    setLoading(true);
    setFilters(newFilters);
    await new Promise((r) => setTimeout(r, 1000));
    setLoading(false);
    window.scroll(0, 0);
  };

  // filterItems
  let filterItems = [...tutorials];
  if (filters && filters.section) {
    filterItems = filterItems.filter((item) => item.section === filters.section);
  }

  return (
    <View
      items={filterItems}
      loading={loading}
      onChangeFilter={handleSetFilters}
      filtersOrigin={filtersCreator(tutorials)}
      filters={filters}
      currentProgram={program}
      onProgramChange={setProgram}
    />
  );
};

export default TutorialsAdvicer;
