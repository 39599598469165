// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media screen and (max-width:700px) {\n  .styles_gridCOntrollerWrapper__TtSSc {\n    margin-top: .5em;\n  }\n}", "",{"version":3,"sources":["webpack://src/pages/dashboard/pages/salesList/styles.module.css"],"names":[],"mappings":"AAAA;EACE;IACE,gBAAgB;EAClB;AACF","sourcesContent":["@media screen and (max-width:700px) {\n  .gridCOntrollerWrapper {\n    margin-top: .5em;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gridCOntrollerWrapper": "styles_gridCOntrollerWrapper__TtSSc"
};
export default ___CSS_LOADER_EXPORT___;
