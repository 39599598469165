import { Box, ButtonBase, CircularProgress, Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import useSignIn from 'src/hooks/useSignIn';
import Container from 'src/components/container';
import Input from 'src/components/inputs';
import ErrorMessage from 'src/components/errorMessage';
import styles from './styles.module.css';

const SignIn = () => {
  const signIn = useSignIn();

  // transform enter into submit
  const handleKeyPress = (event) => {
    if (event.which === 13 && !event.shiftKey) {
      signIn.login(event);
    }
  };

  useEffect(() => {
    window.addEventListener('keypress', handleKeyPress);
    return () => window.removeEventListener('keypress', handleKeyPress);
  });

  return (
    <Box width="100%">
      {signIn.loading && (
        <Grid container justify="center" style={{ minHeight: '20em' }} alignItems="center">
          <CircularProgress />
        </Grid>
      )}
      {!signIn.loading && (
        <Container>
          <div className={styles.fullWrapper}>
            <div className={styles.wrapper}>
              <div className={styles.title}>¡Hola!</div>
              <div className={styles.description}>
                Por favor ingresa a tu cuenta con los datos que te proporcionamos vía email.
              </div>
              <ErrorMessage message={signIn.errorMessage} />
              <div className={styles.inputGroup}>
                <div className={styles.labelText}>Usuario</div>
                <Input
                  className={styles.input}
                  placeholder="Ingresa tu correo electrónico"
                  {...signIn.inputsProps.email}
                />
              </div>
              <div className={styles.inputGroup}>
                <div className={styles.labelText}>Contraseña</div>
                <Input className={styles.input} {...signIn.inputsProps.password} type="password" />
              </div>
              <ButtonBase className={styles.button} onClick={signIn.login}>
                ENTRAR
              </ButtonBase>
            </div>
          </div>
        </Container>
      )}
    </Box>
  );
};

export default SignIn;
