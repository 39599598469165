import { string, element } from 'prop-types';
import React from 'react';
import composeClassNames from '../../helpers/composeClassNames';
import styles from './styles.module.css';

const LabelInput = ({ children, label, verticalPadding, className }) => {
  const inlineStyle = { padding: `${verticalPadding} 0em`, width: '100%' };

  return (
    <div style={inlineStyle}>
      <div className={composeClassNames(styles.label, className)}>{label}</div>
      {children}
    </div>
  );
};

LabelInput.defaultProps = {
  label: string,
  verticalPadding: '0em',
  className: '',
};

LabelInput.propTypes = {
  children: element.isRequired,
  label: string,
  verticalPadding: string,
  className: string,
};

export default LabelInput;
