import { Box, CircularProgress } from '@material-ui/core';
import { bool, func, string } from 'prop-types';
import React from 'react';
import ErrorMessage from '../../../../components/errorMessage';
import Input from '../../../../components/inputs';
import LabelInput from '../../../../components/labelInput';
import ButtonAdmin from '../../components/button';
import styles from './styles.module.css';

const ShippingCostEditorView = (props) => {
  const disabledProp = {};
  if (props.disablePostalCode) disabledProp.disabled = true;

  return (
    <div className={styles.fullWrapper}>
      <div className={styles.card}>
        {props.loading && (
          <Box display="flex" justifyContent="center" minHeight="10em" alignItems="center">
            <CircularProgress />
          </Box>
        )}
        {!props.loading && (
          <>
            <div className={styles.title}>Registrar costo de envío</div>
            <div className={styles.form}>
              <ErrorMessage message={props.errorMessge} />
              <div className={styles.gridWrapper}>
                <LabelInput verticalPadding=".5em" label="Código postal">
                  <Input {...disabledProp} {...props.getInputProps('postalCode')} onlyNumbers maxLength={5} />
                </LabelInput>
                <Box paddingRight="1em" />
                <LabelInput verticalPadding=".5em" label="Costo">
                  <Input {...props.getInputProps('price')} onlyFloat />
                </LabelInput>
              </div>
              <LabelInput label="Comentario" verticalPadding=".5em">
                <Input {...props.getInputProps('comment')} />
              </LabelInput>
              <Box display="flex" justifyContent="center" marginTop="2em">
                <ButtonAdmin onClick={props.onSave}>Guardar</ButtonAdmin>
              </Box>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

ShippingCostEditorView.defaultProps = {
  errorMessge: '',
};

ShippingCostEditorView.propTypes = {
  loading: bool.isRequired,
  getInputProps: func.isRequired,
  onSave: func.isRequired,
  errorMessge: string,
  disablePostalCode: bool.isRequired,
};

export default ShippingCostEditorView;
