const getItems = () => {
  let prevItems = localStorage.getItem('myCart');
  if (!prevItems) prevItems = '[]';
  return JSON.parse(prevItems);
};

// save items on localstorage
const setItems = (newItems) => {
  localStorage.setItem('myCart', JSON.stringify(newItems));
};

const formatProduct = (productInfo) => {
  const { picture, price, title, quantity, id } = productInfo;
  return { picture, price, title, quantity, id };
};

export const getSubTotal = () =>
  getItems().reduce(
    (acum, currentValue) => acum + Number.parseFloat(currentValue.price) * Number.parseInt(currentValue.quantity, 10),
    0,
  );

const localCartModel = {
  getItems,
  setItems,
  formatProduct,
};

export default localCartModel;
