import { Box } from '@material-ui/core';
import { array, number, string, object, oneOfType } from 'prop-types';
import React from 'react';
import { allSubcategories, categories, genders } from 'src/constants';
import getFilePreviewUrl from 'src/helpers/normalizeImagesUrl';
import FormatPrice from '../../../../../../helpers/formatPrice';
import styles from './styles.module.css';

const Summary = (props) => (
  <div className={styles.wrapper}>
    <Box fontSize="1.3em" fontWeight="bold" textAlign="center">
      {props.title}
    </Box>
    <img
      className={styles.primaryPicture}
      src={getFilePreviewUrl(props.picture)}
      alt={typeof props.picture === 'string' ? props.picture : props.picture.name}
    />
    <Box marginTop="2em" marginBottom="2em">
      <div className={styles.infoRow}>
        <span>Precio:</span>
        <div>$ {FormatPrice(props.price)} MXN </div>
      </div>
      <div className={styles.infoRow}>
        <span>Categoría:</span>
        <div>{categories[props.category]}</div>
      </div>
      {props.subCategory && (
        <div className={styles.infoRow}>
          <span>Subcategoría :</span>
          <div>{allSubcategories[props.subCategory]}</div>
        </div>
      )}
      {props.gender && (
        <div className={styles.infoRow}>
          <span>Género:</span>
          <div>{genders[props.gender]}</div>
        </div>
      )}
    </Box>
    <div className={styles.description}>{props.description}</div>
    <div className={styles.picturesListWrapper}>
      {props.pictures.map((currentPicture) => (
        <div
          className={styles.pictureWrapper}
          key={typeof currentPicture === 'string' ? currentPicture : currentPicture.name}
        >
          <img
            className={styles.picture}
            src={getFilePreviewUrl(currentPicture)}
            alt={typeof currentPicture === 'string' ? currentPicture : currentPicture.name}
          />
        </div>
      ))}
    </div>
  </div>
);

Summary.defaultProps = {
  subCategory: null,
  gender: null,
};

Summary.propTypes = {
  title: string.isRequired,
  description: string.isRequired,
  category: string.isRequired,
  price: oneOfType([string, number]).isRequired,
  subCategory: string,
  gender: string,
  picture: oneOfType([string, object]).isRequired,
  pictures: array.isRequired,
};

export default Summary;
