import { array, bool, func, object, string } from 'prop-types';
import React from 'react';
import Container from 'src/components/container';
import EmptyContent from 'src/components/emptyContent';
import Loading from 'src/components/loading';
import Filters from 'src/pages/tutorials/components/filters';
import TutorialCard from 'src/pages/tutorials/components/tutorialCard';
import ProgramSelector from './components/programSelector';
import styles from './styles.module.css';

const ProductsView = (props) => (
  <>
    <div className={styles.fullWidthWrapper}>
      <Container>
        <div className={styles.flexWrapper}>
          <div className={styles.menuWrapper}>
            <ProgramSelector onChange={props.onProgramChange} value={props.currentProgram} />
            <Filters onChange={props.onChangeFilter} value={props.filters} filters={props.filtersOrigin} />
          </div>
          <div className={styles.productsWrapper}>
            {props.loading && <Loading message="Cargando tutoriales" />}

            {!props.loading && !props.items.length && <EmptyContent message="No encontramos resultados" />}

            {!props.loading && !!props.items.length && (
              <div className={styles.listItemsWrapper}>
                {props.items.map((video, index) => (
                  <div className={styles.itemWrapper} key={index}>
                    <TutorialCard title={video.title} video={video.video} id={video.id} />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </Container>
    </div>
  </>
);

ProductsView.propTypes = {
  onChangeFilter: func.isRequired,
  filters: object.isRequired,
  loading: bool.isRequired,
  items: array.isRequired,
  filtersOrigin: array.isRequired,
  onProgramChange: func.isRequired,
  currentProgram: string.isRequired,
};

export default ProductsView;
