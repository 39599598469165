import { Box, CircularProgress } from '@material-ui/core';
import React from 'react';
import { colors } from '../../../../../../constants';
import styles from './styles.module.css';

const Loading = () => (
  <div className={styles.loading}>
    <Box marginBottom="4em" textAlign="center" color={colors.cyan}>
      Guardando publicacion...
    </Box>
    <CircularProgress />
  </div>
);

export default Loading;
