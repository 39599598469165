// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_radio__19I-0 {\n  margin-right: .5em;\n  border: .2em solid #cdcdcd;\n  width: 1em;\n  height: 1em;\n  display: inline-block;\n  border-radius: 50%;\n  box-sizing: border-box;\n}\n\n.styles_radioSelected__1WiYa {\n  border: .3em solid var(--rose);\n}", "",{"version":3,"sources":["webpack://src/pages/dashboard/pages/initiationQuestions/components/radioGroup/styles.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,0BAA0B;EAC1B,UAAU;EACV,WAAW;EACX,qBAAqB;EACrB,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,8BAA8B;AAChC","sourcesContent":[".radio {\n  margin-right: .5em;\n  border: .2em solid #cdcdcd;\n  width: 1em;\n  height: 1em;\n  display: inline-block;\n  border-radius: 50%;\n  box-sizing: border-box;\n}\n\n.radioSelected {\n  border: .3em solid var(--rose);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"radio": "styles_radio__19I-0",
	"radioSelected": "styles_radioSelected__1WiYa"
};
export default ___CSS_LOADER_EXPORT___;
