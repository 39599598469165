import { Box, CircularProgress } from '@material-ui/core';
import React from 'react';
import HeaderForLists from '../../components/headerForLists';
import Loading from '../../../../components/loading';
import EmptyContent from '../../../../components/emptyContent';
import useSales from '../../../../hooks/useSales';
import GridController from './components/gridController';
import useMemoizedState from '../../../../hooks/useMemoizedState';
import GridView from './components/grid';
import ListView from './components/list';
import styles from './styles.module.css';

const Sales = () => {
  const { loading, items, search, setSearch } = useSales();
  const [view, setView] = useMemoizedState('grid', 'viewStyle');

  return (
    <Box padding={{ xs: '1em', sm: '2em' }}>
      <HeaderForLists title="Ventas" value={search} onChange={setSearch} />
      <div className={styles.gridCOntrollerWrapper}>
        <GridController value={view} onChange={setView} />
      </div>

      {loading && !items.length && <Loading />}

      {!loading && !items.length && (
        <EmptyContent message={search ? 'No encontramos resultados' : 'Aún no tienes ventas'} />
      )}

      {!!items.length && (
        <>
          {view === 'grid' && <GridView items={items} />}
          {view === 'list' && <ListView items={items} />}

          {loading && (
            <Box display="flex" margin="2em 0em" justifyContent="center">
              <CircularProgress />
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default Sales;
