// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_container__1QPNj {\n  width: 50%;\n  margin: auto;\n  box-sizing: border-box;\n  padding: 4em 2em;\n}\n@media screen and (max-width:1100px) {\n  .styles_container__1QPNj {\n    width: 70%;\n  }\n}\n\n@media screen and (max-width:1000px) {\n  .styles_container__1QPNj {\n    font-size: .9em;\n    width: 80%;\n  }\n}\n\n@media screen and (max-width:600px) {\n  .styles_container__1QPNj {\n    font-size: .8em;\n    width: 100%;\n  }\n}\n\n\n", "",{"version":3,"sources":["webpack://src/pages/myProfile/styles.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,YAAY;EACZ,sBAAsB;EACtB,gBAAgB;AAClB;AACA;EACE;IACE,UAAU;EACZ;AACF;;AAEA;EACE;IACE,eAAe;IACf,UAAU;EACZ;AACF;;AAEA;EACE;IACE,eAAe;IACf,WAAW;EACb;AACF","sourcesContent":[".container {\n  width: 50%;\n  margin: auto;\n  box-sizing: border-box;\n  padding: 4em 2em;\n}\n@media screen and (max-width:1100px) {\n  .container {\n    width: 70%;\n  }\n}\n\n@media screen and (max-width:1000px) {\n  .container {\n    font-size: .9em;\n    width: 80%;\n  }\n}\n\n@media screen and (max-width:600px) {\n  .container {\n    font-size: .8em;\n    width: 100%;\n  }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__1QPNj"
};
export default ___CSS_LOADER_EXPORT___;
