import { Hidden, IconButton, TableCell, TableRow } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { string } from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import styles from './styles.module.css';

const ProgramItem = ({ title, id, icon }) => (
  <TableRow>
    <Hidden smDown>
      <TableCell component="th" scope="row">
        <img className={styles.icon} src={icon} alt="program icon" />
      </TableCell>
    </Hidden>
    <TableCell align="left">{title}</TableCell>
    <TableCell align="left">{id}</TableCell>
    <TableCell align="left">
      <Link to={`/dashboard/program-detail/${id}`}>
        <IconButton>
          <Edit className={styles.editIcon} />
        </IconButton>
      </Link>
    </TableCell>
  </TableRow>
);

ProgramItem.propTypes = {
  title: string.isRequired,
  icon: string.isRequired,
  id: string.isRequired,
};

export default ProgramItem;
