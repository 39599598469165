import { Box } from '@material-ui/core';
import { bool, func, string } from 'prop-types';
import React from 'react';
import Card from '../../../../components/card';
import ErrorMessage from '../../../../components/errorMessage';
import Input from '../../../../components/inputs';
import LabelInput from '../../../../components/labelInput';
import Loading from '../../../../components/loading';
import { gendersBin } from '../../../../constants';
import ButtonAdmin from '../../components/button';
import styles from './styles.module.css';

const ActivateCountView = (props) => (
  <div className={styles.limiter}>
    <Box fontWeight="600" marginBottom="1em">
      {props.isEditing ? 'Actualizar datos del usuario' : 'Activar cuenta de usuario'}
    </Box>
    <Card className={styles.card}>
      {props.loading && <Loading message="Cargando..." />}
      {!props.loading && (
        <>
          <ErrorMessage message={props.errorMessage} />
          <LabelInput className={styles.label} verticalPadding=".3em" label="Nombre completo">
            <Input className={styles.input} maxLength={100} {...props.getInputProps('name')} />
          </LabelInput>
          <LabelInput className={styles.label} verticalPadding=".3em" label="Correo">
            <Input className={styles.input} maxLength={100} {...props.getInputProps('email')} />
          </LabelInput>
          <LabelInput className={styles.label} verticalPadding=".3em" label="Contraseña">
            <Input className={styles.input} maxLength={100} {...props.getInputProps('password')} />
          </LabelInput>
          <LabelInput className={styles.label} verticalPadding=".3em" label="Fecha de nacimiento">
            <Input className={styles.input} maxLength={100} {...props.getInputProps('birthDate')} />
          </LabelInput>
          <div className={styles.inputDivider}>
            <LabelInput className={styles.label} verticalPadding=".3em" label="Género">
              <Input className={styles.input} type="select" options={gendersBin} {...props.getInputProps('gender')} />
            </LabelInput>
            <span />
            <LabelInput className={styles.label} verticalPadding=".3em" label="Teléfono">
              <Input className={styles.input} maxLength={10} onlyNumbers {...props.getInputProps('phone')} />
            </LabelInput>
          </div>
          <div className={styles.inputDivider}>
            <LabelInput className={styles.label} verticalPadding=".3em" label="País">
              <Input className={styles.input} maxLength={100} {...props.getInputProps('country')} />
            </LabelInput>
            <span />
            <LabelInput className={styles.label} verticalPadding=".3em" label="Estado">
              <Input className={styles.input} maxLength={100} {...props.getInputProps('state')} />
            </LabelInput>
          </div>
          <div className={styles.inputDivider}>
            <LabelInput className={styles.label} verticalPadding=".3em" label="Ciudad">
              <Input className={styles.input} maxLength={100} {...props.getInputProps('city')} />
            </LabelInput>
            <span />
            <LabelInput className={styles.label} verticalPadding=".3em" label="Colonia">
              <Input className={styles.input} maxLength={100} {...props.getInputProps('suburb')} />
            </LabelInput>
          </div>
          <div className={styles.inputDivider}>
            <LabelInput className={styles.label} verticalPadding=".3em" label="Código postal">
              <Input className={styles.input} maxLength={5} onlyNumbers {...props.getInputProps('postalCode')} />
            </LabelInput>
            <span />
            <LabelInput className={styles.label} verticalPadding=".3em" label="Calle y numero">
              <Input className={styles.input} maxLength={100} {...props.getInputProps('streetAndNumber')} />
            </LabelInput>
          </div>
          <Box marginTop="2em">
            <ButtonAdmin primary onClick={props.onSave}>
              Guardar
            </ButtonAdmin>
          </Box>
        </>
      )}
    </Card>
  </div>
);

ActivateCountView.defaultProps = {
  loading: false,
  errorMessage: '',
};

ActivateCountView.propTypes = {
  loading: bool,
  errorMessage: string,
  getInputProps: func.isRequired,
  onSave: func.isRequired,
  isEditing: bool.isRequired,
};

export default ActivateCountView;
