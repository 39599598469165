import { MoreVert } from '@material-ui/icons';
import { array, func } from 'prop-types';
import React from 'react';
import Picture from 'src/components/picture';
import Options from '../../../../../../components/options';
import styles from './styles.module.css';

const List = ({ items, getOptions }) => (
  <div className={styles.productList}>
    {items.map((currentItem) => (
      <div className={styles.itemWrapper} key={currentItem.id}>
        <div className={styles.item}>
          <Options className={styles.options} optionsList={getOptions(currentItem)}>
            <MoreVert />
          </Options>
          <Picture className={styles.picture} src={currentItem.picture} alt={currentItem.title} height={80} />
          <div className={styles.dataWrapper}>
            <div className={styles.title}>{currentItem.title || currentItem.name}</div>
            <div className={styles.meta}>Teléfono: {currentItem.phone}</div>
            <div className={styles.meta}>Email: {currentItem.email}</div>
          </div>
        </div>
      </div>
    ))}
  </div>
);

List.propTypes = {
  items: array.isRequired,
  getOptions: func.isRequired,
};

export default List;
