import React from 'react';
import Chat from './components/chat';
import Form from './components/form';
import Members from './components/members';
import styles from './styles.module.css';

const GroupChat = () => (
  <div className={styles.wrapper}>
    <div className={styles.membersWrapper}>
      <Members />
    </div>
    <div className={styles.bodyWrapper}>
      <Chat />
      <Form />
    </div>
  </div>
);

export default GroupChat;
