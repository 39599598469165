// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_wrapper__31_rr {\r\n  width: 60%;\r\n  margin: 2em auto;\r\n}\r\n\r\n@media screen and (max-width: 1200px) {\r\n  .styles_wrapper__31_rr {\r\n    width: 80%;\r\n  }\r\n}\r\n\r\n@media screen and (max-width: 900px) {\r\n  .styles_wrapper__31_rr {\r\n    width: 100%;\r\n  }\r\n}", "",{"version":3,"sources":["webpack://src/pages/dashboard/pages/createProduct/components/generalInfo/styles.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,gBAAgB;AAClB;;AAEA;EACE;IACE,UAAU;EACZ;AACF;;AAEA;EACE;IACE,WAAW;EACb;AACF","sourcesContent":[".wrapper {\r\n  width: 60%;\r\n  margin: 2em auto;\r\n}\r\n\r\n@media screen and (max-width: 1200px) {\r\n  .wrapper {\r\n    width: 80%;\r\n  }\r\n}\r\n\r\n@media screen and (max-width: 900px) {\r\n  .wrapper {\r\n    width: 100%;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "styles_wrapper__31_rr"
};
export default ___CSS_LOADER_EXPORT___;
