import { Box } from '@material-ui/core';
import { Apps, Reorder } from '@material-ui/icons';
import { func, oneOf } from 'prop-types';
import React from 'react';
import styles from './styles.module.css';

const GridController = (props) => {
  const handleListSelect = () => props.onChange('list');
  const handleGridSelect = () => props.onChange('grid');

  const compueteSelectedClassName = (value) => {
    if (props.value === value) return styles.selected;
    return '';
  };

  return (
    <div className={styles.wrapper}>
      <Apps onClick={handleGridSelect} className={compueteSelectedClassName('grid')} />
      <Box paddingRight="1em" />
      <Reorder onClick={handleListSelect} className={compueteSelectedClassName('list')} />
    </div>
  );
};

GridController.propTypes = {
  onChange: func.isRequired,
  value: oneOf(['list', 'grid']).isRequired,
};

export default GridController;
