import { Box } from '@material-ui/core';
import React from 'react';
import openpayassetsSrc from 'src/assets/openpayassets.png';
import openPaySrc from 'src/assets/openpay.png';
import { func, string } from 'prop-types';
import Input from '../../../../components/inputs';
import styles from './styles.module.css';
import ErrorMessage from '../../../../components/errorMessage';

const getMonts = () => {
  let elements = [...Array(12)];
  elements = elements.map((_element, index) => {
    const monthNumber = index + 1;
    const monthNormalized = monthNumber.toString().length === 1 ? `0${monthNumber}` : monthNumber.toString();
    return monthNormalized;
  });
  return elements.reduce((accum, value) => ({ ...accum, [value]: value }), {});
};

const getYears = () => {
  const init = new Date().getFullYear();
  let elements = [...Array(10)];
  elements = elements.map((_element, index) => {
    const monthNumber = index + init;
    return monthNumber.toString();
  });
  return elements.reduce((accum, value) => ({ ...accum, [value]: value }), {});
};

const CardForm = (props) => (
  <div>
    <img className={styles.openpayLogo} src={openPaySrc} alt="openpay" />
    <Box fontWeight="800" marginBottom="1em" fontSize="1.2em" color="#222d69">
      Pagos con Tarjeta de crédito o débito
    </Box>
    <div className={styles.group}>
      <ErrorMessage message={props.errorMessage} />
      <Box marginBottom="1em">
        <Box fontWeight="600" marginBottom=".5em">
          Nombre del titular
        </Box>
        <Input
          className={styles.input}
          placeholder="Como aparece en la tarjeta"
          maxLength={100}
          {...props.getInputProps('cardName')}
        />
      </Box>
      <Box marginBottom="1em">
        <Box fontWeight="600" marginBottom=".5em">
          Número de tarjeta
        </Box>
        <Input
          className={styles.input}
          placeholder="ingrese los 16 dígitos"
          maxLength={16}
          onlyNumbers
          {...props.getInputProps('cardNumber')}
        />
      </Box>
      <div className={styles.flexSpacer}>
        <Box width="60%">
          <Box fontWeight="600" marginBottom=".5em">
            Fecha de vencimineto
          </Box>
          <div className={styles.flexSpacer}>
            <Input
              className={styles.input}
              placeholder="Mes"
              type="select"
              options={getMonts()}
              {...props.getInputProps('cardMonth')}
            />
            <Box paddingRight="1em" />
            <Input
              className={styles.input}
              placeholder="Año"
              options={getYears()}
              type="select"
              {...props.getInputProps('cardYear')}
            />
          </div>
        </Box>
        <Box paddingRight="1em" />
        <Box width="40%">
          <Box fontWeight="600" marginBottom=".5em">
            Código de seguridad
          </Box>
          <Input className={styles.input} placeholder="cvv" {...props.getInputProps('cardCode')} />
        </Box>
      </div>
    </div>
    <img src={openpayassetsSrc} alt="openpayassetsSrc" className={styles.openpayAssets} />
  </div>
);

CardForm.defaultProps = {
  errorMessage: '',
};

CardForm.propTypes = {
  errorMessage: string,
  getInputProps: func.isRequired,
};

export default CardForm;
