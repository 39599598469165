import React from 'react';
import moticationSrc from 'src/assets/motication.jpg';
import moticationMSrc from 'src/assets/moticationm.jpg';
import Footer from '../../components/footer';
import Categories from './components/categories';
import Community from './components/community';
import Cover from './components/cover';
import CoverVideo from './components/coverVideo';
import Program from './components/program';
import CoverFooterPage from '../../components/coverFooterPage';
import Header from '../../components/header';

const Home = () => (
  <>
    <Header />
    <Cover />
    <CoverVideo />
    <Program />
    <Categories />
    <Community />
    <CoverFooterPage
      picture={moticationSrc}
      pictureMobile={moticationMSrc}
      content="NO TE RINDAS, NO DEJES QUE LA FRUSTRACIÓN TE LIMITE, AL FINAL TODO EL ESFUERZO VALE LA PENA"
    />
    <Footer />
  </>
);

export default Home;
