import { Grid } from '@material-ui/core';
import { PlayCircleFilled } from '@material-ui/icons';
import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../../components/button';
import Container from '../../components/container';
import EmptyContent from '../../components/emptyContent';
import Footer from '../../components/footer';
import Header from '../../components/header';
import Loading from '../../components/loading';
import Picture from '../../components/picture';
import composeClassNames from '../../helpers/composeClassNames';
import useTestimonialLits from '../../hooks/useTestimonialList';
import styles from './styles.module.css';

const Testimoniales = () => {
  const { loading, items } = useTestimonialLits();

  return (
    <>
      <Header grayOptions />
      <Container>
        {loading && <Loading />}
        {!loading && !items.length && <EmptyContent message="Aún no hay testimonios" />}
        {!loading && !!items.length && (
          <div className={styles.body}>
            <div className={styles.title}>Testimonios</div>
            <div className={styles.grid}>
              {items.map((item) => (
                <div className={styles.cardWrapper} key={item.id}>
                  <div className={styles.card}>
                    <Picture className={styles.picture} src={item.picture} height={90} />
                    <div className={styles.dataWrappr}>
                      <div className={styles.sign}>&ldquo;</div>
                      <div className={styles.name}>{item.name}</div>
                      <div className={styles.description}>{item.description}</div>
                      {item.video && (
                        <a
                          target="_blank"
                          href={item.video}
                          className={composeClassNames(styles.description, styles.video)}
                          rel="noreferrer"
                        >
                          <PlayCircleFilled />
                          Ver video
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <Grid container justify="center">
              <Link to="/">
                <Button className={styles.button}>REGRESAR</Button>
              </Link>
            </Grid>
          </div>
        )}
      </Container>
      <Footer />
    </>
  );
};

export default Testimoniales;
