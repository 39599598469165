/* eslint-disable no-param-reassign */
import firebase from 'firebase/app';
import database from 'src/core/database';
import { functions } from 'src/core/firebase';
import training from 'src/helpers/training';
import formatObect from '../../helpers/formatObject';
import { success } from '../../helpers/response';
import { deletePicture, uploadPicture } from '../storage';

export const getSingle = async (userId) => {
  const data = await database.getDocument(`/users/${userId}`);
  return data;
};

export const createProgramUser = async (data) => {
  const { data: response } = await functions.httpsCallable('createProgramUser')(data);
  return response;
};

export const getProgramUsers = async () => {
  const users = await database.getList('users', null, ['name', 'asc'], [['type', '==', 'programUser']]).next();
  return users;
};

export const activateProgram = async (userId) => {
  await database.update(`users/${userId}`, {
    programActive: true,
    programActivatedDate: firebase.firestore.FieldValue.serverTimestamp(),
  });

  // update history limit pay
  const userData = await getSingle(userId);
  const { formPay, paysHistory } = userData;
  const dateProgramStart = training.getTrainingStartDate(userData);
  paysHistory.forEach((current) => {
    if (formPay === 'monthlyPrice') {
      current.limitPay = new Date(dateProgramStart);
      dateProgramStart.setMonth(dateProgramStart.getMonth() + 1);
    } else if (formPay === 'semesterPrice') {
      current.limitPay = new Date(dateProgramStart);
      dateProgramStart.setMonth(dateProgramStart.getMonth() + 6);
    }
  });

  await database.update(`users/${userId}`, { paysHistory });
  return success('Programa activado correctamente');
};

export const deleteProgramUser = async (id) => {
  const response = await functions.httpsCallable('deleteProgramUser')({ id });
  return response.data;
};

export const setAdvicer = async (userId, advicerId) => {
  await database.update(`users/${userId}`, { advicer: advicerId });
  return success('Asesor asignado correctamente');
};

export const setPayedNextPayout = async (userId) => {
  const { paysHistory } = await getSingle(userId);
  const currentPayment = paysHistory.find((element) => element.status === 'pending');
  currentPayment.status = 'approved';
  currentPayment.datePayed = new Date();
  await database.update(`users/${userId}`, { paysHistory });
  return success('El pago se actualizó correctamente');
};

export const updateProgramUser = async (userId, newData) => {
  const prevData = await getSingle(userId);
  const allows = [
    'birthDate',
    'city',
    'country',
    'gender',
    'name',
    'phone',
    'picture',
    'postalCode',
    'state',
    'streetAndNumber',
    'suburb',
  ];
  const { picture, ...secureData } = formatObect(newData, allows);

  // handlePicture
  if (picture && picture instanceof File) {
    if (prevData.picture) await deletePicture(prevData.picture);
    const newUrl = await uploadPicture(picture, `users/${userId}`);
    await database.update(`users/${userId}`, { picture: newUrl });
  }

  await database.update(`users/${userId}`, secureData);
};

export const saveQuestionsByAdmin = async (userId, payload) => {
  await database.update(`users/${userId}`, { questionsByAdmin: payload });
  return success('Datos actualizados corretamente');
};

export const getQuestionsByAdmin = async (userId) => {
  const response = await database.getDocument(`users/${userId}`);
  const data = response || {};
  const { questionsByAdmin = {} } = data;
  return questionsByAdmin;
};

export const getHistoryBodyMeasurements = async (userId) => {
  const list = await database.getList(`users/${userId}/bodyMeasurements`, null, ['createdAt', 'desc']).next();
  return list;
};

export const addBodyMeasurements = async (userId, data) => {
  await database.create(`users/${userId}/bodyMeasurements`, data);
  return success('Registro guardado correctamente');
};

export const deleteBodyMeasurements = async (userId, measurementsId) => {
  await database.remove(`users/${userId}/bodyMeasurements/${measurementsId}`);
  return success('Registro eliminado correctamente');
};

export const updateProgramActivatedDate = async (userId, date) => {
  const status = await database.update(`users/${userId}`, { programActivatedDate: date });
  if (status) return success('Semana de entrenamiento actualizado correctamente');
  return success('Error algo salio mal');
};

export const updateByAdmin = async (userId, newData) => {
  const response = await functions.httpsCallable('updateByAdmin')({ userId, ...newData });
  return response.data;
};

const programUserModel = {
  create: createProgramUser,
  get: getProgramUsers,
  activateProgram,
  delete: deleteProgramUser,
  getSingle,
  setAdvicer,
  updateInfo: updateProgramUser,
  saveQuestionsByAdmin,
  getQuestionsByAdmin,
  getHistoryBodyMeasurements,
  addBodyMeasurements,
  deleteBodyMeasurements,
  updateByAdmin,
};

export default programUserModel;
