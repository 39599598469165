import { bool, array, element, number, oneOfType, string } from 'prop-types';
import React from 'react';
import styles from './styles.module.css';

const Container = ({ small, extraSmall, ...props }) => {
  let computedClassName = props.disabledVerticalPadding ? styles.wrapper : styles.wrapperAll;

  if (small) computedClassName = `${computedClassName} ${styles.small}`;
  if (extraSmall) computedClassName = `${computedClassName} ${styles.eSmall}`;

  computedClassName = `${computedClassName} ${props.className}`;

  return <div className={computedClassName}>{props.children}</div>;
};

Container.defaultProps = {
  disabledVerticalPadding: false,
  className: '',
  small: false,
  extraSmall: false,
};

Container.propTypes = {
  small: bool,
  children: oneOfType([string, number, element, array]).isRequired,
  disabledVerticalPadding: bool,
  className: string,
  extraSmall: bool,
};

export default Container;
