import { Box, Grid } from '@material-ui/core';
import { any, bool, func, object, string } from 'prop-types';
import React from 'react';
import composeClassNames from '../../../../../../helpers/composeClassNames';
import styles from './styles.module.css';

const RadioGroup = (props) => {
  const getHandleClick = (value) => () => {
    if (!props.disabled) {
      const payload = { target: { name: props.name, value } };
      props.onChange(payload);
    }
  };

  return (
    <Grid container spacing={2} alignItems="center">
      {Object.keys(props.options).map((key) => (
        <Grid item key={key}>
          <Box display="flex" alignItems="center" padding=".5em 0em" data-value={key} onClick={getHandleClick(key)}>
            <div className={composeClassNames(styles.radio, props.value === key ? styles.radioSelected : '')} />
            {props.options[key]}
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

RadioGroup.defaultProps = {
  options: {},
  value: '',
  disabled: false,
};

RadioGroup.propTypes = {
  options: object,
  value: any,
  name: string.isRequired,
  onChange: func.isRequired,
  disabled: bool,
};

export default RadioGroup;
