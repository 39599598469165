// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_wrapperMaster__2kAaT {\r\n  padding: 2em;\r\n}\r\n@media screen and (max-width:600px) {\r\n  .styles_wrapperMaster__2kAaT {\r\n    padding: 0em;\r\n  }\r\n}", "",{"version":3,"sources":["webpack://src/pages/dashboard/pages/communities/styles.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;AACA;EACE;IACE,YAAY;EACd;AACF","sourcesContent":[".wrapperMaster {\r\n  padding: 2em;\r\n}\r\n@media screen and (max-width:600px) {\r\n  .wrapperMaster {\r\n    padding: 0em;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapperMaster": "styles_wrapperMaster__2kAaT"
};
export default ___CSS_LOADER_EXPORT___;
