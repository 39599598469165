// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_card__2TYoA {\n  background: #fff;\n  width: 60%;\n  margin: auto;\n  padding: 2em;\n  box-shadow: 5px 5px 15px #cdcdcd;\n  box-sizing: border-box;\n}\n\n@media screen and (max-width:1300px) {\n  .styles_card__2TYoA {\n    width: 70%;\n  }\n}\n\n@media screen and (max-width:1000px) {\n  .styles_card__2TYoA {\n    width: 100%;\n  }\n}\n\n@media screen and (max-width:800px) {\n  .styles_card__2TYoA {\n    padding: 0em;\n    box-shadow: none;\n  }\n}", "",{"version":3,"sources":["webpack://src/pages/dashboard/pages/tipsEditor/styles.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,UAAU;EACV,YAAY;EACZ,YAAY;EACZ,gCAAgC;EAChC,sBAAsB;AACxB;;AAEA;EACE;IACE,UAAU;EACZ;AACF;;AAEA;EACE;IACE,WAAW;EACb;AACF;;AAEA;EACE;IACE,YAAY;IACZ,gBAAgB;EAClB;AACF","sourcesContent":[".card {\n  background: #fff;\n  width: 60%;\n  margin: auto;\n  padding: 2em;\n  box-shadow: 5px 5px 15px #cdcdcd;\n  box-sizing: border-box;\n}\n\n@media screen and (max-width:1300px) {\n  .card {\n    width: 70%;\n  }\n}\n\n@media screen and (max-width:1000px) {\n  .card {\n    width: 100%;\n  }\n}\n\n@media screen and (max-width:800px) {\n  .card {\n    padding: 0em;\n    box-shadow: none;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "styles_card__2TYoA"
};
export default ___CSS_LOADER_EXPORT___;
