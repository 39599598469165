import React from 'react';
import Loading from 'src/components/loading';
import EmptyContent from 'src/components/emptyContent';
import List from './components/comunityList';
import styles from './styles.module.css';
import HeaderForLists from '../../components/headerForLists';
import useCommunityList from '../../../../hooks/useCommunityList';

const AdvicerList = () => {
  const { loading, items, keywords, setKeywords, getOptions } = useCommunityList();

  return (
    <div className={styles.wrapperMaster}>
      <HeaderForLists
        title="Comunidades"
        buttonRedirect="/dashboard/communities/editor"
        value={keywords}
        onChange={setKeywords}
      />
      {loading && <Loading />}
      {!loading && !!items.length && <List items={items} getOptions={getOptions} />}
      {!loading && !items.length && (
        <EmptyContent message={keywords ? 'No encontramos resultados' : 'Aún no tienes publicaciones'} />
      )}
    </div>
  );
};

export default AdvicerList;
