/* eslint-disable no-await-in-loop */
import { failed, success } from 'src/helpers/response';
import { auth } from '../firebase';
import { getUser } from './users';

const errorDiccionary = {
  'auth/email-already-in-use': 'El correo ya se encuentra registrado',
  'auth/account-exists-with-different-credential': 'El correo ya se encuentra registrado',
  'auth/invalid-email': 'El correo no es válido',
  'auth/user-not-found': 'Correo o contraseña incorrecta',
  'auth/wrong-password': 'Correo o contraseña incorrecta',
  'auth/popup-closed-by-user': 'operacion cancelada por el usuario',
};

const errorTranslator = (errorMessage) => errorDiccionary[errorMessage] || errorMessage;

export const loginWithEmailAndPassword = async (email, password) => {
  try {
    const { user } = await auth.signInWithEmailAndPassword(email, password);
    const userData = await getUser(user.uid);
    return success('OK', userData);
  } catch (error) {
    return failed(errorTranslator(error.code));
  }
};

export const signOut = async () => {
  await auth.signOut();
  return success('sesion cerrada correctamente');
};

export const onUserChangeState = async (handler) => {
  auth.onAuthStateChanged(async (user) => {
    if (!user) return handler(user);
    let userData = null;
    for (let index = 0; index < 5 && !userData; index + 1) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      userData = await getUser(user.uid);
    }
    return handler(userData);
  });
};
