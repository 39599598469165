import { any, bool, func, number, string } from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import bagSrc from 'src/assets/bag.svg';
import defaultImage from 'src/assets/fallback-program.png';
import Picture from 'src/components/picture';
import FormatPrice from 'src/helpers/formatPrice';
import limitText from 'src/helpers/limitText';
import AlternativeButton from '../../../../components/alternativeButton';
import styles from './styles.module.css';

const PropductCard = (props) => {
  const history = useHistory();

  const handleCardClick = () => {
    if (!props.isDigital) history.push(`/articulo/${props.id}`);
  };

  return (
    <div className={styles.wrapper} onClick={handleCardClick} role="button">
      <Picture src={props.picture || defaultImage} height={80} className={styles.picture} />
      <div className={styles.data}>
        {!props.isDigital && (
          <>
            <div className={styles.title}>{props.title}</div>
            <div className={styles.description}>{limitText(props.description, 50)}</div>
            <div className={styles.priceAndCart}>
              <div className={styles.price}>
                ${FormatPrice(props.price)}
                <span>MXN</span>
              </div>
              <img
                src={bagSrc}
                alt="icono de bolsa"
                className={styles.bagIcon}
                onClick={props.onAddToCart}
                role="button"
              />
            </div>
          </>
        )}
        {props.isDigital && (
          <>
            {/* <div className={styles.digitalCategory}>PROGRAMA</div> */}
            <div className={styles.title}>{props.title}</div>
            {!!props.description && <div className={styles.description}>{props.description}</div>}
            <div className={styles.priceDigital}>
              <span className={styles.size}>Un solo pago: </span>${FormatPrice(props.price)}
              <span>MXN</span>
            </div>
            <div className={styles.priceDigital}>
              <span className={styles.size}>Pago semestral: </span>${FormatPrice(props.semesterPrice)}
              <span>MXN</span>
            </div>
            <div className={styles.priceDigital}>
              <span className={styles.size}>Pago mensual: </span>${FormatPrice(props.monthlyPrice)}
              <span>MXN</span>
            </div>
            <div className={styles.priceDigital}>
              <span className={styles.size}>Inscripción: </span>${FormatPrice(props.inscription)}
              <span>MXN</span>
            </div>
            <AlternativeButton filled id={styles.button} onClick={props.onBuyNow}>
              Comprar ahora
            </AlternativeButton>
          </>
        )}
      </div>
    </div>
  );
};

PropductCard.defaultProps = {
  semesterPrice: null,
  monthlyPrice: null,
  inscription: null,
  picture: defaultImage,
};

PropductCard.propTypes = {
  title: string.isRequired,
  picture: string,
  description: string.isRequired,
  price: number.isRequired,
  onAddToCart: func.isRequired,
  id: string.isRequired,
  isDigital: bool.isRequired,
  onBuyNow: func.isRequired,
  semesterPrice: any,
  monthlyPrice: any,
  inscription: any,
};

export default PropductCard;
