import React from 'react';
import pictureSrc from 'src/assets/private/portada-video-user.png';
import pictureMSrc from 'src/assets/private/portada-video-userm.png';
// import playSrc from 'src/assets/private/btn-play.svg';
import adornmentSrc from 'src/assets/adornment.svg';
import { useMediaQuery } from '@material-ui/core';
import { Link } from 'react-router-dom';
import AlternativeButton from 'src/components/alternativeButton';
import Container from 'src/components/container';
import PrivateCover from 'src/components/privateCover';
import PrivateFooter from 'src/components/privateFooter';
import PrivateHeader from 'src/components/header';
// import { usePlayer } from 'src/modules/youtubePlayer';
// import { videos } from 'src/constants';
import styles from './styles.module.css';

const PrivateHome = () => {
  const isMobile = useMediaQuery('(max-width:600px)');
  // const player = usePlayer();

  // const handlePLay = () => player.play(videos.privateHome);

  return (
    <>
      <PrivateHeader />
      <PrivateCover backgroundColor="#f1f1f1" />
      <div className={styles.fullWidth}>
        <Container disabledVerticalPadding extraSmall>
          <div className={styles.bodyFlex}>
            <div className={styles.data}>
              <div className={styles.title}>BIENVENIDA</div>
              <div className={styles.description}>
                <p>
                  Queremos darte la mas cordial bienvenida, para toda la organización es muy importante tu participación
                  y nuestro éxito se evalúa en la medida en que logremos generar beneficios en tu vida, que logres
                  mejorar tu estado de salud, tu movilidad, bajar tu estrés y en general que tengas una vida mas plena y
                  mas feliz. Esa es nuestra misión.
                </p>
                <p>
                  Seguramente experimentarás cambios y experiencias nuevas, tendrás altas y bajas dentro del programa,
                  seguramente muchas alegrías, lo mas importante siempre, es mantener la constancia que nos ayudará a
                  generar hábitos benéficos en tu vida diaria. Te pedimos que seas muy paciente. ¡El incorporar la
                  actividad física diaria, te permitirá vivir mejor el resto de tu vida!
                </p>
                <p>¡Bienvenida!</p>
              </div>
              <Link to="/private/training">
                <AlternativeButton id={styles.button} filled>
                  EMPEZAR MI ENTRENAMIENTO
                </AlternativeButton>
              </Link>
            </div>
            <div className={styles.videoWrapper}>
              <img src={isMobile ? pictureMSrc : pictureSrc} className={styles.pictureCover} alt="cover" />
              {/* <img src={playSrc} alt="play" className={styles.play} onClick={handlePLay} role="button" /> */}
            </div>
          </div>
        </Container>
      </div>
      <img src={adornmentSrc} className={styles.adornment} alt="adonment" />
      <PrivateFooter />
    </>
  );
};

export default PrivateHome;
