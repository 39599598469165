import { createContext } from 'react';

/**
 * set Loading
 * @param {Boolean} value the value of loading status;
 */
const setLoading = () => {};

const context = createContext(setLoading);

export default context;
