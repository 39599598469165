import { Box } from '@material-ui/core';
import { PhotoCamera, Send } from '@material-ui/icons';
import { array, bool, func } from 'prop-types';
import React, { useEffect } from 'react';
import composeClassNames from '../../helpers/composeClassNames';
import { toLargeStringDate, toStringTime } from '../../helpers/dates';
import useForm from '../../hooks/useForm';
import Dropzone from '../dropzone';
import Input from '../inputs';
import styles from './style.module.css';

const ChatView = (props) => {
  const { getInputProps, setValues, values } = useForm({});

  const handleSend = (event) => {
    if (event && event.preventDefault) event.preventDefault();
    if (event && event.stopPropagation) event.stopPropagation();
    if (props.onNewMessage && values.message) props.onNewMessage(values.message);
    setValues({});
  };

  // transform enter into submit
  const handleKeyPress = (event) => {
    if (event.which === 13 && !event.shiftKey) {
      handleSend();
      event.preventDefault();
    }
  };

  // scroll into last message
  useEffect(() => {
    setTimeout(() => {
      try {
        const div = document.getElementById('messageWrapper');
        div.scrollTo({ top: div.scrollHeight - div.clientHeight, behavior: 'smooth' });
      } catch (error) {
        console.log(error);
      }
    }, 100);
  }, [props.messages.length]);

  return (
    <div className={styles.wrapper}>
      <Box fontWeight="bold" marginBottom="1em">
        Comentarios
      </Box>
      {!props.hideMessages && (
        <div className={styles.messagesWrapper} id="messageWrapper">
          {props.messages.map((message, index) => (
            <div className={composeClassNames(styles.userCard, !message.isOwn ? styles.otherUserCard : '')} key={index}>
              <img className={styles.avatar} src={message.picture} alt="avatar" />
              <Box>
                <Box fontWeight="bold">{message.name}</Box>
                {message.type === 'text' && <Box>{message.content}</Box>}
                {message.type !== 'text' && <img src={message.content} className={styles.messageAsImage} alt="some" />}
                <Box fontSize=".8em" color="gray">
                  {toLargeStringDate(message.date)}
                  {` `}
                  {toStringTime(message.date)}
                  {` `}
                  hrs.
                </Box>
              </Box>
            </div>
          ))}
        </div>
      )}
      <form className={styles.form} onSubmit={handleSend}>
        <div className={styles.textareaWrapper}>
          <Input
            type="textarea"
            className={styles.textarea}
            onKeyPress={handleKeyPress}
            {...getInputProps('message')}
          />
          <Send className={styles.sendIcon} onClick={handleSend} />
        </div>
        <Dropzone onChange={props.onNewMessage}>
          <PhotoCamera className={styles.photoCamera} />
        </Dropzone>
      </form>
    </div>
  );
};

ChatView.defaultProps = {
  onNewMessage: null,
  hideMessages: false,
};

ChatView.propTypes = {
  onNewMessage: func,
  messages: array.isRequired,
  hideMessages: bool,
};

export default ChatView;
