// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_body__hiM3Y {\r\n  margin-top: 1em;\r\n  width: 80%;\r\n  margin: auto;\r\n}\r\n.styles_messageList__1QANi {\r\n  display: flex;\r\n  flex-wrap: wrap;\r\n}\r\n.styles_messageWrapper__3ISRY {\r\n  width: 50%;\r\n  padding: 1em;\r\n  box-sizing: border-box;\r\n}\r\n\r\n@media screen and (max-width:1200px) {\r\n  .styles_body__hiM3Y {\r\n    width: 100%;\r\n  }\r\n}\r\n\r\n@media screen and (max-width:1000px) {\r\n  .styles_messageWrapper__3ISRY {\r\n    padding: .5em;\r\n    font-size: .9em;\r\n  }\r\n}\r\n\r\n@media screen and (max-width:900px) {\r\n  .styles_messageWrapper__3ISRY {\r\n    width: 100%;\r\n    font-size: .8em;\r\n  }\r\n}", "",{"version":3,"sources":["webpack://src/pages/dashboard/pages/messages/styles.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,UAAU;EACV,YAAY;AACd;AACA;EACE,aAAa;EACb,eAAe;AACjB;AACA;EACE,UAAU;EACV,YAAY;EACZ,sBAAsB;AACxB;;AAEA;EACE;IACE,WAAW;EACb;AACF;;AAEA;EACE;IACE,aAAa;IACb,eAAe;EACjB;AACF;;AAEA;EACE;IACE,WAAW;IACX,eAAe;EACjB;AACF","sourcesContent":[".body {\r\n  margin-top: 1em;\r\n  width: 80%;\r\n  margin: auto;\r\n}\r\n.messageList {\r\n  display: flex;\r\n  flex-wrap: wrap;\r\n}\r\n.messageWrapper {\r\n  width: 50%;\r\n  padding: 1em;\r\n  box-sizing: border-box;\r\n}\r\n\r\n@media screen and (max-width:1200px) {\r\n  .body {\r\n    width: 100%;\r\n  }\r\n}\r\n\r\n@media screen and (max-width:1000px) {\r\n  .messageWrapper {\r\n    padding: .5em;\r\n    font-size: .9em;\r\n  }\r\n}\r\n\r\n@media screen and (max-width:900px) {\r\n  .messageWrapper {\r\n    width: 100%;\r\n    font-size: .8em;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": "styles_body__hiM3Y",
	"messageList": "styles_messageList__1QANi",
	"messageWrapper": "styles_messageWrapper__3ISRY"
};
export default ___CSS_LOADER_EXPORT___;
