import { Box, Grid, Hidden } from '@material-ui/core';
import React, { useState } from 'react';
import Card from 'src/components/card';
import Stepper from 'src/components/stepper';
import useForm from 'src/hooks/useForm';
import { createNewAdvicer, updateAdvicer } from 'src/core/models/advisers';
import storage, { uploadPicture } from 'src/core/storage';
import { useHistory, useLocation } from 'react-router';
import useErrorMessage from 'src/hooks/useErrorMessage';
import useNotification from 'src/modules/notifications/useNotification';
import ButtonAdmin from '../../components/button';
import Container from '../../components/container';
import GeneralInfo from './components/generalInfo';
import styles from './styles.module.css';
import Multimedia from './components/multimedia';
import Loading from './components/Loading';
import computeInitialFormState from './computeInitialFormState';
import validaotors from '../../../../helpers/getValidators';

function CreateAdviser() {
  const setNotification = useNotification();
  const history = useHistory();
  const { state } = useLocation();
  const { errorMessage, setErrorMessage } = useErrorMessage();
  const [currentStep, setStep] = useState(0);

  const { getInputProps, validateInputs, setValues, values } = useForm(computeInitialFormState(state));

  const handlePictureSelect = (picture) => {
    setValues((prevVals) => ({ ...prevVals, picture }));
  };

  const handlePictureDelet = () => setValues((prevVals) => ({ ...prevVals, picture: null }));

  const handleGeneralInfoAcept = () => {
    const { firstErrorMessage } = validateInputs({
      name: validaotors.input('El nombre no es válido'),
      email: validaotors.email(),
      phone: validaotors.phone(),
      password: validaotors.password(),
      description: validaotors.input('La descripción no es válida'),
    });
    if (firstErrorMessage) setErrorMessage(firstErrorMessage);
    else setStep((prevStep) => prevStep + 1);
  };

  const handleUpload = async () => {
    const { firstErrorMessage } = validateInputs({ picture: (val) => (!val ? 'La foto es requerida' : false) });
    if (firstErrorMessage) return setErrorMessage(firstErrorMessage);

    setStep(2);
    const { picture, ...advicerData } = values;
    const response = await createNewAdvicer(advicerData);
    if (response.error) {
      setStep(0);
      setErrorMessage(response.message);
      return null;
    }

    const { uid } = response.data;
    const pictureUrl = await storage.uploadPicture(values.picture, `users/${uid}`);
    await updateAdvicer(uid, { picture: pictureUrl });
    history.push('/dashboard/advisers');

    setNotification({
      status: 'success',
      message: 'Asesor creado correctamente!',
    });
    return null;
  };

  const handleUpdate = async () => {
    const { firstErrorMessage } = validateInputs({ picture: (val) => (!val ? 'La foto es requerida' : false) });
    if (firstErrorMessage) return setErrorMessage(firstErrorMessage);

    setStep(2);
    // handle primary picture
    let pictureUrl = values.picture;
    if (typeof pictureUrl !== 'string') {
      await storage.deletePicture(state.picture);
      pictureUrl = await uploadPicture(values.picture, `users/${state.id}`);
    }

    const response = await updateAdvicer(state.id, { ...values, picture: pictureUrl });
    if (response.error) {
      setStep(0);
      setErrorMessage(response.message);
      return null;
    }

    history.push('/dashboard/advisers');
    setNotification({
      status: 'success',
      message: 'asesor actualizado correctamente',
    });

    return null;
  };

  const handleAcept = () => {
    if (currentStep === 0) handleGeneralInfoAcept();
    if (currentStep === 1 && !state) handleUpload();
    if (currentStep === 1 && state) handleUpdate();
  };

  const handleBack = () => setStep((prevStep) => prevStep - 1);

  return (
    <Container className={styles.container}>
      <Card>
        <Stepper
          steps={[
            <>
              Datos <Hidden smDown>generales</Hidden>
            </>,
            'Multimedia',
          ]}
          activeStep={currentStep}
        />

        {currentStep === 0 && <GeneralInfo getInputProps={getInputProps} errorMessage={errorMessage} />}
        {currentStep === 1 && (
          <Multimedia
            errorMessage={errorMessage}
            onPictureDelete={handlePictureDelet}
            picture={values.picture}
            onPictureSelect={handlePictureSelect}
          />
        )}
        {currentStep === 2 && <Loading />}

        {currentStep <= 1 && (
          <Box marginTop="3em">
            <Grid container justify="flex-end">
              {!!currentStep && <ButtonAdmin onClick={handleBack}>Volver</ButtonAdmin>}
              <Box mr="1em" />
              <ButtonAdmin primary onClick={handleAcept}>
                {currentStep < 1 ? 'Siguente' : 'Guardar'}
              </ButtonAdmin>
            </Grid>
          </Box>
        )}
      </Card>
    </Container>
  );
}

export default CreateAdviser;
