// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_limiter__3iz27 {\n  width: 50%;\n  margin: 1em auto;\n}\n.styles_card__rVPxq {\n  padding: 5em\n}\n.styles_inputDivider__1Nvq9 {\n  display: flex;\n  flex-wrap: nowrap\n}\n\n.styles_inputDivider__1Nvq9 span {\n  display: block;\n  padding-right: 1em;\n}\n.styles_label__VxHhf {\n  font-size: .9em;\n  color: var(--gray);\n  font-weight: 600;\n}\n.styles_input__2k9gu {\n  border-color: #85858533;\n  background-color: #85858533;\n  color: var(--black);\n}\n\n@media screen and (max-width:1000px) {\n  .styles_limiter__3iz27 {\n    width: 80%;\n  }\n}\n@media screen and (max-width:8000px) {\n  .styles_card__rVPxq {\n    padding: 3em\n  }\n}\n@media screen and (max-width:500px) {\n  .styles_limiter__3iz27 {\n    width: 100%;\n  }\n  .styles_card__rVPxq {\n    padding: 1em\n  }\n}", "",{"version":3,"sources":["webpack://src/pages/dashboard/pages/activateAccount/styles.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,gBAAgB;AAClB;AACA;EACE;AACF;AACA;EACE,aAAa;EACb;AACF;;AAEA;EACE,cAAc;EACd,kBAAkB;AACpB;AACA;EACE,eAAe;EACf,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,uBAAuB;EACvB,2BAA2B;EAC3B,mBAAmB;AACrB;;AAEA;EACE;IACE,UAAU;EACZ;AACF;AACA;EACE;IACE;EACF;AACF;AACA;EACE;IACE,WAAW;EACb;EACA;IACE;EACF;AACF","sourcesContent":[".limiter {\n  width: 50%;\n  margin: 1em auto;\n}\n.card {\n  padding: 5em\n}\n.inputDivider {\n  display: flex;\n  flex-wrap: nowrap\n}\n\n.inputDivider span {\n  display: block;\n  padding-right: 1em;\n}\n.label {\n  font-size: .9em;\n  color: var(--gray);\n  font-weight: 600;\n}\n.input {\n  border-color: #85858533;\n  background-color: #85858533;\n  color: var(--black);\n}\n\n@media screen and (max-width:1000px) {\n  .limiter {\n    width: 80%;\n  }\n}\n@media screen and (max-width:8000px) {\n  .card {\n    padding: 3em\n  }\n}\n@media screen and (max-width:500px) {\n  .limiter {\n    width: 100%;\n  }\n  .card {\n    padding: 1em\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"limiter": "styles_limiter__3iz27",
	"card": "styles_card__rVPxq",
	"inputDivider": "styles_inputDivider__1Nvq9",
	"label": "styles_label__VxHhf",
	"input": "styles_input__2k9gu"
};
export default ___CSS_LOADER_EXPORT___;
