import { Backdrop } from '@material-ui/core';
import { string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import messagesSrc from 'src/assets/mensajes.png';
import chatModel from 'src/core/models/chat';
import programUserModel from 'src/core/models/programUsers';
import useFetch from 'src/hooks/useFetch';
import { useSession } from 'src/modules/session';
import styles from './styles.module.css';

const Notifications = (props) => {
  const history = useHistory();
  const session = useSession();
  const [chatId, setChatId] = useState(null);
  const [counter, setCounter] = useState(0);
  const [showNotification, setShowNotification] = useState(false);

  const showNotificationToggle = () => {
    setShowNotification((prevValue) => !prevValue);
  };

  const handleRedirectToViewMessage = () => {
    let dayIndex = new Date().getDay();
    if (dayIndex === 0) dayIndex = 5;
    else dayIndex -= 1;
    history.push(`/private/training/${dayIndex}`);
  };

  // fetch initial data
  useFetch(async () => {
    const userdata = await programUserModel.getSingle(session.data.id);
    if (userdata.advicer && userdata.programActive) {
      const responeChatId = await chatModel.getChatId(userdata.advicer, session.data.id);
      setChatId(responeChatId);
    }
  });

  // initialize realtime counter
  useEffect(() => {
    let unsubscribe;
    if (chatId) {
      unsubscribe = chatModel.onMessagesChange(chatId, (_messages, _advicerCounter, userCounter) => {
        setCounter(userCounter);
      });
    }
    return unsubscribe;
  }, [chatId]);

  return (
    <div className={styles.wrapper} onClick={showNotificationToggle} role="button">
      {!!counter && <div className={styles.counter}>{counter}</div>}
      <img src={messagesSrc} className={props.className} alt="icon" />
      <Backdrop open={showNotification} style={{ zIndex: 1 }} />
      {showNotification && (
        <div className={styles.notificationFloat}>
          {!!counter && (
            <>
              <div>
                Tienes <span>{counter}</span> {counter > 1 ? 'nuevos mensajes sin leer' : 'nuevo mensaje sin leer'}
              </div>
              <div className={styles.button} onClick={handleRedirectToViewMessage} role="button">
                Ver mensaje{counter > 1 && 's'}
              </div>
            </>
          )}
          {!counter && <>No tienes notificaciones</>}
        </div>
      )}
    </div>
  );
};

Notifications.defaultProps = {
  className: '',
};

Notifications.propTypes = {
  className: string,
};

export default Notifications;
