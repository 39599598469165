// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_list__1VLEZ {\n  display: flex;\n  justify-content: center;\n  align-items: stretch;\n  flex-wrap: wrap;\n}\n.styles_itemWrapper__DAC8H {\n  width: 33%;\n  padding: 2em;\n  box-sizing: border-box;\n}\n@media screen and (max-width:1100px) {\n  .styles_itemWrapper__DAC8H {\n    padding: 1em;\n  }\n}\n\n@media screen and (max-width:1000px) {\n  .styles_list__1VLEZ {\n    font-size: .9em;\n  }\n}\n\n@media screen and (max-width:850px) {\n  .styles_itemWrapper__DAC8H {\n    width: 50%;\n  }\n}\n\n@media screen and (max-width:600px) {\n  .styles_itemWrapper__DAC8H {\n    width: 100%;\n    max-width: 400px;\n  }\n}", "",{"version":3,"sources":["webpack://src/pages/community/styles.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,oBAAoB;EACpB,eAAe;AACjB;AACA;EACE,UAAU;EACV,YAAY;EACZ,sBAAsB;AACxB;AACA;EACE;IACE,YAAY;EACd;AACF;;AAEA;EACE;IACE,eAAe;EACjB;AACF;;AAEA;EACE;IACE,UAAU;EACZ;AACF;;AAEA;EACE;IACE,WAAW;IACX,gBAAgB;EAClB;AACF","sourcesContent":[".list {\n  display: flex;\n  justify-content: center;\n  align-items: stretch;\n  flex-wrap: wrap;\n}\n.itemWrapper {\n  width: 33%;\n  padding: 2em;\n  box-sizing: border-box;\n}\n@media screen and (max-width:1100px) {\n  .itemWrapper {\n    padding: 1em;\n  }\n}\n\n@media screen and (max-width:1000px) {\n  .list {\n    font-size: .9em;\n  }\n}\n\n@media screen and (max-width:850px) {\n  .itemWrapper {\n    width: 50%;\n  }\n}\n\n@media screen and (max-width:600px) {\n  .itemWrapper {\n    width: 100%;\n    max-width: 400px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": "styles_list__1VLEZ",
	"itemWrapper": "styles_itemWrapper__DAC8H"
};
export default ___CSS_LOADER_EXPORT___;
