/* eslint-disable no-await-in-loop */
/* eslint-disable no-new */
import { storageRef } from 'src/core/firebase';
import Compressor from 'compressorjs';

const meta = {
  cacheControl: 'public, max-age=604800',
};

const resizeFile = (file) =>
  new Promise((resolve) => {
    new Compressor(file, {
      quality: 0.7,
      maxWidth: 1800,
      success: (result) => {
        resolve(result);
      },
      error: () => {
        resolve(null);
      },
    });
  });

export const uploadPicture = async (file, path, name) => {
  const fileCompresed = await resizeFile(file);
  const time = Date.now();
  const computedName = name || `${time}_${file.name}`;
  let fullPath = `${computedName}`;
  if (path) fullPath = `${path}/${computedName}`;
  const { ref } = await storageRef.child(fullPath).put(fileCompresed, meta);
  const fullUrl = await ref.getDownloadURL();
  const url = fullUrl.split('&token')[0];
  return url;
};

export const deletePicture = async (url) => {
  try {
    const decodedUrl = decodeURIComponent(url);
    let path = decodedUrl.split('/o/')[1];
    [path] = path.split('?');
    await storageRef.child(path).delete();
  } catch (error) {
    console.log('deletePictureError', error);
  }
};

export const uploadMultiplePicture = async (files, path, name) => {
  const urls = [];
  for (const file of files) {
    const url = await uploadPicture(file, path, name);
    urls.push(url);
  }
  console.log('urls', urls);
  return urls;
};

export const uploadFile = async (file, path, name) => {
  const time = Date.now();
  const computedName = name || `${time}_${file.name}`;
  let fullPath = `${computedName}`;
  if (path) fullPath = `${path}/${computedName}`;
  const { ref } = await storageRef.child(fullPath).put(file, meta);
  const fullUrl = await ref.getDownloadURL();
  const url = fullUrl.split('&token')[0];
  return url;
};

const storage = {
  uploadPicture,
  deletePicture,
  uploadMultiplePicture,
};

export default storage;
