import { failed } from '../../helpers/response';
import { functions } from '../firebase';

/**
 * payWithStore
 * @param {Object} values
 * @param {Number} values.total total to pay
 * @param {String} values.deviceId openpay device id
 * @param {String} values.email buyyer email
 * @param {String} values.name buyyer name
 * @returns {Promise<Object>}
 */
export const payWithStore = async (values) => {
  const response = await functions.httpsCallable('payWithStore')(values);
  return response.data;
};

/**
 * payWithStore
 * @param {Object} values
 * @param {Number} values.total total to pay
 * @param {String} values.deviceId openpay device id
 * @param {String} values.email buyyer email
 * @param {String} values.name buyyer name
 * @returns {Promise<Object>}
 */
export const payWithSpei = async (values) => {
  const response = await functions.httpsCallable('payWithSpei')(values);
  return response.data;
};

/**
 * payWithStore
 * @param {Object} values
 * @param {Number} values.total
 * @param {String} values.deviceId
 * @param {String} values.email
 * @param {String} values.name
 * @param {String} values.cardNumber
 * @param {String} values.cardName
 * @param {String} values.cardYear
 * @param {String} values.cardMonth
 * @param {String} values.cardCode
 * @returns {Promise<Object>}
 */
export const payWithCard = async (values) => {
  const token = await new Promise((resolve) => {
    window.OpenPay.token.create(
      {
        card_number: values.cardNumber,
        holder_name: values.cardName,
        expiration_year: values.cardYear,
        expiration_month: values.cardMonth,
        cvv2: values.cardCode,
      },
      (res) => resolve(res.data.id),
      () => resolve(null),
    );
  });

  if (!token) return failed('Tarjeta rechazada');

  const response = await functions.httpsCallable('payWithCard')({
    token,
    total: values.total,
    deviceId: values.deviceId,
    email: values.email,
    name: values.name,
  });
  return response.data;
};

const openPayModel = {
  payWithStore,
  payWithSpei,
  payWithCard,
};

export default openPayModel;
