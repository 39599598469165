import { string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import Youtube from 'react-youtube';
import { usePlayer } from '../../../../modules/youtubePlayer';
import styles from './styles.module.css';

const TutorialCard = (props) => {
  const [size, setSize] = useState({ width: 0, height: 0 });
  const player = usePlayer();

  const handleCalculateSizes = () => {
    const width = document.getElementById('video-container').offsetWidth;
    const height = Math.ceil(width / 100) * 56;
    if (size.width !== width || size.height !== height) setSize({ width, height });
  };

  useEffect(() => {
    handleCalculateSizes();
    window.addEventListener('resize', handleCalculateSizes);
    return () => window.removeEventListener('resize', handleCalculateSizes);
  }, []);

  const opts = {
    height: size.height,
    width: size.width,
    showinfo: 0,
    playerVars: { autoplay: 0, controls: 1, showinfo: 0, autohide: 1, modestbranding: 1 },
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.videoWraper} id="video-container">
        <div className={styles.shadow} role="button" onClick={() => player.play(props.video)} aria-label="play" />
        <Youtube videoId={props.video} opts={opts} className={styles.player} />
      </div>
      <div className={styles.data}>
        <div className={styles.title}>{props.title}</div>
      </div>
    </div>
  );
};

TutorialCard.defaultProps = {};

TutorialCard.propTypes = {
  title: string.isRequired,
  video: string.isRequired,
};

export default TutorialCard;
