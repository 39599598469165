const computeInitialFormState = (state) => {
  if (!state) return { pictures: [] };

  return {
    title: state.title,
    description: state.description,
    picture: state.picture,
    pictures: state.pictures,
    date: state.date,
    youtube: state.youtube || '',
    price: state.price ? state.price.toString() : '',
  };
};

export default computeInitialFormState;
