/* eslint-disable no-await-in-loop */
import database from '../database';
import { functions } from '../firebase';

export const createNewAdvicer = async (values) => {
  const response = await functions.httpsCallable('createNewAdvicer')(values);
  return response.data;
};

export const updateAdvicer = async (id, data) => {
  const response = await functions.httpsCallable('updateAdvicer')({ id, ...data });
  return response.data;
};

export const deleteAdvicer = async (advicerId) => {
  const response = await functions.httpsCallable('deleteAdvicer')({ id: advicerId });
  return response.data;
};

export const getUsersByAdvicer = async (advicerId) => {
  const users = await database.getList('users', null, null, [['advicer', '==', advicerId]]).next();
  return users.map(({ password, ...userData }) => userData);
};

export const getSingleAdvicer = async (advicerId) => {
  const data = await database.getDocument(`users/${advicerId}`);
  delete data.password;
  return data;
};

export const getList = async () => {
  const list = await database.getList('users', null, null, [['type', '==', 'advicer']]).next();
  const composed = [];
  for (const current of list) {
    try {
      const users = await getUsersByAdvicer(current.id);
      const usersNumber = users ? users.length : 0;
      composed.push({ ...current, usersNumber });
    } catch (error) {
      composed.push({ ...current });
    }
  }
  return composed;
};

export default {
  createNewAdvicer,
  getList,
  updateAdvicer,
  deleteAdvicer,
  getUsersByAdvicer,
  getOne: getSingleAdvicer,
};
