// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_icon__3OAvd {\n  width: 6em;\n}\n.styles_editIcon__RG7YY {\n  font-size: 1.3em!important;\n}", "",{"version":3,"sources":["webpack://src/pages/dashboard/pages/programList/components/program/styles.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;AACZ;AACA;EACE,0BAA0B;AAC5B","sourcesContent":[".icon {\n  width: 6em;\n}\n.editIcon {\n  font-size: 1.3em!important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "styles_icon__3OAvd",
	"editIcon": "styles_editIcon__RG7YY"
};
export default ___CSS_LOADER_EXPORT___;
