import React from 'react';
import { createMuiTheme, ThemeProvider as ThemeProviderMaterial } from '@material-ui/core';
import { array, element, object, string, oneOfType } from 'prop-types';
import { colors } from 'src/constants';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: colors.cyan,
    },
    secondary: {
      main: colors.rose,
    },
  },
});

const ThemeProvider = ({ children }) => <ThemeProviderMaterial theme={theme}>{children}</ThemeProviderMaterial>;

ThemeProvider.propTypes = {
  children: oneOfType([string, array, object, element]).isRequired,
};

export default ThemeProvider;
