import { Grid, Hidden } from '@material-ui/core';
import { PlayCircleFilled } from '@material-ui/icons';
import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../../../../components/button';
import Container from '../../../../components/container';
import Picture from '../../../../components/picture';
import composeClassNames from '../../../../helpers/composeClassNames';
import useTestimonialLits from '../../../../hooks/useTestimonialList';
import styles from './styles.module.css';

const Community = () => {
  const { items, loading } = useTestimonialLits({ limit: 2 });

  if (loading || !items.length) return false;

  return (
    <div>
      <Container disabledVerticalPadding>
        <div className={styles.body}>
          <div className={styles.title}>
            Lo que hagas Hoy, importa{' '}
            <Hidden xsDown>
              {' '}
              <br />
            </Hidden>
            como te sentirás mañana{' '}
            <Hidden xsDown>
              {' '}
              <br />
              <br />
            </Hidden>
          </div>
          <div className={styles.grid}>
            {items.map((item, index) => (
              <div className={styles.cardWrapper} key={index}>
                <div className={styles.card}>
                  <Picture className={styles.picture} src={item.picture} height={90} />
                  <div className={styles.dataWrappr}>
                    <div className={styles.sign}>&ldquo;</div>
                    <div className={styles.name}>{item.name}</div>
                    <div className={styles.description}>{item.description}</div>
                    {item.video && (
                      <a
                        target="_blank"
                        href={item.video}
                        className={composeClassNames(styles.description, styles.video)}
                        rel="noreferrer"
                      >
                        <PlayCircleFilled />
                        Ver video
                      </a>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <Grid container justify="center">
            <Link to="/testimonios">
              <Button className={styles.button}>VER TESTIMONIALES</Button>
            </Link>
          </Grid>
        </div>
      </Container>
    </div>
  );
};

export default Community;
