// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_fullWrapper__3KGZT {\n  background-color: #f1f1f1;\n}\n.styles_title__xBsxb {\n  text-align: center;\n  font-size: 2em;\n  font-weight: 600;\n  color: var(--rose);\n  padding: 1.5em 0px;\n  width: 33%;\n  line-height: 1.2em;\n  margin: auto;\n}\n\n@media screen and (max-width:1000px) {\n  .styles_fullWrapper__3KGZT {\n    font-size: .8em;\n  }\n  .styles_title__xBsxb {\n    width: 40%;\n  }\n}\n\n@media screen and (max-width:600px) {\n  .styles_fullWrapper__3KGZT {\n    font-size: .6em;\n  }\n  .styles_title__xBsxb {\n    font-size: 2em;\n    padding-top: 1.5em;\n    padding-bottom: 2em;\n    width: 80%;\n  }\n};", "",{"version":3,"sources":["webpack://src/pages/training/components/title/styles.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;AACA;EACE,kBAAkB;EAClB,cAAc;EACd,gBAAgB;EAChB,kBAAkB;EAClB,kBAAkB;EAClB,UAAU;EACV,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE;IACE,eAAe;EACjB;EACA;IACE,UAAU;EACZ;AACF;;AAEA;EACE;IACE,eAAe;EACjB;EACA;IACE,cAAc;IACd,kBAAkB;IAClB,mBAAmB;IACnB,UAAU;EACZ;AACF","sourcesContent":[".fullWrapper {\n  background-color: #f1f1f1;\n}\n.title {\n  text-align: center;\n  font-size: 2em;\n  font-weight: 600;\n  color: var(--rose);\n  padding: 1.5em 0px;\n  width: 33%;\n  line-height: 1.2em;\n  margin: auto;\n}\n\n@media screen and (max-width:1000px) {\n  .fullWrapper {\n    font-size: .8em;\n  }\n  .title {\n    width: 40%;\n  }\n}\n\n@media screen and (max-width:600px) {\n  .fullWrapper {\n    font-size: .6em;\n  }\n  .title {\n    font-size: 2em;\n    padding-top: 1.5em;\n    padding-bottom: 2em;\n    width: 80%;\n  }\n};"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fullWrapper": "styles_fullWrapper__3KGZT",
	"title": "styles_title__xBsxb"
};
export default ___CSS_LOADER_EXPORT___;
