import React from 'react';
import { useParams } from 'react-router-dom';
import TrainingBody from '../../../training/components/trainingBody';

const TrainingUser = () => {
  const params = useParams();

  return <TrainingBody userId={params.userId} />;
};

export default TrainingUser;
