import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { getAll } from '../../core/models/product';
import { getAsProduct } from '../../core/models/program';
import responseHelper from '../../helpers/response';
import useFetch from '../../hooks/useFetch';
import useCart from '../../modules/cart/useCart';
import useNotification from '../../modules/notifications/useNotification';
import ProductsView from './view';

const Products = () => {
  const { methods } = useCart();
  const setNotification = useNotification();
  const { state = {} } = useLocation();
  const history = useHistory();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  let productsFiltered = products;

  // filter by search
  if (state.search) {
    const searchNormalized = state.search.toString().toLowerCase();
    productsFiltered = productsFiltered.filter((item) => {
      const titleNormalized = item.title.toString().toLowerCase();
      return titleNormalized.includes(searchNormalized);
    });
  }

  // filter by category
  if (state.category) {
    productsFiltered = productsFiltered.filter((item) => item.category === state.category);
  }

  // filter by subCategory
  if (state.subCategory) {
    productsFiltered = productsFiltered.filter((item) => item.subCategory === state.subCategory);
  }

  // filter by gender
  if (state.gender) {
    productsFiltered = productsFiltered.filter((item) => item.gender === state.gender);
  }

  useFetch(async () => {
    let responseProducts = await getAll({ onlyInStock: true });
    const programItems = await getAsProduct();
    if (programItems && programItems.length) responseProducts = [...programItems, ...responseProducts];
    setProducts(responseProducts);
    setLoading(false);
  });

  const handleSetFilters = (filters) => {
    history.replace({ state: filters });
  };

  const handleBuyNow = (item) => {
    history.push('/mi-carrito-digital', item);
  };

  const handleAddToCart = (product) => {
    methods.addToCart(product, 1);
    setNotification(responseHelper.success('Producto agregado al carrito'));
  };

  return (
    <ProductsView
      onChangeFilter={handleSetFilters}
      filters={state || {}}
      loading={loading}
      products={productsFiltered}
      onAddToCart={handleAddToCart}
      onBuyNow={handleBuyNow}
    />
  );
};

export default Products;
