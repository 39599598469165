import { func, string } from 'prop-types';
import React, { useRef } from 'react';
import Input from 'src/components/inputs';
import Dropzone from 'src/components/dropzone';
import { AtomicBlockUtils, EditorState } from 'draft-js';
import LabelInput from 'src/components/labelInput';
import normalizeImagesUrl from 'src/helpers/normalizeImagesUrl';
import { Box, Grid } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import Picture from 'src/components/picture';
import composeClassNames from 'src/helpers/composeClassNames';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ErrorMessage from 'src/components/errorMessage';
import { TipsCategory } from 'src/constants';
import { uploadPicture } from 'src/core/storage';
import ButtonAdmin from '../../../../components/button';
import styles from './styles.module.css';

const Form = (props) => {
  const editorRef = useRef();

  const handleCoverSelect = (newImage) => {
    const { onChange } = props.getInputProps('cover');
    onChange({ target: { name: 'cover', value: newImage } });
  };

  const handleCoverDelete = () => {
    const { onChange } = props.getInputProps('cover');
    onChange({ target: { name: 'cover', value: null } });
  };

  const handleGetCoverValue = () => {
    const { value } = props.getInputProps('cover');
    return value;
  };

  const getEditorProps = () => {
    const { onChange, value } = props.getInputProps('body');
    return {
      onEditorStateChange: (editorValue) => {
        onChange({ target: { name: 'body', value: editorValue } });
      },
      editorState: value,
    };
  };

  const composeStateEditor = (editorState, source) => {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity('IMAGE', 'MUTABLE', { src: source });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });
    return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' ');
  };

  const handleAddImageIntoBody = async (newFile) => {
    const fileExtension = '.png';
    const filename = `___${Date.now()}___${fileExtension}`;
    const fileurl = await uploadPicture(newFile, 'tips/body', filename);
    const { editorState, onEditorStateChange } = getEditorProps();
    const newEditorState = composeStateEditor(editorState, fileurl);
    onEditorStateChange(newEditorState);
  };

  return (
    <div role="form">
      <div className={styles.formHead}>
        <div className={styles.formHeadDivided}>
          <ErrorMessage message={props.errorMessage} />
          <LabelInput verticalPadding=".5em" label="Titulo">
            <Input {...props.getInputProps('title')} maxLength={150} />
          </LabelInput>
          <LabelInput verticalPadding=".5em" label="Categoría">
            <Input {...props.getInputProps('category')} type="select" options={TipsCategory} />
          </LabelInput>
        </div>
        <div className={styles.formHeadDividedImage}>
          {!handleGetCoverValue() && (
            <Dropzone className={composeClassNames(styles.dropzone)} onChange={handleCoverSelect}>
              Imágen de portada
            </Dropzone>
          )}
          {!!handleGetCoverValue() && (
            <Box position="relative" className={styles.imageCover}>
              <Delete className={styles.deleteCover} onClick={handleCoverDelete} />
              <Picture height={80} src={normalizeImagesUrl(handleGetCoverValue())} alt="cover" />
            </Box>
          )}
        </div>
      </div>
      <div className={styles.divider} />
      <Box marginTop="2em" marginBottom="3em" onClick={() => editorRef.current.editor.focus()}>
        <Box marginBottom="1em">
          <Dropzone onChange={handleAddImageIntoBody}>
            <ButtonAdmin>Agregar imagen</ButtonAdmin>
          </Dropzone>
        </Box>
        <Editor {...getEditorProps()} wrapperClassName={styles.editor} ref={editorRef} />
      </Box>
      <Grid container justify="flex-end">
        <ButtonAdmin onClick={props.onSave} primary>
          Guardar
        </ButtonAdmin>
      </Grid>
    </div>
  );
};

Form.defaultProps = {
  errorMessage: '',
};

Form.propTypes = {
  getInputProps: func.isRequired,
  onSave: func.isRequired,
  errorMessage: string,
};

export default Form;
