import { Collapse } from '@material-ui/core';
import { oneOfType, string, number } from 'prop-types';
import React from 'react';
import styles from './styles.module.css';

const ErrorMessage = ({ message, marginBottom }) => {
  const inlineStyles = { marginBottom };

  return (
    <Collapse in={!!message}>
      <div className={styles.wrapper} style={inlineStyles}>
        {message}
      </div>
    </Collapse>
  );
};

ErrorMessage.defaultProps = {
  marginBottom: '.5em',
  message: null,
};

ErrorMessage.propTypes = {
  message: oneOfType([string, number]),
  marginBottom: string,
};

export default ErrorMessage;
