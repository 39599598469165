import { Box } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import { oneOf, array, func } from 'prop-types';

import React from 'react';
import Picture from 'src/components/picture';
import Options from '../../../../../../components/options';
import composeClassNames from '../../../../../../helpers/composeClassNames';
import { toLargeStringDate } from '../../../../../../helpers/dates';
import formatPrice from '../../../../../../helpers/formatPrice';
import styles from './styles.module.css';

const EventsList = ({ items, getOptions, ...props }) => (
  <div className={styles.productList}>
    <Box width="100%" marginBottom="2em">
      <div className={styles.timeSelectorWrapper}>
        <div
          role="button"
          onClick={() => props.onViewChange(null)}
          className={composeClassNames(styles.timeOption, !props.currentView ? styles.timeOptionSelected : '')}
        >
          Todos
        </div>
        <Box paddingRight="1em" />
        <div
          role="button"
          onClick={() => props.onViewChange('next')}
          className={composeClassNames(
            styles.timeOption,
            props.currentView === 'next' ? styles.timeOptionSelected : '',
          )}
        >
          Próximos eventos
        </div>
        <Box paddingRight="1em" />
        <div
          role="button"
          onClick={() => props.onViewChange('past')}
          className={composeClassNames(
            styles.timeOption,
            props.currentView === 'past' ? styles.timeOptionSelected : '',
          )}
        >
          Eventos pasados
        </div>
      </div>
    </Box>
    {items.map((currentItem) => (
      <div className={styles.itemWrapper} key={currentItem.id}>
        <div className={styles.item}>
          <Options className={styles.options} optionsList={getOptions(currentItem)}>
            Opciones
            <ArrowDropDown />
          </Options>
          <Picture className={styles.picture} src={currentItem.picture} alt={currentItem.title} height={80} />
          <div className={styles.dataWrapper}>
            {!!currentItem.price && (
              <Box textAlign="center" marginBottom=".5em" fontWeight="800" color="var(--cyan)">
                $ {formatPrice(currentItem.price)}
              </Box>
            )}
            {!currentItem.price && (
              <Box textAlign="center" marginBottom=".5em" fontWeight="800" color="var(--cyan)">
                Gratis
              </Box>
            )}
            <div className={styles.date}>{toLargeStringDate(currentItem.date)}</div>
            <div className={styles.title}>{currentItem.title}</div>
          </div>
        </div>
      </div>
    ))}
  </div>
);

EventsList.defaultProps = {
  currentView: null,
};

EventsList.propTypes = {
  items: array.isRequired,
  getOptions: func.isRequired,
  currentView: oneOf(['past', 'next']),
  onViewChange: func.isRequired,
};

export default EventsList;
