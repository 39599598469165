import { Box, Grid } from '@material-ui/core';
import { Check, Delete, Edit } from '@material-ui/icons';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toLargeStringDate } from 'src/helpers/dates';
import Input from '../../../../components/inputs';
import Loading from '../../../../components/loading';
import PurchaseSummary from '../../../../components/purchaseSummary';
import { formsPay, gendersBin, methodPay, payStatus } from '../../../../constants';
import { deletePurchase } from '../../../../core/models/purchases';
import { failed } from '../../../../helpers/response';
import usePurchaseDetail from '../../../../hooks/usePurchaseDetail';
import useAlert from '../../../../modules/alert/useAlert';
import useNotification from '../../../../modules/notifications/useNotification';
import ButtonAdmin from '../../components/button';
import styles from './styles.module.css';

const SaleDetail = () => {
  const history = useHistory();
  const setNotification = useNotification();
  const { loading, data, setStatus } = usePurchaseDetail();
  const [editorOpen, setEditorOpen] = useState(false);
  const setAlert = useAlert();

  const handlleEditorOpen = () => setEditorOpen(true);
  const handlleEditorClose = () => setEditorOpen(false);

  const handleChange = (e) => {
    setStatus(e.target.value);
  };

  const handleActivateAcount = () => {
    if (data.status === 'approved') history.push(`/dashboard/activate-acount/${data.id}`);
    else setNotification(failed('No se puede activar la cuenta con estado de pago "pendiente"'));
  };

  const handleDelete = () =>
    setAlert({
      title: '¿Seguro quieres elimanar esta venta?',
      action: async () => {
        const response = await deletePurchase(data.id);
        setNotification(response);
        history.replace('/dashboard/sales');
      },
    });

  const conditionStatusPay = data && data.status === 'approved' ? styles.payed : styles.pending;

  return (
    <div className={styles.wrapepr}>
      {loading && <Loading />}
      {!loading && (
        <div className={styles.card}>
          <Grid container>
            <Grid item xs={12} sm={8}>
              <div className={styles.row}>
                <div className={styles.describe}>Estado de pago</div>
                {!editorOpen && (
                  <div
                    className={`${styles.value} ${conditionStatusPay}`}
                    onClick={handlleEditorOpen}
                    role="button"
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    {payStatus[data.status]} <Edit style={{ fontSize: '1.2em', marginLeft: '.5em' }} />
                  </div>
                )}
                {editorOpen && (
                  <Input
                    type="select"
                    options={payStatus}
                    value={data.status}
                    className={styles.select}
                    onChange={handleChange}
                    onBlur={handlleEditorClose}
                  />
                )}
                <Box
                  marginLeft="1.5em"
                  display="flex"
                  alignItems="center"
                  fontWeight="normal"
                  style={{ cursor: 'pointer' }}
                  onClick={handleDelete}
                >
                  <Delete className={styles.deleteIcon} />
                  Eliminar
                </Box>
              </div>
              <div className={styles.row}>
                <div className={styles.describe}>Fecha de compra</div>
                <div className={styles.value}>{toLargeStringDate(data.createdAt)}</div>
              </div>
              {data.formPay && (
                <div className={styles.row}>
                  <div className={styles.describe}>Forma de pago:</div>
                  <div className={styles.value}>{[formsPay[data.formPay]]}</div>
                </div>
              )}
              <div className={styles.row}>
                <div className={styles.describe}>ID:</div>
                <div className={styles.value}>{data.id}</div>
              </div>
            </Grid>
            {data.digital && (
              <Grid item xs={12} sm={4} container alignItems="center" justify="center">
                <Box padding="1em" paddingBottom="0em">
                  {!data.accountActivated && (
                    <ButtonAdmin primary color="var(--rose)" onClick={handleActivateAcount}>
                      Activar cuenta
                    </ButtonAdmin>
                  )}
                  {data.accountActivated && (
                    <ButtonAdmin id={styles.buttonAcountActivated}>
                      <>
                        <Check />
                        Cuenta Activada
                      </>
                    </ButtonAdmin>
                  )}
                </Box>
              </Grid>
            )}
          </Grid>
          <div className={styles.group}>
            <PurchaseSummary data={data} hiddAddress />
          </div>

          <div className={styles.group}>
            <div className={styles.groupTitle}>Datos del cliente</div>
            <div className={styles.row}>
              <div className={styles.describe}>Nombre:</div>
              <div className={styles.value}>{`${data.userInfo.name} ${data.userInfo.lastName}`}</div>
            </div>
            <div className={styles.row}>
              <div className={styles.describe}>Correo:</div>
              <div className={styles.value}>{data.userInfo.email}</div>
            </div>
            <div className={styles.row}>
              <div className={styles.describe}>Teléfono:</div>
              <div className={styles.value}>{data.userInfo.phone}</div>
            </div>
            <div className={styles.row}>
              <div className={styles.describe}>Método de pago:</div>
              <div className={styles.value}>{methodPay[data.userInfo.methodPay]} </div>
            </div>
            {!data.digital && (
              <>
                <Box marginTop="2em">
                  <div className={styles.groupTitle}>Datos de envío</div>
                </Box>
              </>
            )}
            <div className={styles.row}>
              <div className={styles.describe}>Calle y número:</div>
              <div className={styles.value}>{`${data.userInfo.streetAndNumber} `}</div>
            </div>
            <div className={styles.row}>
              <div className={styles.describe}>Colonia:</div>
              <div className={styles.value}>{`${data.userInfo.suburb} `}</div>
            </div>
            <div className={styles.row}>
              <div className={styles.describe}>Código postal:</div>
              <div className={styles.value}>{`${data.userInfo.postalCode} `}</div>
            </div>
            <div className={styles.row}>
              <div className={styles.describe}>Ciudad:</div>
              <div className={styles.value}>{`${data.userInfo.city} `}</div>
            </div>
            <div className={styles.row}>
              <div className={styles.describe}>Estado</div>
              <div className={styles.value}>{data.userInfo.state}</div>
            </div>
            {data.userInfo.birthDate && (
              <div className={styles.row}>
                <div className={styles.describe}>Cumpleaños</div>
                <div className={styles.value}>{data.userInfo.birthDate}</div>
              </div>
            )}
            {data.userInfo.country && (
              <div className={styles.row}>
                <div className={styles.describe}>Pais</div>
                <div className={styles.value}>{data.userInfo.country}</div>
              </div>
            )}
            {data.userInfo.gender && (
              <div className={styles.row}>
                <div className={styles.describe}>Género</div>
                <div className={styles.value}>{gendersBin[data.userInfo.gender]}</div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SaleDetail;
