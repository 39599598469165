/* eslint-disable no-unused-vars */
import { Box, ButtonBase, Grid } from '@material-ui/core';
import { Cake, Edit, Email, Event, Person, Phone, Wc } from '@material-ui/icons';
import React, { useEffect, useMemo, useState } from 'react';
import userAvatarSrc from 'src/assets/user.png';
import { gendersBin } from 'src/constants';
import { toLargeStringDate } from 'src/helpers/dates';
import useFetch from 'src/hooks/useFetch';
import Loading from 'src/components/loading';
import programUserModel, { updateProgramUser } from 'src/core/models/programUsers';
import training from 'src/helpers/training';
import normalizeUrlImage from 'src/helpers/normalizeImagesUrl';
import Dropzone from 'src/components/dropzone';
import { bool, string } from 'prop-types';
import composeClassNames from 'src/helpers/composeClassNames';
import useForm from 'src/hooks/useForm';
import Input from 'src/components/inputs';
import formatObect from 'src/helpers/formatObject';
import Advicer from '../advicer';
import styles from './styles.module.css';
import validaotors from '../../../../helpers/getValidators';
import useErrorMessage from '../../../../hooks/useErrorMessage';
import ErrorMessage from '../../../../components/errorMessage';
import useNotification from '../../../../modules/notifications/useNotification';
import { success } from '../../../../helpers/response';
import { useSession } from '../../../../modules/session';
import Picture from '../../../../components/picture';

export const getRules = () => ({
  birthDate: validaotors.input('La fecha de nacimiento es requerida'),
  gender: validaotors.input('El género es requerido'),
  phone: validaotors.phone(),
  country: validaotors.input('El país es requerido'),
  state: validaotors.input('El Estado es requerido'),
  city: validaotors.input('La ciudad es requerida'),
  suburb: validaotors.input('La colonia es requerida'),
  postalCode: validaotors.input('El código postal no es válido'),
  streetAndNumber: validaotors.input('Calle y número es requerido'),
  name: validaotors.input('El nombre es requerido'),
});

const UserProfileBody = (props) => {
  const session = useSession();
  const setNotification = useNotification();
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(false);
  const [isDataEditin, setDataEditin] = useState(false);
  const [isPictureEditin, setIsPIctureEditing] = useState(false);
  const { getInputProps, setValues, validateInputs, values } = useForm({}, getRules());
  const { setErrorMessage, errorMessage } = useErrorMessage();

  const currentPreviewPicture = normalizeUrlImage(values.picture || userAvatarSrc);

  // E F F E C  T S
  const handleFetch = async () => {
    const responseUserData = await programUserModel.getSingle(props.userId);
    setUserData(responseUserData);
    setLoading(false);
  };

  // sync user data with form
  useEffect(() => {
    setValues(userData);
  }, [userData]);

  const programStartDate = useMemo(() => (userData ? training.getTrainingStartDate(userData) : ''), [userData]);
  const currentWeek = useMemo(() => (userData ? training.getCurrentWeek(userData) : ''), [userData]);

  // fetch initialData
  useFetch(handleFetch);

  // C O N T R O L L E R S
  const handleSaveNewPIcture = async () => {
    setIsPIctureEditing(false);
    await updateProgramUser(props.userId, { picture: values.picture });
    await handleFetch();
    setNotification(success('Foto actualizado correctamente'));
    if (session.data.id === props.userId) session.refreshSession();
  };

  const handleSaveGeneralInfo = async () => {
    const { firstErrorMessage } = validateInputs();
    if (firstErrorMessage) {
      setErrorMessage(firstErrorMessage);
    } else {
      setDataEditin(false);
      await updateProgramUser(props.userId, values);
      await handleFetch();
      setNotification(success('Perfil actualizado correctamente'));
      if (session.data.id === props.userId) session.refreshSession();
    }
  };

  const handleSelectFile = (file) => {
    setIsPIctureEditing(true);
    setValues((prevVals) => ({ ...prevVals, picture: file }));
  };

  const handleCloseImageEditor = () => {
    setIsPIctureEditing(false);
    setValues(userData);
  };

  if (loading) return <Loading message="Cargando perfil" />;

  // i n t e r f a c e
  return (
    <div className={styles.wrapper}>
      <div className={styles.basicInfo}>
        <Picture src={currentPreviewPicture} alt="user-avatar" className={styles.picture} height={100} />
        {!props.disabledEdit && (
          <>
            {!isPictureEditin && (
              <Dropzone onChange={handleSelectFile}>
                <ButtonBase className={styles.buttonPhotoEdit}>
                  <Edit /> cambiar foto
                </ButtonBase>
              </Dropzone>
            )}
            {!!isPictureEditin && (
              <Grid container>
                <ButtonBase className={styles.buttonPhotoEdit} onClick={handleCloseImageEditor}>
                  Cancelar
                </ButtonBase>
                <ButtonBase
                  onClick={handleSaveNewPIcture}
                  className={composeClassNames(styles.buttonPhotoEdit, styles.buttonPhotoSave)}
                >
                  Guardar
                </ButtonBase>
              </Grid>
            )}
          </>
        )}
        <div className={styles.name}>{userData.name}</div>
      </div>
      <div className={styles.otherInfo}>
        <Box marginBottom="2em" position="relative">
          {!isDataEditin && (
            <ButtonBase className={styles.ToEditInfo} onClick={() => setDataEditin(true)}>
              <Edit />
              Editar
            </ButtonBase>
          )}

          <Box marginBottom="1em" fontWeight="bold">
            Datos Básicos
          </Box>
          <ErrorMessage message={errorMessage} />
          <div className={styles.infoRow}>
            <Person />
            <div className={styles.rowBody}>
              {!isDataEditin ? <div>{values.name}</div> : <Input className={styles.input} {...getInputProps('name')} />}
              <span>Nombre completo</span>
            </div>
          </div>

          <div className={styles.infoRow}>
            <Email />
            <div className={styles.rowBody}>
              <div>{values.email}</div>
              <span>Correo Electrónico</span>
            </div>
          </div>
          <div className={styles.infoRow}>
            <Phone />
            <div className={styles.rowBody}>
              {!isDataEditin ? (
                <div>{values.phone}</div>
              ) : (
                <Input className={styles.input} {...getInputProps('phone')} maxLength="10" onlyNumbers />
              )}
              <span>Teléfono</span>
            </div>
          </div>
          <div className={styles.infoRow}>
            <Cake />
            <div className={styles.rowBody}>
              {isDataEditin ? (
                <Input className={styles.input} {...getInputProps('birthDate')} />
              ) : (
                <div>{values.birthDate}</div>
              )}
              <span>Fecha de nacimiento</span>
            </div>
          </div>
          <div className={styles.infoRow}>
            <Wc />
            <div className={styles.rowBody}>
              {isDataEditin ? (
                <Input className={styles.input} {...getInputProps('gender')} options={gendersBin} type="select" />
              ) : (
                <div>{gendersBin[values.gender]}</div>
              )}
              <span>Género</span>
            </div>
          </div>
        </Box>
        <Box marginBottom="2em">
          <Box marginBottom="1em" fontWeight="bold">
            Dirección
          </Box>
          {!isDataEditin && (
            <>
              <div className={styles.infoRow}>
                <div>
                  <div>
                    {`${values.streetAndNumber},  colonia: ${values.suburb}, C.P.:${values.postalCode}, ${values.city}, ${values.state}, ${values.country}`}
                  </div>
                </div>
              </div>
            </>
          )}
          {!!isDataEditin && (
            <>
              <div className={styles.infoRow}>
                <div className={styles.rowBody}>
                  <Input className={styles.input} {...getInputProps('streetAndNumber')} />
                  <span>Calle y número</span>
                </div>
              </div>
              <div className={styles.infoRow}>
                <div className={styles.rowBody}>
                  <Input className={styles.input} {...getInputProps('suburb')} />
                  <span>Colonia</span>
                </div>
              </div>
              <div className={styles.infoRow}>
                <div className={styles.rowBody}>
                  <Input className={styles.input} {...getInputProps('postalCode')} onlyNumbers maxLength={5} />
                  <span>Código postal</span>
                </div>
              </div>
              <div className={styles.infoRow}>
                <div className={styles.rowBody}>
                  <Input className={styles.input} {...getInputProps('city')} />
                  <span>Ciudad</span>
                </div>
              </div>
              <div className={styles.infoRow}>
                <div className={styles.rowBody}>
                  <Input className={styles.input} {...getInputProps('state')} />
                  <span>Estado</span>
                </div>
              </div>
              <div className={styles.infoRow}>
                <div className={styles.rowBody}>
                  <Input className={styles.input} {...getInputProps('country')} />
                  <span>País</span>
                </div>
              </div>
              <Box marginTop="0em" marginBottom="4em">
                <Grid container>
                  <ButtonBase className={styles.buttonPhotoEdit} onClick={() => setDataEditin(false)}>
                    Cancelar
                  </ButtonBase>
                  <ButtonBase
                    className={composeClassNames(styles.buttonPhotoEdit, styles.buttonPhotoSave)}
                    onClick={handleSaveGeneralInfo}
                  >
                    Guardar
                  </ButtonBase>
                </Grid>
              </Box>
            </>
          )}
        </Box>
        <Box marginBottom="2em">
          <Box marginBottom="1em" fontWeight="bold">
            Programa
          </Box>
          {values.programActive && (
            <>
              {!!programStartDate && (
                <div className={styles.infoRow}>
                  <Event />
                  <div>
                    <div>{toLargeStringDate(programStartDate)}</div>
                    <span>Fecha de inicio</span>
                  </div>
                </div>
              )}
              {!!currentWeek && (
                <div className={styles.infoRow}>
                  <Event />
                  <div>
                    <div>Semana {currentWeek}</div>
                    <span>Semana actual</span>
                  </div>
                </div>
              )}
            </>
          )}
          {!values.programActive && (
            <Box margin="2em 0em" color="var(--rose)" fontWeight="bold">
              Programa inactivo
            </Box>
          )}
        </Box>

        {values.advicer && (
          <Box marginBottom="2em">
            <Box marginBottom="1em" fontWeight="bold">
              Asesor
            </Box>

            <div className={styles.infoRow}>
              <Advicer id={values.advicer} />
            </div>
          </Box>
        )}
      </div>
    </div>
  );
};

UserProfileBody.defaultProps = {
  disabledEdit: false,
};

UserProfileBody.propTypes = {
  disabledEdit: bool,
  userId: string.isRequired,
};

export default UserProfileBody;
