import React, { useEffect } from 'react';
import { BrowserRouter, Redirect, Route, Switch, useLocation } from 'react-router-dom';
import NotificationProvider from 'src/modules/notifications/provider';
import MenuProvider from 'src/modules/menu/provider';
import MenuPrivateProvider from 'src/modules/menuPrivate/provider';
import AlertProvider from 'src/modules/alert/provider';
import { StylesProvider } from '@material-ui/styles';
import EventsAndActivities from 'src/pages/eventsAndActivities';
import analytics from 'react-ga';
import Dashboard from './pages/dashboard';
import ThemeProvider from './utils/themeProvider';
import Home from './pages/home';
import Advisers from './pages/advisers';
import Program from './pages/program';
import ProductDetail from './pages/productDetail';
import Tutorials from './pages/tutorials';
import Checkout from './pages/checkout';
import MyCart from './pages/myCart';
import Products from './pages/productsList';
import Auth from './pages/auth';
import PrivateHome from './pages/privateHome';
import Training from './pages/training';
import CartProvider from './modules/cart/provider';
import LoadingProvider from './modules/loading/provider';
import PurchaseSuccess from './pages/purchaseSuccess';
import YoutuberPlayer from './modules/youtubePlayer';
import PickersProvider from './utils/MuiPickersUtilsProvider';
import Loaders from './utils/loaders';
import Privacity from './pages/prinacity';
import ContactPage from './pages/contact';
import Community from './pages/community';
import Testimoniales from './pages/testimonial';
import { SessionProvider } from './modules/session';
import MyCartDigital from './pages/myCartDigital';
import CheckoutDigital from './pages/checkoutDigital';
import AuthRoute from './components/authRoute';
import TrainingDetail from './pages/trainingDetail';
import MyProfile from './pages/myProfile';
import About from './pages/about';
import Tips from './pages/tips';
import PaymentAlert from './modules/paymentAlert';
import GroupChatPage from './pages/groupChat';

analytics.initialize('UA-61655020-4');

function AnalyticsComponent() {
  const location = useLocation();

  useEffect(() => {
    try {
      analytics.pageview(location.pathname);
    } catch (error) {
      console.log(error);
    }
  }, [location.pathname]);

  return null;
}

function App() {
  return (
    <div className="App">
      <SessionProvider>
        <StylesProvider injectFirst>
          <BrowserRouter>
            <AnalyticsComponent />
            <ThemeProvider>
              <LoadingProvider>
                <CartProvider>
                  <MenuPrivateProvider>
                    <MenuProvider>
                      <AlertProvider>
                        <NotificationProvider>
                          <PaymentAlert>
                            <YoutuberPlayer.Provider>
                              <PickersProvider>
                                <Loaders>
                                  <Switch>
                                    {/* MODULES */}
                                    <Route path="/auth" component={Auth} />
                                    <Route path="/dashboard" component={Dashboard} />

                                    {/* PAGES */}
                                    <Route path="/contacto" exact component={ContactPage} />
                                    <Route path="/comunidad" exact component={Community} />
                                    <Route path="/home" exact component={Home} />
                                    <Route path="/testimonios" exact component={Testimoniales} />
                                    <Route path="/privacity" exatc component={Privacity} />
                                    <Route path="/articulo/:productId" component={ProductDetail} />
                                    <Route path="/programa-de-entrenamiento" component={Program} />
                                    <Route path="/asesores" component={Advisers} />
                                    <Route path="/checkout" component={Checkout} />
                                    <Route path="/checkout-digital" component={CheckoutDigital} />
                                    <Route path="/mi-carrito" component={MyCart} />
                                    <Route path="/articulos" component={Products} />
                                    <Route path="/compra-exitosa/:id" component={PurchaseSuccess} />
                                    <Route path="/mi-carrito-digital" component={MyCartDigital} />
                                    <Route path="/" exact component={() => <Redirect to="/home" />} />
                                    <Route path="/events-and-activities" component={EventsAndActivities} />
                                    <Route path="/about" exact component={About} />
                                    <Route path="/tips/:id?" exact component={Tips} />

                                    <AuthRoute userAuthorized="user" path="/private/home" component={PrivateHome} />
                                    <AuthRoute userAuthorized="user" path="/group-chat" component={GroupChatPage} />
                                    <AuthRoute
                                      userAuthorized="user"
                                      path="/private/training"
                                      component={Training}
                                      exact
                                    />
                                    <AuthRoute
                                      exact
                                      userAuthorized="user"
                                      path="/private/training/:dayIndex"
                                      component={TrainingDetail}
                                    />
                                    <AuthRoute userAuthorized="user" path="/private/tutorials" component={Tutorials} />

                                    <AuthRoute userAuthorized="user" path="/private/my-profile" component={MyProfile} />
                                  </Switch>
                                </Loaders>
                              </PickersProvider>
                            </YoutuberPlayer.Provider>
                          </PaymentAlert>
                        </NotificationProvider>
                      </AlertProvider>
                    </MenuProvider>
                  </MenuPrivateProvider>
                </CartProvider>
              </LoadingProvider>
            </ThemeProvider>
          </BrowserRouter>
        </StylesProvider>
      </SessionProvider>
    </div>
  );
}

export default App;
