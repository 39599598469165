import { Box, CircularProgress } from '@material-ui/core';
import { func, string } from 'prop-types';
import React, { useState } from 'react';
import advicerModel from 'src/core/models/advisers';
import useFetch from 'src/hooks/useFetch';
import userAvatarSrc from 'src/assets/user.png';
import { Close } from '@material-ui/icons';
import styles from './styles.module.css';

const UsersByAdvicer = (props) => {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);

  useFetch(async () => {
    const responseUsers = await advicerModel.getUsersByAdvicer(props.advicerId);
    setUsers(responseUsers);
    setLoading(false);
  });

  return (
    <div className={styles.fullWrapper}>
      <div className={styles.card}>
        <Close className={styles.close} onClick={props.onClose} />
        {loading && <CircularProgress />}
        {!loading && !users.length && (
          <Box fontSize="1.2em" textAlign="center" lineHeight="1.5em">
            Este asesor aún no <br /> tiene usuarios
          </Box>
        )}
        {!loading && !!users.length && (
          <div className={styles.bodyHeigLimiter}>
            <div className={styles.listWrapper}>
              {users.map((currentItem) => (
                <div className={styles.userWrapper} key={currentItem.id}>
                  <div className={styles.userCard}>
                    <img className={styles.avatar} alt="user-avatar" src={currentItem.picture || userAvatarSrc} />
                    <div>
                      <Box fontWeight="600" marginBottom=".5em">
                        {currentItem.name}
                      </Box>
                      <Box>{currentItem.email}</Box>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

UsersByAdvicer.propTypes = {
  advicerId: string.isRequired,
  onClose: func.isRequired,
};

export default UsersByAdvicer;
