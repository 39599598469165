import {
  ChatBubble,
  DirectionsRun,
  Email,
  Event,
  Favorite,
  Language,
  ListAlt,
  LocalMall,
  LocalOffer,
  LocalShipping,
  Lock,
  PlayCircleFilled,
  SportsHandball,
  SupervisorAccount,
} from '@material-ui/icons';
import React from 'react';
import { NavLink } from 'react-router-dom';
import logoSrc from 'src/assets/logo.svg';
import { signOut } from 'src/core/models/auth';
import { useSession } from 'src/modules/session';
import styles from './styles.module.css';

function MenuSide() {
  const session = useSession();

  return (
    <aside className={styles.wrapper}>
      <img className={styles.logo} src={logoSrc} alt="logo" />
      {session.data.type === 'admin' && (
        <>
          <NavLink to="/dashboard/products" className={styles.link} activeClassName={styles.activeLink}>
            <LocalMall className={styles.icon} />
            Productos
          </NavLink>
          <NavLink to="/dashboard/advisers" className={styles.link} activeClassName={styles.activeLink}>
            <DirectionsRun className={styles.icon} />
            Asesores
          </NavLink>
          <NavLink to="/dashboard/shipping-cost" className={styles.link} activeClassName={styles.activeLink}>
            <LocalShipping className={styles.icon} />
            Costos de envío
          </NavLink>
          <NavLink to="/dashboard/events" className={styles.link} activeClassName={styles.activeLink}>
            <Event className={styles.icon} />
            Eventos y actividades
          </NavLink>
          <NavLink to="/dashboard/communities" className={styles.link} activeClassName={styles.activeLink}>
            <SupervisorAccount className={styles.icon} />
            Comunidades
          </NavLink>
          <NavLink to="/dashboard/sales" className={styles.link} activeClassName={styles.activeLink}>
            <LocalOffer className={styles.icon} />
            Ventas
          </NavLink>
          <NavLink to="/dashboard/messages" className={styles.link} activeClassName={styles.activeLink}>
            <Email className={styles.icon} />
            Mensajes
          </NavLink>
          <NavLink to="/dashboard/testimonials" className={styles.link} activeClassName={styles.activeLink}>
            <Favorite className={styles.icon} />
            Testimonios
          </NavLink>
          <NavLink to="/dashboard/program-list" className={styles.link} activeClassName={styles.activeLink}>
            <SportsHandball className={styles.icon} />
            Programas
          </NavLink>
          <NavLink to="/dashboard/users" className={styles.link} activeClassName={styles.activeLink}>
            <SupervisorAccount className={styles.icon} />
            Usuarios
          </NavLink>
          <NavLink to="/dashboard/tips" className={styles.link} activeClassName={styles.activeLink}>
            <ListAlt className={styles.icon} />
            Tips
          </NavLink>
          <NavLink to="/dashboard/group-chat-admin" className={styles.link} activeClassName={styles.activeLink}>
            <ChatBubble className={styles.icon} />
            Chat
          </NavLink>
        </>
      )}
      {session.data.type === 'advicer' && (
        <>
          <NavLink to="/dashboard/users-advicer" className={styles.link} activeClassName={styles.activeLink}>
            <SupervisorAccount className={styles.icon} />
            Usuarios
          </NavLink>
          <NavLink to="/dashboard/group-chat" className={styles.link} activeClassName={styles.activeLink}>
            <ChatBubble className={styles.icon} />
            Chat del grupo
          </NavLink>
          <NavLink to="/dashboard/tutorials" className={styles.link} activeClassName={styles.activeLink}>
            <PlayCircleFilled className={styles.icon} />
            Tutoriales
          </NavLink>
        </>
      )}
      <NavLink to="/" className={styles.link} activeClassName={styles.activeLink} exact>
        <Language className={styles.icon} />
        Ir al sitio web
      </NavLink>
      <div className={styles.link} onClick={signOut} role="button">
        <Lock className={styles.icon} />
        Cerrar sesión
      </div>
    </aside>
  );
}

export default MenuSide;
