import React from 'react';
import Container from 'src/components/container';
import picSrc from 'src/assets/para-quien.png';
import adornment from 'src/assets/adornment.svg';
import styles from './styles.module.css';

const WhoIsThisPRogramFor = () => (
  <div id="who-is-this-program-for">
    <Container disabledVerticalPadding>
      <div className={styles.body}>
        <div className={styles.title}>PARA QUIÉN ES ESTE PROGRAMA</div>
        <div className={styles.flexWrapper}>
          <img src={picSrc} alt="some pic" className={styles.picture} />
          <div className={styles.paragraphWrapper}>
            <div className={styles.paragraph}>
              Para personas a partir de los 18 años, que no realizan actividad física de manera regular o constante y
              que deseen hacer un cambio en su estilo de vida, sin el tradicional trauma de las personas que se inician
              en el deporte.
            </div>
            <div className={styles.paragraph}>
              Muy especialmente se enfoca en aquellas personas que nunca en su vida han hecho ejercicio o tienen muchos
              años sin hacerlo. Tradicionalmente cuando alguien decide empezar a hacer ejercicio, es sometido a rutinas
              que en los primeros días los dejan adoloridos y sin la posibilidad de moverse, incluso llegando a generar
              lesiones, desilusión y el consecuente alejamiento de la actividad.
            </div>
          </div>
        </div>
      </div>
    </Container>
    <img src={adornment} alt="adornment" className={styles.adornment} />
  </div>
);

export default WhoIsThisPRogramFor;
