import { Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import EmptyContent from 'src/components/emptyContent';
import Loading from 'src/components/loading';
import useEventsList from 'src/hooks/useEventsList';
import HeaderForLists from '../../components/headerForLists';
import EventsList from './components/list';

const EventsPage = () => {
  const { loading, events, getOptions, keywords, setKeyword } = useEventsList();
  const [currentView, setCurrentView] = useState(null); // next || past || null

  useEffect(() => {
    setKeyword('');
  }, [currentView]);

  // apply time filter

  let eventsFiltered = [...events];
  const date = new Date();
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);

  if (currentView === 'past') eventsFiltered = eventsFiltered.filter((currentItem) => currentItem.date < date);
  if (currentView === 'next') eventsFiltered = eventsFiltered.filter((currentItem) => currentItem.date >= date);

  return (
    <Box padding="1em">
      <HeaderForLists
        title="Eventos y Actividades"
        onChange={setKeyword}
        value={keywords}
        buttonRedirect="/dashboard/create-event"
      />
      {!!loading && <Loading />}
      {!loading && !events.length && (
        <EmptyContent message={keywords ? 'No encontramos resultados' : 'Aún no hay eventos publicados'} />
      )}
      {!loading && !!events.length && (
        <EventsList
          items={eventsFiltered}
          getOptions={getOptions}
          onViewChange={setCurrentView}
          currentView={currentView}
        />
      )}
    </Box>
  );
};

export default EventsPage;
