import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import response, { success } from '../../helpers/response';
import database from '../database';
import { deletePicture, uploadPicture } from '../storage';

export const getTip = async (id) => {
  const data = await database.getDocument(`/tips/${id}`);
  return data;
};

export const createTip = async ({ cover, ...data }) => {
  const raw = convertToRaw(data.body.getCurrentContent());
  const body = draftToHtml(raw);

  // save data
  const { id } = await database.create('tips', {
    raw,
    body,
    category: data.category,
    title: data.title,
  });

  // update cover
  const coverUrl = await uploadPicture(cover, `tips/${id}`);
  await database.update(`tips/${id}`, { cover: coverUrl });
  return response.success('Publicación creado correctamente');
};

export const updateTip = async (id, { cover, ...data }) => {
  const { cover: prevCover, body: prevBody } = await getTip(id);
  const raw = convertToRaw(data.body.getCurrentContent());
  const body = draftToHtml(raw);

  // save data
  await database.update(`tips/${id}`, {
    raw,
    body,
    category: data.category,
    title: data.title,
  });

  // update cover
  if (cover instanceof File) {
    await deletePicture(prevCover);
    const coverUrl = await uploadPicture(cover, `tips/${id}`);
    await database.update(`tips/${id}`, { cover: coverUrl });
  }

  // delete pictures at body
  const prevPictures = prevBody.match(/https:\/\/firebasestorage.*?media/g) || [];
  const currentPictures = body.match(/https:\/\/firebasestorage.*?media/g) || [];
  const picturesToDelete = prevPictures.filter((crrentPicture) => !currentPictures.includes(crrentPicture));
  await Promise.all(picturesToDelete.map((currentPicture) => deletePicture(currentPicture)));
  return response.success('Publicación actualizado correctamente');
};

export const getTipsList = async () => {
  const data = await database.getList('tips', null, ['createdAt', 'desc'], null).next();
  return data;
};

export const deleteTip = async (id) => {
  const { cover = '', body = '' } = await getTip(id);
  await database.remove(`tips/${id}`);

  // handlePictures
  await deletePicture(cover);
  const picturesAtBody = body.match(/https:\/\/firebasestorage.*?media/g) || [];
  await Promise.all(picturesAtBody.map((currentPic) => deletePicture(currentPic)));
  return success('Publicación eliminado correctamente');
};

export default {
  createTip,
  update: updateTip,
};
