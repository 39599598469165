/* eslint-disable react/no-danger */
import { object } from 'prop-types';
import React, { useEffect } from 'react';
import Picture from 'src/components/picture';
import styles from './styles.module.css';

const Detail = (props) => {
  useEffect(() => {
    setTimeout(() => {
      try {
        const target = document.getElementById('articleTarget');
        target.scrollIntoView();
      } catch (error) {
        console.log('error at scrolling');
      }
    }, 10);
  }, [props.item.id]);

  return (
    <div id="articleTarget">
      <div className={styles.item}>
        <div className={styles.title}>{props.item.title}</div>
        <Picture src={props.item.cover} className={styles.picture} height="60" />
        <p className={styles.paragraph} dangerouslySetInnerHTML={{ __html: props.item.body }} />
      </div>
    </div>
  );
};

Detail.propTypes = {
  item: object.isRequired,
};

export default Detail;
