import { Box } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { any, array, bool, string } from 'prop-types';
import React, { useState } from 'react';
import userProfileSrc from 'src/assets/user.png';
import Card from 'src/components/card';
import Options from 'src/components/options';
import { getSingleAdvicer } from 'src/core/models/advisers';
import useFetch from '../../../../../../hooks/useFetch';
import styles from './styles.module.css';

const UserCard = (props) => {
  const [advicer, setAdvicer] = useState('loading');

  useFetch(async () => {
    if (props.advicer) {
      const advicerResponse = await getSingleAdvicer(props.advicer);
      if (advicerResponse) setAdvicer(advicerResponse.name);
      else setAdvicer(null);
    } else {
      setAdvicer(null);
    }
  });

  return (
    <Card className={styles.card}>
      {props.programActive && <div className={styles.programStatusActive} />}
      {!props.programActive && <div className={styles.programStatusInactive} />}
      <img src={props.picture} className={styles.picture} alt="userAvatar" />
      <div className={styles.data}>
        <Box fontWeight="600">{props.name}</Box>
        <Box>{props.email}</Box>
        <Box>
          <Box component="span" color="gray">
            Contraseña:
          </Box>{' '}
          {props.password}
        </Box>
        {!props.advicer && (
          <Box color="var(--rose)">
            <Box component="span" color="gray">
              Asesor:
            </Box>{' '}
            No asignado
          </Box>
        )}
        {!!props.advicer && !!advicer && advicer !== 'loading' && (
          <Box color="var(--rose)">
            <Box component="span" color="gray">
              Asesor:
            </Box>{' '}
            {advicer}
          </Box>
        )}
        {!!props.advicer && !advicer && advicer !== 'loading' && (
          <Box color="var(--rose)">
            <Box component="span" color="gray">
              Asesor:
            </Box>{' '}
            error al cargar el asesor
          </Box>
        )}
        {props.programActive && (
          <Box color="var(--rose)">
            <Box component="span" color="gray">
              Semana de entrenamiento:
            </Box>{' '}
            {props.currentWeek}
          </Box>
        )}
        {props.programActive && (
          <Box color="var(--rose)" display="flex" flexWrap="wrap">
            <Box component="span" color="gray" marginRight="1em">
              Programa:
            </Box>{' '}
            <Box>{props.program}</Box>
          </Box>
        )}
      </div>
      <Options optionsList={props.options}>
        <MoreVert className={styles.options} />
      </Options>
    </Card>
  );
};

UserCard.defaultProps = {
  picture: userProfileSrc,
  programActive: false,
  options: [],
  advicer: null,
  program: null,
};

UserCard.propTypes = {
  advicer: string,
  name: string.isRequired,
  picture: string,
  email: string.isRequired,
  password: string.isRequired,
  programActive: bool,
  options: array,
  currentWeek: any.isRequired,
  program: string,
};

export default UserCard;
