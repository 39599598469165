/* eslint-disable import/prefer-default-export */
import formatObect from 'src/helpers/formatObject';
import validaotors from '../../../../helpers/getValidators';

export const transformUserInfoForForm = (saleUserInfo) => {
  const allows = [
    'email',
    'birthDate',
    'gender',
    'phone',
    'country',
    'state',
    'city',
    'suburb',
    'postalCode',
    'streetAndNumber',
  ];
  const name = `${saleUserInfo.name} ${saleUserInfo.lastName}`;
  const secureData = formatObect(saleUserInfo, allows);
  return { name, ...secureData };
};

export const filterDataForEditing = (prevData) => {
  const allows = [
    'email',
    'birthDate',
    'gender',
    'phone',
    'country',
    'state',
    'city',
    'suburb',
    'postalCode',
    'streetAndNumber',
    'name',
    'password',
  ];
  return formatObect(prevData, allows);
};

export const getRules = () => ({
  email: validaotors.email(),
  birthDate: validaotors.input('La fecha de nacimiento es requerida'),
  gender: validaotors.input('El género es requerido'),
  phone: validaotors.phone(),
  country: validaotors.input('El país es requerido'),
  state: validaotors.input('El Estado es requerido'),
  city: validaotors.input('La ciudad es requerida'),
  suburb: validaotors.input('La colonia es requerida'),
  postalCode: validaotors.input('El código postal no es válido'),
  streetAndNumber: validaotors.input('Calle y número es requerido'),
  name: validaotors.input('El nombre es requerido'),
  password: validaotors.password(),
});
