import React, { useMemo, useState } from 'react';
import programModel from 'src/core/models/program';
import useFetch from 'src/hooks/useFetch';
import Loading from 'src/components/loading';
import Container from 'src/components/container';
import { getCurrentWeek } from 'src/helpers/training';
import programUserModel from 'src/core/models/programUsers';
import { useSession } from 'src/modules/session';
import { string } from 'prop-types';
import { useHistory } from 'react-router-dom';
import styles from './styles.module.css';
import Weekly from '../weekly';

const TrainingBody = (props) => {
  const history = useHistory();
  const session = useSession();
  const [loading, setLoading] = useState(true);
  const [trainingData, setTrainingData] = useState([]);
  const [userData, setUserData] = useState({});

  const currentWeek = useMemo(() => (Object.keys(userData).length ? getCurrentWeek(userData) : 0), [userData]);

  // hidde past weeks
  const trainingDataFiltered = trainingData.filter((week, index) => {
    const currentWeekParsed = currentWeek || 1;
    if (props.userId) {
      return currentWeekParsed - 1 === index;
    }
    return currentWeekParsed - 1 <= index;
  });

  useFetch(async () => {
    const responseUserData = await programUserModel.getSingle(props.userId || session.data.id);
    const response = await programModel.getActiveImplement(responseUserData.programId || 'main');
    setUserData(responseUserData);
    setTrainingData(response.payload);
    setLoading(false);
  });

  // only for admin
  const handleView = (dayIndex) => {
    history.push(`/dashboard/training-user/${props.userId}/${dayIndex}`);
  };

  return (
    <div className={styles.fullWrapper}>
      <Container extraSmall>
        {loading && <Loading message="Cargando entrenamiento" />}
        {!loading &&
          trainingDataFiltered.map((trainingWeekly, index) => (
            <Weekly
              key={index}
              weekNumber={index + currentWeek}
              isCurrentWeek={index === 0}
              {...trainingWeekly}
              onView={!props.userId ? null : handleView}
            />
          ))}
      </Container>
    </div>
  );
};

TrainingBody.propTypes = {
  userId: string,
};

TrainingBody.defaultProps = {
  userId: null,
};

export default TrainingBody;
