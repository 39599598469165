const computeInitialFormState = (state) => {
  if (!state) return { pictures: [] };

  return {
    title: state.title,
    price: state.price.toString(),
    category: state.category,
    subCategory: state.subCategory,
    gender: state.gender,
    description: state.description,
    picture: state.picture,
    pictures: state.pictures,
    quantity: state.quantity || '',
  };
};

export default computeInitialFormState;
