import { Box } from '@material-ui/core';
import React from 'react';
import { useParams } from 'react-router-dom';
import useProgramListModel from 'src/hooks/useProgramListModel';
import useUploadProgramModel from 'src/hooks/useUploadProgramModel';
import InfoEditor from './components/infoEditor';
import ProgramHistory from './components/programHistory';
import UploadImplement from './components/uploadImplement';
import VisivilityEditor from './components/VisivilityError';
import styles from './styles.module.css';

const ProgramView = () => {
  const { programId } = useParams();
  const listProgram = useProgramListModel(programId);
  const uploadProgram = useUploadProgramModel({ onSuccess: listProgram.refresh, programId });

  return (
    <div className={styles.limiter}>
      <UploadImplement onFileSelect={uploadProgram.fileSelect} loading={uploadProgram.loading} />
      <ProgramHistory loading={listProgram.loading} items={listProgram.items} getOptions={listProgram.getOptions} />
      <Box paddingBottom="1em" />
      <VisivilityEditor programId={programId} />
      <InfoEditor programId={programId} />
    </div>
  );
};

export default ProgramView;
