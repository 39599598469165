/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Container from 'src/components/container';
import Loading from 'src/components/loading';
import { getQuestionsByAdmin, saveQuestionsByAdmin } from 'src/core/models/programUsers';
import useFetch from 'src/hooks/useFetch';
import useNotification from 'src/modules/notifications/useNotification';
import { useSession } from '../../../../modules/session';
import Form from './components/form';

const InitiationQuestions = () => {
  const session = useSession();
  const { userId } = useParams();
  const setNotification = useNotification();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  // fetcher
  const handleFetch = async () => {
    setLoading(true);
    const questions = await getQuestionsByAdmin(userId);
    setData(questions);
    setLoading(false);
  };

  const handleSave = async (newData) => {
    setLoading(true);
    const response = await saveQuestionsByAdmin(userId, newData);
    await handleFetch();
    setNotification(response);
    setLoading(false);
  };

  // fetch initial data
  useFetch(handleFetch);

  return (
    <>
      {loading && <Loading />}
      {!loading && (
        <Container extraSmall>
          <Form
            onSave={handleSave}
            values={data}
            disableEditing={!session || !session.data || session.data.type !== 'admin'}
          />
        </Container>
      )}
    </>
  );
};

export default InitiationQuestions;
