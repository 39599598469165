import { useState } from 'react';
import { getProgramList } from 'src/core/models/program';
import useFetch from 'src/hooks/useFetch';

/**
 * @typedef UseProgramListReturn
 * @property {bolean} loading
 * @property {Array} programList
 */

/**
 * fetch a list of programs
 * @returns {UseProgramListReturn}
 */
const useProgramList = () => {
  const [loading, setLoading] = useState(true);
  const [programList, setProgramList] = useState([]);

  useFetch(async () => {
    setLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 1000));
    const programListResponse = await getProgramList();
    setProgramList(programListResponse);
    setLoading(false);
  }, []);

  return {
    loading,
    programList,
  };
};

export default useProgramList;
