import React from 'react';
import logoSrc from 'src/assets/logo.svg';
import instagramSrc from 'src/assets/instagram.svg';
import facebookSrc from 'src/assets/facebook.svg';
import { Link } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { contact } from '../../constants';
import Container from '../container';
import styles from './styles.module.css';

const PrivateFooter = () => (
  <Container small disabledVerticalPadding>
    <div className={styles.bodyFlex}>
      <img className={styles.logo} alt="logo" src={logoSrc} />
      <div className={styles.data}>
        <div className={styles.contactWrapper}>
          <a href={contact.instagram} target="_blank" rel="noreferrer">
            <img src={instagramSrc} alt="icon" className={styles.icon} />
          </a>
          <a href={contact.facebook} target="_blank" rel="noreferrer">
            <img src={facebookSrc} alt="icon" className={styles.icon} />
          </a>
          <Box paddingLeft=".5em" />
          <a href={`emailto:${contact.email}`}>{contact.email}</a>
        </div>
        <div className={styles.legal}>
          <div className={styles.legalText}>{new Date().getFullYear()}, Derechos Reservados</div>
          <Box paddingRight="1em" />
          <Link to="/privacity" className={styles.legalText}>
            Aviso de privacidad
          </Link>
        </div>
      </div>
    </div>
  </Container>
);

export default PrivateFooter;
