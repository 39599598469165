import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import EmptyContent from '../../../../components/emptyContent';
import Loading from '../../../../components/loading';
import { deleteTip, getTipsList } from '../../../../core/models/tips';
import useFetch from '../../../../hooks/useFetch';
import useAlert from '../../../../modules/alert/useAlert';
import useNotification from '../../../../modules/notifications/useNotification';
import HeaderForLists from '../../components/headerForLists';
import TipsList from './components/tipsList';

const Tips = () => {
  const history = useHistory();
  const setAlert = useAlert();
  const setNotification = useNotification();
  const [tips, setTips] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState();

  // filter by search
  let itemsFiltered = [...tips];
  if (search) {
    const searchNormalized = search.toString().toLowerCase();
    itemsFiltered = itemsFiltered.filter((currentItem) => currentItem.title.toLowerCase().includes(searchNormalized));
  }

  const handleFetch = async () => {
    const tipsResponse = await getTipsList();
    setTips(tipsResponse);
    setLoading(false);
  };

  // E F F E C T S
  useFetch(handleFetch);

  // A C T I O N S

  const handleGetOptions = (item) => [
    {
      label: 'Eliminar',
      handler: () =>
        setAlert({
          action: async () => {
            const deleteResponse = await deleteTip(item.id);
            setNotification(deleteResponse);
            await handleFetch();
          },
          title: '¿Seguro quieres eliminar esta publicacion?',
        }),
    },
    {
      label: 'Editar',
      handler: () => history.push({ pathname: '/dashboard/tips-editor', state: item }),
    },
    {
      label: 'Ver publicación',
      handler: () => history.push(`/tips/${item.id}`),
    },
  ];

  return (
    <div>
      <HeaderForLists title="Tips" buttonRedirect="/dashboard/tips-editor" onChange={setSearch} value={search} />

      {loading && <Loading message="cargando Tips" />}
      {!loading && !!itemsFiltered.length && <TipsList items={itemsFiltered} getOptions={handleGetOptions} />}
      {!loading && !itemsFiltered.length && (
        <EmptyContent message={search ? 'No encontramos resultados' : 'Aún no hay tips publicados'} />
      )}
    </div>
  );
};

export default Tips;
