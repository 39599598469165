import { element } from 'prop-types';
import React, { useState } from 'react';
import context from './context';
import ViewNotifications from './view';

const Provider = ({ children }) => {
  const initialState = {
    action: null,
    title: null,
    message: null,
    active: false,
    loading: false,
  };
  const [state, setState] = useState(initialState);

  const handleSetLoading = (loadingStatus = true) => {
    setState((prevState) => ({ ...prevState, loading: loadingStatus }));
  };

  const handleSetAlert = ({ title, message, action }) => {
    setState({
      loading: false,
      action,
      title,
      message: message || null,
      active: true,
    });
  };

  const handleClose = () => setState(initialState);

  const handleTriggerAction = async () => {
    handleSetLoading();
    if (state.action) await state.action();
    handleClose();
  };

  return (
    <context.Provider value={handleSetAlert}>
      <>
        {children}
        <ViewNotifications
          onAcept={handleTriggerAction}
          title={state.title}
          message={state.message}
          active={state.active}
          loading={state.loading}
          onClose={handleClose}
        />
      </>
    </context.Provider>
  );
};

Provider.propTypes = {
  children: element.isRequired,
};

export default Provider;
