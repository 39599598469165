import { Box, Modal } from '@material-ui/core';
import { bool } from 'prop-types';
import { Link } from 'react-router-dom';
import React from 'react';
import logOut from 'src/assets/private/logout.svg';
import userSrc from 'src/assets/user.png';
import HeaderMobile from 'src/components/header/components/privateMobile';
import Container from 'src/components/container';
import { signOut } from 'src/core/models/auth';
import { useSession } from '../session';
import useCart from '../cart/useCart';
import styles from './styles.module.css';

const ViewPrivateMenu = ({ open }) => {
  const session = useSession() || {};
  const sessionData = session.data || {};
  const { itemsOnCart } = useCart();

  return (
    <Modal open={open} className={styles.modal}>
      <div className={styles.wrapper}>
        <HeaderMobile
          hideSecondary
          numItemsOnCart={itemsOnCart.length}
          name={sessionData.name ? sessionData.name.split(' ')[0] : ''}
          photo={sessionData.picture || userSrc}
        />
        <Container>
          <div className={styles.body}>
            <Link className={styles.link} to="/private/training">
              PROGRAMA DE ENTRENAMIENTO
            </Link>
            <Link className={styles.link} to="/articulos">
              TIENDA
            </Link>
            <Link className={styles.link} to="/private/tutorials">
              TUTORIALES
            </Link>
            <Link className={styles.link} to="/events-and-activities">
              EVENTOS Y ACTIVIDADES
            </Link>
            <Link className={styles.link} to="/group-chat">
              CHAT
            </Link>
            <div className={styles.link} onClick={signOut} role="button">
              <img className={styles.logOutImg} alt="candado" src={logOut} />
              <Box color="var(--rose)">SALIR</Box>
            </div>
          </div>
        </Container>
      </div>
    </Modal>
  );
};

ViewPrivateMenu.propTypes = {
  open: bool.isRequired,
};

export default ViewPrivateMenu;
