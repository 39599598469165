import React from 'react';
import Container from 'src/components/container';
import coverVideoSrc from 'src/assets/cover_video.jpg';
import coverVideoMovileSrc from 'src/assets/cover_video_movil.jpg';
import ButtonPLaySrc from 'src/assets/btn-play.svg';
import { useMediaQuery } from '@material-ui/core';
import styles from './styles.module.css';
import Button from '../../../../components/button';
import { usePlayer } from '../../../../modules/youtubePlayer';
import { videos } from '../../../../constants';

const CoverVideo = () => {
  const mobile = useMediaQuery('(max-width:600px)');
  const player = usePlayer();

  return (
    <div id="what-is-poltri">
      <Container className={styles.container}>
        <div className={styles.title}>
          SOMOS EL CAMINO PARA MEJORAR TU SALUD, FELICIDAD, SATISFACCIÓN Y REALIZACIÓN PERSONAL.
        </div>
        <div className={styles.subtitle}>
          Feminne Life tiene como finalidad motivar y orientar a las mujeres a tener una mejor calidad de vida a través
          de la actividad física y deportiva.
        </div>
      </Container>
      <div className={styles.pictureWrapper}>
        <img
          src={mobile ? coverVideoMovileSrc : coverVideoSrc}
          alt="personas haciendo ejercisio"
          className={styles.picture}
        />
        <img
          src={ButtonPLaySrc}
          alt="playIcon"
          className={styles.playIcon}
          onClick={() => player.play(videos.home)}
          role="button"
        />
      </div>

      <Button className={styles.button}>CONOCE POLTRI INSPIRA</Button>
    </div>
  );
};

export default CoverVideo;
