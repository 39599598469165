import { Box, Grid, Hidden } from '@material-ui/core';
import React, { useState } from 'react';
import { useDidUpdate } from 'rooks';
import Card from 'src/components/card';
import Stepper from 'src/components/stepper';
import useForm from 'src/hooks/useForm';
import productModel, { updateProduct } from 'src/core/models/product';
import storage, { uploadPicture } from 'src/core/storage';
import { useHistory, useLocation } from 'react-router';
import useErrorMessage from 'src/hooks/useErrorMessage';
import useNotification from 'src/modules/notifications/useNotification';
import ButtonAdmin from '../../components/button';
import Container from '../../components/container';
import GeneralInfo from './components/generalInfo';
import styles from './styles.module.css';
import { computeGeneralInfoValidators, multimediaRules } from './inputRules';
import Multimedia from './components/multimedia';
import Summary from './components/summary';
import Loading from './components/Loading';
import computeInitialFormState from './computeInitialFormState';

function createProduct() {
  const setNotification = useNotification();
  const history = useHistory();
  const { state } = useLocation();
  const { errorMessage, setErrorMessage } = useErrorMessage();
  const [currentStep, setStep] = useState(0);

  const { getInputProps, validateInputs, setValues, values } = useForm(computeInitialFormState(state));

  const handlePictureSelect = (picture) => {
    setValues((prevVals) => ({ ...prevVals, picture }));
  };

  const handlePicturesSelect = (pictures) => {
    setValues((prevVals) => ({
      ...prevVals,
      pictures: [...prevVals.pictures, ...pictures],
    }));
  };

  const handlePictureDelet = () => setValues((prevVals) => ({ ...prevVals, picture: null }));

  const handlePicturesDelete = (picture) => {
    setValues((prevValues) => ({
      ...prevValues,
      pictures: prevValues.pictures.filter((img) => img !== picture),
    }));
  };

  const handleGeneralInfoAcept = () => {
    const { firstErrorMessage } = validateInputs(computeGeneralInfoValidators(values.category));
    if (firstErrorMessage) setErrorMessage(firstErrorMessage);
    else setStep((prevStep) => prevStep + 1);
  };

  const handleAceptMultimedia = () => {
    const { firstErrorMessage } = validateInputs(multimediaRules);
    if (firstErrorMessage) setErrorMessage(firstErrorMessage);
    else setStep((prev) => prev + 1);
  };

  const handleUpload = async () => {
    setStep((prevStep) => prevStep + 1);
    const { data } = await productModel.createProduct(values);
    const primaryUrl = await storage.uploadPicture(values.picture, `products/${data.id}`);
    const picturesUrl = await storage.uploadMultiplePicture(values.pictures, `products/${data.id}`);
    await updateProduct(data.id, {
      picture: primaryUrl,
      pictures: picturesUrl,
    });
    history.push('/dashboard/products');
    setNotification({
      status: 'success',
      message: 'Publicacion guardado correctamente',
    });
  };

  const handleUpdate = async () => {
    setStep((prevStep) => prevStep + 1);

    // handle primary picture
    let pictureUrl = values.picture;
    if (typeof pictureUrl !== 'string') {
      await storage.deletePicture(state.picture);
      pictureUrl = await uploadPicture(values.picture, `products/${state.id}`);
    }

    // handle other pictures
    const urlsToDelete = state.pictures.filter((currentPicture) => !values.pictures.includes(currentPicture));
    await Promise.all(urlsToDelete.map((d) => storage.deletePicture(d)));
    const picsToUpload = values.pictures.filter((currentPic) => typeof currentPic !== 'string');
    const newPicturesUrl = await storage.uploadMultiplePicture(picsToUpload, `products/${state.id}`);
    const composedPicturesUrl = [...values.pictures.filter((p) => typeof p === 'string'), ...newPicturesUrl];
    await productModel.updateProduct(state.id, {
      ...values,
      picture: pictureUrl,
      pictures: composedPicturesUrl,
    });
    history.push('/dashboard/products');
    setNotification({
      status: 'success',
      message: 'Publicacion actualizado correctamente',
    });
  };

  const handleAcept = () => {
    if (currentStep === 0) handleGeneralInfoAcept();
    if (currentStep === 1) handleAceptMultimedia();
    if (currentStep === 2 && !state) handleUpload();
    if (currentStep === 2 && state) handleUpdate();
  };

  const handleBack = () => setStep((prevStep) => prevStep - 1);

  // C O M P U T E S - - - - - - - - - - - - - - - - - - -
  // reset subCategiory and gender when category change
  useDidUpdate(() => {
    setValues((prevValues) => ({
      ...prevValues,
      subCategory: null,
      gender: null,
    }));
  }, [values.category]);

  return (
    <Container className={styles.container}>
      <Card>
        <Stepper
          steps={[
            <>
              Datos <Hidden smDown>generales</Hidden>
            </>,
            'Multimedia',
            'Resumen',
          ]}
          activeStep={currentStep}
        />

        {currentStep === 0 && (
          <GeneralInfo getInputProps={getInputProps} errorMessage={errorMessage} category={values.category} />
        )}
        {currentStep === 1 && (
          <Multimedia
            errorMessage={errorMessage}
            onPicturesDelete={handlePicturesDelete}
            onPictureDelete={handlePictureDelet}
            pictures={values.pictures}
            picture={values.picture}
            onPictureSelect={handlePictureSelect}
            onPicturesSelect={handlePicturesSelect}
          />
        )}
        {currentStep === 2 && (
          <Summary
            picture={values.picture}
            pictures={values.pictures}
            description={values.description}
            category={values.category}
            subCategory={values.subCategory}
            gender={values.gender}
            price={values.price}
            title={values.title}
          />
        )}
        {currentStep === 3 && <Loading />}

        {currentStep <= 2 && (
          <Box marginTop="3em">
            <Grid container justify="flex-end">
              {!!currentStep && <ButtonAdmin onClick={handleBack}>Volver</ButtonAdmin>}
              <Box mr="1em" />
              <ButtonAdmin primary onClick={handleAcept}>
                {currentStep < 2 ? 'Siguente' : 'Guardar'}
              </ButtonAdmin>
            </Grid>
          </Box>
        )}
      </Card>
    </Container>
  );
}

export default createProduct;
