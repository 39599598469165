import { useState } from 'react';
import inputValidators from 'src/helpers/getValidators';
import { loginWithEmailAndPassword } from 'src/core/models/auth';
import { useSession } from 'src/modules/session';
import { useHistory } from 'react-router-dom';
import useErrorMessage from './useErrorMessage';
import useForm from './useForm';

const inputRules = {
  email: inputValidators.email(),
  password: inputValidators.password(),
};

const useSignIn = () => {
  const history = useHistory();
  const session = useSession();
  const form = useForm({}, inputRules);
  const { errorMessage, setErrorMessage } = useErrorMessage();
  const [loading, setLoading] = useState();

  const handleValidateInputs = () => {
    const { firstErrorMessage } = form.validateInputs();
    if (!firstErrorMessage) return true;
    setErrorMessage(firstErrorMessage);
    return false;
  };

  // handle redirects
  if (session.data) {
    if (session.data.type === 'admin') history.replace('/dashboard');
    if (session.data.type === 'advicer') history.replace('/dashboard');
    if (session.data.type === 'programUser') history.replace('/private/home');
  }

  const handleLogin = async (event) => {
    if (event && event.preventDefault) event.preventDefault();
    if (handleValidateInputs()) {
      setLoading(true);
      const response = await loginWithEmailAndPassword(form.values.email, form.values.password);
      if (response.success) {
        session.setSession(response.data);
        setLoading(false);
      } else {
        setErrorMessage(response.message);
        setLoading(false);
      }
    }
  };

  return {
    login: handleLogin,
    loading,
    errorMessage,
    inputsProps: {
      password: form.getInputProps('password'),
      email: form.getInputProps('email'),
    },
  };
};

export default useSignIn;
