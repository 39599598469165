// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_card__2aNpD {\r\n  background-color: #fff;\r\n  padding: 2em;\r\n  border-radius: .4em;\r\n}\r\n\r\n@media screen and (max-width:600px) {\r\n  .styles_card__2aNpD {\r\n    padding: 1.5em;\r\n  }\r\n}", "",{"version":3,"sources":["webpack://src/components/card/styles.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE;IACE,cAAc;EAChB;AACF","sourcesContent":[".card {\r\n  background-color: #fff;\r\n  padding: 2em;\r\n  border-radius: .4em;\r\n}\r\n\r\n@media screen and (max-width:600px) {\r\n  .card {\r\n    padding: 1.5em;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "styles_card__2aNpD"
};
export default ___CSS_LOADER_EXPORT___;
