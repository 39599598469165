import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useSession } from 'src/modules/session';
import programModel from '../../core/models/program';
import useFetch from '../../hooks/useFetch';
import TutorialView from './view';
import composeFiltersCreator from './filtetersComposed';
import Header from '../../components/header';
import SubHeader from '../eventsAndActivities/components/subHeader';
import PrivateFooter from '../../components/privateFooter';

const Tutorials = () => {
  const session = useSession();
  const { state = {} } = useLocation();
  const history = useHistory();
  const [items, setItesms] = useState([]);
  const [loading, setLoading] = useState(true);

  let itemsFilterd = [...items];

  // filter by section
  if (state.section) {
    itemsFilterd = itemsFilterd.filter((item) => item.section === state.section);
  }

  const handleSetFilters = async (filters) => {
    window.scroll(0, 0);
    setLoading(true);
    history.replace({ state: filters });
    setLoading(false);
    window.scroll(0, 0);
  };

  // fetch initial data
  useFetch(async () => {
    const responseTutorials = await programModel.getTutorialsOfCurrentImplement(session?.data?.programId || 'main');
    setItesms(responseTutorials);
    setLoading(false);
  });

  const filtersOrigin = composeFiltersCreator(items);

  return (
    <>
      <Header />
      <SubHeader />
      <TutorialView
        onChangeFilter={handleSetFilters}
        filters={state || {}}
        loading={loading}
        items={itemsFilterd}
        filtersOrigin={filtersOrigin}
      />
      <PrivateFooter />
    </>
  );
};

export default Tutorials;
