// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_fullWrapper__3wFUJ {\n  position: fixed;\n  background-color: #00000060;\n  width: 100vw;\n  height: 100vh;\n  right: 0;\n  top: 0;\n  z-index: 4;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.styles_body__VzFQo {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: #fff;\n  padding: 2em;\n  border-radius: .5em;\n  min-width: 15em;\n  min-height: 20em;\n  flex-direction: column;\n}\n.styles_day__36Hr4 {\n  display: block;\n  font-size: 1em;\n  padding: .5em;\n}\n.styles_day__36Hr4:hover {\n  color: var(--rose);\n}", "",{"version":3,"sources":["webpack://src/pages/dashboard/pages/advicerUsers/components/daySelectorModal/styles.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,2BAA2B;EAC3B,YAAY;EACZ,aAAa;EACb,QAAQ;EACR,MAAM;EACN,UAAU;EACV,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;EACtB,YAAY;EACZ,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,sBAAsB;AACxB;AACA;EACE,cAAc;EACd,cAAc;EACd,aAAa;AACf;AACA;EACE,kBAAkB;AACpB","sourcesContent":[".fullWrapper {\n  position: fixed;\n  background-color: #00000060;\n  width: 100vw;\n  height: 100vh;\n  right: 0;\n  top: 0;\n  z-index: 4;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.body {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: #fff;\n  padding: 2em;\n  border-radius: .5em;\n  min-width: 15em;\n  min-height: 20em;\n  flex-direction: column;\n}\n.day {\n  display: block;\n  font-size: 1em;\n  padding: .5em;\n}\n.day:hover {\n  color: var(--rose);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fullWrapper": "styles_fullWrapper__3wFUJ",
	"body": "styles_body__VzFQo",
	"day": "styles_day__36Hr4"
};
export default ___CSS_LOADER_EXPORT___;
