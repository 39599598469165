import { Grid, useMediaQuery } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { array, func, number } from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import AlternativeButton from 'src/components/alternativeButton';
import Breadcrumb from 'src/components/breadcrumb';
import Container from 'src/components/container';
import Footer from 'src/components/footer';
import Header from 'src/components/header';
import Picture from 'src/components/picture';
import formatPrice from 'src/helpers/formatPrice';
import EmptyContent from '../../components/emptyContent';
import styles from './styles.module.css';

const MyCartView = (props) => {
  const isMobile = useMediaQuery('(max-width:700px)');

  return (
    <>
      <Header grayOptions />
      <Breadcrumb values={[{ label: 'TIENDA' }, { label: 'MI CARRITO' }]} />
      <Container small disabledVerticalPadding>
        <div className={styles.contentLimiter}>
          {!!props.items.length && (
            <>
              <div className={styles.listWrapper}>
                <div className={styles.productWrapperHeader}>
                  <div className={styles.title}>PRODUCTO</div>
                  <div className={styles.productPicture} />
                  <div className={styles.quantityWrapper}>CANTIDAD</div>
                  <div className={styles.price}>PRECIO</div>
                  <div className={styles.price}>SUBTOTAL</div>
                  <div className={styles.deleteIconWrapper} />
                </div>

                {props.items.map((item) => (
                  <div className={styles.productWrapper} key={item.id}>
                    <Picture height={80} className={styles.productPicture} src={item.picture} alt="product" />
                    <div className={styles.title}>
                      {!isMobile && <>{item.title}</>}
                      {isMobile && (
                        <>
                          <div>{item.title}</div>
                          <div className={styles.quantityMobile}>Cantidad: 5</div>
                        </>
                      )}
                    </div>
                    <div className={styles.quantityWrapper}>
                      <div className={styles.quantityBox}>
                        <div
                          className={styles.controlQuantity}
                          onClick={() => props.onQuantityDown(item)}
                          role="button"
                        >
                          -
                        </div>
                        <div>{item.quantity}</div>
                        <div className={styles.controlQuantity} onClick={() => props.onQuantityUp(item)} role="button">
                          +
                        </div>
                      </div>
                    </div>
                    <div className={`${styles.price} ${styles.subprice}`}>{formatPrice(item.price)}</div>
                    <div className={styles.price}>{formatPrice(item.price * item.quantity)}</div>
                    <div className={styles.deleteIconWrapper}>
                      <Delete className={styles.iconDelete} onClick={() => props.onDelete(item)} />
                    </div>
                  </div>
                ))}
              </div>
              <Grid container justify="flex-end">
                <div className={styles.sumaryDataWrapper}>
                  <div className={styles.summaryDataBox}>
                    <div>
                      <span>Subtotal:</span>${formatPrice(props.subTotal)}
                    </div>
                    <div>
                      <span>Envío:</span>${formatPrice(props.shippingCost)}
                    </div>
                  </div>
                  <div className={styles.summaryTotalBox}>
                    <div>
                      Total<span>${formatPrice(props.total)}</span>mxn
                    </div>
                  </div>
                </div>
              </Grid>
            </>
          )}

          {!props.items.length && <EmptyContent message="Aún no tienes productos en tu carrito" />}

          <div className={styles.buttonWrapper}>
            <Grid container justify="space-between">
              <Link to="/articulos">
                <AlternativeButton className={styles.button}>SEGUIR COMPRANDO</AlternativeButton>
              </Link>
              {!!props.items.length && (
                <Link to="/checkout">
                  <AlternativeButton className={styles.button} filled>
                    CONTINUAR
                  </AlternativeButton>
                </Link>
              )}
            </Grid>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
};

MyCartView.propTypes = {
  items: array.isRequired,
  subTotal: number.isRequired,
  onQuantityUp: func.isRequired,
  onQuantityDown: func.isRequired,
  onDelete: func.isRequired,
  shippingCost: number.isRequired,
  total: number.isRequired,
};

export default MyCartView;
