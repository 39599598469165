import { string, bool, object, func, number } from 'prop-types';
import React from 'react';
import mapObjects from '../../helpers/mapObjects';
import styles from './styles.module.css';

// filters
const onlyNumbersFilter = (value = '') => {
  const lastCharacter = value.toString().split('')[value.toString().split('').length - 1];
  return [1, 2, 3, 4, 5, 6, 7, 8, 9, 0, ''].includes(parseInt(lastCharacter, 10));
};

const maxLengthFilter = (value = '', maxLengthAvailable = 0) => {
  const currentLength = value.toString().length;
  return currentLength <= maxLengthAvailable;
};

const onlyFloatFilter = (value = '') => {
  const arrayOfCharacteres = value.toString().split('');
  const lastCharacter = arrayOfCharacteres[arrayOfCharacteres.length - 1];
  const hasMutipleDots = arrayOfCharacteres.filter((character) => character === '.').length > 1;
  if (hasMutipleDots) return false;
  return [1, 2, 3, 4, 5, 6, 7, 8, 9, 0, '1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '.'].includes(lastCharacter);
};

const Input = (props) => {
  const { onlyFloat, onlyNumbers, maxLength, type, onChange, options, error, className, ...otherProps } = props;

  // set border color if input has error
  let inlineStyle = {};
  if (error) inlineStyle = { ...inlineStyle, borderColor: '#ff00006e' };

  const handleChange = (event) => {
    if (!event.target.value) return onChange(event);
    if (onlyNumbers && !onlyNumbersFilter(event.target.value)) return null;
    if (onlyFloat && !onlyFloatFilter(event.target.value)) return null;
    if (maxLength && !maxLengthFilter(event.target.value, maxLength)) return null;
    return onChange(event);
  };

  // make input props
  const inputProps = {
    ...otherProps,
    className: `${styles.input} ${className}`,
    style: inlineStyle,
    onChange: handleChange,
  };

  if (type === 'select') {
    return (
      <select {...inputProps}>
        <option label={inputProps.placeholder} disabled="disabled" style={{ display: 'none' }} />
        {mapObjects(options, (val, keyname) => (
          <option key={keyname} value={keyname} label={val}>
            {val}
          </option>
        ))}
      </select>
    );
  }

  if (type === 'textarea') {
    return <textarea {...inputProps} />;
  }

  return <input {...inputProps} type={type} />;
};

Input.defaultProps = {
  error: false,
  type: 'text',
  className: '',
  options: {},
  onlyFloat: false,
  onlyNumbers: false,
  maxLength: null,
  placeholder: '',
};

Input.propTypes = {
  error: bool,
  type: string,
  className: string,
  options: object,
  onChange: func.isRequired,
  onlyFloat: bool,
  onlyNumbers: bool,
  maxLength: number,
  placeholder: string,
};

export default Input;
