import { Hidden, SwipeableDrawer, useMediaQuery } from '@material-ui/core';
import { Menu } from '@material-ui/icons';
import { array, element, object, oneOfType, string } from 'prop-types';
import React, { useState } from 'react';
import { useLocation } from 'react-router';
import { useDidUpdate } from 'rooks';

import MenuSide from '../menuSide';
import styles from './styles.module.css';

function LayoutDashboard({ children }) {
  const { pathname } = useLocation();
  const isMobile = useMediaQuery('(max-width:600px)');
  const [isMenuOpen, setMenuOpen] = useState(false);
  const handleMenuOpen = () => setMenuOpen(true);
  const handleMenuClose = () => setMenuOpen(false);

  // close menu when location change
  useDidUpdate(handleMenuClose, [pathname]);

  return (
    <>
      <SwipeableDrawer
        open={isMenuOpen && isMobile}
        onOpen={handleMenuOpen}
        onClose={handleMenuClose}
        classes={{ paper: styles.paper }}
      >
        <MenuSide />
      </SwipeableDrawer>
      <Hidden smUp>
        <div className={styles.header}>
          <Menu className={styles.icon} onClick={handleMenuOpen} />
        </div>
      </Hidden>
      <div className={styles.wrapperPrimary}>
        <div className={styles.menuWrapper}>
          <MenuSide />
        </div>
        <div className={styles.bodyContainer}>{children}</div>
      </div>
    </>
  );
}

LayoutDashboard.propTypes = {
  children: oneOfType([string, object, array, element]).isRequired,
};

export default LayoutDashboard;
