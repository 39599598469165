import { array, element, node, object, oneOfType, string } from 'prop-types';
import React from 'react';
import styles from './styles.module.css';

const Card = ({ children, className, ...props }) => (
  <div {...props} className={`${styles.card} ${className}`}>
    {children}
  </div>
);

Card.defaultProps = {
  className: '',
};

Card.propTypes = {
  className: string,
  children: oneOfType([string, array, object, node, element]).isRequired,
};

export default Card;
