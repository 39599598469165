/* eslint-disable no-constant-condition */
import xlsx, { RichText } from 'xlsx-populate';

const getModuleNumberBySheetName = (sheetName = '') => {
  try {
    const name = sheetName.trim().toLowerCase().replace(/\s/g, '');
    const number = name.split('-m')[1];
    return Number.parseInt(number, 10);
  } catch (error) {
    return 0;
  }
};

const normalizeString = (val = '') => {
  try {
    return val
      .toString()
      .trim()
      .replace(/\r?\n|\r/g, '')
      .trim();
  } catch (error) {
    console.error('normalizeStringError', error);
    return '';
  }
};

const formatVideos = (videosSource = '') => {
  try {
    let splited = videosSource.split('\r\n');
    splited = splited.filter((element) => !!element);
    splited = splited.map((element) => normalizeString(element));
    return splited;
  } catch (error) {
    console.log('normalizeString', error);
    return [];
  }
};

const composeExcelcicesWithVideos = (excercies, videos) => {
  let videosList = [...videos];
  let result = [];
  result = excercies.map((e) => {
    if (e.type === 'title') return e;
    const [currentVideo, ...restVideos] = videosList;
    videosList = restVideos;
    return {
      ...e,
      video: currentVideo || null,
    };
  });

  const restVideosFormated = videosList.map((video) => ({
    type: 'body',
    value: '',
    video: video || null,
  }));

  result = [...result, ...restVideosFormated];
  return result;
};

const formatExcercices = (excercices) => {
  try {
    let excecicesMaped = [];
    let prevTitleCalculated = '';

    for (const exercice of excercices) {
      if (exercice.type === 'title') {
        prevTitleCalculated = `${prevTitleCalculated} ${normalizeString(exercice.value)}`;
      } else {
        if (prevTitleCalculated) {
          excecicesMaped.push({
            type: 'title',
            value: prevTitleCalculated.trim(),
          });
          prevTitleCalculated = '';
        }
        const currentExcerciceValue = exercice.value ? exercice.value.toString() : '';
        const bodySplited = currentExcerciceValue.split('\r\n').filter((v) => !!v);
        const bodyFormated = bodySplited.map((e) => ({ type: 'body', value: normalizeString(e) }));
        excecicesMaped = [...excecicesMaped, ...bodyFormated];
      }
    }

    if (prevTitleCalculated) {
      excecicesMaped.push({
        type: 'title',
        value: prevTitleCalculated.trim(),
      });
      prevTitleCalculated = '';
    }

    return excecicesMaped;
  } catch (error) {
    console.log('formatExcercices', error);
    return [];
  }
};

const fileToBuffer = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const richTextParser = (val = '') => {
  try {
    const parts = [];
    if (!(val instanceof RichText)) {
      parts.push({ type: 'body', value: val });
    } else {
      for (let index = 0; index < val.length; index += 1) {
        const fragment = val.get(index);
        const type = fragment.style('bold') || (val.length > 1 && index === 0) ? 'title' : 'body';
        const value = fragment.value();
        parts.push({ type, value });
      }
    }
    return parts;
  } catch (error) {
    console.log('richTextParserError', error);
    return [];
  }
};

const parseDay = (day) =>
  day.map((type) => {
    const richTextPased = richTextParser(type[1]);
    const execicesFormated = formatExcercices(richTextPased);
    const videosFormated = formatVideos(type[2]);
    const excercicesComposed = composeExcelcicesWithVideos(execicesFormated, videosFormated);
    const value = {
      label: type[0] || 'undefined',
      exercices: excercicesComposed,
    };
    return value;
  });

const parseProgramSheet = (sheet) => {
  const week = sheet.row(1).cell(1).value();
  const title = sheet.row(1).cell(2).value();
  const objetive = sheet.row(2).cell(2).value();

  const sheetData = {
    moduleNumber: getModuleNumberBySheetName(sheet.name() || ''),
    week: normalizeString(week),
    objetive: normalizeString(objetive),
    title: normalizeString(title),
    days: [
      {
        label: 'Lunes',
        types: parseDay(sheet.range('D1:F3').value()),
      },
      {
        label: 'Martes',
        types: parseDay(sheet.range('D4:F6').value()),
      },
      {
        label: 'Miércoles ',
        types: parseDay(sheet.range('D7:F9').value()),
      },
      {
        label: 'Jueves',
        types: parseDay(sheet.range('D10:F12').value()),
      },
      {
        label: 'Viernes',
        types: parseDay(sheet.range('D13:F15').value()),
      },
      {
        label: 'Sábado',
        types: parseDay(sheet.range('D16:F18').value()),
      },
    ],
  };
  return sheetData;
};

const parseTutorialsSheet = (sheet) => {
  let currentRow = 1;
  let lastTitle = null;
  const data = [];

  while (true) {
    const row = sheet.row(currentRow);
    const sectionTitle = row.cell(1).value() || lastTitle;
    const title = row.cell(2).value() || null;
    const url = row.cell(3).value() || null;
    const module = row.cell(4).value() || null;

    if (row.cell(1).value()) lastTitle = row.cell(1).value();
    if (!title && !url) break;

    currentRow += 1;
    data.push({
      title,
      url,
      sectionTitle,
      module,
    });
  }

  return data;
};

const xlsxParser = async (file) => {
  const stringFile = await fileToBuffer(file);
  const book = await xlsx.fromDataAsync(stringFile);
  const programSheets = book.sheets().slice(1, 53);
  const programSheetsParsed = programSheets.map((sheet) => parseProgramSheet(sheet));

  // tutorials
  const tutorialSheet = book.sheet(0);
  const tutorialSheetParsed = parseTutorialsSheet(tutorialSheet);

  console.log('debug here', {
    programSheetsParsed,
    tutorialSheetParsed,
  });

  return {
    program: programSheetsParsed,
    tutorial: tutorialSheetParsed,
  };
};

export default xlsxParser;
