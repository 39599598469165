import { createContext } from 'react';

// IMPORTANT these functions is only a interface, these ones will be remplaced by cartProvider

const methods = {
  addToCart: () => {},
  removeProductFromCart: () => {},
  updateQuantityProductOnCart: () => {},
  emptyCart: () => {},
};

const itemsOnCart = [];

const context = createContext({ methods, itemsOnCart });

export default context;
