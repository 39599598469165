import { useMediaQuery } from '@material-ui/core';
import { array } from 'prop-types';
import React from 'react';
import Container from '../../../../components/container';
import Picture from '../../../../components/picture';
import styles from './styles.module.css';

const AsesorList = ({ advisers }) => {
  const isMobile = useMediaQuery('(max-width:700px)');

  return (
    <Container>
      <div className={styles.listContainer}>
        {advisers.map((advicer) => (
          <div className={styles.card}>
            <Picture height={!isMobile ? 100 : 65} className={styles.picture} src={advicer.picture} />
            <div className={styles.dataWrapper}>
              <div className={styles.title}>{advicer.name}</div>
              <div className={styles.description}>{advicer.description}</div>
            </div>
          </div>
        ))}
      </div>
    </Container>
  );
};

AsesorList.propTypes = {
  advisers: array.isRequired,
};

export default AsesorList;
