import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import community, { deleteCommunity } from '../core/models/community';
import useAlert from '../modules/alert/useAlert';
import useNotification from '../modules/notifications/useNotification';
import useFetch from './useFetch';

const useCommunityList = () => {
  const setAlert = useAlert();
  const setNotification = useNotification();
  const history = useHistory();
  const [state, setState] = useState({
    loading: true,
    items: [],
    keywords: '',
  });

  // aply filters
  let filteredItems = state.items;
  if (state.keywords) {
    const keywordsNormalized = state.keywords.toString().toLowerCase();
    filteredItems = filteredItems.filter((item) => {
      const titleNormalized = item.title.toString().toLowerCase();
      return titleNormalized.includes(keywordsNormalized);
    });
  }

  const setComposeState = (partialState) => setState((prevState) => ({ ...prevState, ...partialState }));

  const handleFetch = async () => {
    const responseItems = await community.getList();
    setComposeState({ items: responseItems, loading: false });
  };

  const handleSetKeywords = (value) => setComposeState({ keywords: value });

  const handleGetOptions = (item) => [
    {
      label: 'Eliminar',
      handler: () => {
        setAlert({
          title: '¿Seguro quieres eliminar esta publicación?',
          message: 'Despues de ejecutar esta acción no podras recuperar la información',
          action: async () => {
            const response = await deleteCommunity(item);
            await handleFetch();
            setNotification(response);
          },
        });
      },
    },
    {
      label: 'Editar',
      handler: () => history.push({ pathname: '/dashboard/communities/editor', state: item }),
    },
  ];

  useFetch(handleFetch);

  return {
    loading: state.loading,
    items: filteredItems,
    keywords: state.keywords,
    getOptions: handleGetOptions,
    setKeywords: handleSetKeywords,
  };
};

export default useCommunityList;
