import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import purchases from 'src/core/models/purchases';
import openPayModel from 'src/core/models/openpay';
import { getSubTotal } from '../../helpers/cart';
import useForm from '../../hooks/useForm';
import useAlert from '../../modules/alert/useAlert';
import useCart from '../../modules/cart/useCart';
import View from './view';
import inputValidators from './inputValidators';
import useErrorMessage from '../../hooks/useErrorMessage';
import useLoading from '../../modules/loading/useLoading';
import useShippingCost from '../../hooks/useShippingCost';
import validaotors from '../../helpers/getValidators';

const Checkout = () => {
  const [activeStep, setActiveStep] = useState(0);
  const cart = useCart();
  const error = useErrorMessage();
  const form = useForm({});
  const shippingCost = useShippingCost(form.values.postalCode);
  const setAlert = useAlert();
  const history = useHistory();
  const setLoading = useLoading();

  // define variables
  const subTotal = getSubTotal();
  const total = subTotal + shippingCost;

  // methods
  const handlePaymentApprov = async (orderId = null, reference = null) => {
    const { data } = await purchases.savePurchase({
      userInfo: form.values,
      items: cart.itemsOnCart,
      status: 'pending',
      orderId,
      total,
      subTotal,
      shippingCost,
      reference,
    });
    cart.methods.emptyCart();
    setLoading(false);
    history.replace(`/compra-exitosa/${data.id}`);
  };

  const handleDelete = (product) =>
    setAlert({
      title: '¿Seguro quieres eliminar este producto de tu carrito?',
      action: () => cart.methods.removeProductFromCart(product.id),
    });

  const handlePayWithStore = async () => {
    setLoading(true);
    const response = await openPayModel.payWithStore({
      deviceId: window.OpenPay.deviceData.setup(),
      email: form.values.email,
      name: `${form.values.name} ${form.values.lastName}`,
      total,
    });
    setLoading(false);
    if (response.error) {
      error.setErrorMessage('Error algo salió mal');
      setActiveStep(0);
    } else {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      await handlePaymentApprov(response.data.id, response.data.payment_method.reference);
    }
  };

  const handlePayWithSpei = async () => {
    setLoading(true);
    const response = await openPayModel.payWithSpei({
      deviceId: window.OpenPay.deviceData.setup(),
      email: form.values.email,
      name: `${form.values.name} ${form.values.lastName}`,
      total,
    });
    setLoading(false);
    if (response.error) {
      error.setErrorMessage('Error algo salió mal');
      setActiveStep(0);
    } else {
      await handlePaymentApprov(response.data.id);
    }
  };

  const handlePayWithCard = async () => {
    setLoading(true);
    const response = await openPayModel.payWithCard({
      cardCode: form.values.cardCode,
      cardMonth: form.values.cardMonth,
      cardNumber: form.values.cardNumber,
      cardName: form.values.cardName,
      cardYear: form.values.cardYear,
      deviceId: window.OpenPay.deviceData.setup(),
      email: form.values.email,
      name: `${form.values.name} ${form.values.lastName}`,
      total,
    });
    setLoading(false);
    if (response.error) {
      error.setErrorMessage(response.message);
      setActiveStep(0);
    } else {
      await handlePaymentApprov(response.data.id);
    }
  };

  const handlePayManual = async () => {
    setLoading(true);
    await handlePaymentApprov();
  };

  const generalInfoValidator = () => {
    const { firstErrorMessage } = form.validateInputs(inputValidators.generalInfoValidator);
    if (!firstErrorMessage) return true;
    error.setErrorMessage(firstErrorMessage);
    return false;
  };

  const validateMethoPay = () => {
    const { firstErrorMessage } = form.validateInputs({ methodPay: validaotors.input('Selecciona un método de pago') });
    if (!firstErrorMessage) return true;
    error.setErrorMessage(firstErrorMessage);
    return false;
  };

  const validateCardForm = () => {
    const { firstErrorMessage } = form.validateInputs(inputValidators.cardFormValidator);
    if (!firstErrorMessage) return true;
    error.setErrorMessage(firstErrorMessage);
    return false;
  };

  const handleAcept = () => {
    if (activeStep === 0 && generalInfoValidator()) setActiveStep(1);
    if (activeStep === 1) setActiveStep(2);
    if (activeStep === 2 && validateMethoPay() && form.values.methodPay === 'store') handlePayWithStore();
    if (activeStep === 2 && validateMethoPay() && form.values.methodPay === 'spei') handlePayWithSpei();
    if (activeStep === 2 && validateMethoPay() && form.values.methodPay === 'card') setActiveStep(3);
    if (activeStep === 2 && validateMethoPay() && form.values.methodPay === 'manual') handlePayManual(3);
    if (activeStep === 3 && form.values.methodPay === 'card' && validateCardForm()) handlePayWithCard();
  };

  const handleBack = () => {
    if (activeStep === 0) history.push('/articulos');
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleGetButtonText = () => {
    if (activeStep === 3) return 'PAGAR';
    if (activeStep === 2 && form.values.methodPay === 'paypal') return 'Pagar con PayPal';
    if (activeStep === 2 && form.values.methodPay === 'manual') return 'Generar orden';
    if (activeStep === 2 && (form.values.methodPay === 'spei' || form.values.methodPay === 'store'))
      return 'Generar orden de pago';
    return 'Siguiente';
  };

  const paypalConfig = useMemo(
    () => ({
      createOrder: (_data, actions) => {
        setLoading(true);
        return actions.order.create({ purchase_units: [{ amount: { value: total } }] });
      },
      onApprove: async (_data, actions) => {
        const { id } = await actions.order.capture();
        await handlePaymentApprov(id);
      },
      onCancel: () => setLoading(false),
    }),
    [total, form.values],
  );

  return (
    <View
      activeStep={activeStep}
      items={cart.itemsOnCart}
      subTotal={subTotal}
      shippingCost={shippingCost}
      total={total}
      errorMessage={error.errorMessage}
      values={form.values}
      buttonText={handleGetButtonText()}
      paypalConfig={paypalConfig}
      onDelete={handleDelete}
      getInputProps={form.getInputProps}
      onAcept={handleAcept}
      onBack={handleBack}
    />
  );
};

export default Checkout;
