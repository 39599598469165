import { survey } from 'src/constants';
import validaotors from '../../../../helpers/getValidators';

export const getValidators = () => {
  const inputValidator = validaotors.input('Todos los campos son requeridos');
  const computedValidaotors = {};
  survey.forEach((_question, index) => {
    computedValidaotors[`question_${index}`] = inputValidator;
  });
  return computedValidaotors;
};

export default {
  getValidators,
};
