import { Check } from '@material-ui/icons';
import { any, bool, func, string } from 'prop-types';
import React from 'react';
import styles from './styled.module.css';

const CheckBox = (props) => {
  const getHandleClick = () => {
    if (!props.disabled) {
      const payload = { target: { name: props.name, value: !props.value } };
      props.onChange(payload);
    }
  };

  return (
    <div className={styles.checkboxGroup} onClick={getHandleClick} role="button">
      <div className={styles.checkbox}>{!!props.value && <Check />}</div>
      {props.label}
    </div>
  );
};

CheckBox.defaultProps = {
  disabled: false,
};

CheckBox.propTypes = {
  label: string.isRequired,
  onChange: func.isRequired,
  value: any.isRequired,
  name: string.isRequired,
  disabled: bool,
};

export default CheckBox;
