import { Box, CircularProgress } from '@material-ui/core';
import { Check, Close } from '@material-ui/icons';
import { func, string } from 'prop-types';
import React, { useMemo, useState } from 'react';
import programUserModel, { setPayedNextPayout } from '../../../../../../core/models/programUsers';
import composeClassNames from '../../../../../../helpers/composeClassNames';
import { toStringDate } from '../../../../../../helpers/dates';
import formatPrice from '../../../../../../helpers/formatPrice';
import useFetch from '../../../../../../hooks/useFetch';
import useAlert from '../../../../../../modules/alert/useAlert';
import useNotification from '../../../../../../modules/notifications/useNotification';
import styles from './styles.module.css';

const formsPayDicc = {
  price: 'Pago en una sola exhibición',
  semesterPrice: 'Pago semestral',
  monthlyPrice: 'Pago mensual',
};

const HistoryPaymentsModal = (props) => {
  const [loading, setLoading] = useState(true);
  const [paysHistory, setPaysHistory] = useState([]);
  const [formPay, setFormPay] = useState(false);
  const setAlert = useAlert();
  const setNotification = useNotification();

  const handleFetch = async () => {
    const respose = await programUserModel.getSingle(props.userId);
    if (respose.paysHistory) {
      setPaysHistory(respose.paysHistory);
      setFormPay(respose.formPay);
      setLoading(false);
    } else {
      props.onClose();
    }
  };

  useFetch(handleFetch);

  const nextPayNumber = useMemo(() => {
    const firstElement = paysHistory.find((current) => current.status === 'pending') || {};
    return firstElement.payNumber;
  }, [paysHistory]);

  const handlePayNextPayment = async () => {
    setAlert({
      title: '¿Seguro quieres establecer el estado de este pago como "pagado"?',
      action: async () => {
        const response = await setPayedNextPayout(props.userId);
        await handleFetch();
        setNotification(response);
      },
    });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.card}>
        <Close onClick={props.onClose} className={styles.close} />
        {loading && <CircularProgress />}
        {!loading && (
          <div className={styles.listWrapper}>
            <Box marginBottom="1em" fontWeight="600">
              {formsPayDicc[formPay]}
            </Box>
            {paysHistory.map((payment, index) => (
              <div
                className={composeClassNames(
                  styles.paymentCard,
                  payment.status === 'approved' ? styles.paymentCardPayed : '',
                )}
                key={index}
              >
                <Box marginBottom=".2em">Pago # {payment.payNumber}</Box>
                <Box marginBottom=".2em">
                  Precio:{' '}
                  <Box component="span" fontWeight="600" marginLeft=".5em">
                    {formatPrice(payment.price)}
                  </Box>
                </Box>
                {payment.status === 'pending' && (
                  <Box marginBottom=".2em">
                    Fecha limite de pago:
                    <Box component="span" fontWeight="600" marginLeft=".5em">
                      {payment.limitPay ? toStringDate(payment.limitPay) : 'Indefinido'}
                    </Box>
                  </Box>
                )}
                {payment.status === 'approved' && (
                  <Box>
                    Fecha de pago:
                    <Box component="span" fontWeight="600" marginLeft=".5em">
                      {toStringDate(payment.datePayed)}
                    </Box>
                  </Box>
                )}
                {nextPayNumber === payment.payNumber && (
                  <Box
                    color="var(--cyan)"
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={handlePayNextPayment}
                  >
                    Marcar como pagado
                  </Box>
                )}
                {payment.status === 'approved' && <Check className={styles.iconPayed} />}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

HistoryPaymentsModal.propTypes = {
  onClose: func.isRequired,
  userId: string.isRequired,
};

export default HistoryPaymentsModal;
