/* eslint-disable no-unused-vars */
import { Grid, useMediaQuery } from '@material-ui/core';
import { array, bool, func, object } from 'prop-types';
import adornmentSrc from 'src/assets/adornment.svg';
import React from 'react';
import { Search, Tune } from '@material-ui/icons';
import Container from 'src/components/container';
import EmptyContent from 'src/components/emptyContent';
import Loading from 'src/components/loading';
import PrivateFooter from 'src/components/privateFooter';
import Header from 'src/components/header';
import Input from 'src/components/inputs';
import Filters from './components/filters';
import TutorialCard from './components/tutorialCard';
import styles from './styles.module.css';
import SubHeader from './components/subHeader';

const ProductsView = (props) => {
  const isMobile = useMediaQuery('(max-width:700px)');

  return (
    <>
      <div className={styles.fullWidthWrapper}>
        {/* {isMobile && (
          <Container>
            <Grid container justify="space-between" className={styles.controlsMobileWrapper}>
              <div className={styles.filterWrapper}>
                <div>FILTRO</div>
                <Tune />
              </div>
              <div className={styles.seracherMobileWrapper}>
                <Input placeholder="Buscar" />
                <Search className={styles.serachIcon} />
              </div>
            </Grid>
          </Container>
        )} */}

        <Container small>
          <div className={styles.flexWrapper}>
            <div className={styles.menuWrapper}>
              <Filters onChange={props.onChangeFilter} value={props.filters} filters={props.filtersOrigin} />
            </div>
            <div className={styles.productsWrapper}>
              {props.loading && <Loading message="Cargando tutoriales" />}

              {!props.loading && !props.items.length && <EmptyContent message="No encontramos resultados" />}

              {!props.loading && !!props.items.length && (
                <div className={styles.listItemsWrapper}>
                  {props.items.map((video, index) => (
                    <div className={styles.itemWrapper} key={index}>
                      <TutorialCard title={video.title} video={video.video} id={video.id} />
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </Container>
      </div>
      <img src={adornmentSrc} width="100%" alt="adornment" />
    </>
  );
};

ProductsView.propTypes = {
  onChangeFilter: func.isRequired,
  filters: object.isRequired,
  loading: bool.isRequired,
  items: array.isRequired,
  filtersOrigin: array.isRequired,
};

export default ProductsView;
