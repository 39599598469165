// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_container__2goNj {\r\n  max-width: 1200px;\r\n  margin: auto;\r\n  box-sizing: border-box;\r\n}", "",{"version":3,"sources":["webpack://src/pages/dashboard/components/container/styles.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,YAAY;EACZ,sBAAsB;AACxB","sourcesContent":[".container {\r\n  max-width: 1200px;\r\n  margin: auto;\r\n  box-sizing: border-box;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__2goNj"
};
export default ___CSS_LOADER_EXPORT___;
