import { Box, SwipeableDrawer, useMediaQuery } from '@material-ui/core';
import { array, bool, func, object } from 'prop-types';
import React, { useState } from 'react';
import storeCoverHeadSrc from 'src/assets/storeCoverHead.jpg';
import storeCoverHeadMSrc from 'src/assets/tienda-slide-poltri.jpg';
import coverSrc from 'src/assets/storecoverfooter.jpg';
import coverMSrc from 'src/assets/tienda-footer-poltri.jpg';
import { Tune } from '@material-ui/icons';
import Breadcrumb from '../../components/breadcrumb';
import Container from '../../components/container';
import CoverFooterPage from '../../components/coverFooterPage';
import CoverPage from '../../components/coverPage';
import EmptyContent from '../../components/emptyContent';
import Loading from '../../components/loading';
import Filters from './components/filters';
import PropductCard from './components/productCard';
import styles from './styles.module.css';
import Footer from '../../components/footer';
import Header from '../../components/header';

const ProductsView = (props) => {
  const isMobile = useMediaQuery('(max-width:700px)');
  const [isFiltersAtvive, setIsFiltersActive] = useState(false);

  const handleFilterToggle = () => setIsFiltersActive((prev) => !prev);

  return (
    <>
      <Header overlap />
      <CoverPage
        picture={storeCoverHeadSrc}
        mobilePicture={storeCoverHeadMSrc}
        content={
          <>
            ENTRENA CON <br />
            TODA COMODIDAD
          </>
        }
      />
      <Box marginTop="2em">
        {!isMobile && <Breadcrumb values={[{ label: 'tienda' }]} />}
        {isMobile && (
          <Box justify="space-between" alignItems="center" position="relative">
            <Tune className={styles.filter} onClick={handleFilterToggle} />
            <Breadcrumb values={[{ label: 'tienda' }]} />
          </Box>
        )}
      </Box>
      <Container small>
        <SwipeableDrawer open={isFiltersAtvive && isMobile} onClose={handleFilterToggle}>
          <Box padding="4em" fontSize=".8em" onClick={handleFilterToggle}>
            <Filters onChange={props.onChangeFilter} value={props.filters} />
          </Box>
        </SwipeableDrawer>
        <div className={styles.flexWrapper}>
          <div className={styles.menuWrapper}>
            <Filters onChange={props.onChangeFilter} value={props.filters} />
          </div>
          <div className={styles.productsWrapper}>
            {props.loading && <Loading />}

            {!props.loading && !props.products.length && <EmptyContent message="No encontramos resultados" />}

            {!props.loading && !!props.products.length && (
              <div className={styles.listItemsWrapper}>
                {props.products.map((currentProdct) => (
                  <div className={styles.itemWrapper} key={currentProdct.id}>
                    <PropductCard
                      isDigital={currentProdct.category === 'sportsPrograms'}
                      category={currentProdct.category}
                      title={currentProdct.title}
                      picture={currentProdct.picture}
                      description={currentProdct.description}
                      price={currentProdct.price}
                      monthlyPrice={currentProdct.monthlyPrice}
                      semesterPrice={currentProdct.semesterPrice}
                      inscription={currentProdct.inscription}
                      id={currentProdct.id}
                      onBuyNow={(e) => {
                        props.onBuyNow(currentProdct);
                        e.stopPropagation();
                        e.preventDefault();
                      }}
                      onAddToCart={(e) => {
                        props.onAddToCart(currentProdct);
                        e.stopPropagation();
                        e.preventDefault();
                      }}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </Container>
      <CoverFooterPage
        content="SI FALLASTE AYER, HOY TIENES LA OPORTUNIDAD DE INTENTARLO OTRA VEZ"
        picture={coverSrc}
        pictureMobile={coverMSrc}
      />
      <Footer />
    </>
  );
};

ProductsView.propTypes = {
  onChangeFilter: func.isRequired,
  filters: object.isRequired,
  loading: bool.isRequired,
  products: array.isRequired,
  onAddToCart: func.isRequired,
  onBuyNow: func.isRequired,
};

export default ProductsView;
