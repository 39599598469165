import { any, func, number } from 'prop-types';
import React from 'react';
import logoSrc from 'src/assets/logo.svg';
import bagSrc from 'src/assets/bag.svg';
import loginSrc from 'src/assets/login.svg';
import { Menu } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { Box } from '@material-ui/core';
import Container from '../../../container';
import styles from './styles.module.css';

const HeaderMobile = (props) => (
  <header className={styles.header} style={props.getinlineStyle()} ref={props.headerRef}>
    <Container disabledVerticalPadding>
      <div className={styles.headerBody}>
        <img src={logoSrc} className={styles.logo} alt="logo" />
        <div className={styles.action}>
          <Link to="/mi-carrito">
            <Box position="relative">
              {!!props.numberItemsOnCart && <div className={styles.numberItemsOnCart}>{props.numberItemsOnCart}</div>}
              <img className={styles.icon} alt="icon" src={bagSrc} />
            </Box>
          </Link>
          <Link to="/auth/signin">
            <img className={styles.icon} alt="icon" src={loginSrc} />
          </Link>
          <Menu className={styles.iconMaterial} onClick={props.onMenuOpen} />
        </div>
      </div>
    </Container>
  </header>
);

HeaderMobile.defaultProps = {
  numberItemsOnCart: 0,
};

HeaderMobile.propTypes = {
  getinlineStyle: func.isRequired,
  headerRef: any.isRequired,
  onMenuOpen: func.isRequired,
  numberItemsOnCart: number,
};

export default HeaderMobile;
