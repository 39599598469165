import React from 'react';
import pictureSrc from 'src/assets/about.png';
import picturemSrc from 'src/assets/aboutm.png';
import CoverPage from 'src/components/coverPage';
import AboutGridPictureSrc from 'src/assets/about-grid.png';
import objetivePctureSrc from 'src/assets/objetive.jpg';
import AboutSecondSrc from 'src/assets/about-second.jpg';
import aboutGrid1 from 'src/assets/about-grid-1.png';
import aboutGrid2 from 'src/assets/about-grid-2.png';
import adornmentSrc from 'src/assets/adornment.svg';
import Footer from 'src/components/footer';
import Header from 'src/components/header';
import Container from 'src/components/container';
import { Box, Hidden } from '@material-ui/core';
import styles from './styles.module.css';

const About = () => (
  <>
    <Header overlap />
    <div className={styles.bodyGray}>
      <CoverPage picture={pictureSrc} mobilePicture={picturemSrc} content="QUÉ ES FEMINNE LIFE" />
      <div className={styles.resize}>
        <Container extraSmall>
          <div className={styles.firstSectionTitleWrapper}>
            <div className={styles.title}>
              Feminne Life es una organización que tiene como{' '}
              <Box component="span" color="var(--cyan)">
                finalidad
              </Box>
              , ayudar a la gente a tener una mejor calidad de vida a través de la{' '}
              <Box component="span" color="var(--cyan)">
                práctica
              </Box>{' '}
              deportiva.
            </div>
          </div>
        </Container>
        <div className={styles.gridWrapper}>
          <Hidden xsDown>
            <img src={AboutGridPictureSrc} className={styles.pictureGrid} alt="grid" />
          </Hidden>
          <Hidden smUp>
            <div>
              <img src={aboutGrid1} className={styles.pictureGrid} alt="grid" />
              <Box paddingTop="2em" />
              <img src={aboutGrid2} className={styles.pictureGrid} alt="grid" />
            </div>
          </Hidden>
        </div>
        <Container extraSmall>
          <div className={styles.objetive}>
            <div className={styles.objetiveText}>
              <div className={styles.title}>Nuestro objetivo</div>
              <p>
                Es generar un cambio de estilo de vida, a través del ejercicio o la práctica deportiva, para mejorar la
                calidad de vida, salud, movilidad, imagen y en general la realización personal.
              </p>
              <p>
                Es un programa que te lleva paso a paso, de una manera amigable desarrollando técnica y condición
                física, además de orientación para tu alimentación y descanso.
              </p>
            </div>
            <img src={objetivePctureSrc} alt="objetive" />
          </div>
        </Container>
      </div>
    </div>
    <img width="100%" src={adornmentSrc} alt="adornment" />
    <div className={styles.resize}>
      <Container extraSmall>
        <div className={styles.objetive}>
          <div className={styles.objetiveText}>
            <div className={styles.title}>Quién puede tomar nuestro programa</div>
            <p>
              Enfocado en mujeres a partir de los 18 años, que no realizan actividad física de manera regular o
              constante y que deseen hacer un cambio en su estilo de vida, sin el tradicional trauma de las personas que
              se inician en el deporte.
            </p>
            <p>
              Muy especialmente se enfoca en aquellas personas que nunca en su vida han hecho ejercicio o tienen muchos
              años sin hacerlo.
            </p>
            <p>
              Tradicionalmente cuando alguien decide empezar a hacer ejercicio, es sometido a rutinas que en los
              primeros días los dejan adoloridos y sin la posibilidad de moverse, incluso llegando a generar lesiones,
              desilusión y el consecuente alejamiento de la actividad.
            </p>
          </div>
          <img src={AboutSecondSrc} alt="objetive" />
        </div>
      </Container>
    </div>
    <Footer />
  </>
);

export default About;
