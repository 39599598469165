import React, { useMemo, useState } from 'react';
import Footer from 'src/components/footer';
import Loading from 'src/components/loading';
import PrivateCover from 'src/components/privateCover';
import Header from 'src/components/header';
import { useSession } from 'src/modules/session';
import TrainingDailyCard from 'src/components/trainingDailyCard';
import programModel from 'src/core/models/program';
import programUserModel from 'src/core/models/programUsers';
import { getCurrentWeek } from 'src/helpers/training';
import useFetch from 'src/hooks/useFetch';
import { useParams } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { any, string } from 'prop-types';
import styless from './styles.module.css';
import Controls from '../training/components/controls';

const TrainingDetail = (props) => {
  const { dayIndex } = useParams();
  const session = useSession();
  const [userData, setUserData] = useState({});
  const [program, setProgram] = useState({});
  const [loading, setLoading] = useState(true);

  const dayIndexCalculated = props.day || dayIndex;

  useFetch(async () => {
    const responseUserData = await programUserModel.getSingle(props.userId || session.data.id);
    const responseProgramData = await programModel.getActiveImplement(responseUserData.programId || 'main');
    setProgram(responseProgramData.payload);
    setUserData(responseUserData);
    setLoading(false);
  });

  const currentWeek = useMemo(() => (Object.keys(userData).length ? getCurrentWeek(userData) : 0), [userData]);

  const currentTraining = useMemo(() => {
    if (loading) return null;
    return program[currentWeek - 1].days[dayIndexCalculated];
  }, [loading, currentWeek, dayIndexCalculated]);

  const isCompleted = useMemo(() => new Date().getDay() - 1 > dayIndexCalculated);

  return (
    <>
      {!props.userId && (
        <>
          <Header />
          <PrivateCover />
          <Controls />
        </>
      )}
      {loading && <Loading message="Cargando entrenamiento" />}
      {!loading && (
        <Box style={{ backgroundColor: '#f1f1f1', padding: '2em 0em' }}>
          <div className={styless.lim}>
            <TrainingDailyCard
              weekNumber={currentWeek}
              dayName={currentTraining.label}
              dayData={currentTraining}
              completed={isCompleted}
            />
          </div>
        </Box>
      )}
      {!props.userId && <Footer />}
    </>
  );
};

TrainingDetail.propTypes = {
  userId: string,
  day: any,
};

TrainingDetail.defaultProps = {
  userId: null,
  day: null,
};

export default TrainingDetail;
