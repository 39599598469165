/**
 * format number adding decimals
 * @param {String, NUmber} value
 * @returns {String} value with decimal
 */
const formatPrice = (value) => {
  try {
    const valueParsed = parseFloat(value).toFixed(2);
    const integer = valueParsed.toString().split('.')[0];
    const cent = valueParsed.toString().split('.')[1];

    // format ingener
    let internalCounter = 1;
    let integerParsed = '';
    integer
      .split('')
      .reverse()
      .forEach((currentDigit) => {
        if (internalCounter === 4) {
          internalCounter = 1;
          integerParsed = `${integerParsed},${currentDigit}`;
        } else {
          integerParsed = `${integerParsed}${currentDigit}`;
        }
        internalCounter += 1;
      });

    integerParsed = integerParsed.split('').reverse().join('');

    return `${integerParsed}.${cent}`;
  } catch (error) {
    console.log('error', error);
    return 'error formating...';
  }
};

window.formatPrice = formatPrice;

export default formatPrice;
