import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import testimonialModel from '../core/models/testimonials';
import storage from '../core/storage';
import formatObect from '../helpers/formatObject';
import validaotors from '../helpers/getValidators';
import { success } from '../helpers/response';
import useNotification from '../modules/notifications/useNotification';
import useErrorMessage from './useErrorMessage';
import useForm from './useForm';

const computeInitialFormState = (state) => {
  if (!state) return {};
  const allows = ['name', 'description', 'picture', 'video'];
  return formatObect(state, allows);
};

const useTestimonialEditor = (state) => {
  const setNotification = useNotification();
  const history = useHistory();
  const { errorMessage, setErrorMessage } = useErrorMessage();
  const [loading, setLoading] = useState(false);

  const { getInputProps, validateInputs, setValues, values } = useForm(computeInitialFormState(state));

  const handlePictureSelect = (picture) => {
    setValues((prevVals) => ({ ...prevVals, picture }));
  };

  const handlePictureDelete = () => setValues((prevVals) => ({ ...prevVals, picture: null }));

  const handleValidateInputs = () => {
    const { firstErrorMessage } = validateInputs({
      name: validaotors.input('El nombre es requerido'),
      description: validaotors.input('La descripción no es válida'),
      picture: (val) => (!val ? 'La imágen es requerida' : false),
    });
    if (!firstErrorMessage) return true;
    setErrorMessage(firstErrorMessage);
    return false;
  };

  const handleUpload = async () => {
    setLoading(true);
    const { picture, ...testimonialData } = values;
    const { data } = await testimonialModel.create(testimonialData);
    const pictureUrl = await storage.uploadPicture(values.picture, `testimonials/${data.id}`);
    await testimonialModel.update(data.id, { picture: pictureUrl });
    history.push('/dashboard/testimonials');
    setNotification(success('Testimonio creado correctamente'));
  };

  const handleUpdate = async () => {
    setLoading(true);
    let pictureUrl = values.picture;
    if (typeof pictureUrl !== 'string') {
      await storage.deletePicture(state.picture);
      pictureUrl = await storage.uploadPicture(values.picture, `testimonials/${state.id}`);
    }
    await testimonialModel.update(state.id, { ...values, picture: pictureUrl });
    history.push('/dashboard/testimonials');
    setNotification(success('Testimonio actualizado correctamente'));
    return null;
  };

  const handleAcept = () => {
    if (handleValidateInputs() && !state) handleUpload();
    if (handleValidateInputs() && state) handleUpdate();
  };

  return {
    acept: handleAcept,
    deletePicture: handlePictureDelete,
    selectPicture: handlePictureSelect,
    getInputProps,
    errorMessage,
    loading,
    values,
  };
};

export default useTestimonialEditor;
