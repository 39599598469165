// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_limiter__3flKB {\n  width: 70%;\n  margin: 2em auto;\n  line-height: 1.5em;\n}\n\n@media screen and (max-width:1000px) {\n  .styles_limiter__3flKB {\n    width: 100%;\n    line-height: 1.5em;\n    font-size: .9em;\n  }\n}", "",{"version":3,"sources":["webpack://src/pages/dashboard/pages/trainingView/styles.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE;IACE,WAAW;IACX,kBAAkB;IAClB,eAAe;EACjB;AACF","sourcesContent":[".limiter {\n  width: 70%;\n  margin: 2em auto;\n  line-height: 1.5em;\n}\n\n@media screen and (max-width:1000px) {\n  .limiter {\n    width: 100%;\n    line-height: 1.5em;\n    font-size: .9em;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"limiter": "styles_limiter__3flKB"
};
export default ___CSS_LOADER_EXPORT___;
