import { Menu } from '@material-ui/core';
import { oneOfType, element, string, number, object, array } from 'prop-types';
import React, { useState } from 'react';
import styles from './styles.module.css';

const Options = ({ optionsList, className, children, ...props }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleToggleMenu = (event) => setAnchorEl(!anchorEl ? event.currentTarget : null);

  return (
    <div {...props} className={className} onClick={handleToggleMenu} role="button">
      {children}
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        className={styles.menu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <div className={styles.optionsWrapper}>
          {optionsList.map((currentOption, index) => (
            <div role="button" className={styles.optionList} key={index} onClick={currentOption.handler}>
              {currentOption.label}
            </div>
          ))}
        </div>
      </Menu>
    </div>
  );
};

Options.defaultProps = {
  className: '',
  optionsList: [],
};

Options.propTypes = {
  optionsList: array,
  className: string,
  children: oneOfType([string, element, number, object, array]).isRequired,
};

export default Options;
