import React from 'react';
import { HashLink } from 'react-router-hash-link';
import styles from './styles.module.css';

const Menu = () => (
  <div className={styles.wrapper}>
    <HashLink smooth to="/programa-de-entrenamiento#objetive" className={styles.option}>
      OBJETIVO
    </HashLink>
    <HashLink smooth to="/programa-de-entrenamiento#who-is-this-program-for" className={styles.option}>
      PARA QUIÉN ES EL PROGRAMA
    </HashLink>
    <HashLink smooth to="/programa-de-entrenamiento#duration" className={styles.option}>
      DURACIÓN
    </HashLink>
  </div>
);

export default Menu;
