import { Box } from '@material-ui/core';
import { bool, func, object, string } from 'prop-types';
import React from 'react';
import paypalSrc from 'src/assets/paypal.png';
import styles from './styles.module.css';
import ErrorMessage from '../../../../components/errorMessage';
import { formsPay } from '../../../../constants';
import formatPrice from '../../../../helpers/formatPrice';

const MethodPaySelectorAndSenInfo = (props) => {
  const getMethodPayRadioInputProps = (value) => {
    const { onChange } = props.getInputProps();
    return {
      onClick: () => onChange({ target: { name: 'methodPay', value } }),
    };
  };

  const getFormrsPayRadioInputProps = (value) => {
    const { onChange } = props.getInputProps();
    return {
      onClick: () => onChange({ target: { name: 'formPay', value } }),
    };
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.group}>
        <div className={styles.flexSpacer}>
          <div className={styles.describe}>
            <span className={styles.gray}>Contacto:</span>
          </div>
          <div className={styles.describeValue}>{props.values.email}</div>
        </div>
      </div>
      <div className={styles.group}>
        <div className={styles.flexSpacer}>
          <div className={styles.describe}>
            <span className={styles.gray}>Datos:</span>
          </div>
          <div className={styles.describeValue}>
            {props.values.streetAndNumber}, {props.values.suburb}, {props.values.city}, {props.values.state}, C.P.:
            {props.values.postalCode}
          </div>
        </div>
      </div>
      {props.showMethoPay && (
        <>
          <Box fontSize="1.2em" fontWeight="800" margin="2em 0em 1em 0em">
            MÉTODO DE PAGO
          </Box>
          <ErrorMessage message={props.errorMessage} />
          <div className={styles.methodPayWrapper} {...getMethodPayRadioInputProps('paypal')}>
            <div className={styles.flexSpacer}>
              <div className={styles.radio}>
                {props.values.methodPay === 'paypal' && <div className={styles.radioSelected} />}
              </div>
              <img className={styles.payIcon} src={paypalSrc} alt="icon" />
            </div>
          </div>
          <div className={styles.methodPayWrapper} {...getMethodPayRadioInputProps('manual')}>
            <div className={styles.flexSpacer}>
              <div className={styles.radio}>
                {props.values.methodPay === 'manual' && <div className={styles.radioSelected} />}
              </div>
              Pago en efectivo o transferencia
            </div>
          </div>
          <Box fontSize="1.2em" fontWeight="800" margin="2em 0em 1em 0em">
            FORMA DE PAGO
          </Box>
          {Object.keys(formsPay).map((currentKey) => (
            <div className={styles.methodPayWrapper} {...getFormrsPayRadioInputProps(currentKey)} key={currentKey}>
              <div className={styles.flexSpacer}>
                <div className={styles.radio}>
                  {props.values.formPay === currentKey && <div className={styles.radioSelected} />}
                </div>
                $ {formatPrice(props.productData[currentKey])}
                <Box component="span" fontWeight="normal" marginLeft=".5em">
                  ({formsPay[currentKey]})
                </Box>
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

MethodPaySelectorAndSenInfo.propTypes = {
  showMethoPay: bool.isRequired,
  values: object.isRequired,
  onBack: func.isRequired,
  getInputProps: func.isRequired,
  errorMessage: string.isRequired,
  productData: object.isRequired,
};

export default MethodPaySelectorAndSenInfo;
