import React, { useState } from 'react';
import Container from 'src/components/container';
import Header from 'src/components/header';
import Cover from 'src/components/coverPage';
import advicersSrc from 'src/assets/advicers.jpg';
import advicersMSrc from 'src/assets/asesores-slide-poltri.jpg';
import Input from 'src/components/inputs';
import { Email, Phone } from '@material-ui/icons';
import { contact } from 'src/constants';
import Footer from 'src/components/footer';
import { Box } from '@material-ui/core';
import useNewMessage from 'src/hooks/useNewMessage';
import Loading from 'src/components/loading';
import ErrorMessage from 'src/components/errorMessage';
import styles from './styles.module.css';
import ButtonAdmin from '../dashboard/components/button';

const ContactPage = () => {
  const newMessage = useNewMessage();
  const [success, setSusccess] = useState(false);

  const handleSubmit = async (event) => {
    const isSuccess = await newMessage.send(event);
    if (isSuccess) setSusccess(true);
  };

  return (
    <>
      <Header overlap />
      <Cover picture={advicersSrc} mobilePicture={advicersMSrc} content="CONTÁCTANOS" />
      <Container>
        <div className={styles.body}>
          <div className={styles.title}>
            Llena el siguente formulario y nos pondremos en contacto contigo a la brevedad
          </div>
          <form className={styles.form} onSubmit={handleSubmit}>
            <div className={styles.contactHead}>
              <a href={`mailto:${contact.email}`}>
                <Email />
                {contact.email}
              </a>
              <a href={`phone:${contact.phone}`}>
                <Phone />
                {contact.phoneLabel}
              </a>
            </div>
            {!newMessage.loading && !success && (
              <>
                <ErrorMessage message={newMessage.errorMessage} />
                <div className={styles.inputWrapper}>
                  <Input placeholder="nombre completo" {...newMessage.getInputProps('name')} maxLength={100} />
                </div>
                <div className={styles.inputWrapper}>
                  <Input placeholder="Email" {...newMessage.getInputProps('email')} maxLength={100} />
                  <Box paddingRight="1em" />
                  <Input placeholder="Tel./Cel." {...newMessage.getInputProps('phone')} maxLength={10} onlyNumbers />
                </div>
                <div className={styles.inputWrapper}>
                  <Input
                    type="textarea"
                    placeholder="Mensaje"
                    maxLength={500}
                    {...newMessage.getInputProps('message')}
                  />
                </div>
                <ButtonAdmin color="var(--rose)" primary id={styles.button} onClick={handleSubmit}>
                  Enviar
                </ButtonAdmin>
              </>
            )}

            {success && !newMessage.loading && (
              <div className={styles.successMessage}>Mensaje enviando, Gracias por contactarnos</div>
            )}

            {!success && newMessage.loading && <Loading message="Enviando mensaje..." />}
          </form>
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default ContactPage;
