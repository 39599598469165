import { func, string } from 'prop-types';
import React from 'react';
import Input from 'src/components/inputs';
import LabelInput from 'src/components/labelInput';
import ErrorMessage from 'src/components/errorMessage';
import styles from './styles.module.css';

const GeneralInfo = ({ getInputProps, errorMessage }) => (
  <div className={styles.wrapper}>
    <ErrorMessage message={errorMessage} />
    <LabelInput label="Nombre completo" verticalPadding=".3em">
      <Input {...getInputProps('name')} maxLength={100} />
    </LabelInput>
    <LabelInput label="Correo" verticalPadding=".3em">
      <Input {...getInputProps('email')} maxLength={100} />
    </LabelInput>
    <LabelInput label="Teléfono" verticalPadding=".3em">
      <Input {...getInputProps('phone')} onlyNumbers maxLength={10} />
    </LabelInput>
    <LabelInput label="Contraseña" verticalPadding=".3em">
      <Input {...getInputProps('password')} type="text" maxLength={100} />
    </LabelInput>
    <LabelInput label="Descripción" verticalPadding=".3em">
      <Input type="textarea" {...getInputProps('description')} />
    </LabelInput>
  </div>
);

GeneralInfo.defaultProps = {
  errorMessage: null,
};

GeneralInfo.propTypes = {
  getInputProps: func.isRequired,
  errorMessage: string,
};

export default GeneralInfo;
