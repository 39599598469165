// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_title__1SWCF {\n  padding: 1em;\n  font-size: 1.4em;\n  font-weight: bold;\n  display: flex;\n  align-items: center;\n}\n.styles_title__1SWCF img {\n  width: 1.4em;\n  margin-right: 1em;\n}\n.styles_limiter__vzt31 {\n  width: 70%;\n  margin: auto;\n  margin-top: 2em;\n}\n\n@media screen and (max-width:1200px) {\n  .styles_limiter__vzt31 {\n    width: 80%;\n  }\n  .styles_fullWrapper__27OnA {\n    font-size: .9em;\n  }\n}\n\n@media screen and (max-width:1000px) {\n  .styles_limiter__vzt31 {\n    width: 90%;\n  }\n  .styles_fullWrapper__27OnA {\n    font-size: .8em;\n  }\n}\n\n@media screen and (max-width:600px) {\n  .styles_limiter__vzt31 {\n    width: 100%;\n  }\n}", "",{"version":3,"sources":["webpack://src/pages/dashboard/pages/groupChat/styles.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gBAAgB;EAChB,iBAAiB;EACjB,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,YAAY;EACZ,iBAAiB;AACnB;AACA;EACE,UAAU;EACV,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE;IACE,UAAU;EACZ;EACA;IACE,eAAe;EACjB;AACF;;AAEA;EACE;IACE,UAAU;EACZ;EACA;IACE,eAAe;EACjB;AACF;;AAEA;EACE;IACE,WAAW;EACb;AACF","sourcesContent":[".title {\n  padding: 1em;\n  font-size: 1.4em;\n  font-weight: bold;\n  display: flex;\n  align-items: center;\n}\n.title img {\n  width: 1.4em;\n  margin-right: 1em;\n}\n.limiter {\n  width: 70%;\n  margin: auto;\n  margin-top: 2em;\n}\n\n@media screen and (max-width:1200px) {\n  .limiter {\n    width: 80%;\n  }\n  .fullWrapper {\n    font-size: .9em;\n  }\n}\n\n@media screen and (max-width:1000px) {\n  .limiter {\n    width: 90%;\n  }\n  .fullWrapper {\n    font-size: .8em;\n  }\n}\n\n@media screen and (max-width:600px) {\n  .limiter {\n    width: 100%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "styles_title__1SWCF",
	"limiter": "styles_limiter__vzt31",
	"fullWrapper": "styles_fullWrapper__27OnA"
};
export default ___CSS_LOADER_EXPORT___;
