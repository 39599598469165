// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_button__1y16K {\r\n  min-width: 8em!important;\r\n  padding: .5em!important;\r\n  box-sizing: border-box;\r\n  border-radius: 5px!important;\r\n}\r\n", "",{"version":3,"sources":["webpack://src/pages/dashboard/components/button/styles.module.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,uBAAuB;EACvB,sBAAsB;EACtB,4BAA4B;AAC9B","sourcesContent":[".button {\r\n  min-width: 8em!important;\r\n  padding: .5em!important;\r\n  box-sizing: border-box;\r\n  border-radius: 5px!important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "styles_button__1y16K"
};
export default ___CSS_LOADER_EXPORT___;
