import { Box } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { toLargeStringDate } from 'src/helpers/dates';
import styles from './styles.module.css';

const MessageCard = (props) => (
  <div className={styles.card}>
    <Delete className={styles.deleteIcon} onClick={props.onDelete} />
    <div className={styles.row}>
      <Box color="var(--cyan)" fontWeight="bold">
        {props.name}
      </Box>
    </div>

    <div className={styles.row}>
      <span>Fecha:</span>
      {toLargeStringDate(props.createdAt)}
    </div>
    <div className={styles.row}>
      <span>Teléfono:</span>
      {props.phone}
    </div>
    <div className={styles.row}>
      <span>Correo:</span>
      {props.email}
    </div>
    <div className={styles.message}>
      <div className={styles.caption}>Mensaje</div>
      {props.message}
    </div>
  </div>
);

MessageCard.propTypes = {
  createdAt: PropTypes.instanceOf(Date).isRequired,
  email: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default MessageCard;
