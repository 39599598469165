import { Box, CircularProgress, Grid, Hidden, Modal, useMediaQuery } from '@material-ui/core';
import { bool, string, func } from 'prop-types';
import React from 'react';
import Button from 'src/pages/dashboard/components/button';
import { colors } from '../../constants';
import styles from './styles.module.css';

const ViewAlert = ({ loading, title, message, onAcept, active, onClose }) => {
  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <Modal open={active} className={styles.modal} BackdropProps={{ style: { background: 'rgb(0 0 0 / 67%)' } }}>
      <div className={styles.card}>
        {loading && <CircularProgress />}
        {!loading && (
          <>
            <Box marginBottom="3em">
              <Box
                marginBottom="1em"
                fontSize={isMobile ? '.9em' : '1.1em'}
                fontWeight="bold"
                textAlign="center"
                lineHeight="1.4em"
              >
                {title}
              </Box>
              {message && (
                <Box textAlign="center" lineHeight="1.4em">
                  {message}
                </Box>
              )}
            </Box>

            <Grid container justify="center">
              <Button color={colors.cyan} onClick={onClose}>
                Cancelar
              </Button>
              <Hidden xsDown>
                <Box paddingRight="2em" />
              </Hidden>
              <Hidden smUp>
                <Box paddingRight="1em" />
              </Hidden>
              <Button color={colors.cyan} primary onClick={onAcept}>
                Aceptar
              </Button>
            </Grid>
          </>
        )}
      </div>
    </Modal>
  );
};

ViewAlert.defaultProps = {
  message: null,
  onAcept: null,
  title: null,
};

ViewAlert.propTypes = {
  onAcept: func,
  title: string,
  message: string,
  active: bool.isRequired,
  loading: bool.isRequired,
  onClose: func.isRequired,
};

export default ViewAlert;
