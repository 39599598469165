import React, { useEffect, useState } from 'react';
import ChatView from 'src/components/chat';
import chatModel, { newMessage, onMessagesChange, setNotificationsViewed } from 'src/core/models/chat';
import programUserModel from 'src/core/models/programUsers';
import useFetch from 'src/hooks/useFetch';
import { useSession } from 'src/modules/session';
import avatarSrc from 'src/assets/user.png';
import normalizeImagesUrl from 'src/helpers/normalizeImagesUrl';
import users from '../../../../core/models/users';

const Chat = () => {
  const session = useSession();
  const [chatId, setChatId] = useState(null);
  const [chat, setChat] = useState([]);
  const [advicer, setAdvicer] = useState({});

  // get chatId
  useFetch(async () => {
    const { advicer: advicerId } = await programUserModel.getSingle(session.data.id);
    const chatIdFetched = await chatModel.getChatId(advicerId, session.data.id);
    const advicerData = await users.getSingle(advicerId);
    setChatId(chatIdFetched);
    setAdvicer(advicerData);
  });

  // initialize realtime messages
  useEffect(() => {
    if (chatId) {
      const unsubscribe = onMessagesChange(chatId, (chats) => {
        const chatsFomated = chats.map((currentChat) => {
          if (currentChat.userId === session.data.id)
            return {
              ...currentChat,
              isOwn: true,
              picture: session.data.picture || avatarSrc,
              name: 'Tú',
            };
          return {
            ...currentChat,
            isOwn: false,
            picture: advicer.picture || avatarSrc,
            name: `Coach ${advicer.name.split(' ')[0]}`,
          };
        });
        setChat(chatsFomated);
        setNotificationsViewed(chatId, 'user');
      });
      return unsubscribe;
    }
    return undefined;
  }, [chatId, advicer]);

  const handleNewMessage = async (newContent) => {
    if (newContent instanceof File) {
      setChat((prevVals) => [
        ...prevVals,
        {
          date: new Date(),
          userId: session.data.id,
          content: normalizeImagesUrl(newContent),
          isOwn: true,
          picture: session.data.picture || avatarSrc,
          name: 'Tú',
        },
      ]);
    }
    newMessage(chatId, session.data.id, newContent, 'advicer');
  };

  return <ChatView onNewMessage={handleNewMessage} messages={chat} userId={session.data.id} chatWith="Coach" />;
};

export default Chat;
