import React from 'react';
import { Box } from '@material-ui/core';
import ChatIconScr from 'src/assets/chat.png';
import adornment from 'src/assets/adornment.svg';
import Header from '../../components/header';
import PrivateCover from '../../components/privateCover';
import PrivateFooter from '../../components/privateFooter';
import styles from './styles.module.css';
import Container from '../../components/container';
import GroupChat from '../../components/groupChat';

const GroupChatPage = () => (
  <Box className={styles.fullWrapper}>
    <Header />
    <PrivateCover />
    <div className={styles.titleWrapper}>
      <img className={styles.icon} src={ChatIconScr} alt="icon chat" />
      <div className={styles.title}>Chat del grupo</div>
    </div>
    <div className={styles.bachgroundGray}>
      <Container extraSmall>
        <GroupChat />
      </Container>
    </div>
    <img src={adornment} alt="adorment" width="100%" />
    <PrivateFooter />
  </Box>
);

export default GroupChatPage;
