// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_wrapper__3SN3S {\n  position: fixed;\n  z-index: 2;\n  top: 0;\n  right: 0;\n  left: 0;\n  bottom: 0;\n  margin: auto;\n  width: 100vw;\n  height: 100vh;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background-color: rgba(0, 0, 0, 0.74);\n}\n.styles_body__3-gJ_ {\n  position: relative;\n}\n\n.styles_close__2Moke {\n  font-size: 2em;\n  right: 0em;\n  top: -1.5em;\n  position: absolute;\n  color: #fff;\n  cursor: pointer;\n}\n.styles_shadow__13Mp8 {\n  width: 100%;\n  height: 100%;\n  background-color: #ff000000;\n  z-index: 999999;\n  position: absolute;\n}", "",{"version":3,"sources":["webpack://src/modules/youtubePlayer/styles.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,UAAU;EACV,MAAM;EACN,QAAQ;EACR,OAAO;EACP,SAAS;EACT,YAAY;EACZ,YAAY;EACZ,aAAa;EACb,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,qCAAqC;AACvC;AACA;EACE,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,UAAU;EACV,WAAW;EACX,kBAAkB;EAClB,WAAW;EACX,eAAe;AACjB;AACA;EACE,WAAW;EACX,YAAY;EACZ,2BAA2B;EAC3B,eAAe;EACf,kBAAkB;AACpB","sourcesContent":[".wrapper {\n  position: fixed;\n  z-index: 2;\n  top: 0;\n  right: 0;\n  left: 0;\n  bottom: 0;\n  margin: auto;\n  width: 100vw;\n  height: 100vh;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background-color: rgba(0, 0, 0, 0.74);\n}\n.body {\n  position: relative;\n}\n\n.close {\n  font-size: 2em;\n  right: 0em;\n  top: -1.5em;\n  position: absolute;\n  color: #fff;\n  cursor: pointer;\n}\n.shadow {\n  width: 100%;\n  height: 100%;\n  background-color: #ff000000;\n  z-index: 999999;\n  position: absolute;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "styles_wrapper__3SN3S",
	"body": "styles_body__3-gJ_",
	"close": "styles_close__2Moke",
	"shadow": "styles_shadow__13Mp8"
};
export default ___CSS_LOADER_EXPORT___;
