import validaotors from 'src/helpers/getValidators';

const { validateCardNumber, validateCVC, validateExpiry } = window.OpenPay.card;

export const generalInfoValidator = {
  email: validaotors.email(),
  birthDate: validaotors.input('La fecha de nacimiento es requerido'),
  gender: validaotors.input('El genero es requerido'),
  country: validaotors.input('El pais es requerido'),
  name: validaotors.input('El nombre no es válido'),
  lastName: validaotors.input('Los apellidos no son válidos'),
  streetAndNumber: validaotors.input('calle y numero inválido'),
  suburb: validaotors.input('La colonia no es válida'),
  city: validaotors.input('La ciudad no es válida'),
  state: validaotors.input('El estado no es válido'),
  phone: (value = '') => {
    const errorMessage = 'El numero de teléfono no es valido';
    const validator = validaotors.number();
    const error = validator(value);
    if (error) return errorMessage;
    if (value.toString().length !== 10) return errorMessage;
    return null;
  },
  postalCode: (value = '') => {
    const errorMessage = 'Código postal inválido';
    const validator = validaotors.number();
    const error = validator(value);
    if (error) return errorMessage;
    if (value.toString().length !== 5) return errorMessage;
    return null;
  },
};

export const cardFormValidator = {
  cardName: validaotors.input('El nombre no es válido'),
  cardNumber: (val) => {
    if (!validateCardNumber(val)) return 'El número de tarjeta no es válido';
    return false;
  },
  cardMonth: (_val, values) => {
    if (!validateExpiry(values.cardMonth, values.cardYear)) return 'La fecha de vencimiento no es válida';
    return false;
  },
  cardYear: (_val, values) => {
    if (!validateExpiry(values.cardMonth, values.cardYear)) return 'La fecha de vencimiento no es válida';
    return false;
  },
  cardCode: (value) => {
    if (!validateCVC(value)) return 'El código de seguridad no es válido';
    return false;
  },
};

export default {
  generalInfoValidator,
  cardFormValidator,
};
