import React, { useState } from 'react';
import advicersSrc from 'src/assets/advicers.jpg';
import advicersMSrc from 'src/assets/asesores-slide-poltri.jpg';
import asesorfooterSrc from 'src/assets/asesorfooter.jpg';
import asesorfooterMSrc from 'src/assets/asesores-footer-poltri.jpg';
import adivisersModel from 'src/core/models/advisers';
import CoverFooterPage from '../../components/coverFooterPage';
import CoverPage from '../../components/coverPage';
import Footer from '../../components/footer';
import AsesorList from './components/list';
import Header from '../../components/header';
import useFetch from '../../hooks/useFetch';
import EmptyContent from '../../components/emptyContent';

const Advicers = () => {
  const [advisers, setAdvisers] = useState([]);

  useFetch(async () => {
    const data = await adivisersModel.getList();
    setAdvisers(data);
  });

  return (
    <div>
      <Header overlap />
      <CoverPage content="ASESORES" picture={advicersSrc} mobilePicture={advicersMSrc} />

      {!!advisers.length && <AsesorList advisers={advisers} />}
      {!advisers.length && <EmptyContent message="Aún no hay asesores" />}

      <CoverFooterPage
        content="EL DOLOR DE HOY ES EL BENEFICIO DE MAÑANA"
        picture={asesorfooterSrc}
        pictureMobile={asesorfooterMSrc}
      />
      <Footer />
    </div>
  );
};

export default Advicers;
