/* eslint-disable no-unused-vars */
import { Box } from '@material-ui/core';
import React from 'react';
import { NavLink } from 'react-router-dom';
import Container from '../../../../components/container';
import styles from './styles.module.css';

const Controls = () => (
  <div className={styles.fullWrapper}>
    <Container extraSmall disabledVerticalPadding>
      <div className={styles.title}>MI ENTRENAMIENTO</div>
    </Container>
    {/* <div className={styles.menuFullWrapper}>
      <Container extraSmall disabledVerticalPadding>
        <div className={styles.menuWrapperFlex}>
          <NavLink to="/private/training/current" activeClassName={styles.activeMenuItem} className={styles.menuItem}>
            RUTINA ACTUAL
          </NavLink>
          <Box paddingLeft="4em" />
          <NavLink to="/private/training/past" activeClassName={styles.activeMenuItem} className={styles.menuItem}>
            RUTINAS PASADAS
          </NavLink>
        </div>
      </Container>
    </div> */}
  </div>
);

export default Controls;
