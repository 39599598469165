import { element } from 'prop-types';
import React, { useState } from 'react';
import context from './context';
import ViewLoading from './view';

const Provider = ({ children }) => {
  const [isActive, setActive] = useState(false);

  return (
    <context.Provider value={setActive}>
      <>
        {children}
        <ViewLoading isActive={isActive} />
      </>
    </context.Provider>
  );
};

Provider.propTypes = {
  children: element.isRequired,
};

export default Provider;
