const addZero = (date) => (date < 10 ? `0${date}` : date);

export const toStringTime = (date) => {
  try {
    const minute = date.getMinutes();
    const hour = date.getHours();
    return `${addZero(hour)}:${addZero(minute)}`;
  } catch (error) {
    return error.toString();
  }
};

export const toStringDate = (date) => {
  try {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  } catch (error) {
    return error.toString();
  }
};

export const toLargeStringDate = (date) => {
  try {
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();
    const esMonths = [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ];
    return `${day} de ${esMonths[month]}  ${year}`;
  } catch (error) {
    return error.toString();
  }
};

export const getDayNumberOfYear = (date) => {
  const start = new Date(date.getFullYear(), 0, 0);
  const diff = date - start;
  const oneDay = 1000 * 60 * 60 * 24;
  const day = Math.floor(diff / oneDay);
  return day;
};

const date = {
  toStringTime,
  toStringDate,
  toLargeStringDate,
};

export default date;
