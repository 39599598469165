import { func, string } from 'prop-types';
import React, { useState } from 'react';
import Input from 'src/components/inputs';
import LabelInput from 'src/components/labelInput';
import ErrorMessage from 'src/components/errorMessage';
import { AccessTime } from '@material-ui/icons';
import { DatePicker } from '@material-ui/pickers';
import { Box } from '@material-ui/core';
import styles from './styles.module.css';
import date from '../../../../../../helpers/dates';

const GeneralInfo = ({ getInputProps, errorMessage }) => {
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const handleDatePickerOpen = () => setIsDatePickerOpen(true);
  const handleDatePickerClose = () => setIsDatePickerOpen(false);

  const handleDatePickerChange = (val) => {
    const { onChange } = getInputProps('date');
    onChange({ target: { name: 'date', value: val } });
  };

  const getInputPropsWithFormattedDate = (tagname) => {
    const { value, ...otherProps } = getInputProps(tagname);
    return { otherProps, value: !value ? value : date.toLargeStringDate(value) };
  };

  const handleGetdateVaue = () => {
    const { value } = getInputProps('date');
    return value;
  };

  return (
    <div className={styles.wrapper}>
      <Box display="none">
        <DatePicker
          variant="dialog"
          open={isDatePickerOpen}
          onClose={handleDatePickerClose}
          onChange={handleDatePickerChange}
          value={handleGetdateVaue()}
        />
      </Box>
      <ErrorMessage message={errorMessage} />
      <LabelInput label="Título" verticalPadding=".3em">
        <Input {...getInputProps('title')} />
      </LabelInput>
      <LabelInput label="Precio" verticalPadding=".3em">
        <Input {...getInputProps('price')} onlyFloat />
      </LabelInput>
      <LabelInput label="Link youtube (opcional)" verticalPadding=".3em">
        <Input {...getInputProps('youtube')} />
      </LabelInput>
      <LabelInput label="Fecha del evento" verticalPadding=".3em">
        <div className={styles.datePickerWrapper}>
          <Input {...getInputPropsWithFormattedDate('date')} disabled />
          <AccessTime className={styles.iconPicker} onClick={handleDatePickerOpen} />
        </div>
      </LabelInput>
      <LabelInput label="Descripción" verticalPadding=".3em">
        <Input type="textarea" {...getInputProps('description')} />
      </LabelInput>
    </div>
  );
};

GeneralInfo.defaultProps = {
  errorMessage: '',
};

GeneralInfo.propTypes = {
  getInputProps: func.isRequired,
  errorMessage: string,
};

export default GeneralInfo;
