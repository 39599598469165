import { Box, useMediaQuery } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import aSrc from 'src/assets/a.jpg';
import cSrc from 'src/assets/c.jpg';
import dSrc from 'src/assets/d.jpg';
import eSrc from 'src/assets/e.jpg';
import fSrc from 'src/assets/f.jpg';
import neededSrc from 'src/assets/needed_logo.png';
import Button from '../../../../components/button';
import Picture from '../../../../components/picture';
import styles from './styles.module.css';

const Grid = () => {
  const isMobile = useMediaQuery('(max-width:800px)');

  const card = (
    <div className={styles.card}>
      <div className={styles.title}>OBJETIVO</div>
      <div className={styles.subtitle}>
        Generar un cambio de estilo de vida, a través del ejercicio o práctica deportiva.
      </div>
      <div className={styles.paragraph}>
        Mejora la calidad de vida, salud, movilidad, imagen y en general la realización personal.
      </div>
      <div className={styles.paragraph}>
        <Box color="#003e4b">
          <Box fontWeight="bold">$450* inscripción</Box>
          *El 50% de tu aportación se donará al Instituto Needed Instituto de Rehabilitación al Maltrato de Menores.
        </Box>
      </div>
      <a href="https://www.needed.org.mx/" target="_blank" rel="noreferrer">
        <img src={neededSrc} alt="nedded logo" className={styles.neededLogo} />
      </a>
      <Link to={{ pathname: '/articulos', state: { category: 'sportsPrograms' } }}>
        <Button className={styles.button}>COMPRAR AHORA</Button>
      </Link>
    </div>
  );

  return (
    <div className={styles.wrapper} id="objetive">
      {!isMobile && (
        <div className={styles.gridContainer}>
          <div className={styles.a}>
            <Picture className={styles.picture} src={aSrc} />
          </div>
          <div className={styles.b}>{card}</div>
          <div className={styles.c}>
            <Picture className={styles.picture} src={cSrc} />
          </div>
          <div className={styles.d}>
            <Picture className={styles.picture} src={dSrc} />
          </div>
          <div className={styles.e}>
            <Picture className={styles.picture} src={eSrc} />
          </div>
          <div className={styles.f}>
            <Picture className={styles.picture} src={fSrc} />
          </div>
        </div>
      )}
      {isMobile && (
        <>
          {card}
          <div className={styles.gridMaterialContainer}>
            <Picture className={styles.picture} src={aSrc} />
            <Picture className={styles.picture} src={cSrc} />
          </div>
          <div className={styles.gridMaterialContainer}>
            <Picture className={styles.pictureFillWidth} src={fSrc} />
          </div>
        </>
      )}
    </div>
  );
};

export default Grid;
