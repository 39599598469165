/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-unused-vars */
import { any, array, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import Card from 'src/components/card';
import userSrc from 'src/assets/user.png';
import Picture from 'src/components/picture';
import { Box } from '@material-ui/core';
import { useSession } from 'src/modules/session';
import useFetch from 'src/hooks/useFetch';
import chatModel, { onMessagesChange } from 'src/core/models/chat';
import { MoreVert } from '@material-ui/icons';
import styles from './styles.module.css';
import Options from '../../../../../../components/options';

const UserCard = (props) => {
  const session = useSession();
  const [counter, setCounter] = useState(0);
  const [chatId, setChatId] = useState(null);

  // fetch initial data
  useFetch(async () => {
    const responseChatId = await chatModel.getChatId(session.data.id, props.id);
    setChatId(responseChatId);
  });

  // initialize realtime counter
  useEffect(() => {
    let unsibscribe;
    if (chatId) {
      unsibscribe = onMessagesChange(chatId, (_messages, numMessagesForAdvicer) => setCounter(numMessagesForAdvicer));
    }
    return unsibscribe;
  }, [chatId]);

  return (
    <Card className={styles.card}>
      {!!counter && (
        <div className={styles.messageFloat}>
          <span>{counter}</span> {counter > 1 ? 'Mensajes' : 'mensaje'}
        </div>
      )}
      <Picture className={styles.avatar} src={props.picture} />
      <Box flexGrow="1" lineHeight="1.5em">
        <Box flexGrow="1" fontWeight="600" marginBottom=".5em">
          {props.name}
        </Box>
        <Box>
          Semana de entrenamiento:
          <Box color="var(--rose)" component="span" marginLeft=".5em">
            {props.currentWeek}
          </Box>
        </Box>
        <Box>
          <Box marginRight="1em" component="span">
            Programa:
          </Box>
          <Box color="var(--rose)" component="span">
            {props.program}
          </Box>
        </Box>
      </Box>
      <Options optionsList={props.options}>
        <MoreVert className={styles.options} />
      </Options>
    </Card>
  );
};

UserCard.defaultProps = {
  picture: userSrc,
};

UserCard.propTypes = {
  name: string.isRequired,
  picture: string,
  options: array.isRequired,
  id: string.isRequired,
  currentWeek: any.isRequired,
  program: string.isRequired,
};

export default UserCard;
