import { array, bool, func, oneOf } from 'prop-types';
import adornmentSrc from 'src/assets/adornment.svg';
import React from 'react';
import Container from 'src/components/container';
import EmptyContent from 'src/components/emptyContent';
import Loading from 'src/components/loading';
import PrivateFooter from 'src/components/privateFooter';
import Header from 'src/components/header';
import { Box } from '@material-ui/core';
import Eventcard from './components/eventCard';
import styles from './styles.module.css';
import SubHeader from './components/subHeader';
import composeClassNames from '../../helpers/composeClassNames';

const EventsAndActivitiesView = (props) => (
  <>
    <Header />
    <SubHeader />
    <div className={styles.fullWidthWrapper}>
      <Container small>
        <div className={styles.productsWrapper}>
          {props.loading && <Loading />}
          {!props.loading && (
            <div className={styles.optionsWrapper}>
              <div
                role="button"
                onClick={() => props.onViewChange('next')}
                className={composeClassNames(styles.option, props.currentView === 'next' ? styles.optionSelected : '')}
              >
                Próximos eventos
              </div>
              <Box paddingRight="2em" />
              <div
                role="button"
                onClick={() => props.onViewChange('past')}
                className={composeClassNames(styles.option, props.currentView === 'past' ? styles.optionSelected : '')}
              >
                Eventos pasados
              </div>
            </div>
          )}
          {!props.loading && !props.items.length && <EmptyContent message="No encontramos resultados" />}
          {!props.loading && (
            <>
              <div className={styles.listItemsWrapper}>
                {props.items.map((currentEvent) => (
                  <div className={styles.itemWrapper} key={currentEvent.id}>
                    <Eventcard
                      title={currentEvent.title}
                      picture={currentEvent.picture}
                      description={currentEvent.description}
                      pictures={currentEvent.pictures}
                      id={currentEvent.id}
                      eventDate={currentEvent.eventDate}
                      date={currentEvent.date}
                      price={currentEvent.price}
                      youtube={currentEvent.youtube}
                      currentView={props.currentView}
                    />
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </Container>
    </div>
    <img src={adornmentSrc} width="100%" alt="adornment" />
    <PrivateFooter />
  </>
);

EventsAndActivitiesView.propTypes = {
  loading: bool.isRequired,
  items: array.isRequired,
  currentView: oneOf(['next', 'past']).isRequired,
  onViewChange: func.isRequired,
};

export default EventsAndActivitiesView;
