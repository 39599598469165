// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_dropzone__1oDAF {\n  background-color: #00eae21a;\n  height: 8em;\n  border: 1px dashed var(--cyan);\n  border-radius: 1em;\n  margin-bottom: 2em;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: 2em;\n  box-sizing: border-box;\n}", "",{"version":3,"sources":["webpack://src/pages/dashboard/pages/programDetail/components/uploadImplement/styles.module.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,WAAW;EACX,8BAA8B;EAC9B,kBAAkB;EAClB,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,sBAAsB;AACxB","sourcesContent":[".dropzone {\n  background-color: #00eae21a;\n  height: 8em;\n  border: 1px dashed var(--cyan);\n  border-radius: 1em;\n  margin-bottom: 2em;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: 2em;\n  box-sizing: border-box;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropzone": "styles_dropzone__1oDAF"
};
export default ___CSS_LOADER_EXPORT___;
