import { useMediaQuery } from '@material-ui/core';
import { bool } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import profileSrc from 'src/assets/user.png';
import { signOut } from '../../core/models/auth';
import useCart from '../../modules/cart/useCart';
import useMenu from '../../modules/menu/useMenu';
import { useSession } from '../../modules/session';
import HeaderDesktop from './components/desktop';
import HeaderMobile from './components/mobile';
import PrivateDesktop from './components/privateDesktop';
import PrivateMobile from './components/privateMobile';

const Header = (props) => {
  const session = useSession();
  const isMobile = useMediaQuery('(max-width:600px)');
  const onMenuOpen = useMenu();
  const [heigth, setHeight] = useState(0);
  const headerRef = useRef();
  const { itemsOnCart } = useCart();

  const getinlineStyle = () => {
    const style = { position: 'relative' };
    if (props.overlap) style.marginBottom = `-${heigth}px`;
    if (props.sticky) style.position = `sticky`;
    return style;
  };

  const handleHeightChange = () => {
    if (headerRef.current && headerRef.current.offsetHeight !== heigth) setHeight(headerRef.current.offsetHeight);
  };

  useEffect(() => {
    handleHeightChange();
    setTimeout(handleHeightChange, 100);
    setTimeout(handleHeightChange, 200);
    setTimeout(handleHeightChange, 500);
    window.addEventListener('resize', handleHeightChange);
    return () => window.removeEventListener('resize', handleHeightChange);
  }, [isMobile]);

  return (
    <>
      {(!session.data || session.data.type !== 'programUser') && !isMobile && (
        <HeaderDesktop
          getinlineStyle={getinlineStyle}
          headerRef={headerRef}
          onMenuOpen={onMenuOpen}
          grayOptions={props.grayOptions}
          numberItemsOnCart={itemsOnCart.length}
        />
      )}
      {(!session.data || session.data.type !== 'programUser') && isMobile && (
        <HeaderMobile
          getinlineStyle={getinlineStyle}
          headerRef={headerRef}
          onMenuOpen={onMenuOpen}
          numberItemsOnCart={itemsOnCart.length}
        />
      )}
      {!!session.data && session.data.type === 'programUser' && !isMobile && (
        <>
          <PrivateDesktop
            getinlineStyle={getinlineStyle}
            headerRef={headerRef}
            logOut={signOut}
            name={session.data.name.split(' ')[0]}
            photo={session.data.picture || profileSrc}
            numItemsOnCart={itemsOnCart.length}
          />
        </>
      )}
      {!!session.data && session.data.type === 'programUser' && isMobile && (
        <PrivateMobile
          getinlineStyle={getinlineStyle}
          headerRef={headerRef}
          numItemsOnCart={itemsOnCart.length}
          name={session.data.name.split(' ')[0]}
          photo={session.data.picture || profileSrc}
        />
      )}
    </>
  );
};

Header.defaultProps = {
  overlap: false,
  sticky: false,
  grayOptions: false,
};

Header.propTypes = {
  overlap: bool,
  sticky: bool,
  grayOptions: bool,
};

export default Header;
