import { Box } from '@material-ui/core';
import React from 'react';
import Container from '../../components/container';
import Footer from '../../components/footer';
import Header from '../../components/header';
import styles from './styles.module.css';

const Privacity = () => (
  <>
    <Header grayOptions />
    <Container extraSmall>
      <div className={styles.body}>
        <h1 className={styles.title}>Aviso de privacidad</h1>
        <p>
          Imelda Cabello Larios y/o Paulo Ramón Lara Medina, (en adelante identificada como “Poltri Inspira”), con
          domicilio en 5to Retorno de los Alpes No. 63 Col. Vistas de Altozano CP 58350, Morelia Mich. México, reconoce
          la importancia del tratamiento legítimo, controlado e informado de sus datos personales. Este Aviso de
          Privacidad se pone a su disposición, a fin de dar cumplimiento con las disposiciones de la Ley Federal de
          Protección de Datos Personales en Posesión de los Particulares (“la Ley”) e informarle sobre el tratamiento de
          sus datos personales.
        </p>
        <p>
          Los datos personales que tratamos los obtenemos directamente de usted cuando se registra en nuestra página web
          cuando decide contratar nuestros servicios, o cuando se da de alta/registra en nuestros diferentes formatos
          físicos o en línea. También podríamos obtener sus datos personales de directorios de empresas que estén
          abiertas a consulta o bases de datos y otras fuentes de acceso público.
        </p>
        <p>
          <Box fontWeight="bold">Datos personales:</Box>
          <ul>
            <li>Datos de identificación. Nombre completo. Fecha de nacimiento.</li>
            <li>Datos de contacto. Correo electrónico. Teléfono celular y/o fijo.</li>
            <li>
              Datos de facturación. Para facturarle por los servicios que prestamos en caso de que llegue a contratarnos
              para prestarle algún servicio.
            </li>
          </ul>
        </p>
        <p>
          Poltri Inspira no recaba datos personales sensibles (tales como origen racial o étnico, estado de salud
          presente y futuro, información genética, creencias religiosas, filosóficas y morales, afiliación sindical,
          opiniones políticas, preferencia sexual) o datos que no sean propios del titular de los datos, por lo que le
          pedimos abstenerse de proporcionar este tipo de datos personales en las secciones abiertas de nuestra página
          web.
        </p>
        <p>
          <Box fontWeight="bold">Finalidades y usos de los datos personales.</Box>
          Los datos personales que tratamos son utilizados para las siguientes finalidades:
          <ul>
            <li>Brindarle el servicio que solicita.</li>
            <li>Hacerle llegar promociones sobre nuestro servicio.</li>
            <li>Hacerle llegar comunicaciones sobre eventos, cursos, talleres, reuniones.</li>
            <li>Llevar a cabo la facturación que corresponda.</li>
            <li>Brindarle servicio de atención y seguimiento al programa.</li>
            <li>Tener una base de datos de clientes</li>
          </ul>
        </p>
        <p>
          <Box fontWeight="bold">Seguridad de la información:</Box>
          Poltri Inspira ha adoptado las medidas de seguridad técnicas, físicas y administrativas razonables para
          mantener la confidencialidad de sus datos personales, así como evitar su daño, pérdida, alteración, uso,
          acceso o tratamiento no autorizado.
        </p>
        <p>
          <Box fontWeight="bold">Información compartida con terceras personas:</Box>
          Poltri Inspira no comparte sus datos personales con persona físicas, tampoco con empresas terceras ajenas a
          nosotros.
        </p>
        <p>
          <Box fontWeight="bold">Uso de Cookies:</Box>
          Es posible que utilizamos cookies y web beacons, en nuestro sitio web, a través de los cuales obtenemos datos
          electrónicos cuando interactúa con nosotros. Estos datos incluyen la dirección IP asociada a su computadora o
          dispositivo electrónico, el sistema operativo que utiliza, la página web de la cual usted fue enlazado a
          nuestro sitio web, su actividad dentro de nuestros sitios web, fecha y hora en la que visitó nuestro sitio
          web.
        </p>
        <p>
          Las cookies son pequeños archivos de datos que se almacenan en el disco duro de su equipo de cómputo o del
          dispositivo de comunicación electrónica que utiliza cuando navega en nuestro sitio web. Las cookies nos
          permiten monitorear el comportamiento de un usuario en línea. Utilizamos la información que es obtenida a
          través de cookies para ayudarnos a optimizar su experiencia en nuestro sitio web.
        </p>
        <p>
          Su navegador aceptará las cookies y permitirá la recolección automática de información a menos que usted
          cambie la configuración predeterminada del navegador. La mayoría de los navegadores web permiten que usted
          pueda gestionar sus preferencias de cookies.
        </p>
        <p>
          <Box fontWeight="bold">Enlaces a sitios de terceros:</Box>
          Nuestro sitio web y comunicados podrán dirigirlo a sitios web ajenos a nosotros, Poltri Inspira no acepta
          responsabilidad alguna respecto del contenido, precisión o función de la información en dichos sitios web o
          por el uso que hagan de sus datos personales. La inserción o mención de cualquier enlace a un sitio web, no
          implica que dicho sitio web se encuentre avalado o autorizado por Poltri Inspira. Le recomendamos que sea
          cuidadoso y lea todos los avisos de privacidad de los distintos sitios web que visita.
        </p>
        <p>
          <Box fontWeight="bold">Derechos del titular de datos personales:</Box>
          El titular de datos personales o su representante legal podrán ejercer cualquiera de los derechos de acceso,
          rectificación, cancelación u oposición (en lo sucesivo “Derechos ARCO”), así́ como revocar su consentimiento
          para el tratamiento de sus datos personales o limitar el uso o divulgación de los mismos, enviando un correo
          electrónico a hola@poltri.mx.
        </p>
        <p>
          Su solicitud podrá ser realizada en un escrito libre, cumpliendo con los requisitos establecidos en la Ley. En
          caso de que la información proporcionada sea insuficiente o no se acompañen los documentos que acrediten su
          identidad y/o la representación legal del representante, le requeriremos dentro de los cinco (5) días hábiles
          siguientes a la recepción de la solicitud, para que aporte los elementos o documentos necesarios para dar
          trámite a su solicitud. Usted contará con diez (10) días hábiles para atender el requerimiento, contados a
          partir del día siguiente al que haya recibido el requerimiento. De no dar respuesta en dicho plazo, su
          solicitud se tendrá por no presentada. Le comunicaremos la determinación adoptada, en un plazo máximo de
          veinte (20) días hábiles, contados desde la fecha en que se recibió la solicitud, a efecto de que, si resulta
          procedente, la misma se haga efectiva dentro de los siguientes quince (15) días.
        </p>
        <p>
          Para cualquier duda o comentario respecto al presente aviso de privacidad o el tratamiento de sus datos
          personales, le pedimos que nos envíe un correo electrónico a hola@poltri.mx.
        </p>
        <p>
          <Box fontWeight="bold">Cambios al Aviso de Privacidad:</Box>
          Nos reservamos el derecho de modificar el presente Aviso de Privacidad para actualizarlo respecto a nuevas
          finalidades en el uso de sus datos personales o novedades legislativas. Publicaremos nuestro Aviso de
          Privacidad actualizado en nuestra página web www.poltri.mx/privacity.
        </p>
        <p>
          <Box fontWeight="bold">Consentimiento:</Box>
          Al proporcionar sus datos personales voluntariamente, ya sea en nuestros diferentes formatos físicos o en
          línea, o al utilizar y registrarse en nuestro sitio web y otros medios aquí descritos, manifiesta su
          consentimiento para el tratamiento de sus datos personales conforme al presente. En caso de haber obtenido sus
          datos de fuentes de acceso público, podrá indicarnos que desea que los dejemos de utilizar a través del correo
          mencionado o conforme a lo indicado en nuestras comunicaciones.
        </p>
      </div>
    </Container>
    <Footer />
  </>
);

export default Privacity;
