import React from 'react';
import { func, number, object, string } from 'prop-types';
import Container from '../../components/container';
import Stepper from '../../components/stepper';
import Summary from './components/summary';
import Information from './components/information';
import styles from './styles.module.css';
import MethodPaySelectorAndSenInfo from './components/methodPaySelectorAndSenInfo';
import CardForm from './components/cardForm';
import Header from '../../components/header';

const CheckoutView = (props) => {
  const steps = ['INFORMACIÓN', 'PAGOS'];

  return (
    <>
      <Header grayOptions />
      <Container small>
        <div className={styles.body}>
          <div className={styles.stepperWrapper}>
            <Stepper steps={steps} activeStep={props.activeStep} />
          </div>
          <div className={styles.flexContainer}>
            <div className={styles.formWrapper}>
              {props.activeStep === 0 && (
                <Information getInputProps={props.getInputProps} errorMessage={props.errorMessage} />
              )}
              {props.activeStep === 1 && (
                <MethodPaySelectorAndSenInfo
                  showMethoPay
                  values={props.values}
                  onBack={props.onBack}
                  getInputProps={props.getInputProps}
                  errorMessage={props.errorMessage}
                  productData={props.productData}
                />
              )}
              {props.activeStep === 3 && (
                <CardForm errorMessage={props.errorMessage} getInputProps={props.getInputProps} />
              )}
            </div>
            <div className={styles.summaryWrapper}>
              <Summary
                productData={props.productData}
                total={props.total}
                onAcept={props.onAcept}
                onBack={props.onBack}
                paypalConfig={props.paypalConfig}
                renderPaypal={props.values.methodPay === 'paypal' && props.activeStep === 1}
                buttonText={props.buttonText}
                hidePrices={props.activeStep === 1}
              />
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

CheckoutView.propTypes = {
  activeStep: number.isRequired,
  productData: object.isRequired,
  total: number.isRequired,
  getInputProps: func.isRequired,
  onAcept: func.isRequired,
  onBack: func.isRequired,
  errorMessage: string.isRequired,
  values: object.isRequired,
  paypalConfig: object.isRequired,
  buttonText: string.isRequired,
};

export default CheckoutView;
