import { number, string } from 'prop-types';
import React, { useLayoutEffect, useRef, useState } from 'react';

const Picture = ({ alt, height, ...props }) => {
  const [calculatedHeigth, setCalculatedHeigth] = useState(null);
  const imageRef = useRef();

  const composedProps = { ...props, style: { objectFit: 'cover' } };
  if (calculatedHeigth) composedProps.height = `${calculatedHeigth}px`;

  const handleComputeHeight = () => {
    if (height) {
      const currentWith = imageRef.current.offsetWidth;
      const localHeigth = Math.ceil((currentWith / 100) * height);
      setCalculatedHeigth(localHeigth);
    }
  };

  useLayoutEffect(() => {
    try {
      setTimeout(handleComputeHeight, 10);
      window.addEventListener('resize', handleComputeHeight);
      return () => window.removeEventListener('resize', handleComputeHeight);
    } catch (error) {
      console.log('error', error);
      return null;
    }
  }, [height]);

  return <img {...composedProps} alt={alt} ref={imageRef} />;
};

Picture.defaultProps = {
  height: null,
  alt: 'some image',
};

Picture.propTypes = {
  height: number,
  alt: string,
};

export default Picture;
