import database from 'src/core/database';
import formatObect from '../../helpers/formatObject';
import { success } from '../../helpers/response';
import storage from '../storage';

const collection = 'testimonials';

const createTestimonial = async (data) => {
  const setcureData = {
    name: data.name,
    description: data.description,
    userId: data.userId || null,
    video: data.video || null,
  };
  const responseData = await database.create(collection, setcureData);
  return success('Testimonio creado correctamente', responseData);
};

export const updateTestimonal = async (id, data) => {
  const allows = ['name', 'video', 'description', 'picture'];
  const secureData = formatObect(data, allows);
  await database.update(`${collection}/${id}`, secureData);
  return success('Testimonio actualizado correctamente');
};

export const deleteTestimonial = async (item) => {
  await storage.deletePicture(item.picture);
  await database.remove(`${collection}/${item.id}`);
  return success('Testimonio eliminado correctamente');
};

export const getTestimonialsList = async (limit = null) => {
  const data = await database.getList(collection, limit, ['createdAt', 'desc']).next();
  return data;
};

export default {
  create: createTestimonial,
  update: updateTestimonal,
  delete: deleteTestimonial,
  getList: getTestimonialsList,
};
