import React, { useState } from 'react';
import productsModel from 'src/core/models/product';
import { defaultShippingCost } from '../../constants';
import { getSubTotal } from '../../helpers/cart';
import response from '../../helpers/response';
import useFetch from '../../hooks/useFetch';
import useAlert from '../../modules/alert/useAlert';
import useCart from '../../modules/cart/useCart';
import useNotification from '../../modules/notifications/useNotification';
import View from './view';

const MyCart = () => {
  const [products, setProducts] = useState([]);
  const { itemsOnCart, methods } = useCart();
  const setAlert = useAlert();
  const setNotification = useNotification();

  // defined variables
  const shippinConst = defaultShippingCost;
  const subTotal = getSubTotal();
  const total = subTotal + shippinConst;

  const handleQuantityUp = (product) => {
    const currentProduc = products.find((currentItem) => currentItem.id === product.id);
    if (currentProduc && currentProduc.quantity > product.quantity)
      methods.updateQuantityProductOnCart(product.id, product.quantity + 1);
  };

  const handleQuantityDown = (product) => {
    methods.updateQuantityProductOnCart(product.id, product.quantity > 1 ? product.quantity - 1 : 1);
  };

  const handleDelete = (product) =>
    setAlert({
      title: '¿Seguro quieres quitar este producto de tu carrito?',
      action: () => {
        methods.removeProductFromCart(product.id);
        setNotification(response.success('Producto eliminado del carrito'));
      },
    });

  // fetch products
  useFetch(async () => {
    const responseProducts = await productsModel.getAll();
    setProducts(responseProducts);
  });

  return (
    <View
      items={itemsOnCart}
      subTotal={subTotal}
      shippingCost={shippinConst}
      total={total}
      onQuantityDown={handleQuantityDown}
      onQuantityUp={handleQuantityUp}
      onDelete={handleDelete}
    />
  );
};

export default MyCart;
