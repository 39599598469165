import React from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import View from './view';

const MyCartDigital = () => {
  const { state } = useLocation();
  const history = useHistory();

  const handleDelete = () => {
    history.push('/articulos');
  };

  if (!state) return <Redirect to="/articulos" />;

  return <View data={state} onDelete={handleDelete} />;
};

export default MyCartDigital;
