import React from 'react';
import paypalSrc from 'src/assets/paypal.png';
import { NotificationsNone } from '@material-ui/icons';
import usePurchaseDetail from 'src/hooks/usePurchaseDetail';
import Container from '../../components/container';
import Header from '../../components/header';
import Loading from '../../components/loading';
import styles from './styles.module.css';
import Footer from '../../components/footer';
import { contact, transferData } from '../../constants';
import Picture from '../../components/picture';
import formatPrice from '../../helpers/formatPrice';

const PurchaseSuccess = () => {
  const { loading, data } = usePurchaseDetail();

  return (
    <>
      <Header />
      <Container>
        {loading && <Loading />}
        {!loading && (
          <div className={styles.body}>
            <h1 className={styles.title}>
              {data.userInfo.methodPay === 'manual' ? 'Resumen de tu pedido' : 'Gracias por tu pago via'}
            </h1>
            {data.userInfo.methodPay === 'manual' && (
              <div className={styles.boxBlue}>
                <NotificationsNone className={styles.bellIcon} />
                <div className={styles.boxTitle}>IMPORTANTE</div>
                No pierdas tu comprobante de pago. Para poder procesar tu compra es necesario que envíes tu voucher al
                correo {contact.email} ó mándanos una foto al Whatsapp {contact.whatsapp}
                {data.digital && (
                  <>
                    <br />
                    <br />
                    Te contactaremos por correo para enviarte tus datos de acceso y el link para ingresar a la
                    plataforma.
                  </>
                )}
              </div>
            )}
            {data.userInfo.methodPay === 'paypal' && (
              <img className={styles.paypalImage} src={paypalSrc} alt="paypal logo" />
            )}
            {data.digital && data.userInfo.methodPay === 'paypal' && (
              <div className={styles.boxBlue}>
                <NotificationsNone className={styles.bellIcon} />
                Te contactaremos por correo para enviarte tus datos de acceso y el link para ingresar a la plataforma.
              </div>
            )}
            <div className={styles.orderBox}>
              <div className={styles.describe}>Tu número de orden: </div>
              <div className={styles.value}>{data.id}</div>
            </div>
            <div className={styles.textBlue}>Detalle de pedido</div>
            {data.items.map((item) => (
              <div className={styles.item} key={item.id}>
                <Picture height={100} src={item.picture} className={styles.itemPicture} />
                <div className={styles.itemTitle}>{item.title}</div>
                <div className={styles.itemPrice}>
                  <span>{item.quantity}x</span> ${formatPrice(item.price)}
                </div>
              </div>
            ))}
            <div className={styles.totalBox}>
              {!!data.shippingCost && (
                <div className={styles.flexSpace}>
                  <div>Envío</div>
                  <div>${formatPrice(data.shippingCost)}</div>
                </div>
              )}
              <div className={styles.flexSpace}>
                <div>{data.userInfo.methodPay === 'paypal' ? 'Total pagado' : 'Total a pagar'}</div>
                <div>${formatPrice(data.total)}</div>
              </div>
            </div>
            {data.userInfo.methodPay === 'manual' && (
              <>
                <div className={styles.textBlue}>Datos de transferencia</div>
                <div className={styles.group}>
                  <div className={styles.flexSpace}>
                    <div className={styles.describe}>Nombre:</div>
                    <div className={styles.value}>{transferData.fullName}</div>
                  </div>
                  <div className={styles.flexSpace}>
                    <div className={styles.describe}>Clabe:</div>
                    <div className={styles.value}>{transferData.clabe}</div>
                  </div>
                  <div className={styles.flexSpace}>
                    <div className={styles.describe}>Cuenta:</div>
                    <div className={styles.value}>{transferData.account}</div>
                  </div>
                  <div className={styles.flexSpace}>
                    <div className={styles.describe}>Sucursal:</div>
                    <div className={styles.value}>{transferData.sucursal}</div>
                  </div>
                  <div className={styles.flexSpace}>
                    <div className={styles.describe}>Banco:</div>
                    <div className={styles.value}>{transferData.bankName}</div>
                  </div>
                </div>
              </>
            )}

            <div className={styles.textBlue}>Tus datos</div>
            <div className={styles.group}>
              <div className={styles.flexSpace}>
                <div className={styles.describe}>Nombre:</div>
                <div className={styles.value}>
                  {data.userInfo.name} {data.userInfo.lastName}
                </div>
              </div>
              <div className={styles.flexSpace}>
                <div className={styles.describe}>Email:</div>
                <div className={styles.value}>{data.userInfo.email}</div>
              </div>
              <div className={styles.flexSpace}>
                <div className={styles.describe}>Dirección:</div>
                <div className={styles.value}>
                  {data.userInfo.streetAndNumber}, {data.userInfo.suburb}, {data.userInfo.city}, {data.userInfo.state},
                  C.P.:
                  {data.userInfo.postalCode}
                </div>
              </div>
            </div>
            <div className={styles.caption}>Nota: Se envió esta misma información al correo proporcionado.</div>
          </div>
        )}
      </Container>
      <Footer />
    </>
  );
};

export default PurchaseSuccess;
