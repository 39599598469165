import { ButtonBase } from '@material-ui/core';
import { string, bool, element, oneOfType, number } from 'prop-types';

import React from 'react';
import { colors } from '../../../../constants';
import styles from './styles.module.css';

const ButtonAdmin = ({ children, primary, color, ...props }) => {
  const inlineStyles = primary ? { background: color, color: '#fff' } : { border: `1px solid ${color}`, color };

  return (
    <ButtonBase {...props} className={`${styles.button}`} style={inlineStyles}>
      {children}
    </ButtonBase>
  );
};

ButtonAdmin.defaultProps = {
  primary: false,
  color: colors.cyan,
};

ButtonAdmin.propTypes = {
  children: oneOfType([element, string.isRequired, number]).isRequired,
  primary: bool,
  color: string,
};

export default ButtonAdmin;
