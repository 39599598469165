import React from 'react';
import Container from '../../../../components/container';
import styles from './styles.module.css';

const SubHeader = () => (
  <div className={styles.fullWidthWRapper}>
    <Container disabledVerticalPadding>
      <div className={styles.subHeader}>
        <div className={styles.title}>EVENTOS Y ACTIVIDADES</div>
        <div className={styles.slogan}>#feminnelife</div>
      </div>
    </Container>
  </div>
);

export default SubHeader;
