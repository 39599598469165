/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-unused-vars */
import { Box, CircularProgress, Grid } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { func, string } from 'prop-types';
import React, { useState } from 'react';
import Card from 'src/components/card';
import Picture from 'src/components/picture';
import advisers from 'src/core/models/advisers';
import useFetch from 'src/hooks/useFetch';
import useAlert from 'src/modules/alert/useAlert';
import useNotification from 'src/modules/notifications/useNotification';
import { setAdvicer } from '../../../../../../core/models/programUsers';
import { success } from '../../../../../../helpers/response';
import ButtonAdmin from '../../../../components/button';
import styles from './styles.module.css';

const AdvicerEditor = (props) => {
  const [loading, setLoading] = useState(true);
  const [advicers, setAdvicers] = useState([]);
  const setAlert = useAlert();
  const setNotification = useNotification();

  useFetch(async () => {
    const response = await advisers.getList();
    setAdvicers(response);
    setLoading(false);
  });

  const handleAddAdvicer = (advicerId) => () =>
    setAlert({
      title: `¿Seguro quieres asignar este asesor al usuario?`,
      action: async () => {
        await setAdvicer(props.userId, advicerId);
        if (props.onClose) props.onClose();
        if (props.onSuccess) await props.onSuccess();
        setNotification(success('asesor asignado correctamente'));
      },
    });

  return (
    <div className={styles.fullWrapper}>
      <Card className={styles.card}>
        <Close className={styles.close} onClick={props.onClose} />
        {loading && <CircularProgress />}
        {!advicers.length && !loading && (
          <Box fontSize="1.3em" color="var(--rose)" fontWeight="600">
            Aun no hay asesores
          </Box>
        )}
        {!loading && !!advicers.length && (
          <Box flexGrow="1">
            <Box fontSize="1.2em" color="var(--cyan)" fontWeight="600" textAlign="center">
              Selecciona un asesor
            </Box>
            <Box marginTop="2em" minHeight="50vh" maxHeight="70vh" overflow="scroll" flexGrow="1">
              <Grid container justify="center">
                {advicers.map((advicer, index) => (
                  <div className={styles.itemWrapper} key={index}>
                    <div className={styles.item}>
                      <Picture src={advicer.picture} className={styles.picture} />
                      <Box flexGrow="1">
                        <Box fontWeight="600">{advicer.name}</Box>
                        <ButtonAdmin
                          color="var(--cyan)"
                          id={styles.button}
                          primary
                          onClick={handleAddAdvicer(advicer.id)}
                        >
                          Seleccionar
                        </ButtonAdmin>
                      </Box>
                    </div>
                  </div>
                ))}
              </Grid>
            </Box>
          </Box>
        )}
      </Card>
    </div>
  );
};

AdvicerEditor.propTypes = {
  userId: string.isRequired,
  onClose: func.isRequired,
  onSuccess: func.isRequired,
};

export default AdvicerEditor;
