import { element } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import context from './context';
import ViewNotifications from './view';

const MenuPrivateProvider = ({ children }) => {
  const { pathname } = useLocation();
  const [isActive, setMenuActive] = useState(false);

  const handleOpen = () => setMenuActive(true);
  const handleClose = () => setMenuActive(false);
  const handleToggle = () => setMenuActive((prevVal) => !prevVal);

  useEffect(() => {
    handleClose();
  }, [pathname]);

  return (
    <context.Provider value={{ handleOpen, handleToggle }}>
      <>
        {children}
        <ViewNotifications open={isActive} onClose={handleClose} />
      </>
    </context.Provider>
  );
};

MenuPrivateProvider.propTypes = {
  children: element.isRequired,
};

export default MenuPrivateProvider;
