import { Box, ButtonBase } from '@material-ui/core';
import { any } from 'prop-types';
import programUsers, { setPayedNextPayout } from 'src/core/models/programUsers';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { Close, KeyboardArrowLeft } from '@material-ui/icons';
import paypalSrc from 'src/assets/paypal.png';
import styles from './styles.module.css';
import { useSession } from '../session';
import useFetch from '../../hooks/useFetch';
import formatPrice from '../../helpers/formatPrice';
import { toLargeStringDate } from '../../helpers/dates';
import { contact, transferData } from '../../constants';

const context = createContext();

export const useCalculatePaymentAlert = () => useContext(context);

const PaymentAlert = (props) => {
  const session = useSession();
  const [currentView, setCurrentView] = useState('main');
  const [isModalOpen, setModalOpen] = useState(false);
  const [nextPayment, setNextPayment] = useState(null);
  const [isOutDated, setOutDated] = useState(false);

  const handleFetch = async () => {
    if (session.data && session.data.id) {
      const responseUserData = await programUsers.getSingle(session.data.id);
      if (responseUserData && responseUserData.paysHistory && responseUserData.programActive) {
        if (responseUserData.formPay === 'semesterPrice' || responseUserData.formPay === 'monthlyPrice') {
          const nextPay = responseUserData.paysHistory.find((pay) => pay.status === 'pending');
          if (nextPay) setNextPayment(nextPay);
        }
      }
    }
  };

  const handleCalculate = () => {
    if (nextPayment) {
      console.log('recalculating...');
      const { limitPay } = nextPayment;
      limitPay.setHours(0);
      limitPay.setSeconds(0);
      limitPay.setMinutes(0);
      limitPay.setMilliseconds(0);

      const timeNextPayment = limitPay.getTime();

      const currentDate = new Date();

      currentDate.setHours(0);
      currentDate.setSeconds(0);
      currentDate.setMinutes(0);
      currentDate.setMilliseconds(0);

      const currentTime = currentDate.getTime();
      const milisecondsInFiveDays = 1000 * 60 * 60 * 24 * 5;
      const timeDiff = timeNextPayment - currentTime;
      if (timeDiff <= milisecondsInFiveDays) setModalOpen(true);
      if (timeDiff < 0) setOutDated(true);
    }
  };
  // compute show modal
  useEffect(handleCalculate, [nextPayment]);

  // fetch data
  useFetch(handleFetch, [session.data]);

  // render paypal
  useEffect(() => {
    const target = document.getElementById('paypal_tarjet');
    if (target && nextPayment) {
      target.innerHTML = '';
      window.paypal
        .Buttons({
          createOrder: (_data, actions) =>
            actions.order.create({ purchase_units: [{ amount: { value: nextPayment.price } }] }),
          onApprove: async () => {
            await setPayedNextPayout(session.data.id);
            setCurrentView('paypalApproved');
          },
        })
        .render('#paypal_tarjet');
    }
  }, [nextPayment, currentView, isModalOpen]);

  return (
    <context.Provider value={handleCalculate}>
      {isModalOpen && nextPayment && (
        <div className={styles.fullWrapper}>
          <div className={styles.card}>
            <Close className={styles.closeButton} onClick={() => setModalOpen(false)} />
            {currentView === 'main' && (
              <Box width="100%" textAlign="center">
                {!isOutDated && (
                  <Box fontWeight="600">Tu pago esta apunto de vencer, pronto perderas acceso a tu entrenamiento.</Box>
                )}
                {isOutDated && <Box fontWeight="600">Realiza tu pago para tener acceso a tu entrenamiento</Box>}
                {!isOutDated && (
                  <Box marginTop="1em">
                    <Box color="var(--rose)">Fecha limite de pago:</Box>
                    <Box>{toLargeStringDate(nextPayment.limitPay)}</Box>
                  </Box>
                )}
                <Box marginTop="1em">
                  <Box color="var(--rose)">Total a pagar</Box>
                  <Box>$ {formatPrice(nextPayment.price)}</Box>
                </Box>
                <Box marginTop="2em">
                  <ButtonBase className={styles.button} style={{ position: 'relative', overflow: 'hidden' }}>
                    <Box id="paypal_tarjet" className={styles.paypalShadoowButton} />
                    Pagar con PayPal
                  </ButtonBase>
                  <ButtonBase className={styles.button} onClick={() => setCurrentView('transfer')}>
                    Pagar con transferencia
                  </ButtonBase>
                </Box>
              </Box>
            )}

            {currentView === 'transfer' && (
              <Box fontSize=".9em">
                <Box>
                  No pierdas tu comprobante de pago. Para poder procesar tu pago es necesario que envíes tu voucher al
                  correo {contact.email} ó mándanos una foto al Whatsapp {contact.whatsapp}.
                </Box>
                <Box marginTop="1em">
                  <Box color="var(--rose)" fontWeight="bold" marginBottom=".5em">
                    Datos de transferencia
                  </Box>
                  <Box>
                    Nombre:
                    <Box color="#2d4f75" marginLeft=".5em" component="span">
                      {transferData.fullName}
                    </Box>
                  </Box>
                  <Box>
                    Clabe:
                    <Box color="#2d4f75" marginLeft=".5em" component="span">
                      {transferData.clabe}
                    </Box>
                  </Box>
                  <Box>
                    Cuenta:
                    <Box color="#2d4f75" marginLeft=".5em" component="span">
                      {transferData.account}
                    </Box>
                  </Box>
                  <Box>
                    Sucursal:
                    <Box color="#2d4f75" marginLeft=".5em" component="span">
                      {transferData.sucursal}
                    </Box>
                  </Box>
                  <Box>
                    Banco:
                    <Box color="#2d4f75" marginLeft=".5em" component="span">
                      {transferData.bankName}
                    </Box>
                  </Box>
                </Box>
                <Box
                  onClick={() => setCurrentView('main')}
                  marginTop="2em"
                  color="var(--rose)"
                  display="flex"
                  alignItems="center"
                  style={{ cursor: 'pointer' }}
                >
                  <KeyboardArrowLeft style={{ fontSize: '1.4em', marginRight: '.5em' }} />
                  Atrás
                </Box>
              </Box>
            )}
            {currentView === 'paypalApproved' && (
              <Box textAlign="center" paddingTop="2em" paddingBottom="2em">
                <Box fontWeight="600">Gracias por tu pago vía</Box>
                <img src={paypalSrc} className={styles.paypalIcon} alt="paypalIcon" />
              </Box>
            )}
          </div>
        </div>
      )}
      {props.children}
    </context.Provider>
  );
};

PaymentAlert.propTypes = {
  children: any.isRequired,
};

export default PaymentAlert;
