import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import getValidators from 'src/helpers/getValidators';
import { saveSippingCost, updateShippingCost } from '../../../../core/models/shipping';
import useErrorMessage from '../../../../hooks/useErrorMessage';
import useForm from '../../../../hooks/useForm';
import useNotification from '../../../../modules/notifications/useNotification';
import View from './view';

const inputRules = {
  postalCode: getValidators.number('Código postal no válido'),
  price: getValidators.number('El precio no es válido'),
};

const computeInitialState = (state) => {
  if (!state) return {};
  return {
    postalCode: state.cp.toString(),
    comment: state.comment,
    price: state.price.toString(),
  };
};

const ShippingCostEditor = () => {
  const setNotification = useNotification();
  const { state } = useLocation();
  const { errorMessage, setErrorMessage } = useErrorMessage();
  const [loading, setLoading] = useState(false);
  const { getInputProps, validateInputs, values } = useForm(computeInitialState(state));
  const history = useHistory();

  const handleValidateInputs = () => {
    const { firstErrorMessage } = validateInputs(inputRules);
    if (firstErrorMessage) {
      setErrorMessage(firstErrorMessage);
      return false;
    }
    return true;
  };

  const handleSave = async () => {
    const isValid = handleValidateInputs();
    if (isValid) {
      setLoading(true);
      const response = await saveSippingCost({
        postalCode: values.postalCode,
        price: values.price,
        comment: values.comment,
      });
      setLoading(false);
      if (response.error) {
        setErrorMessage(response.message);
      } else {
        setNotification(response);
        history.replace('/dashboard/shipping-cost');
      }
    }
  };

  const handleUpdate = async () => {
    const isValid = handleValidateInputs();
    if (isValid) {
      setLoading(true);
      const response = await updateShippingCost({
        postalCode: values.postalCode,
        price: values.price,
        comment: values.comment,
      });
      setLoading(false);
      if (response.error) {
        setErrorMessage(response.message);
      } else {
        setNotification(response);
        history.replace('/dashboard/shipping-cost');
      }
    }
  };

  const handleConfirm = () => {
    if (state) handleUpdate();
    else handleSave();
  };

  return (
    <View
      getInputProps={getInputProps}
      loading={loading}
      errorMessge={errorMessage}
      onSave={handleConfirm}
      disablePostalCode={!!state}
    />
  );
};

export default ShippingCostEditor;
