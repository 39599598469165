import { Box, CircularProgress } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import React, { useState } from 'react';
import Card from 'src/components/card';
import programModel from 'src/core/models/program';
import Input from 'src/components/inputs';
import LabelInput from 'src/components/labelInput';
import Picture from 'src/components/picture';
import formatPrice from 'src/helpers/formatPrice';
import useFetch from 'src/hooks/useFetch';
import useForm from 'src/hooks/useForm';
import normalizeImagesUrl from 'src/helpers/normalizeImagesUrl';
import formatObect from 'src/helpers/formatObject';
import Dropzone from 'src/components/dropzone';
import useErrorMessage from 'src/hooks/useErrorMessage';
import ErrorMessage from 'src/components/errorMessage';
import { string } from 'prop-types';
import validaotors from 'src/helpers/getValidators';
import useNotification from 'src/modules/notifications/useNotification';
import defaultProgramIcon from 'src/assets/fallback-program.png';
import styles from './styles.module.css';
import ButtonAdmin from '../../../../components/button';

const formRules = {
  picture: validaotors.input('La imagen es requerida'),
  title: validaotors.input('El título es requerido'),
  price: validaotors.number(),
  monthlyPrice: validaotors.number('Costo mensual no válido'),
  semesterPrice: validaotors.number('Costo semestral no válido'),
  inscription: validaotors.number('Costo de inscripción no válido'),
};

const InfoEditor = ({ programId }) => {
  const setNotification = useNotification();
  const { errorMessage, setErrorMessage } = useErrorMessage();
  const { getInputProps, setValues, validateInputs, values } = useForm({}, formRules);
  const [data, setData] = useState(null);
  const [currentView, setView] = useState('data'); // data || form || loading

  const handleFetch = async () => {
    setView('loading');
    const response = await programModel.getInfo(programId);
    setData(response);
    setView('data');
    if (response)
      setValues(
        formatObect(response, [
          'title',
          'price',
          'picture',
          'semesterPrice',
          'monthlyPrice',
          'inscription',
          'description',
        ]),
      );
  };

  const handleImageDelete = () => setValues((prev) => ({ ...prev, picture: null }));

  const handleImageSelect = (pic) => setValues((prev) => ({ ...prev, picture: pic }));

  const handleCancelEdit = () => setView('data');

  const handleSave = async () => {
    const { firstErrorMessage } = validateInputs();
    if (firstErrorMessage) return setErrorMessage(firstErrorMessage);
    setView('loading');
    const response = await programModel.updateInfo(programId, values);
    await handleFetch();
    setNotification(response);
    setView('data');
    return null;
  };

  useFetch(handleFetch);

  return (
    <div>
      <Box fontWeight="600" marginBottom=".5em">
        Información general
      </Box>
      <Card className={styles.card}>
        {currentView === 'loading' && (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="10em">
            <CircularProgress />
          </Box>
        )}
        {currentView === 'data' && !data && (
          <Box display="flex" justifyContent="center" alignItems="center" padding="2em" color="gray">
            <Box>
              <Box>Aún no se ha cargado la información</Box>
              <Box display="flex" justifyContent="center" marginTop="2em">
                <ButtonAdmin onClick={() => setView('form')}>Cargar información</ButtonAdmin>
              </Box>
            </Box>
          </Box>
        )}
        {currentView === 'data' && !!data && (
          <div className={styles.dataWrapper}>
            <Edit onClick={() => setView('form')} />
            <Picture src={data.picture || defaultProgramIcon} height={100} className={styles.picture} />
            <div className={styles.dataBody}>
              <Box display="flex" alignItems="center" marginBottom=".5em">
                <Box marginRight=".5em">Id:</Box>
                <Box fontWeight="600">{programId}</Box>
              </Box>
              <Box display="flex" alignItems="center" marginBottom=".5em">
                <Box marginRight=".5em">Título:</Box>
                <Box fontWeight="600">{data.title}</Box>
              </Box>
              <Box display="flex" alignItems="center" marginBottom=".5em">
                <Box marginRight=".5em">Inscripción:</Box>
                <Box fontWeight="600">$ {formatPrice(data.inscription)}</Box>
              </Box>
              <Box display="flex" alignItems="center" marginBottom=".5em">
                <Box marginRight=".5em">Precio:</Box>
                <Box fontWeight="600">$ {formatPrice(data.price)}</Box>
              </Box>
              <Box display="flex" alignItems="center" marginBottom=".5em">
                <Box marginRight=".5em">Precio semestral:</Box>
                <Box fontWeight="600">$ {formatPrice(data.semesterPrice)}</Box>
              </Box>
              <Box display="flex" alignItems="center" marginBottom=".5em">
                <Box marginRight=".5em">Precio mensual:</Box>
                <Box fontWeight="600">$ {formatPrice(data.monthlyPrice)}</Box>
              </Box>
              <Box alignItems="center" marginBottom=".5em">
                <Box marginRight=".5em">Descripción:</Box>
                <Box fontWeight="600" lineHeight="1.5em">
                  {data.description}
                </Box>
              </Box>
            </div>
          </div>
        )}

        {currentView === 'form' && (
          <div className={styles.form}>
            <ErrorMessage message={errorMessage} />
            {!!values.picture && (
              <div className={styles.formPictureWrapper}>
                <Delete onClick={handleImageDelete} />
                <Picture src={normalizeImagesUrl(values.picture || defaultProgramIcon)} height={100} />
              </div>
            )}
            {!values.picture && (
              <Dropzone className={styles.dropzone} onChange={handleImageSelect}>
                Seleccionar imagen
              </Dropzone>
            )}
            <LabelInput verticalPadding=".5em" label="Título" maxLength={10}>
              <Input {...getInputProps('title')} />
            </LabelInput>
            <LabelInput verticalPadding=".5em" label="Inscripción">
              <Input {...getInputProps('inscription')} onlyFloat maxLength={10} />
            </LabelInput>
            <LabelInput verticalPadding=".5em" label="Precio">
              <Input {...getInputProps('price')} onlyFloat maxLength={10} />
            </LabelInput>
            <LabelInput verticalPadding=".5em" label="Precio semestral">
              <Input {...getInputProps('semesterPrice')} onlyFloat maxLength={10} />
            </LabelInput>
            <LabelInput verticalPadding=".5em" label="Precio mensual">
              <Input {...getInputProps('monthlyPrice')} onlyFloat maxLength={10} />
            </LabelInput>
            <LabelInput verticalPadding=".5em" label="Descripción">
              <Input {...getInputProps('description')} maxLength={500} type="textarea" />
            </LabelInput>
            <Box display="flex" justifyContent="space-between" marginTop="2em">
              <ButtonAdmin onClick={handleCancelEdit} id={styles.formButton}>
                Cancelar
              </ButtonAdmin>
              <ButtonAdmin onClick={handleSave} id={styles.formButton} primary>
                Guardar
              </ButtonAdmin>
            </Box>
          </div>
        )}
      </Card>
    </div>
  );
};

InfoEditor.propTypes = {
  programId: string.isRequired,
};

export default InfoEditor;
