import { useEffect, useState } from 'react';

const getInitialStateFromLocalStorage = (id) => {
  try {
    const value = window.localStorage.getItem(id);
    return value || null;
  } catch (error) {
    console.log('localStorage error');
    return null;
  }
};

const useMemoizedState = (initiaValue, id = 'memoState') => {
  const [state, setState] = useState(getInitialStateFromLocalStorage(id) || initiaValue || null);

  // sync state at localStorage
  useEffect(() => {
    try {
      window.localStorage.setItem(id, state);
    } catch (error) {
      console.log('localsTorge error');
    }
  }, [state]);

  return [state, setState];
};

export default useMemoizedState;
