// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_bachgroundGray__Ysi40 {\n  background-color: #f1f1f1;\n}\n.styles_titleWrapper__2QN7U {\n  font-size: 2em;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  color: var(--rose);\n  font-weight: 800;\n  text-align: center;\n  background-color: #fff;\n  padding-bottom: 1em;\n  padding-top: .5em;\n}\n\n.styles_icon__2vj8Q {\n  width: 1.2em;\n  display: block;\n  margin-right: .5em;\n}\n\n@media screen and (max-width:1000px) {\n  .styles_fullWrapper__29VMA {\n    font-size: .9em;\n  }\n}\n\n@media screen and (max-width:600px) {\n  .styles_fullWrapper__29VMA {\n    font-size: .7em;\n  }\n  .styles_titleWrapper__2QN7U {\n    font-size: 1.5em;\n  }\n}", "",{"version":3,"sources":["webpack://src/pages/groupChat/styles.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;AACA;EACE,cAAc;EACd,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;EAClB,sBAAsB;EACtB,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE;IACE,eAAe;EACjB;AACF;;AAEA;EACE;IACE,eAAe;EACjB;EACA;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".bachgroundGray {\n  background-color: #f1f1f1;\n}\n.titleWrapper {\n  font-size: 2em;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  color: var(--rose);\n  font-weight: 800;\n  text-align: center;\n  background-color: #fff;\n  padding-bottom: 1em;\n  padding-top: .5em;\n}\n\n.icon {\n  width: 1.2em;\n  display: block;\n  margin-right: .5em;\n}\n\n@media screen and (max-width:1000px) {\n  .fullWrapper {\n    font-size: .9em;\n  }\n}\n\n@media screen and (max-width:600px) {\n  .fullWrapper {\n    font-size: .7em;\n  }\n  .titleWrapper {\n    font-size: 1.5em;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bachgroundGray": "styles_bachgroundGray__Ysi40",
	"titleWrapper": "styles_titleWrapper__2QN7U",
	"icon": "styles_icon__2vj8Q",
	"fullWrapper": "styles_fullWrapper__29VMA"
};
export default ___CSS_LOADER_EXPORT___;
