export const getTrainingStartDate = (userData) => {
  try {
    const { programActivatedDate } = userData;
    programActivatedDate.setHours(18);
    programActivatedDate.setMinutes(0);
    programActivatedDate.setSeconds(0);
    programActivatedDate.setMilliseconds(0);

    while (programActivatedDate.getDay() !== 0) {
      programActivatedDate.setDate(programActivatedDate.getDate() + 1);
    }
    return programActivatedDate;
  } catch (error) {
    return null;
  }
};

export const getCurrentWeek = (userData) => {
  try {
    const timeStarted = getTrainingStartDate(userData).getTime();
    const currentDate = new Date();
    currentDate.setSeconds(0);
    currentDate.setMilliseconds(0);

    const timeDiff = currentDate.getTime() - timeStarted;
    const daysDiff = timeDiff / (24 * 60 * 60 * 1000);
    const weeksDiff = Math.floor(daysDiff / 7);
    const currentTrainingWeek = weeksDiff + 1;
    return currentTrainingWeek;
  } catch (error) {
    console.log('e', error);
    return null;
  }
};

export const getProgramStartDateByWeek = (weekNumber) => {
  const weekParsed = weekNumber;
  const daysToAdd = weekParsed * 7;
  const daysTransformed = daysToAdd * -1;
  const currentDate = new Date();

  currentDate.setDate(currentDate.getDate() + daysTransformed);

  return currentDate;
};

const training = {
  getTrainingStartDate,
  getCurrentWeek,
  getProgramStartDateByWeek,
};

export default training;
