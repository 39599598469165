// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_group__3eXdN {\n  background-color: #eee;\n  padding: 1.5em;\n  border-radius: .4em;\n  margin-bottom: 1em;\n}\n.styles_flexSpacer__pvmjk {\n  display: flex;\n  flex-wrap: nowrap;\n  margin-bottom: 1em;\n}\n.styles_input__1qNK4 {\n  height: 4em!important;\n}", "",{"version":3,"sources":["webpack://src/pages/checkout/components/information/styles.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,cAAc;EACd,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,iBAAiB;EACjB,kBAAkB;AACpB;AACA;EACE,qBAAqB;AACvB","sourcesContent":[".group {\n  background-color: #eee;\n  padding: 1.5em;\n  border-radius: .4em;\n  margin-bottom: 1em;\n}\n.flexSpacer {\n  display: flex;\n  flex-wrap: nowrap;\n  margin-bottom: 1em;\n}\n.input {\n  height: 4em!important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"group": "styles_group__3eXdN",
	"flexSpacer": "styles_flexSpacer__pvmjk",
	"input": "styles_input__1qNK4"
};
export default ___CSS_LOADER_EXPORT___;
