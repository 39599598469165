// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_label__uYDNe {\r\n  font-size: 1em;\r\n  margin-bottom: .4em;\r\n  color: var(--black);\r\n}", "",{"version":3,"sources":["webpack://src/components/labelInput/styles.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,mBAAmB;EACnB,mBAAmB;AACrB","sourcesContent":[".label {\r\n  font-size: 1em;\r\n  margin-bottom: .4em;\r\n  color: var(--black);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": "styles_label__uYDNe"
};
export default ___CSS_LOADER_EXPORT___;
