/* eslint-disable no-unused-vars */
import { Hidden, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import React from 'react';
import Loading from 'src/components/loading';
import useProgramList from 'src/logic/programList';
import fallbackProgram from 'src/assets/fallback-program.png';
import ProgramItem from './components/program';
import styles from './styles.module.css';

const ProgramList = () => {
  const { loading, programList } = useProgramList();

  if (loading) return <Loading />;

  return (
    <div className={styles.limiter}>
      <h1 className={styles.title}>Lista de programas</h1>
      <div className={styles.card}>
        <Table aria-label="simple table" className={styles.table}>
          <TableHead>
            <TableRow>
              <Hidden smDown>
                <TableCell />
              </Hidden>
              <TableCell align="left">Titulo</TableCell>
              <TableCell align="left">ID</TableCell>
              <TableCell align="left">Editar</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {programList.map((currentProgram) => (
              <ProgramItem
                key={currentProgram.id}
                title={currentProgram.title}
                id={currentProgram.id}
                icon={currentProgram.picture || fallbackProgram}
              />
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default ProgramList;
