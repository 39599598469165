import { Box } from '@material-ui/core';
import { SentimentVeryDissatisfied } from '@material-ui/icons';
import { string } from 'prop-types';
import React from 'react';
import styles from './styles.module.css';

const EmptyContent = ({ message }) => (
  <div className={styles.wrapper}>
    <SentimentVeryDissatisfied />
    <Box>{message}</Box>
  </div>
);

EmptyContent.propTypes = {
  message: string.isRequired,
};

export default EmptyContent;
