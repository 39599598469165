import { element } from 'prop-types';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const Loaders = ({ children }) => {
  const { pathname } = useLocation();

  // scroll to top when cchange location
  useEffect(() => {
    if (!pathname.includes('tips')) {
      window.scrollTo({ top: 0 });
    }
  }, [pathname]);
  return <>{children}</>;
};

Loaders.propTypes = {
  children: element.isRequired,
};

export default Loaders;
