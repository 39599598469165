import { useMediaQuery } from '@material-ui/core';
import React from 'react';
import { Route, Switch } from 'react-router';
import pictureSrc from 'src/assets/iampoltri.png';
import logoSrc from 'src/assets/logo.svg';
import pictureMSrc from 'src/assets/iampoltrim.png';
import AuthRoute from 'src/components/authRoute';
import SignIn from './components/signIn';
import Survey from './components/survey';
import styles from './styles.module.css';

const Auth = () => {
  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <div className={styles.fullWidth}>
      <div className={styles.body}>
        <Switch>
          <Route path="/auth/signin" component={SignIn} />
          <AuthRoute userAuthorized="user" path="/auth/survey" component={Survey} />
        </Switch>
      </div>
      <div
        className={styles.imageWrapper}
        style={{
          backgroundImage: `url(${isMobile ? pictureMSrc : pictureSrc})`,
        }}
      >
        {isMobile && (
          <>
            <img src={logoSrc} className={styles.logo} alt="logo" />
            <div className={styles.title}>#YoSoyPoltri</div>
          </>
        )}
      </div>
    </div>
  );
};

export default Auth;
