// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* CHECBOX */\n.styled_checkboxGroup__2MlDI {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: .5em 1em;\n  cursor: pointer;\n}\n.styled_checkbox__3hCuX {\n  width: .8em;\n  height: .8em;\n  margin-right: .5em;\n  border: 2px solid #cdcdcd;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n", "",{"version":3,"sources":["webpack://src/pages/dashboard/pages/initiationQuestions/components/checkbox/styled.module.css"],"names":[],"mappings":"AAAA,YAAY;AACZ;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,iBAAiB;EACjB,eAAe;AACjB;AACA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;EACzB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB","sourcesContent":["/* CHECBOX */\n.checkboxGroup {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: .5em 1em;\n  cursor: pointer;\n}\n.checkbox {\n  width: .8em;\n  height: .8em;\n  margin-right: .5em;\n  border: 2px solid #cdcdcd;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkboxGroup": "styled_checkboxGroup__2MlDI",
	"checkbox": "styled_checkbox__3hCuX"
};
export default ___CSS_LOADER_EXPORT___;
