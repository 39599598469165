import firebaseApp from 'firebase/app';
import 'firebase/storage';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/auth';
import { runtime } from 'src/runtime.config';

export const storageRef = firebaseApp.storage().ref();
export const firestore = firebaseApp.firestore();
export const functions = firebaseApp.functions();
export const auth = firebaseApp.auth();
export const firebase = firebaseApp;

if (runtime === 'development') functions.useEmulator('localhost', 5001);
