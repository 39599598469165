import { Box } from '@material-ui/core';
import React from 'react';
import EmptyContent from 'src/components/emptyContent';
import Loading from 'src/components/loading';
import useMessageList from 'src/hooks/useMessageList';
import useAlert from 'src/modules/alert/useAlert';
import HeaderForLists from '../../components/headerForLists';
import MessageCard from './components/messageCard';
import styles from './styles.module.css';

const MessagesPage = () => {
  const messageList = useMessageList();
  const setAlert = useAlert();

  const handleDelete = (item) => () => {
    const deleteMessage = messageList.getDelete(item);
    setAlert({
      title: '¿Seguro quieres eliminar este mensaje?',
      action: deleteMessage,
    });
  };

  return (
    <Box padding="1em">
      <HeaderForLists title="Mensajes" searcherDisabled />
      <div className={styles.body}>
        {messageList.loading && <Loading message="Cargando mensajes" />}
        {!messageList.loading && !messageList.messages.length && <EmptyContent message="Aún no tienes mensajes" />}
        {!messageList.loading && !!messageList.messages.length && (
          <div className={styles.messageList}>
            {messageList.messages.map((message) => (
              <div className={styles.messageWrapper} key={message.id}>
                <MessageCard
                  createdAt={message.createdAt}
                  message={message.message}
                  email={message.email}
                  name={message.name}
                  phone={message.phone}
                  onDelete={handleDelete(message)}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </Box>
  );
};

export default MessagesPage;
