import database from 'src/core/database';
import response, { failed, success } from 'src/helpers/response';
/**
 * create a new message
 */
export const createContactMessage = async (data) => {
  const secureData = {
    email: data.email,
    name: data.name,
    phone: data.phone,
    message: data.message,
  };

  const documentData = await database.create('messages', secureData);
  return documentData ? response.success('Mensaje enviado correctamente') : response.failed('algo salio mal');
};

/**
 *  get last 300 messages
 */
export const getMessagesList = async () => {
  const list = await database.getList('messages', 300, ['createdAt', 'desc'], null).next();
  return list;
};

/**
 *  delete message by id
 */
export const deleteMessage = async (id) => {
  const status = await database.remove(`/messages/${id}`);
  return status ? success('Mensaje eliminado correctamente') : failed();
};

export default {
  create: createContactMessage,
  getList: getMessagesList,
  delete: deleteMessage,
};
