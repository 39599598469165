// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_limiter__3iNRx {\n  max-width: 900px;\n  margin: auto;\n}\n\n.styles_card__fRrED {\n  background-color: #fff;\n  padding: 2em;\n}\n.styles_title__1fO1j {\n  font-size: 1.2em;\n  color: #000;\n}\n.styles_table__1bzQq * {\n  font-size: 1em;\n}\n\n@media screen and (max-width:1500px) {\n  .styles_limiter__3iNRx {\n    max-width: 700px;\n  }\n}\n\n@media screen and (max-width:1300px) {\n  .styles_limiter__3iNRx {\n    max-width: 600px;\n  }\n}\n\n@media screen and (max-width:900px) {\n  .styles_card__fRrED {\n    padding: 1em;\n  }\n}\n\n@media screen and (max-width:600px) {\n  .styles_card__fRrED {\n    padding: 0em;\n  }\n}", "",{"version":3,"sources":["webpack://src/pages/dashboard/pages/programList/styles.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,sBAAsB;EACtB,YAAY;AACd;AACA;EACE,gBAAgB;EAChB,WAAW;AACb;AACA;EACE,cAAc;AAChB;;AAEA;EACE;IACE,gBAAgB;EAClB;AACF;;AAEA;EACE;IACE,gBAAgB;EAClB;AACF;;AAEA;EACE;IACE,YAAY;EACd;AACF;;AAEA;EACE;IACE,YAAY;EACd;AACF","sourcesContent":[".limiter {\n  max-width: 900px;\n  margin: auto;\n}\n\n.card {\n  background-color: #fff;\n  padding: 2em;\n}\n.title {\n  font-size: 1.2em;\n  color: #000;\n}\n.table * {\n  font-size: 1em;\n}\n\n@media screen and (max-width:1500px) {\n  .limiter {\n    max-width: 700px;\n  }\n}\n\n@media screen and (max-width:1300px) {\n  .limiter {\n    max-width: 600px;\n  }\n}\n\n@media screen and (max-width:900px) {\n  .card {\n    padding: 1em;\n  }\n}\n\n@media screen and (max-width:600px) {\n  .card {\n    padding: 0em;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"limiter": "styles_limiter__3iNRx",
	"card": "styles_card__fRrED",
	"title": "styles_title__1fO1j",
	"table": "styles_table__1bzQq"
};
export default ___CSS_LOADER_EXPORT___;
