// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_wrapper__xKoDU {\r\n  color: #fff;\r\n  padding: 2em 0em;\r\n  background-color: var(--black);\r\n  min-height: 100vh;\r\n  box-sizing: border-box;\r\n  position: -webkit-sticky;\r\n  position: sticky;\r\n  top: 0;\r\n}\r\n.styles_link__2FF0C {\r\n  padding: .5em 1.5em;\r\n  display: flex;\r\n  align-items: center;\r\n  color: var(--cyan)!important;\r\n  margin: .5em 0em;\r\n  cursor: pointer;\r\n}\r\n.styles_activeLink__3CqfL {\r\n  background-color: var(--rose);\r\n  color: #000!important;\r\n}\r\n.styles_icon__1FlMB {\r\n  font-size: 1.4em!important;\r\n  margin-right: .5em;\r\n}\r\n.styles_logo__32vGO {\r\n  width: 65%;\r\n  display: block;\r\n  margin: 2em auto;\r\n}", "",{"version":3,"sources":["webpack://src/pages/dashboard/components/menuSide/styles.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gBAAgB;EAChB,8BAA8B;EAC9B,iBAAiB;EACjB,sBAAsB;EACtB,wBAAgB;EAAhB,gBAAgB;EAChB,MAAM;AACR;AACA;EACE,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,4BAA4B;EAC5B,gBAAgB;EAChB,eAAe;AACjB;AACA;EACE,6BAA6B;EAC7B,qBAAqB;AACvB;AACA;EACE,0BAA0B;EAC1B,kBAAkB;AACpB;AACA;EACE,UAAU;EACV,cAAc;EACd,gBAAgB;AAClB","sourcesContent":[".wrapper {\r\n  color: #fff;\r\n  padding: 2em 0em;\r\n  background-color: var(--black);\r\n  min-height: 100vh;\r\n  box-sizing: border-box;\r\n  position: sticky;\r\n  top: 0;\r\n}\r\n.link {\r\n  padding: .5em 1.5em;\r\n  display: flex;\r\n  align-items: center;\r\n  color: var(--cyan)!important;\r\n  margin: .5em 0em;\r\n  cursor: pointer;\r\n}\r\n.activeLink {\r\n  background-color: var(--rose);\r\n  color: #000!important;\r\n}\r\n.icon {\r\n  font-size: 1.4em!important;\r\n  margin-right: .5em;\r\n}\r\n.logo {\r\n  width: 65%;\r\n  display: block;\r\n  margin: 2em auto;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "styles_wrapper__xKoDU",
	"link": "styles_link__2FF0C",
	"activeLink": "styles_activeLink__3CqfL",
	"icon": "styles_icon__1FlMB",
	"logo": "styles_logo__32vGO"
};
export default ___CSS_LOADER_EXPORT___;
