import { Box, Grid, Hidden } from '@material-ui/core';
import { Check, PlayArrow, QueryBuilder } from '@material-ui/icons';
import { bool, number, object, string } from 'prop-types';
import React from 'react';
import composeClassNames from 'src/helpers/composeClassNames';
import { usePlayer } from 'src/modules/youtubePlayer';
import Chat from 'src/pages/trainingDetail/components/chat';
import { useSession } from '../../modules/session';
import styles from './styles.module.css';

const TrainingDetailCard = (props) => {
  const session = useSession();
  const player = usePlayer();

  const playBinded = (video) => () => player.play(video);

  return (
    <div className={styles.wrapperFlex}>
      <Hidden xsDown>
        <div className={styles.weekNumberCard}>
          <div>SEM</div>
          <Box fontSize="1.5em" fontWeight="bold">
            {props.weekNumber}
          </Box>
        </div>
      </Hidden>

      <div className={styles.cardData}>
        <Hidden smUp>
          <Box fontSize="1.2em" fontWeight="bold" marginBottom="2em">
            <Grid container alignItems="center">
              <Box color="var(--cyan)">SEMANA</Box>
              <Box marginLeft="1.2em" color="var(--gray)" fontSize="1.1em">
                {props.weekNumber}
              </Box>
            </Grid>
          </Box>
        </Hidden>
        <div className={styles.bodyHeader}>
          <Box fontSize="1.5em" fontWeight="bold">
            {props.dayName}
          </Box>
          {!props.completed && (
            <div className={styles.boxStatus}>
              <QueryBuilder />
              PENDIENTE
            </div>
          )}
          {props.completed && (
            <div className={composeClassNames(styles.boxStatus, styles.boxStatusCompleted)}>
              <Check />
              COMPLETADO
            </div>
          )}
        </div>

        <div className={styles.cardDataBody}>
          {props.dayData.types.map((currentType, typeIndex) => (
            <div className={styles.typeCard} key={typeIndex}>
              <div className={styles.typeTitle}>{currentType.label}</div>
              <div className={styles.typeCardBody}>
                {currentType.exercices.map((excercice, excerciceIndex) => {
                  if (excercice.type === 'title')
                    return (
                      <Box key={excerciceIndex} fontWeight="800">
                        {excercice.value}
                      </Box>
                    );
                  if (excercice.type === 'body')
                    return (
                      <div className={styles.excerciceBodyRow} key={excerciceIndex}>
                        <Box>{excercice.value}</Box>
                        {excercice.video && (
                          <div className={styles.videoRow} onClick={playBinded(excercice.video)} role="button">
                            <PlayArrow />
                            Demostración de ejercicio
                          </div>
                        )}
                      </div>
                    );
                  return null;
                })}
              </div>
            </div>
          ))}
          <Box lineHeight="1.4em" marginBottom="2em">
            <Box fontWeight="bold">¡Día completado!</Box>
            <Box>Tu siguiente rutina se activará automáticamente.</Box>
            <Box fontWeight="bold">¡Nos vemos mañana!</Box>
          </Box>
          {session.data.advicer && <Chat />}
        </div>
      </div>
    </div>
  );
};

TrainingDetailCard.defaultProps = {
  completed: false,
};

TrainingDetailCard.propTypes = {
  dayName: string.isRequired,
  weekNumber: number.isRequired,
  completed: bool,
  dayData: object.isRequired,
};

export default TrainingDetailCard;
