/* eslint-disable no-unused-vars */
import { Box } from '@material-ui/core';
import { PhotoCamera, Send } from '@material-ui/icons';
import React, { useState } from 'react';
import pictureDefault from 'src/assets/user.png';
import groupChat from 'src/core/models/groupChat';
import useForm from '../../../../hooks/useForm';
import { useSession } from '../../../../modules/session';
import Dropzone from '../../../dropzone';
import Input from '../../../inputs';
import styles from './styles.module.css';

const Form = () => {
  const session = useSession();
  const { getInputProps, setValues, values } = useForm({});
  const [uploadingPhoto, setUploadingPhoto] = useState(false);

  const handleSend = (event) => {
    if (event && event.preventDefault) event.preventDefault();
    if (event && event.stopPropagation) event.stopPropagation();
    if (values.message) {
      groupChat.newMessage(session.data.id, values.message, session.data.picture || pictureDefault, session.data.name);
      setValues({});
    }
  };

  const handleSelecteImage = async (file) => {
    if (file) {
      setUploadingPhoto(true);
      await groupChat.newMessage(session.data.id, file, session.data.picture || pictureDefault, session.data.name);
      setUploadingPhoto(false);
    }
  };

  // transform enter into submit
  const handleKeyPress = (event) => {
    if (event.which === 13 && !event.shiftKey) {
      handleSend();
      event.preventDefault();
    }
  };

  return (
    <div className={styles.wrapper}>
      {uploadingPhoto && (
        <Box textAlign="right" marginBottom="1em">
          Cargando imagen...
        </Box>
      )}
      <div className={styles.body}>
        <Input
          type="textarea"
          className={styles.textarea}
          placeholder="Escribe tu mensaje"
          {...getInputProps('message')}
          onKeyPress={handleKeyPress}
        />
        <div className={styles.actionsWrapper}>
          <Dropzone onChange={handleSelecteImage}>
            <PhotoCamera className={styles.icon} />
          </Dropzone>
          <Send className={styles.iconSend} onClick={handleSend} />
        </div>
      </div>
    </div>
  );
};

export default Form;
