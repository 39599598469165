/* eslint-disable no-unused-vars */
import mapObjects from '../../helpers/mapObjects';

const formatSimpleFilter = (source, type) =>
  mapObjects(source, (value, keyname) => ({
    label: value,
    value: keyname,
    type,
  }));

const recursiveComputeValue = (options, composeValue = {}) =>
  options.map((option) => {
    const localOption = { ...option };
    localOption.calculatedValue = {
      ...composeValue,
      [localOption.type]: localOption.value,
    };
    if (localOption.options) {
      localOption.options = recursiveComputeValue(localOption.options, localOption.calculatedValue);
    }
    return localOption;
  });

const composeFiltersCreator = (data) => {
  const FIltersCrud = [
    {
      type: 'module',
      label: 'TODOS',
      value: null,
    },
  ];

  const acum = {};
  for (const module of data) {
    if (!acum[module.section]) acum[module.section] = null;
  }

  Object.keys(acum).forEach((ketyname) => {
    const currentModule = acum[ketyname];
    FIltersCrud.push({
      type: 'section',
      label: ketyname,
      value: ketyname,
    });
  });

  return recursiveComputeValue(FIltersCrud);
};

export default composeFiltersCreator;
