// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_body__1MNMK  {\n  font-size: 1.1em;\n  line-height: 1.6em;\n}\n\n.styles_body__1MNMK p {\n  margin-bottom: 2em;\n  color: var(--gray);\n}\n\n.styles_title__3CKmt {\n  font-weight: bold;\n  text-align: center;\n  color: var(--cyan);\n  margin-bottom: 2em;\n}\n\n@media screen and (max-width:1000px) {\n  .styles_body__1MNMK  {\n    font-size: .9em;\n  } \n}\n\n@media screen and (max-width:600px) {\n  .styles_body__1MNMK  {\n    font-size: .8em;\n  }\n  \n  .styles_title__3CKmt {\n    margin-bottom: 1em;\n  }\n}", "",{"version":3,"sources":["webpack://src/pages/prinacity/styles.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE;IACE,eAAe;EACjB;AACF;;AAEA;EACE;IACE,eAAe;EACjB;;EAEA;IACE,kBAAkB;EACpB;AACF","sourcesContent":[".body  {\n  font-size: 1.1em;\n  line-height: 1.6em;\n}\n\n.body p {\n  margin-bottom: 2em;\n  color: var(--gray);\n}\n\n.title {\n  font-weight: bold;\n  text-align: center;\n  color: var(--cyan);\n  margin-bottom: 2em;\n}\n\n@media screen and (max-width:1000px) {\n  .body  {\n    font-size: .9em;\n  } \n}\n\n@media screen and (max-width:600px) {\n  .body  {\n    font-size: .8em;\n  }\n  \n  .title {\n    margin-bottom: 1em;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": "styles_body__1MNMK",
	"title": "styles_title__3CKmt"
};
export default ___CSS_LOADER_EXPORT___;
