// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_fullWrapper__1Xu51 {\n  background-color: #f1f1f1;\n}", "",{"version":3,"sources":["webpack://src/pages/training/components/trainingBody/styles.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B","sourcesContent":[".fullWrapper {\n  background-color: #f1f1f1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fullWrapper": "styles_fullWrapper__1Xu51"
};
export default ___CSS_LOADER_EXPORT___;
