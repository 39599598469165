import { Box, Grid } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import EmptyContent from '../../../../components/emptyContent';
import Loading from '../../../../components/loading';
import shipping, { deleteShippingCost } from '../../../../core/models/shipping';
import formatPrice from '../../../../helpers/formatPrice';
import useFetch from '../../../../hooks/useFetch';
import useAlert from '../../../../modules/alert/useAlert';
import useNotification from '../../../../modules/notifications/useNotification';
import ButtonAdmin from '../../components/button';
import HeaderForLists from '../../components/headerForLists';
import styles from './styles.module.css';

const shippingCost = () => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [keywords, setKeywords] = useState('');
  const setAlert = useAlert();
  const setNotification = useNotification();
  const history = useHistory();

  let itemsFiltered = items;
  if (keywords) {
    const keywordsNormalized = keywords.toString().toLowerCase();
    itemsFiltered = itemsFiltered.filter((item) => {
      const cpNormalize = item.cp.toString().toLowerCase();
      const commentNormalized = item.comment ? item.comment.toString().toLowerCase() : '';
      if (commentNormalized.includes(keywordsNormalized) || cpNormalize.includes(keywordsNormalized)) return true;
      return false;
    });
  }

  const handleFetch = async () => {
    setLoading(true);
    const data = await shipping.getList();
    setItems(data);
    setLoading(false);
  };

  useFetch(handleFetch);

  const handleDelete = (postalCode) =>
    setAlert({
      title: '¿Seguro quieres eliminar este código postal?',
      action: async () => {
        const response = await deleteShippingCost(postalCode);
        await handleFetch();
        setNotification(response);
      },
    });

  const handleEdit = (item) => {
    history.push({
      pathname: '/dashboard/shipping-cost/editor',
      state: item,
    });
  };

  return (
    <Box padding="1em">
      <HeaderForLists title="Costos de envío" keywords={keywords} onChange={setKeywords} />
      {loading && <Loading />}
      {!loading && !itemsFiltered.length && (
        <>
          <EmptyContent message={keywords ? 'No encontramos resultados' : 'Aun no tienes costos de envío registrado'} />
          {!keywords && (
            <Box display="flex" justifyContent="center" marginTop="-10em">
              <Link to="/dashboard/shipping-cost/editor">
                <ButtonAdmin primary>Crear</ButtonAdmin>
              </Link>
            </Box>
          )}
        </>
      )}
      {!loading && !!itemsFiltered.length && (
        <>
          <div className={styles.listWrapper}>
            <Box margin="1em 0em" display="flex" justifyContent="flex-end">
              <Link to="/dashboard/shipping-cost/editor">
                <ButtonAdmin primary>Crear</ButtonAdmin>
              </Link>
            </Box>

            <Box fontWeight="bold" color="var(--cyan)">
              <div className={styles.row}>
                <div className={styles.describe}>Código postal</div>
                <div className={styles.comment}>Comentario</div>
                <div className={styles.price}>Costo</div>
                <Box className={styles.actionWrapper} />
              </div>
            </Box>

            {itemsFiltered.map((item) => (
              <div className={styles.row} key={item.cp}>
                <div className={styles.describe}>{item.cp}</div>
                <div className={styles.comment}>{item.comment}</div>
                <div className={styles.price}>${formatPrice(item.price)}</div>
                <Box className={styles.actionWrapper}>
                  <Grid container alignItems="center">
                    <Edit className={styles.icon} onClick={() => handleEdit(item)} />
                    <Delete className={styles.icon} onClick={() => handleDelete(item.cp)} />
                  </Grid>
                </Box>
              </div>
            ))}
          </div>
        </>
      )}
    </Box>
  );
};

export default shippingCost;
