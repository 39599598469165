import React from 'react';
import logoSrc from 'src/assets/logo.svg';
import bagSrc from 'src/assets/bag.svg';
import { Menu } from '@material-ui/icons';
import logInSrc from 'src/assets/login.svg';
import useMenuPrivate from 'src/modules/menuPrivate/useMenuPrivate';
import { any, bool, func, number, string } from 'prop-types';
import { Link } from 'react-router-dom';
import styles from './styles.module.css';
import Container from '../../../container';
import Notifications from '../notifications';

const PrivateMobile = ({ hideSecondary, ...props }) => {
  const { handleToggle } = useMenuPrivate();

  return (
    <header
      style={props.getinlineStyle ? props.getinlineStyle() : {}}
      ref={props.headerRef}
      className={styles.headerRoot}
    >
      <Container disabledVerticalPadding>
        <div className={styles.colorLine} />
        <div className={styles.body}>
          <div className={styles.primary}>
            <img className={styles.logo} src={logoSrc} alt="logo" />
            <div className={styles.primaryRigthWrapper}>
              <img className={styles.profilePicture} alt="profile" src={props.photo} />
              <Menu className={styles.menuIcon} onClick={handleToggle} />
            </div>
          </div>
          {!hideSecondary && (
            <div className={styles.secondary}>
              <div className={styles.userName}>
                <span>Hola</span>
                {props.name}
              </div>
              <div className={styles.actionsWrapper}>
                <Link to="/mi-carrito" className={styles.link}>
                  {!!props.numItemsOnCart && <div className={styles.counter}>{props.numItemsOnCart}</div>}
                  <img src={bagSrc} className={styles.icon} alt="icon" />
                </Link>
                <div className={styles.divider} />
                <Notifications className={styles.icon} />
                <div className={styles.divider} />
                <Link to="/private/my-profile">
                  <img src={logInSrc} className={styles.icon} alt="icon" />
                </Link>
              </div>
            </div>
          )}
        </div>
      </Container>
    </header>
  );
};

PrivateMobile.defaultProps = {
  hideSecondary: false,
  name: '',
  getinlineStyle: null,
  headerRef: null,
};

PrivateMobile.propTypes = {
  hideSecondary: bool,
  numItemsOnCart: number.isRequired,
  name: string,
  photo: string.isRequired,
  getinlineStyle: func,
  headerRef: any,
};

export default PrivateMobile;
