// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_wrapper__2u6y_ {\n  display: flex;\n  justify-content: center;\n  align-items: stretch;\n}\n.styles_membersWrapper__39N1r {\n  width: 30%;\n  margin-right: 1em;\n  border-top: 5px solid var(--cyan);\n}\n.styles_bodyWrapper__2SYxV {\n  width: 70%;\n  border-top: 5px solid var(--rose);\n  background-color: #fff;\n}\n@media screen and (max-width:700px) {\n  .styles_membersWrapper__39N1r {\n    display: none;\n  }\n  .styles_bodyWrapper__2SYxV {\n    width: 100%;\n  }\n}", "",{"version":3,"sources":["webpack://src/components/groupChat/styles.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,oBAAoB;AACtB;AACA;EACE,UAAU;EACV,iBAAiB;EACjB,iCAAiC;AACnC;AACA;EACE,UAAU;EACV,iCAAiC;EACjC,sBAAsB;AACxB;AACA;EACE;IACE,aAAa;EACf;EACA;IACE,WAAW;EACb;AACF","sourcesContent":[".wrapper {\n  display: flex;\n  justify-content: center;\n  align-items: stretch;\n}\n.membersWrapper {\n  width: 30%;\n  margin-right: 1em;\n  border-top: 5px solid var(--cyan);\n}\n.bodyWrapper {\n  width: 70%;\n  border-top: 5px solid var(--rose);\n  background-color: #fff;\n}\n@media screen and (max-width:700px) {\n  .membersWrapper {\n    display: none;\n  }\n  .bodyWrapper {\n    width: 100%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "styles_wrapper__2u6y_",
	"membersWrapper": "styles_membersWrapper__39N1r",
	"bodyWrapper": "styles_bodyWrapper__2SYxV"
};
export default ___CSS_LOADER_EXPORT___;
