import { useEffect, useMemo, useState } from 'react';

const useHideMessages = () => {
  const [hide, setHide] = useState(false);
  const initialHeigth = useMemo(() => window.innerHeight);

  console.log('hideStatus', hide);

  const handlerResize = () => {
    const currentHeight = window.innerHeight;
    const minDiff = 100;
    const diff = initialHeigth - currentHeight;
    if (diff >= minDiff) setHide(true);
    else setHide(false);
  };

  useEffect(() => {
    window.addEventListener('resize', handlerResize);
    return () => window.removeEventListener('resize', handlerResize);
  }, []);

  return hide;
};

export default useHideMessages;
