import { useState } from 'react';
import contact from 'src/core/models/contact';
import useFetch from './useFetch';

const useMessageList = () => {
  const [state, setState] = useState({
    loading: true,
    messages: [],
  });

  const setComposeState = (partialState) => setState((prev) => ({ ...prev, ...partialState }));

  const handleFetch = async () => {
    setComposeState({ loading: true });
    const messages = await contact.getList();
    setComposeState({ loading: false, messages });
  };

  useFetch(handleFetch);

  const handleGetDelete = (item) => async () => {
    const status = await contact.delete(item.id);
    if (status.success) await handleFetch();
    return status;
  };

  return {
    loading: state.loading,
    messages: state.messages,
    getDelete: handleGetDelete,
  };
};

export default useMessageList;
