import { element } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import context from './context';
import ViewNotifications from './view';

const MenuProvider = ({ children }) => {
  const [isActive, setMenuActive] = useState(false);
  const { pathname } = useLocation();

  const handleOpen = () => setMenuActive(true);
  const handleClose = () => setMenuActive(false);

  useEffect(() => {
    handleClose();
  }, [pathname]);

  return (
    <context.Provider value={handleOpen}>
      <>
        {children}
        <ViewNotifications open={isActive} onClose={handleClose} />
      </>
    </context.Provider>
  );
};

MenuProvider.propTypes = {
  children: element.isRequired,
};

export default MenuProvider;
