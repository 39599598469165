import PropTypes from 'prop-types';
import React from 'react';
import Container from 'src/components/container';
import Input from 'src/components/inputs';
import logoSrc from 'src/assets/poltri-inspira--logo_color.svg';
import { ButtonBase, CircularProgress, Grid } from '@material-ui/core';
import { survey } from 'src/constants';
import styles from './styles.module.css';
import ErrorMessage from '../../../../components/errorMessage';

const surveyTransformed = survey.map((currentSurvey) => ({
  question: currentSurvey.question,
  options: currentSurvey.options.reduce((acum, current) => ({ ...acum, [current]: current }), {}),
}));

const SurverView = (props) => (
  <>
    {props.loading && (
      <Grid container justify="center" style={{ minHeight: '20em' }} alignItems="center">
        <CircularProgress />
      </Grid>
    )}
    {!props.loading && (
      <Container>
        {props.currentStep === 1 && (
          <div className={styles.wrapper}>
            <img src={logoSrc} className={styles.logo} alt="logo" />
            <div className={styles.title}>Antes de empezar</div>
            <div className={styles.description}>
              Te pedimos que llenes el siguiente cuestionario. Queremos conocerte mejor.
            </div>
            <div className={styles.stepContainer}>
              <div className={`${styles.step} ${props.currentStep !== 1 ? styles.stepUnselected : ''}`}>1</div>
              <div className={`${styles.step} ${props.currentStep !== 2 ? styles.stepUnselected : ''}`}>2</div>
            </div>
            <div className={styles.formGroup}>
              <ErrorMessage message={props.errorMessage} />
              {surveyTransformed.map((currentSurvey, index) => (
                <div className={styles.inputGroup} key={index}>
                  <div className={styles.labelText}>{currentSurvey.question}</div>
                  <Input
                    className={styles.input}
                    type="select"
                    options={currentSurvey.options}
                    {...props.getInputProps(`question_${index}`)}
                  />
                </div>
              ))}

              <ButtonBase className={styles.button} onClick={props.onStepOneNext}>
                Siguente
              </ButtonBase>
            </div>
          </div>
        )}
        {props.currentStep === 2 && (
          <div className={styles.wrapper}>
            <img src={logoSrc} className={styles.logo} alt="logo" />
            <div className={styles.title}>¡Ya casi terminamos!</div>
            <div className={styles.description}>Te pedimos por favor leer y aceptar la siguiente responsiva.</div>
            <div className={styles.stepContainer}>
              <div className={`${styles.step} ${props.currentStep !== 1 ? styles.stepUnselected : ''}`}>1</div>
              <div className={`${styles.step} ${props.currentStep !== 2 ? styles.stepUnselected : ''}`}>2</div>
            </div>
            <div className={styles.paragraphWrapper}>
              <div className={styles.paragraph}>EXONERACIÓN DE RESPONSABILIDAD LEGAL</div>
              <div className={styles.paragraph}>
                Al ser mayor de edad, es mi voluntad participar en el PROGRAMA DE INICIACIÓN DEPORTIVA de la
                Organización Poltri Inspira por lo que manifiesto que:
              </div>
              <ol>
                <li>
                  <div className={styles.paragraph}>He leído la información descriptiva del programa.</div>
                </li>
                <li>
                  <div className={styles.paragraph}>
                    Ratifico la veracidad de la información proporcionada en mi registro, así como de los datos
                    personales proporcionados. Si fueran datos falsos, entiendo y estoy consciente de que perderé la
                    posibilidad de participar en el Programa, sin responsabilidad para el POLTRI INSPIRA.
                  </div>
                </li>
                <li>
                  <div className={styles.paragraph}>
                    Conozco y soy consciente de todos los riesgos, peligros y daños que pudiera sufrir mi salud al ser
                    una actividad física, por lo que en este acto manifiesto que me encuentro en óptimas condiciones de
                    salud, aceptando que soy el (la) único (a) responsable de cualquier accidente o deficiencia que
                    pudiera afectar a la misma o de cualquier daño a mi integridad física que pudieran incluso provocar
                    mi muerte. Por tal motivo, en este acto libero y exonero a POLTRI INSPIRA, patrocinadores,
                    voluntarios y colaboradores, organizadores, accionistas o representantes de las compañías,
                    Instituciones o a cualquier otra persona relacionada con las mismas, de cualquier responsabilidad,
                    sin reservarme acción o derecho por cualquier reclamación presente o futura derivada de los actos
                    que en este momento asumo.
                  </div>
                </li>
                <li>
                  <div className={styles.paragraph}>
                    Autorizo al POLTRI INSPIRA, asesores, colaboradores y/o a quien éste designe, para que en caso de
                    que mi persona sufra algún accidente por mi participación, actividad o inactividad antes, durante o
                    después de alguna práctica, me sea brindada la atención médica necesaria para salvaguardar mi salud,
                    aceptando que todos los gastos que pudieran generarse, serán a mi costa y que pude consistir en:
                  </div>
                  <div className={styles.paragraph}>
                    <ul>
                      <li>Traslado a la unidad hospitalaria más cercana para mi atención oportuna.</li>
                      <li>
                        Suministro de medicamentos que los médicos de la Unidad Hospitalaria a cargo de mi atención,
                        consideren convenientes para salvaguardad mi salud.
                      </li>
                      <li>
                        En su caso, que se realicen la intervención quirúrgica que sea necesaria para salvaguardar mi
                        salud.
                      </li>
                      <li>Cualquier otro procedimiento médico que sea necesario.</li>
                    </ul>
                  </div>
                  <div className={styles.paragraph}>
                    Por lo anterior, en este acto libero y exonero a POLTRI INSPIRA, patrocinadores, voluntarios y
                    colaboradores, organizadores, accionistas o representantes de las compañías, Instituciones o a
                    cualquier otra persona relacionada con las mismas, de cualquier responsabilidad por las acciones
                    médicas que me sean efectuadas o por las consecuencias inmediatas o futurasa mi salud que se
                    pudieran derivar de mi participación en el PROGRAMA, sin reservarme acción o derecho alguno por
                    cualquier reclamación presente o futura derivada de los actos que en este momento asumo.
                  </div>
                </li>
                <li>
                  <div className={styles.paragraph}>
                    Es mi responsabilidad el monitoreo de mi salud, como lo es revisiones médicas y realización de
                    “pruebas de esfuerzo”, que las he realizado y no se encuentra impedimento diagnosticado, para mi
                    participación en el programa y sus actividades.
                  </div>
                </li>
                <li>
                  <div className={styles.paragraph}>
                    Autorizo a la Organización POLTRI INSPIRA para utilizar mi imagen, voz y datos ya sea total o
                    parcialmente, única y exclusivamente en todo lo relacionado con mi participación en el PROGRAMA DE
                    INICIACIÓN DEPORTIVA.
                  </div>
                </li>
              </ol>
              <div className={styles.paragraph}>
                Al elegir la opción “ACEPTO” al terminar la total lectura de la presente, me obligo a dar cumplimiento a
                cada una de sus partes, así como a las reglas de participación del Programa, reconociendo dicha
                ACEPTACIÓN como mi firma para los efectos que sean necesarios.
              </div>
            </div>
            <ButtonBase className={styles.buttonAcept} onClick={props.onStepTwoAcept}>
              ACEPTAR
            </ButtonBase>
          </div>
        )}
      </Container>
    )}
  </>
);

SurverView.defaultProps = {
  errorMessage: null,
};

SurverView.propTypes = {
  currentStep: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  getInputProps: PropTypes.func.isRequired,
  onStepOneNext: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  onStepTwoAcept: PropTypes.func.isRequired,
};

export default SurverView;
