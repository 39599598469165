// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_fullWrapper__2k-lG {\n  background-color: #fff;\n  padding: 5em;\n}\n.styles_icon__19QGM {\n  width: 150em;\n  display: block;\n  margin: auto;\n}\n.styles_title__3_EYw {\n  line-height: 1.5em;\n  text-align: center;\n  margin-bottom: 2em;\n  font-size: 2em;\n  color: var(--cyan);\n  font-weight: 600;\n}\n@media screen and (max-width:600px) {\n  .styles_fullWrapper__2k-lG {\n    padding: 2em;\n  }\n  .styles_title__3_EYw {\n    font-size: 1.5em;\n  }\n  .styles_icon__19QGM {\n    width: 12em;\n  }\n}", "",{"version":3,"sources":["webpack://src/pages/training/components/waiting/styles.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,YAAY;AACd;AACA;EACE,YAAY;EACZ,cAAc;EACd,YAAY;AACd;AACA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,kBAAkB;EAClB,cAAc;EACd,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE;IACE,YAAY;EACd;EACA;IACE,gBAAgB;EAClB;EACA;IACE,WAAW;EACb;AACF","sourcesContent":[".fullWrapper {\n  background-color: #fff;\n  padding: 5em;\n}\n.icon {\n  width: 150em;\n  display: block;\n  margin: auto;\n}\n.title {\n  line-height: 1.5em;\n  text-align: center;\n  margin-bottom: 2em;\n  font-size: 2em;\n  color: var(--cyan);\n  font-weight: 600;\n}\n@media screen and (max-width:600px) {\n  .fullWrapper {\n    padding: 2em;\n  }\n  .title {\n    font-size: 1.5em;\n  }\n  .icon {\n    width: 12em;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fullWrapper": "styles_fullWrapper__2k-lG",
	"icon": "styles_icon__19QGM",
	"title": "styles_title__3_EYw"
};
export default ___CSS_LOADER_EXPORT___;
