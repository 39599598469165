/* eslint-disable no-unused-vars */
import { Box } from '@material-ui/core';
import { ArrowDropDown, Edit } from '@material-ui/icons';
import { func, string } from 'prop-types';
import React from 'react';
import Options from 'src/components/options';
import useProgramList from 'src/logic/programList';
import styles from './styles.module.css';

const ProgramSelector = ({ onChange, value }) => {
  const { loading, programList } = useProgramList();

  const optionsList = programList.map(({ title, id }) => ({ label: title.toUpperCase(), handler: () => onChange(id) }));

  return (
    <>
      {!loading && (
        <Box marginBottom="2em">
          <Options optionsList={optionsList}>
            <Box display="flex" alignItems="center" className={styles.option}>
              <Box fontSize="1.2em" fontWeight="600" className={styles.title}>
                {programList.find((e) => e.id === value).title}
              </Box>
              <ArrowDropDown className={styles.arrow} />
            </Box>
          </Options>
        </Box>
      )}
    </>
  );
};

ProgramSelector.propTypes = {
  onChange: func.isRequired,
  value: string.isRequired,
};

export default ProgramSelector;
