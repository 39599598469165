import { useEffect, useMemo, useState } from 'react';
import purchases from '../core/models/purchases';
import lastPosition from '../helpers/lastPosition';
import useFetch from './useFetch';

const useSales = () => {
  const [state, internalUpdater] = useState({
    loading: false,
    items: [],
    finished: false,
    search: '',
  });

  const setState = (newState) => internalUpdater((prevSTate) => ({ ...prevSTate, ...newState }));
  const fetcher = useMemo(() => purchases.getListPaginated(), []);

  const getItemsFiltered = () => {
    if (state.loading || !state.finished || !state.search) return state.items;
    return state.items.filter((item) => {
      const searchNormalized = state.search.toString().toLowerCase();
      const clientNameNormalized = item.userInfo.name ? item.userInfo.name.toLowerCase() : '';
      const orderIdNormalized = item.id.toString().toLowerCase();
      return clientNameNormalized.includes(searchNormalized) || orderIdNormalized.includes(searchNormalized);
    });
  };

  /**
   * search value updated
   * @param {String} value search value
   */
  const setSearch = (value) => setState({ search: value });

  /** handler for fetch next page items */
  const handleFecthNextPage = async () => {
    setState({ loading: true });
    await new Promise((resolve) => setTimeout(resolve, 2000));
    const responseItems = await fetcher.next();
    setState({
      items: [...state.items, ...responseItems],
      loading: false,
      finished: fetcher.end,
    });
  };

  /** get all items an remlace at state.items */
  const handleFecthForSearch = async () => {
    if (!state.finished && !state.loading) {
      setState({ loading: true, items: [] });
      const responseItems = await purchases.getList();
      setState({ loading: false, items: responseItems, finished: true });
    }
  };

  /** scroll handler */
  const handleScroll = () => {
    const islastPosition = lastPosition(200);
    if (!state.finished && islastPosition && !state.loading) handleFecthNextPage();
  };

  /** get initial items */
  useFetch(handleFecthNextPage);

  /** add and remove scroll listener for pagination */
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [state.finished, state.loading]);

  /** fetch all items when search change */
  useEffect(() => {
    if (state.search) {
      const timerId = setTimeout(handleFecthForSearch, 1000);
      return () => clearTimeout(timerId);
    }
    return undefined;
  }, [state.search]);

  return {
    loading: state.loading,
    items: getItemsFiltered(),
    search: state.search,
    setSearch,
  };
};

export default useSales;
