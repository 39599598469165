// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_wrapper__2DTQs {\r\n  width: 60%;\r\n  margin: 2em auto;\r\n}\r\n\r\n.styles_datePickerWrapper__eddJZ {\r\n  position: relative;\r\n}\r\n.styles_iconPicker__3FKho {\r\n  position: absolute;\r\n  right: .5em;\r\n  top: 0;\r\n  bottom: 0;\r\n  margin: auto;\r\n  font-size: 1.6em;\r\n  color: var(--cyan);\r\n  cursor: pointer;\r\n}\r\n@media screen and (max-width: 1200px) {\r\n  .styles_wrapper__2DTQs {\r\n    width: 80%;\r\n  }\r\n}\r\n\r\n@media screen and (max-width: 900px) {\r\n  .styles_wrapper__2DTQs {\r\n    width: 100%;\r\n  }\r\n}", "",{"version":3,"sources":["webpack://src/pages/dashboard/pages/createEvent/components/generalInfo/styles.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,WAAW;EACX,MAAM;EACN,SAAS;EACT,YAAY;EACZ,gBAAgB;EAChB,kBAAkB;EAClB,eAAe;AACjB;AACA;EACE;IACE,UAAU;EACZ;AACF;;AAEA;EACE;IACE,WAAW;EACb;AACF","sourcesContent":[".wrapper {\r\n  width: 60%;\r\n  margin: 2em auto;\r\n}\r\n\r\n.datePickerWrapper {\r\n  position: relative;\r\n}\r\n.iconPicker {\r\n  position: absolute;\r\n  right: .5em;\r\n  top: 0;\r\n  bottom: 0;\r\n  margin: auto;\r\n  font-size: 1.6em;\r\n  color: var(--cyan);\r\n  cursor: pointer;\r\n}\r\n@media screen and (max-width: 1200px) {\r\n  .wrapper {\r\n    width: 80%;\r\n  }\r\n}\r\n\r\n@media screen and (max-width: 900px) {\r\n  .wrapper {\r\n    width: 100%;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "styles_wrapper__2DTQs",
	"datePickerWrapper": "styles_datePickerWrapper__eddJZ",
	"iconPicker": "styles_iconPicker__3FKho"
};
export default ___CSS_LOADER_EXPORT___;
