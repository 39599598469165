import React from 'react';
import whatsappSrc from 'src/assets/whatsapp.svg';
import Container from 'src//components/container';
import { contact } from 'src/constants';
import { array, element, object, oneOfType, string } from 'prop-types';
import { useMediaQuery } from '@material-ui/core';
import styles from './styles.module.css';

const CoverPage = ({ content, mobilePicture, picture }) => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const computedPicture = isMobile && mobilePicture ? mobilePicture : picture;

  return (
    <div className={styles.fullwidth} style={{ backgroundImage: `url(${computedPicture})` }}>
      <Container className={styles.container}>
        <div className={styles.title}>{content}</div>
      </Container>
      <a href={contact.whatsappLink} target="_blank" rel="noreferrer" className={styles.whatsappLink}>
        <img src={whatsappSrc} alt="icon" />
      </a>
    </div>
  );
};

CoverPage.defaultProps = {
  mobilePicture: '',
};

CoverPage.propTypes = {
  content: oneOfType([string, element, array, object]).isRequired,
  picture: string.isRequired,
  mobilePicture: string,
};

export default CoverPage;
