import React from 'react';
import coverSrc from 'src/assets/community.jpg';
import coverMobileSrc from 'src/assets/communitym.jpg';
import CoverPage from 'src/components/coverPage';
import Header from 'src/components/header';
import pictureFooterSrc from 'src/assets/asesorfooter.jpg';
import pictureFooterMobileSrc from 'src/assets/asesores-footer-poltri.jpg';
import CoverFooterPage from 'src/components/coverFooterPage';
import Footer from 'src/components/footer';
import Container from 'src/components/container';
import CommunityCard from './components/card';
import styles from './styles.module.css';
import useCommunityList from '../../hooks/useCommunityList';
import Loading from '../../components/loading';
import EmptyContent from '../../components/emptyContent';

const Community = () => {
  const { loading, items } = useCommunityList();

  return (
    <>
      <Header overlap />
      <CoverPage picture={coverSrc} mobilePicture={coverMobileSrc} content="COMUNIDAD" />
      <Container small>
        {loading && <Loading />}

        {!loading && !items.length && <EmptyContent message="Aún no hay publicaciones" />}

        {!loading && !!items.length && (
          <div className={styles.list}>
            {items.map((item, index) => (
              <div className={styles.itemWrapper} key={index}>
                <CommunityCard
                  picture={item.picture}
                  description={item.description}
                  email={item.email}
                  phone={item.phone}
                  title={item.title}
                  whatsapp={item.whatsappNumber}
                  web={item.web}
                />
              </div>
            ))}
          </div>
        )}
      </Container>
      <CoverFooterPage
        picture={pictureFooterSrc}
        pictureMobile={pictureFooterMobileSrc}
        content="EL DOLOR DE HOY ES EL BENEFICIO DE MAÑANA"
      />
      <Footer />
    </>
  );
};

export default Community;
