import { useMediaQuery } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { element } from 'prop-types';
import React, { createContext, useContext, useEffect, useState } from 'react';
import Youtube from 'react-youtube';
import styles from './styles.module.css';

const context = createContext({ play: () => {}, close: () => {} });
export const usePlayer = () => useContext(context);

export const Provider = (props) => {
  const [videoId, setVideoId] = useState(null);
  const [size, setSize] = useState({ width: 640, height: 370 });
  const isMobile = useMediaQuery('(max-width:600px)');
  const rate = isMobile ? 75 : 60;

  const handleCalculateSizes = () => {
    const width = Math.ceil(window.innerWidth / 100) * rate;
    const height = Math.ceil(width / 100) * 56;
    if (size.width !== width || size.height !== height) setSize({ width, height });
  };

  useEffect(() => {
    handleCalculateSizes();
    window.addEventListener('resize', handleCalculateSizes);
    return () => window.removeEventListener('resize', handleCalculateSizes);
  }, [rate]);

  const handlePlay = (newVideoId) => {
    const videoIdParsed = newVideoId.replace('https://youtu.be/', '');
    setVideoId(videoIdParsed);
  };

  const handleClose = () => setVideoId(null);

  const opts = {
    height: size.height,
    width: size.width,
    showinfo: 0,
    playerVars: { autoplay: 1, controls: 1, showinfo: 0, autohide: 1, modestbranding: 1 },
  };

  return (
    <context.Provider value={{ play: handlePlay, close: handleClose }}>
      {props.children}
      {videoId && (
        <div className={styles.wrapper}>
          <div className={styles.body}>
            <Close className={styles.close} onClick={handleClose} />
            <Youtube videoId={videoId} opts={opts} className={styles.player} onEnd={handleClose} />
          </div>
        </div>
      )}
    </context.Provider>
  );
};

const YoutuberPlayer = {
  Provider,
  usePlayer,
};

Provider.propTypes = {
  children: element.isRequired,
};

export default YoutuberPlayer;
