import { array, bool, func, string } from 'prop-types';
import React from 'react';
import EmptyContent from 'src/components/emptyContent';
import Loading from 'src/components/loading';
import useProgramList from 'src/logic/programList';
import { getCurrentWeek } from '../../../../helpers/training';
import HeaderForLists from '../../components/headerForLists';
import AdvicerEditor from './components/advicerEditor';
import BodyMeasurementsModal from './components/bodyMeasurementsModal&';
import HistoryPaymentsModal from './components/historyPaymentsModal';
import SurveyModal from './components/surveyModal';
import UserCard from './components/userCard';
import styles from './styles.module.css';

const UsersView = (props) => {
  const { programList } = useProgramList();

  const getProgramList = (programId) => {
    if (!programList || !programList.length) return 'Cargando programa';
    const program = programList.find((currentProgram) => currentProgram.id === programId);
    return program ? program.title : 'Cargando';
  };

  return (
    <>
      {props.surveyActive && <SurveyModal userId={props.surveyActive} onClose={props.onSurveyClose} />}
      {props.edittingAdvicer && (
        <AdvicerEditor
          userId={props.edittingAdvicer}
          onClose={props.onEdittingAdvicerClose}
          onSuccess={props.onReloadUsers}
        />
      )}

      {props.historyPaymentsActive && (
        <HistoryPaymentsModal onClose={props.onHistoryPaymentClose} userId={props.historyPaymentsActive} />
      )}

      {props.bodyMeasurements && (
        <BodyMeasurementsModal userId={props.bodyMeasurements} onClose={props.onBodyMeasurementsClose} />
      )}

      <div className={styles.viewPort}>
        <HeaderForLists title="Usuarios" onChange={props.onKeywordChange} value={props.keyWords} />
        {props.loading && <Loading />}

        {!props.loading && !props.users.length && (
          <EmptyContent message={props.keyWords ? 'No encontramos resultados' : 'Aún no hay usuarios'} />
        )}

        {!props.loading && !!props.users.length && (
          <div className={styles.itemList}>
            {props.users.map((currentUser) => (
              <div className={styles.itemWrapper} key={currentUser.id}>
                <UserCard
                  picture={currentUser.picture}
                  advicer={currentUser.advicer}
                  name={currentUser.name}
                  email={currentUser.email}
                  password={currentUser.password}
                  options={props.getOptions(currentUser)}
                  programActive={currentUser.programActive}
                  surveyAnswered={currentUser.surveyAnswered}
                  currentWeek={getCurrentWeek(currentUser)}
                  program={getProgramList(currentUser.programId || 'main')}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

UsersView.defaultProps = {
  surveyActive: null,
  edittingAdvicer: null,
  historyPaymentsActive: null,
  bodyMeasurements: null,
};

UsersView.propTypes = {
  loading: bool.isRequired,
  users: array.isRequired,
  getOptions: func.isRequired,
  onKeywordChange: func.isRequired,
  keyWords: string.isRequired,
  surveyActive: bool,
  onSurveyClose: func.isRequired,
  edittingAdvicer: string,
  onEdittingAdvicerClose: func.isRequired,
  onReloadUsers: func.isRequired,
  onHistoryPaymentClose: func.isRequired,
  historyPaymentsActive: string,
  bodyMeasurements: string,
  onBodyMeasurementsClose: func.isRequired,
};

export default UsersView;
