import { useState } from 'react';
import { useParams } from 'react-router';
import purchaseModel from '../core/models/purchases';
import useAlert from '../modules/alert/useAlert';
import useFetch from './useFetch';

const usePurchaseDetail = () => {
  const setAlert = useAlert();
  const { id } = useParams();
  const [state, setState] = useState({
    loading: true,
    data: null,
  });

  const setComposeState = (partialState) => setState((prevState) => ({ ...prevState, ...partialState }));

  const handleFetch = async () => {
    const data = await purchaseModel.getSingle(id);
    setComposeState({ loading: false, data });
  };

  useFetch(handleFetch);

  const handleSetStatus = (newStatus) =>
    setAlert({
      title: '¿Seguro quieres cambiar el estado?',
      action: async () => {
        await purchaseModel.updateStatus(state.data.id, newStatus);
        await handleFetch();
      },
    });

  return {
    loading: state.loading,
    data: state.data,
    id,
    setStatus: handleSetStatus,
  };
};

export default usePurchaseDetail;
