import { string } from 'prop-types';
import React from 'react';
import Container from '../../../../components/container';
import styles from './styles.module.css';

const Title = ({ title }) => (
  <div className={styles.fullWrapper}>
    <Container extraSmall disabledVerticalPadding>
      <div className={styles.title}>{title}</div>
    </Container>
  </div>
);

Title.propTypes = {
  title: string.isRequired,
};

export default Title;
