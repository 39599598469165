import { Box, Grid } from '@material-ui/core';
import React from 'react';
import { HashLink } from 'react-router-hash-link';
import logo from 'src/assets/logo.svg';
import facebookSrc from 'src/assets/facebook.svg';
import instagramSrc from 'src/assets/instagram.svg';
import Container from '../container';
import styles from './styles.module.css';
import { contact } from '../../constants';

const Footer = () => (
  <div className={styles.fullWrapper}>
    <Container>
      <div className={styles.body}>
        <img src={logo} alt="logo poltri inspira" className={styles.logo} />

        <Grid container justify="center">
          <div className={styles.optionWrapper}>
            <HashLink className={styles.option} to="/programa-de-entrenamiento">
              PROGRAMAS
            </HashLink>
            <HashLink className={styles.option} to="/articulos">
              TIENDA
            </HashLink>
            <HashLink className={styles.option} to="/tips">
              TIPS
            </HashLink>
            <HashLink className={styles.option} to="/about" smooth>
              QUÉ ES FEMINNE LIFE
            </HashLink>
            <HashLink className={styles.option} to="/asesores">
              ASESORES
            </HashLink>
            <HashLink className={styles.option} to="/comunidad">
              COMUNIDAD
            </HashLink>
            <HashLink className={styles.option} to="/">
              CLÍNICAS Y CONFERENCIAS
            </HashLink>
          </div>
        </Grid>

        <Grid container className={styles.secondaryBodyWrapper}>
          <div className={styles.divided}>
            <div>{new Date().getFullYear()}, Derechos Reservados</div>
            <Box paddingRight="1em" />
            <HashLink to="/privacity">Aviso de privacidad</HashLink>
          </div>
          <div className={styles.divided}>
            <a href={contact.facebook} target="_blank" rel="noreferrer">
              <img className={styles.icon} src={facebookSrc} alt="icon" />
            </a>
            <a href={contact.instagram} target="_blank" rel="noreferrer">
              <img className={styles.icon} src={instagramSrc} alt="icon" />
            </a>
            <Box padding="0em 1em">
              <div className={styles.divider} />
            </Box>
            <a href={`mailto:${contact.email}`} className={styles.email}>
              {contact.email}
            </a>
          </div>
        </Grid>
      </div>
    </Container>
  </div>
);

export default Footer;
