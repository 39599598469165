// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_wrapper__skK-w {\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  min-height: 30em;\r\n  flex-direction: column;\r\n  font-size: 1.2em;\r\n  width: 70%;\r\n  margin: auto;\r\n  text-align: center;\r\n  line-height: 1.4em;\r\n  color: var(--cyan);\r\n}\r\n\r\n.styles_wrapper__skK-w svg {\r\n  margin-bottom: 1em;\r\n  font-size: 3em;\r\n  color: #00eae25e;\r\n}\r\n\r\n@media screen and (max-width:1000px) {\r\n  .styles_wrapper__skK-w {\r\n    width: 80%;\r\n  }\r\n}\r\n\r\n@media screen and (max-width:600px) {\r\n  .styles_wrapper__skK-w {\r\n    width: 90%;\r\n  }\r\n}", "",{"version":3,"sources":["webpack://src/components/emptyContent/styles.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;EAChB,sBAAsB;EACtB,gBAAgB;EAChB,UAAU;EACV,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE;IACE,UAAU;EACZ;AACF;;AAEA;EACE;IACE,UAAU;EACZ;AACF","sourcesContent":[".wrapper {\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  min-height: 30em;\r\n  flex-direction: column;\r\n  font-size: 1.2em;\r\n  width: 70%;\r\n  margin: auto;\r\n  text-align: center;\r\n  line-height: 1.4em;\r\n  color: var(--cyan);\r\n}\r\n\r\n.wrapper svg {\r\n  margin-bottom: 1em;\r\n  font-size: 3em;\r\n  color: #00eae25e;\r\n}\r\n\r\n@media screen and (max-width:1000px) {\r\n  .wrapper {\r\n    width: 80%;\r\n  }\r\n}\r\n\r\n@media screen and (max-width:600px) {\r\n  .wrapper {\r\n    width: 90%;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "styles_wrapper__skK-w"
};
export default ___CSS_LOADER_EXPORT___;
