import { firebase, firestore } from 'src/core/firebase';
import database from '../database';
import snapShotParser from '../snapshotParser';
import { uploadPicture } from '../storage';

export const getMemebers = async () => {
  const users = await database.getList('users', null, null, [['type', '==', 'programUser']]).next();
  const advicers = await database.getList('users', null, null, [['type', '==', 'advicer']]).next();
  return [...users, ...advicers];
};

export const newMessage = async (userId, content, profilePicture, name) => {
  const messageSchema = {
    userId,
    date: new Date(),
    picture: profilePicture,
    name,
  };
  if (content instanceof File) {
    const url = await uploadPicture(content, `group-chat`);
    messageSchema.image = url;
  } else {
    messageSchema.message = content;
  }

  await database.update('groupChat/main', {
    messages: firebase.firestore.FieldValue.arrayUnion(messageSchema),
  });
};

export const onMessagesChange = (userId, handler) => {
  const unsubscribe = firestore.doc('groupChat/main').onSnapshot((snap) => {
    try {
      const data = snapShotParser(snap);
      const messagesMaped = data.messages.map((message) => {
        const current = { ...message };
        current.isOwned = userId === current.userId;
        return current;
      });
      handler(messagesMaped);
    } catch (error) {
      handler([]);
    }
  });
  return unsubscribe;
};

export default {
  getMemebers,
  newMessage,
  onMessagesChange,
};
