// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_button__-xoC2 {\r\n  border: 1px solid #cdcdcd!important;\r\n  padding: 2em 1.5em!important;\r\n  color: gray!important;\r\n  font-weight: 600;\r\n  min-width: 15em;\r\n  box-sizing: border-box;\r\n}\r\n\r\n.styles_filled__3yqCx {\r\n  background-color: var(--rose)!important;\r\n  border: none!important;\r\n  color: #fff!important;\r\n}\r\n\r\n@media screen and (max-width:600px) {\r\n  .styles_button__-xoC2 {\r\n    font-size: .7em!important;\r\n    padding-top: 1.5em!important;\r\n    padding-bottom: 1.5em!important;\r\n  }\r\n}", "",{"version":3,"sources":["webpack://src/components/alternativeButton/styles.module.css"],"names":[],"mappings":"AAAA;EACE,mCAAmC;EACnC,4BAA4B;EAC5B,qBAAqB;EACrB,gBAAgB;EAChB,eAAe;EACf,sBAAsB;AACxB;;AAEA;EACE,uCAAuC;EACvC,sBAAsB;EACtB,qBAAqB;AACvB;;AAEA;EACE;IACE,yBAAyB;IACzB,4BAA4B;IAC5B,+BAA+B;EACjC;AACF","sourcesContent":[".button {\r\n  border: 1px solid #cdcdcd!important;\r\n  padding: 2em 1.5em!important;\r\n  color: gray!important;\r\n  font-weight: 600;\r\n  min-width: 15em;\r\n  box-sizing: border-box;\r\n}\r\n\r\n.filled {\r\n  background-color: var(--rose)!important;\r\n  border: none!important;\r\n  color: #fff!important;\r\n}\r\n\r\n@media screen and (max-width:600px) {\r\n  .button {\r\n    font-size: .7em!important;\r\n    padding-top: 1.5em!important;\r\n    padding-bottom: 1.5em!important;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "styles_button__-xoC2",
	"filled": "styles_filled__3yqCx"
};
export default ___CSS_LOADER_EXPORT___;
