import { element } from 'prop-types';
import React, { useState } from 'react';
import localCartModel from 'src/helpers/cart';
import context from './context';

const CartProvider = ({ children }) => {
  const [items, setItems] = useState(localCartModel.getItems());

  const handleSetItems = (value) => {
    localCartModel.setItems(value);
    setItems(value);
  };

  const handleRemoveProduct = (productId) => {
    const itemsFiltered = items.filter((item) => item.id !== productId);
    handleSetItems(itemsFiltered);
  };

  const handleUpdateQuantity = (productId, newQuantity) => {
    if (newQuantity === 0) return handleRemoveProduct(productId);
    const newItems = items.map((item) => {
      if (item.id === productId) return { ...item, quantity: newQuantity };
      return item;
    });
    return handleSetItems(newItems);
  };

  const handleAddToCart = (product, quantity) => {
    const prevSameItem = items.find((item) => item.id === product.id);
    if (prevSameItem) {
      const newQuantity = prevSameItem.quantity + quantity;
      handleUpdateQuantity(product.id, newQuantity);
    } else {
      const newItems = [...items, { ...localCartModel.formatProduct(product), quantity }];
      handleSetItems(newItems);
    }
  };

  const handleEmptyCart = () => handleSetItems([]);

  const methods = {
    addToCart: handleAddToCart,
    removeProductFromCart: handleRemoveProduct,
    updateQuantityProductOnCart: handleUpdateQuantity,
    emptyCart: handleEmptyCart,
  };

  const itemsOnCart = items;

  return <context.Provider value={{ methods, itemsOnCart }}>{children}</context.Provider>;
};

CartProvider.propTypes = {
  children: element.isRequired,
};

export default CartProvider;
