/* eslint-disable react/prop-types */
/* eslint-disable arrow-body-style */
import { oneOf } from 'prop-types';
import React from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { useSession } from 'src/modules/session';

const userTypes = ['admin', 'advicer', 'user'];

const AuthRoute = ({ userAuthorized, ...props }) => {
  const { data } = useSession();
  const { pathname } = useLocation();

  if (!data) return <Route component={() => <Redirect to="/auth/signIn" />} />;
  if (!userTypes.includes(userAuthorized)) return <Route component={() => <Redirect to="/error404" />} />;

  if (userAuthorized === 'admin' && data.type === 'admin') return <Route {...props} />;
  if (userAuthorized === 'advicer' && data.type === 'advicer') return <Route {...props} />;

  // handle user autorized
  if (userAuthorized === 'user' && data.type === 'programUser' && !data.surveyAnswered && pathname === '/auth/survey')
    return <Route {...props} />;
  if (userAuthorized === 'user' && data.type === 'programUser' && data.surveyAnswered && pathname !== '/auth/survey')
    return <Route {...props} />;

  // handle user not autorized
  if (userAuthorized === 'user' && data.type === 'programUser' && !data.surveyAnswered && pathname !== '/auth/survey')
    return <Route component={() => <Redirect to="/auth/survey" />} />;
  if (userAuthorized === 'user' && data.type === 'programUser' && data.surveyAnswered && pathname === '/auth/survey')
    return <Route component={() => <Redirect to="/private/home" />} />;

  return <Route component={() => <Redirect to="/" />} />;
};

AuthRoute.propTypes = {
  userAuthorized: oneOf(['admin', 'advicer', 'user']).isRequired,
};

export default AuthRoute;
