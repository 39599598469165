/* eslint-disable no-unused-vars */
import { Box, Checkbox } from '@material-ui/core';
import { string } from 'prop-types';
import React, { useState } from 'react';
import { getActiveImplement, getInfo, showAtTheStore } from 'src/core/models/program';
import useFetch from 'src/hooks/useFetch';
import useNotification from 'src/modules/notifications/useNotification';

const VisivilityEditor = ({ programId }) => {
  const [active, setActive] = useState(false);
  const setNotification = useNotification();

  const handleFetch = async () => {
    const { showAtStore = false } = await getInfo(programId);
    setActive(showAtStore);
  };

  const handleChange = async (event) => {
    setActive(event.target.checked);

    const activeImplement = await getActiveImplement(programId);
    if (!activeImplement && !!event.target.checked) {
      handleFetch();
      return setNotification({
        status: 'error',
        message: 'Error, el programa no se puede mostrar en la tienda porque no hay ninguna implementación activa',
      });
    }

    const response = await showAtTheStore(programId, event.target.checked);
    setNotification({
      status: 'success',
      message: event.target.checked
        ? 'Programa actualizado, ahora es visible en la tienda'
        : 'El programa se ha ocultado de la tienda',
    });
    return handleFetch();
  };

  useFetch(handleFetch, []);

  return (
    <Box display="flex" alignItems="center" marginBottom="1em">
      <Checkbox onChange={handleChange} checked={active} />
      <Box>Mostrar en la tienda</Box>
    </Box>
  );
};

VisivilityEditor.propTypes = {
  programId: string.isRequired,
};

export default VisivilityEditor;
