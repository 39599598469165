import React from 'react';
import Loading from 'src/components/loading';
import EmptyContent from 'src/components/emptyContent';
import useTestimonialLits from 'src/hooks/useTestimonialList';
import { MoreVert } from '@material-ui/icons';
import HeaderForLists from '../../components/headerForLists';
import styles from './styles.module.css';
import Options from '../../../../components/options';
import Picture from '../../../../components/picture';
import limitText from '../../../../helpers/limitText';

const Testimonials = () => {
  const { loading, items, keywords, setKeywords, getOptions } = useTestimonialLits();

  return (
    <div className={styles.wrapperMaster}>
      <HeaderForLists
        title="Testimonios"
        buttonRedirect="/dashboard/testimonials/editor"
        value={keywords}
        onChange={setKeywords}
      />
      {loading && <Loading />}
      {!loading && !!items.length && (
        <div className={styles.productList}>
          {items.map((currentItem) => (
            <div className={styles.itemWrapper} key={currentItem.id}>
              <div className={styles.item}>
                <Options className={styles.options} optionsList={getOptions(currentItem)}>
                  <MoreVert />
                </Options>
                <Picture className={styles.picture} src={currentItem.picture} alt={currentItem.title} height={80} />
                <div className={styles.dataWrapper}>
                  <div className={styles.title}>{currentItem.title || currentItem.name}</div>
                  <p className={styles.description}>{limitText(currentItem.description, 150)}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      {!loading && !items.length && (
        <EmptyContent message={keywords ? 'No encontramos resultados' : 'Aún no hay testimonios'} />
      )}
    </div>
  );
};

export default Testimonials;
