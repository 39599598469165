import React, { useState } from 'react';
import Loading from 'src/components/loading';
import EmptyContent from 'src/components/emptyContent';
import product from 'src/core/models/product';
import useFetch from 'src/hooks/useFetch';
import { useHistory } from 'react-router';
import useAlert from 'src/modules/alert/useAlert';
import useNotification from 'src/modules/notifications/useNotification';
import List from './components/productLis';
import styles from './styles.module.css';
import HeaderForLists from '../../components/headerForLists';

const ProductList = () => {
  const setAlert = useAlert();
  const setNotification = useNotification();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [keywords, setKeywords] = useState('');

  // aply filters
  let filteredItems = products;
  if (keywords) {
    const keywordsNormalized = keywords.toString().toLowerCase();
    filteredItems = filteredItems.filter((item) => {
      const titleNormalized = item.title.toString().toLowerCase();
      return titleNormalized.includes(keywordsNormalized);
    });
  }

  const handleFetchData = async () => {
    const response = await product.getAll();
    setProducts(response);
    setLoading(false);
  };

  useFetch(handleFetchData);

  const getOptions = (item) => [
    {
      label: 'Ver detalles',
      handler: () => history.push({ pathname: `/articulo/${item.id}` }),
    },
    {
      label: 'Editar',
      handler: () =>
        history.push({
          pathname: item.category !== 'sportsPrograms' ? '/dashboard/create-product' : '/dashboard/programs/editor',
          state: item,
        }),
    },
    {
      label: 'Eliminar',
      handler: () => {
        setAlert({
          title: '¿Seguro quieres eliminar esta publicación?',
          message: 'Despues de ejecutar esta acción no podras recuperar la información',
          action: async () => {
            const response = await product.deleteProduct(item.id);
            await handleFetchData();
            setNotification(response);
          },
        });
      },
    },
  ];

  return (
    <div className={styles.wrapperMaster}>
      <HeaderForLists
        title="Productos"
        buttonRedirect="/dashboard/create-product"
        onChange={setKeywords}
        value={keywords}
      />
      {loading && <Loading />}
      {!loading && !!filteredItems.length && <List items={filteredItems} getOptions={getOptions} />}
      {!loading && !filteredItems.length && (
        <EmptyContent message={keywords ? 'No encontramos resultados' : 'Aún no tienes publicaciones'} />
      )}
    </div>
  );
};

export default ProductList;
