import { useMediaQuery } from '@material-ui/core';
import React from 'react';
import picture from 'src/assets/fullmodules.png';
import picturem from 'src/assets/fullmodulesm.png';
import Container from '../../../../components/container';
import styles from './styles.module.css';

const Modules = () => {
  const isMobile = useMediaQuery('(max-width:700px)');

  return (
    <div id="duration">
      <Container disabledVerticalPadding>
        <div className={styles.body}>
          <div className={styles.title}>DIVIDIDO EN 6 MODULOS</div>
          <img src={!isMobile ? picture : picturem} alt="fullmodules" className={styles.picture} />
        </div>
      </Container>
    </div>
  );
};
export default Modules;
