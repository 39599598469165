/* eslint-disable no-unused-vars */
import { Box } from '@material-ui/core';
import { Email, Lock, MoreVert, Menu, Apps } from '@material-ui/icons';
import { array, func } from 'prop-types';
import React from 'react';
import Picture from 'src/components/picture';
import Options from '../../../../../../components/options';
import composeClassNames from '../../../../../../helpers/composeClassNames';
import useMemoizedState from '../../../../../../hooks/useMemoizedState';
import styles from './styles.module.css';

const AdvicerListBody = ({ items, getOptions }) => {
  const [viewType, setViewType] = useMemoizedState('list', 'advicers_view_type');

  return (
    <>
      <Box display="flex" alignItems="center" marginTop="1em">
        <Apps
          className={composeClassNames(styles.iconsControl, viewType === 'grid' ? styles.iconsControlSelected : '')}
          onClick={() => setViewType('grid')}
        />
        <Box paddingRight="1em" />
        <Menu
          className={composeClassNames(styles.iconsControl, viewType === 'list' ? styles.iconsControlSelected : '')}
          onClick={() => setViewType('list')}
        />
      </Box>
      {viewType === 'grid' && (
        <div className={styles.productList}>
          {items.map((currentItem) => (
            <div className={styles.itemWrapper} key={currentItem.id}>
              <div className={styles.item}>
                <Options className={styles.options} optionsList={getOptions(currentItem)}>
                  <MoreVert />
                </Options>
                <Picture className={styles.picture} src={currentItem.picture} alt={currentItem.title} height={80} />
                <div className={styles.dataWrapper}>
                  <div className={styles.userCounter} style={{ textAlign: 'center' }}>
                    Usuarios: {currentItem.usersNumber}
                  </div>
                  <div className={styles.title}>{currentItem.title || currentItem.name}</div>
                  <div className={styles.info}>
                    <Email />
                    {currentItem.email}
                  </div>
                  <div className={styles.info}>
                    <Lock />
                    {currentItem.password}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      {viewType === 'list' && (
        <div className={styles.listWrapper}>
          <div
            className={composeClassNames(styles.userRow, styles.hideMobile)}
            style={{ borderBottom: 'none', fontWeight: 'bold', marginBottom: '.5em' }}
          >
            <div className={styles.rowPicture} style={{ height: 'auto' }} />
            <div className={styles.nameWrapper}>Nombre</div>
            <div className={styles.emailWrapper}>Correo</div>
            <div className={styles.passWrapper}>Contraseña</div>
            <div className={styles.row_options_wrapper} />
          </div>
          <div style={{ background: '#fff' }}>
            {items.map((currentItem) => (
              <div className={styles.userRow}>
                <img className={styles.rowPicture} src={currentItem.picture} alt="user profile" />
                <div className={styles.nameWrapper}>
                  <div>{currentItem.name}</div>
                  <div className={styles.onlymobile}>{currentItem.email}</div>
                  <div className={styles.onlymobile}>{currentItem.password}</div>
                  <div className={styles.userCounter}>Usuarios: {currentItem.usersNumber}</div>
                </div>
                <div className={styles.emailWrapper}>{currentItem.email}</div>
                <div className={styles.passWrapper}>{currentItem.password}</div>
                <div className={styles.row_options_wrapper}>
                  <Options className={styles.row_options} optionsList={getOptions(currentItem)}>
                    <MoreVert />
                  </Options>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

AdvicerListBody.propTypes = {
  items: array.isRequired,
  getOptions: func.isRequired,
};

export default AdvicerListBody;
