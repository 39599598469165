import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import events, { getEventsList } from '../core/models/events';
import useAlert from '../modules/alert/useAlert';
import useNotification from '../modules/notifications/useNotification';
import useFetch from './useFetch';

/**
 * model for list events
 */
const useEventsList = () => {
  const [state, setState] = useState({
    events: [],
    loading: true,
    keywords: '',
  });

  // aply filters
  let filteredItems = state.events;
  if (state.keywords) {
    const keywordsNormalized = state.keywords.toString().toLowerCase();
    filteredItems = filteredItems.filter((item) => {
      const titleNormalized = item.title.toString().toLowerCase();
      return titleNormalized.includes(keywordsNormalized);
    });
  }

  const history = useHistory();
  const setNotifications = useNotification();
  const setAlert = useAlert();

  const handleFetch = async () => {
    const eventsList = await getEventsList();
    setState((prev) => ({ ...prev, events: eventsList, loading: false }));
  };

  useFetch(handleFetch);

  // M E T H O D S
  const handleGetOptions = (item) => [
    {
      label: 'Editar',
      handler: () => history.push({ pathname: '/dashboard/create-event', state: item }),
    },
    {
      label: 'Eliminar',
      handler: () =>
        setAlert({
          title: '¿Seguro quieres eliminar este evento?',
          action: async () => {
            const response = await events.delete(item);
            await handleFetch();
            setNotifications(response);
          },
        }),
    },
  ];

  const handleSetKeywords = (value) =>
    setState((prevState) => ({
      ...prevState,
      keywords: value,
    }));

  return {
    events: filteredItems,
    loading: state.loading,
    getOptions: handleGetOptions,
    keywords: state.keywords,
    setKeyword: handleSetKeywords,
  };
};

export default useEventsList;
