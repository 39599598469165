import { Box, ButtonBase, createMuiTheme, Grid, ThemeProvider } from '@material-ui/core';
import { bool, func, object } from 'prop-types';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Input from '../../../../../../components/inputs';
import useForm from '../../../../../../hooks/useForm';
import { useSession } from '../../../../../../modules/session';
import questionsConstants from '../../questionsConstants';
import CheckBox from '../checkbox';
import RadioGroup from '../radioGroup';
import styles from './styles.module.css';

const theme = createMuiTheme({
  spacing: (value) => `${value}em`,
});

const Form = (props) => {
  const { getInputProps, values, setValues } = useForm();
  const session = useSession();

  // fill form by props
  useEffect(() => {
    setValues({ ...props.values });
  }, [props.values]);

  return (
    <ThemeProvider theme={theme}>
      <div className={styles.fullWidthWrapper}>
        <div className={styles.card}>
          <div className={styles.cardHeader}>HISTORIAL DEPORTIVO</div>
          <div className={styles.cardBody}>
            <Grid container spacing={2}>
              {/* ROGHT */}
              <Grid xs={12} sm={6} item>
                <div className={styles.questionGroup}>
                  <div className={styles.question}>¿Cuál es tu objetivo de hacer ejercicio?</div>
                  <Input
                    disabled={props.disableEditing}
                    type="select"
                    options={questionsConstants.objetveToDoExercise}
                    placeholder="Seleccionar"
                    {...getInputProps('objetveToDoExercise')}
                  />
                </div>
                <div className={styles.questionGroup}>
                  <div className={styles.question}>¿Alguna vez practicaste ejercicio o deporte?</div>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item>
                      <RadioGroup
                        disabled={props.disableEditing}
                        options={questionsConstants.binaryOptiopns}
                        {...getInputProps('haveEverDoExercise')}
                      />
                    </Grid>
                    <Grid item xs>
                      <Input
                        disabled={values.haveEverDoExercise !== 'yes' || props.disableEditing}
                        type="select"
                        options={questionsConstants.exerciceDid}
                        placeholder="Seleccionar"
                        {...getInputProps('exerciseDid')}
                      />
                    </Grid>
                  </Grid>
                </div>
                <div className={styles.questionGroup}>
                  <div className={styles.question}>Si tu elección fue “Otro” por favor dinos cuál</div>
                  <Input {...getInputProps('otherTypeExerciseDid')} disabled={props.disableEditing} />
                </div>
              </Grid>

              {/* LEFT */}
              <Grid xs={12} sm={6} item>
                <div className={styles.questionGroup}>
                  <div className={styles.question}>¿Anteriormente intentaste hacer ejercicio?</div>
                  <RadioGroup
                    options={questionsConstants.binaryOptiopns}
                    {...getInputProps('haveTryDoExercise')}
                    disabled={props.disableEditing}
                  />
                </div>
                <div className={styles.questionGroup}>
                  <div className={styles.question}>¿Cuando intentas hacer ejercicio que te ha hecho desistir?</div>
                  <Input
                    type="select"
                    options={questionsConstants.makeDesist}
                    placeholder="Seleccionar"
                    {...getInputProps('makeDesist')}
                    disabled={props.disableEditing}
                  />
                </div>
                <div className={styles.questionGroup}>
                  <div className={styles.question}>
                    ¿Estas dispuesto a invertir 80 minutos diarios durante seis días de la semana ?
                  </div>
                  <RadioGroup
                    options={questionsConstants.binaryOptiopns}
                    {...getInputProps('willingSpendTimeForExercise')}
                    disabled={props.disableEditing}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </div>

        <div className={styles.card}>
          <div className={styles.cardHeader}>SITUACIÓN LABORAL</div>
          <div className={styles.cardBody}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={4}>
                <div className={styles.questionGroup}>
                  <div className={styles.question}>¿Cómo es tu trabajo físicamente? </div>
                  <RadioGroup
                    options={questionsConstants.workType}
                    {...getInputProps('workType')}
                    disabled={props.disableEditing}
                  />
                </div>
                <div className={styles.questionGroup}>
                  <div className={styles.question}>¿Cuántos días a la semana laboras?</div>
                  <Input
                    type="select"
                    options={questionsConstants.numberDaysWorking}
                    {...getInputProps('numberDaysWorking')}
                    disabled={props.disableEditing}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div className={styles.questionGroup}>
                  <div className={styles.question}>
                    ¿Cuantas horas al día trabajas?
                    <br />
                    <span style={{ color: '#fff' }}>h</span>
                  </div>
                  <Input
                    type="select"
                    options={questionsConstants.workDuration}
                    {...getInputProps('workDuration')}
                    disabled={props.disableEditing}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div className={styles.questionGroup}>
                  <div className={styles.question}>¿Cuantas horas al día permaneces sentado?</div>
                  <Input
                    type="select"
                    options={questionsConstants.workDurationSitting}
                    {...getInputProps('workDurationSitting')}
                    disabled={props.disableEditing}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </div>

        <div className={styles.card}>
          <div className={styles.cardHeader}>ALIMENTACIÓN</div>
          <div className={styles.cardBody}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={4}>
                <div className={styles.questionGroup}>
                  <div className={styles.question}>¿Cuántas comidas haces al día? </div>
                  <Input
                    type="select"
                    options={questionsConstants.numberOfMeals}
                    {...getInputProps('numberOfMeals')}
                    disabled={props.disableEditing}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div className={styles.questionGroup}>
                  <div className={styles.question}>¿Cuántas colaciones tienes al día?</div>
                  <Input
                    type="select"
                    options={questionsConstants.numberOfSnacks}
                    {...getInputProps('numberOfSnacks')}
                    disabled={props.disableEditing}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div className={styles.questionGroup}>
                  <div className={styles.question}>¿Cuántos días comes en casa ?</div>
                  <Input
                    type="select"
                    options={questionsConstants.numberOfDaysEatingAtHome}
                    {...getInputProps('numberOfDaysEatingAtHome')}
                    disabled={props.disableEditing}
                  />
                </div>
              </Grid>
            </Grid>

            <div className={styles.questionGroup}>
              <div className={styles.question}>¿De los siguientes alimentos cuales consumes regularmente al día?</div>
              <Box mt="1em">
                <Grid container spacing={1}>
                  <CheckBox {...getInputProps('Refrescos')} label="Refrescos" disabled={props.disableEditing} />
                  <CheckBox
                    {...getInputProps('ComidaProcesada')}
                    label="Comida procesada"
                    disabled={props.disableEditing}
                  />
                  <CheckBox {...getInputProps('Embutidos')} label="Embutidos" disabled={props.disableEditing} />
                  <CheckBox {...getInputProps('Lacteos')} label="Lacteos" disabled={props.disableEditing} />
                  <CheckBox {...getInputProps('Azucares')} label="Azucares" disabled={props.disableEditing} />
                  <CheckBox {...getInputProps('Fruta')} label="Fruta" disabled={props.disableEditing} />
                  <CheckBox {...getInputProps('Harinas')} label="Harinas" disabled={props.disableEditing} />
                  <CheckBox {...getInputProps('Verduras')} label="Verduras" disabled={props.disableEditing} />
                  <CheckBox {...getInputProps('Tortillas')} label="Tortillas" disabled={props.disableEditing} />
                  <CheckBox {...getInputProps('Proteina')} label="Proteina" disabled={props.disableEditing} />
                  <CheckBox {...getInputProps('Cereales')} label="Cereales" disabled={props.disableEditing} />
                  <CheckBox {...getInputProps('Grasas')} label="Grasas" disabled={props.disableEditing} />
                  <CheckBox {...getInputProps('Agua')} label="Agua" disabled={props.disableEditing} />
                </Grid>
              </Box>
            </div>

            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <div className={styles.questionGroup}>
                  <div className={styles.question}>¿Sufres de algun padecimiento en tu digestión?</div>
                  <Input
                    type="select"
                    options={questionsConstants.digestionDisorder}
                    {...getInputProps('digestionDisorder')}
                    disabled={props.disableEditing}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className={styles.questionGroup}>
                  <div className={styles.question}>Si tu elección fue “Otro” por favor dinos cuál</div>
                  <Input {...getInputProps('otherDigestionDisorder')} disabled={props.disableEditing} />
                </div>
              </Grid>
            </Grid>
          </div>
        </div>

        <div className={styles.card}>
          <div className={styles.cardHeader}>SUEÑO</div>
          <div className={styles.cardBody}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={4}>
                <div className={styles.questionGroup}>
                  <div className={styles.question}>
                    ¿Cuántas horas duermes?
                    <br />
                    <span style={{ color: '#fff' }}>h</span>
                  </div>
                  <Input
                    type="select"
                    options={questionsConstants.NumberHoursSleeping}
                    {...getInputProps('numberHoursSleeping')}
                    disabled={props.disableEditing}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div className={styles.questionGroup}>
                  <div className={styles.question}>
                    Sufres de insomio
                    <br />
                    <span style={{ color: '#fff' }}>h</span>
                  </div>
                  <Input
                    type="select"
                    options={questionsConstants.sufferInsomnia}
                    {...getInputProps('sufferInsomnia')}
                    disabled={props.disableEditing}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div className={styles.questionGroup}>
                  <div className={styles.question}>¿Despiertas varias veces durante el sueño?</div>
                  <RadioGroup
                    options={questionsConstants.binaryOptiopns}
                    {...getInputProps('wakeUpDuringSleepinig')}
                    disabled={props.disableEditing}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </div>

        <div className={styles.card}>
          <div className={styles.cardHeader}>EMOCIONAL</div>
          <div className={styles.cardBody}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={4}>
                <div className={styles.questionGroup}>
                  <div className={styles.question}>¿Te consideras disciplinado?</div>
                  <RadioGroup
                    options={questionsConstants.binaryOptiopns}
                    {...getInputProps('yourselfDisciplined')}
                    disabled={props.disableEditing}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div className={styles.questionGroup}>
                  <div className={styles.question}>¿Te gustan los retos?</div>
                  <RadioGroup
                    options={questionsConstants.binaryOptiopns}
                    {...getInputProps('likeChallenges')}
                    disabled={props.disableEditing}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div className={styles.questionGroup}>
                  <div className={styles.question}>¿Terminas lo que empiezas?</div>
                  <RadioGroup
                    options={questionsConstants.binaryOptiopns}
                    {...getInputProps('finishWhatStart')}
                    disabled={props.disableEditing}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div className={styles.questionGroup}>
                  <div className={styles.question}>Para ti ¿Quién puede hacer ejercicio?</div>
                  <Input
                    type="select"
                    options={questionsConstants.whoCanDoExercise}
                    {...getInputProps('whoeCanDoExercise')}
                    disabled={props.disableEditing}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div className={styles.questionGroup}>
                  <div className={styles.question}>
                    ¿Qué no te gusta del ejercicio? <br />
                  </div>

                  <Input
                    type="select"
                    options={questionsConstants.whatUnlinkeDoExersice}
                    {...getInputProps('whatUnlinkeDoExersice')}
                    disabled={props.disableEditing}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div className={styles.questionGroup}>
                  <div className={styles.question}>¿Te llama la atención algún deporte?</div>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item>
                      <RadioGroup
                        options={questionsConstants.binaryOptiopns}
                        {...getInputProps('sportInterest')}
                        disabled={props.disableEditing}
                      />
                    </Grid>
                    <Grid item xs>
                      <Input
                        placeholder="¿Cual?"
                        {...getInputProps('nameSportInterest')}
                        disabled={values.sportInterest !== 'yes' || props.disableEditing}
                      />
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div className={styles.questionGroup}>
                  <div className={styles.question}>¿Cuál es tu objetivo al finalizar?</div>
                  <Input
                    type="select"
                    options={questionsConstants.objetiveAtEndRutine}
                    {...getInputProps('objetiveAtEndRutine')}
                    disabled={props.disableEditing}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </div>

        <div className={styles.card}>
          <div className={styles.cardBody}>
            <div className={styles.question}>¿Cuál es tu meta deportiva al final del curso?</div>
            <Input type="textarea" {...getInputProps('objetiveAtEdnOfTraining')} disabled={props.disableEditing} />
          </div>
        </div>
      </div>

      <Grid container justify="flex-end">
        <Link to={!session.data || session.data.type !== 'admin' ? '/dashboard/advicer-users' : '/dashboard/users'}>
          <ButtonBase className={styles.buttonBack}>{props.disableEditing ? 'VOLVER' : 'CANCELAR'}</ButtonBase>
        </Link>
        {!props.disableEditing && (
          <>
            <Box paddingRight="1em" />
            <ButtonBase className={styles.button} onClick={() => props.onSave(values)}>
              TERMINAR
            </ButtonBase>
          </>
        )}
      </Grid>
    </ThemeProvider>
  );
};

Form.propTypes = {
  onSave: func.isRequired, // ejecute when click save button
  values: object.isRequired, // initial values for form
  disableEditing: bool.isRequired,
};

export default Form;
