import { bool, func, string } from 'prop-types';
import React from 'react';
import Loading from '../../../../components/loading';
import Form from './components/form';
import styles from './styles.module.css';

const ViewTipsEditor = (props) => (
  <section className={styles.card}>
    {!props.loading && (
      <Form getInputProps={props.getInputProps} onSave={props.onSave} errorMessage={props.errorMessage} />
    )}
    {props.loading && <Loading message="Guardando publicación" />}
  </section>
);

ViewTipsEditor.defaultProps = {
  errorMessage: '',
};

ViewTipsEditor.propTypes = {
  getInputProps: func.isRequired,
  onSave: func.isRequired,
  errorMessage: string,
  loading: bool.isRequired,
};

export default ViewTipsEditor;
