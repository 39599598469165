import { useState } from 'react';
import validaotors from 'src/helpers/getValidators';
import contactModel from 'src/core/models/contact';
import useErrorMessage from './useErrorMessage';
import useForm from './useForm';

/**
 * hook for create new message
 * inputs names: name, email, phone, message
 */
const rules = {
  name: validaotors.input('El nombre es requerido'),
  email: validaotors.email(),
  phone: validaotors.phone(),
  message: validaotors.input('El mensaje es requerido'),
};

const useNewMessage = () => {
  const [loading, setLoading] = useState(false);
  const { getInputProps, validateInputs, values } = useForm({}, rules);
  const { errorMessage, setErrorMessage } = useErrorMessage();

  const handleValidateForm = () => {
    const { firstErrorMessage } = validateInputs();
    if (!firstErrorMessage) return true;
    setErrorMessage(firstErrorMessage);
    return false;
  };

  /**
   * send message
   * @returns {Promise<Boolean>} operation status
   */
  const handleSend = async (event) => {
    if (event && event.preventDefault) event.preventDefault();
    if (!handleValidateForm()) return null;
    setLoading(true);
    const response = await contactModel.create(values);
    setLoading(false);
    if (response.success) return true;
    setErrorMessage(response.message);
    return false;
  };

  return {
    getInputProps,
    loading,
    send: handleSend,
    errorMessage,
  };
};

export default useNewMessage;
