import { ButtonBase } from '@material-ui/core';
import { element, oneOfType, string } from 'prop-types';
import React from 'react';
import { colors } from '../../constants';
import styles from './styles.module.css';

const Button = ({ className, fontWeight, width, padding, color, fontSize, ...props }) => {
  const inlineStyle = {
    width,
    padding,
    color,
    fontSize,
    fontWeight,
  };

  return (
    <ButtonBase style={inlineStyle} className={`${styles.button} ${className}`} {...props}>
      {props.children}
    </ButtonBase>
  );
};

Button.defaultProps = {
  width: 'auto',
  padding: '1em',
  color: colors.cyan,
  fontSize: '1em',
  fontWeight: 'regular',
  className: '',
};

Button.propTypes = {
  fontWeight: string,
  width: string,
  padding: string,
  color: string,
  fontSize: string,
  children: oneOfType([string, element]).isRequired,
  className: string,
};

export default Button;
