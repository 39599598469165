import React, { useState } from 'react';
import Card from 'src/components/card';
import Loading from 'src/components/loading';
import programUserModel, { updateProgramActivatedDate } from 'src/core/models/programUsers';
import useFetch from 'src/hooks/useFetch';
import Picture from 'src/components/picture';
import userPictureSrc from 'src/assets/user.png';
import { useHistory, useParams } from 'react-router';
import { Box, Grid } from '@material-ui/core';
import { Cake, DirectionsRun, Edit, Email, Lock, Phone, Wc } from '@material-ui/icons';
import { getInfo } from 'src/core/models/program';
import styles from './styles.module.css';
import { gendersBin } from '../../../../constants';
import ButtonAdmin from '../../components/button';
import Input from '../../../../components/inputs';
import { getCurrentWeek, getProgramStartDateByWeek } from '../../../../helpers/training';
import useForm from '../../../../hooks/useForm';
import useNotification from '../../../../modules/notifications/useNotification';
import useErrorMessage from '../../../../hooks/useErrorMessage';
import ErrorMessage from '../../../../components/errorMessage';
import { useSession } from '../../../../modules/session';

const UserDetail = () => {
  const session = useSession();
  const hostory = useHistory();
  const { userId } = useParams();
  const [userdata, setUserdata] = useState({});
  const [loading, setLoading] = useState(true);
  const [isWeekEditorOpen, setWeekEditorOpen] = useState(false);
  const { getInputProps, values, setValues, validateInputs } = useForm();
  const setNotification = useNotification();
  const { errorMessage, setErrorMessage } = useErrorMessage();

  const handleFetch = async () => {
    const data = await programUserModel.getSingle(userId);
    const programInfo = await getInfo(data.programId || 'main');
    setUserdata({ ...data, programTitle: programInfo.title });
    setLoading(false);
    setValues((prev) => ({ ...prev, week: getCurrentWeek(data) }));
  };

  useFetch(handleFetch);

  const handleWeekEditorCancel = () => {
    setWeekEditorOpen(false);
    setValues((prev) => ({ ...prev, week: getCurrentWeek(userdata) }));
  };

  const handleWeekUpdate = async () => {
    const { firstErrorMessage } = validateInputs({
      week: (value) => {
        const weekValueParsed = Number.parseInt(value, 10);
        if (weekValueParsed > 52) return 'El valor maximo es 52';
        if (!weekValueParsed && weekValueParsed !== 0) return 'El valor es requerido';
        return false;
      },
    });

    if (firstErrorMessage) return setErrorMessage(firstErrorMessage);

    setWeekEditorOpen(false);
    const newProgramActivatedDate = getProgramStartDateByWeek(values.week);
    setUserdata((prev) => ({ ...prev, programActivatedDate: newProgramActivatedDate }));
    const response = await updateProgramActivatedDate(userId, newProgramActivatedDate);
    setNotification(response);
    handleFetch();
    return null;
  };

  const handleBack = () => hostory.goBack();

  return (
    <div className={styles.limiter}>
      <Card className={styles.card}>
        {loading && <Loading message="Cargando" />}
        {!loading && (
          <>
            <Picture src={userdata.picture || userPictureSrc} className={styles.avatar} />
            <div className={styles.name}>{userdata.name || 'hello'}</div>
            <div className={styles.divider} />
            <div className={styles.dataWrapper}>
              <div className={styles.dataBody}>
                <Box marginBottom=".5em" fontWeight="600" color="var(--black)">
                  Dirección
                </Box>
                <div className={styles.row}>
                  <div>
                    <div>{`${userdata.streetAndNumber},  colonia: ${userdata.suburb}, C.P.:${userdata.postalCode}, ${userdata.city}, ${userdata.state}, ${userdata.country}`}</div>
                  </div>
                </div>
              </div>
              <div className={styles.divider} />
              <div className={styles.dataBody}>
                <Box marginBottom=".5em" fontWeight="600" color="var(--black)">
                  Resumen
                </Box>
                <div className={styles.row}>
                  <Email />
                  <div>
                    <div>{userdata.email}</div>
                  </div>
                </div>
                <div className={styles.row}>
                  <Phone />
                  <div>
                    <div>{userdata.phone}</div>
                  </div>
                </div>
                <div className={styles.row}>
                  <Wc />
                  <div>
                    <div>{gendersBin[userdata.gender]}</div>
                  </div>
                </div>
                <div className={styles.row}>
                  <Cake />
                  <div>
                    <div>{userdata.birthDate}</div>
                  </div>
                </div>
                <div className={styles.row}>
                  <Lock />
                  <div>
                    <Box fontSize=".8em">Contraseña</Box>
                    <div>{userdata.password}</div>
                  </div>
                </div>
                {userdata.programActive && (
                  <>
                    <div className={styles.row}>
                      <DirectionsRun />
                      <div className={styles.weekCounterWrapper}>
                        <div>
                          <Box fontSize=".8em">Programa de entrenamiento</Box>
                          <div>{userdata.programTitle}</div>
                        </div>
                      </div>
                    </div>

                    {!isWeekEditorOpen && (
                      <div className={styles.row}>
                        <DirectionsRun />
                        <div className={styles.weekCounterWrapper}>
                          <div>
                            <Box fontSize=".8em">Semana actual de entrenamiento</Box>
                            <div>Semana {getCurrentWeek(userdata)}</div>
                          </div>
                          {!!session && !!session.data && session.data.type === 'admin' && (
                            <Edit className={styles.editButton} onClick={() => setWeekEditorOpen(true)} />
                          )}
                        </div>
                      </div>
                    )}
                    {isWeekEditorOpen && (
                      <>
                        <ErrorMessage message={errorMessage} />
                        <div className={styles.row}>
                          <DirectionsRun />
                          <div className={styles.weekCounterWrapperEditor}>
                            <div>
                              <Box fontSize=".8em">Semana actual de entrenamiento</Box>
                              <Input {...getInputProps('week')} onlyNumbers maxLength={2} />
                            </div>
                            <Box container display="flex" className={styles.buttonContainerMobile}>
                              <div className={styles.editorButton} onClick={handleWeekUpdate} role="button">
                                Guardar
                              </div>
                              <Box paddingRight="1em" />
                              <div className={styles.editorButton} onClick={handleWeekEditorCancel} role="button">
                                Cancelar
                              </div>
                            </Box>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
                {!userdata.programActive && (
                  <Box color="var(--rose)">Aún no se ha activado el programa de entrenamiento</Box>
                )}
              </div>
            </div>
          </>
        )}
      </Card>
      <Box marginTop="1em">
        <Grid container>
          <ButtonAdmin onClick={handleBack}>Volver</ButtonAdmin>
        </Grid>
      </Box>
    </div>
  );
};

export default UserDetail;
