import { Box, CircularProgress, Grid } from '@material-ui/core';
import React from 'react';
import Card from 'src/components/card';
import { useLocation } from 'react-router-dom';
import Input from 'src/components/inputs';
import LabelInput from 'src/components/labelInput';
import ErrorMessage from 'src/components/errorMessage';
import { Delete } from '@material-ui/icons';
import getFilePreviewUrl from 'src/helpers/normalizeImagesUrl';
import ButtonAdmin from '../../components/button';
import Container from '../../components/container';
import styles from './styles.module.css';
import useTestimonialEditor from '../../../../hooks/useTestimonialEditor';
import Dropzone from '../../../../components/dropzone';
import { colors } from '../../../../constants';

function TestimonialEditor() {
  const { state } = useLocation();
  const { values, loading, deletePicture, selectPicture, acept, getInputProps, errorMessage } =
    useTestimonialEditor(state);

  return (
    <Container className={styles.container}>
      <Card>
        {!loading && (
          <>
            <div className={styles.wrapper}>
              <Box fontWeight="bold" color="var(--black)" fontSize="1.2em" textAlign="center" padding="2em">
                {state ? 'Actualizar Testimonio' : 'Crear nuevo testimonio'}
              </Box>
              <ErrorMessage message={errorMessage} />
              <Box textAlign="center" fontSize="1em">
                FOTO
              </Box>
              <Box margin="1em 0em">
                {!values.picture && (
                  <Dropzone className={styles.dropzone} onChange={selectPicture}>
                    Seleccionar imagen
                  </Dropzone>
                )}
                {!!values.picture && (
                  <div className={styles.pictureListContainer}>
                    <div className={styles.pictureWrapper}>
                      <div className={styles.deleteIconWrapper} onClick={deletePicture} role="button">
                        <Delete className={styles.deleteIcon} />
                      </div>
                      <img className={styles.image} src={getFilePreviewUrl(values.picture)} alt="imagen" />
                    </div>
                  </div>
                )}
              </Box>
              <LabelInput label="Nombre" verticalPadding=".3em">
                <Input {...getInputProps('name')} maxLength={100} />
              </LabelInput>
              <LabelInput label="Link de vide (opcional)" verticalPadding=".3em">
                <Input {...getInputProps('video')} maxLength={100} />
              </LabelInput>
              <LabelInput label="Descripción" verticalPadding=".3em">
                <Input type="textarea" {...getInputProps('description')} maxLength={400} />
              </LabelInput>
            </div>
            <Box marginTop="3em">
              <Grid container justify="flex-end">
                <ButtonAdmin primary onClick={acept}>
                  Guardar
                </ButtonAdmin>
              </Grid>
            </Box>
          </>
        )}

        {loading && (
          <div className={styles.loading}>
            <Box marginBottom="4em" textAlign="center" color={colors.cyan}>
              Guardando testimonio...
            </Box>
            <CircularProgress />
          </div>
        )}
      </Card>
    </Container>
  );
}

export default TestimonialEditor;
