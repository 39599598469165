import { array } from 'prop-types';
import React, { Fragment } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { ArrowForwardIos, Search } from '@material-ui/icons';
import Container from '../container';
import styles from './styles.module.css';
import Input from '../inputs';

const Breadcrumb = ({ values }) => {
  const history = useHistory();
  const { state = {} } = useLocation();

  const handleChange = (event) => {
    console.log({ filter: { search: event.target.value } });
    history.replace('/articulos', { search: event.target.value });
  };

  return (
    <Container small disabledVerticalPadding>
      <div className={styles.wrapper}>
        <div className={styles.breadcrumbWrapper}>
          {values.map((currentVal, index) => (
            <Fragment key={currentVal.label}>
              {!!index && <ArrowForwardIos className={styles.separator} />}
              {currentVal.to && (
                <Link to={currentVal.to} className={styles.option}>
                  {currentVal.label}
                </Link>
              )}
              {!currentVal.to && <div className={styles.option}>{currentVal.label}</div>}
            </Fragment>
          ))}
        </div>

        <div className={styles.serachWrapper}>
          <Search className={styles.serachIcon} />
          <Input
            type="text"
            className={styles.input}
            placeholder="Buscar producto"
            value={state.search || ''}
            onChange={handleChange}
          />
        </div>
      </div>
    </Container>
  );
};

Breadcrumb.propTypes = {
  values: array.isRequired,
};

export default Breadcrumb;
