import { Box, Hidden } from '@material-ui/core';
import { bool, object } from 'prop-types';
import React from 'react';
import formatPrice from '../../helpers/formatPrice';
import Picture from '../picture';
import styles from './styles.module.css';

const PurchaseSummary = (props) => (
  <>
    {props.data.items.map((item) => (
      <div className={styles.item} key={item.id}>
        <Picture src={item.picture} height={80} />
        <div className={styles.itemTitle}>
          <div>{item.title}</div>
          <Hidden smUp>
            <Box marginTop=".5em">
              <div className={styles.price}>
                <span className={styles.quantity}>x{item.quantity}</span>${formatPrice(item.price)} MXN
              </div>
            </Box>
          </Hidden>
        </div>
        <Hidden xsDown>
          <div className={styles.price}>
            <span className={styles.quantity}>x{item.quantity}</span>${formatPrice(item.price)} MXN
          </div>
        </Hidden>
      </div>
    ))}
    {!!props.data.shippingCost && (
      <div className={styles.rowsSummaryPrice}>
        <div>ENVIO:</div>
        <span>${formatPrice(props.data.shippingCost)}</span>
      </div>
    )}
    {!!props.data.inscription && (
      <div className={styles.rowsSummaryPrice}>
        <div>INSCRIPCIÓN:</div>
        <span>${formatPrice(props.data.inscription)}</span>
      </div>
    )}
    <div className={styles.rowsSummaryPrice}>
      <div>TOTAL:</div>
      <span>${formatPrice(props.data.total)}</span>
    </div>
    {!props.hiddAddress && (
      <div className={styles.address}>
        <div>
          <span>Datos de envio:</span> {props.data.userInfo.streetAndNumber}, {props.data.userInfo.suburb},{' '}
          {props.data.userInfo.city}, {props.data.userInfo.state}, C.P.:
          {props.data.userInfo.postalCode}
        </div>
        <div>
          <span>Contacto:</span> {props.data.userInfo.phone}, {props.data.userInfo.email}
        </div>
      </div>
    )}
  </>
);

PurchaseSummary.defaultProps = {
  hiddAddress: false,
};

PurchaseSummary.propTypes = {
  data: object.isRequired,
  hiddAddress: bool,
};

export default PurchaseSummary;
