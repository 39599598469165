/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import useProgramList from 'src/logic/programList';
import programUserModel from '../../../../core/models/programUsers';
import { failed } from '../../../../helpers/response';
import { getTrainingStartDate } from '../../../../helpers/training';
import useFetch from '../../../../hooks/useFetch';
import useAlert from '../../../../modules/alert/useAlert';
import useNotification from '../../../../modules/notifications/useNotification';
import UsersView from './view';

const Users = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [keyWords, setKeywords] = useState('');
  const [surveyActive, setSurveyActive] = useState(null);
  const [editingAdvicer, setEditingAdvicer] = useState(null);
  const [historyPaymentsActive, setHistoryPaymentsActive] = useState(null);
  const [bodyMeasurements, setBodyMeasurements] = useState(false); // user id
  const setNotification = useNotification();
  const setAlert = useAlert();

  // apply search
  let usersFiltered = [...users];
  if (keyWords) {
    const keywordNormalized = keyWords.toLowerCase();
    usersFiltered = usersFiltered.filter((currentUser) => {
      const nameNormalized = currentUser.name.toLowerCase();
      const emailNormalized = currentUser.email.toLowerCase();
      if (nameNormalized.includes(keywordNormalized)) return true;
      if (emailNormalized.includes(keywordNormalized)) return true;
      return false;
    });
  }

  // fecth data and update states
  const handleFetch = async () => {
    setLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 1000));
    const responseUsers = await programUserModel.get();
    setUsers(responseUsers);
    setLoading(false);
  };

  // E V E N T S  H A N D L E R S
  const handleActive = (id) =>
    setAlert({
      title: '¿Seguro quieres activar el programa para este usuario?',
      message: 'Una vez activado el programa no se podra desactivar',
      action: async () => {
        const response = await programUserModel.activateProgram(id);
        setNotification(response);
        await handleFetch();
      },
    });

  const handleDelete = (id) =>
    setAlert({
      title: '¿Seguro quieres Elimminar este usuario?',
      action: async () => {
        const response = await programUserModel.delete(id);
        setNotification(response);
        await handleFetch();
      },
    });

  const handleGetOptions = (user) => {
    const options = [
      { label: 'Eliminar', handler: () => handleDelete(user.id) },
      { label: 'Editar', handler: () => history.push(`/dashboard/activate-acount/${user.id}?edit=1`) },
      { label: 'Ver detalles', handler: () => history.push(`/dashboard/users/${user.id}`) },
      { label: 'Ver Pagos', handler: () => setHistoryPaymentsActive(user.id) },
      { label: 'Historial deportivo', handler: () => history.push(`/dashboard/users/questions/${user.id}`) },
      { label: 'Medidas de cuerpo', handler: () => setBodyMeasurements(user.id) },
    ];

    if (user.programActive) {
      options.push({
        label: 'Ver entrenamiento',
        handler: () => {
          const startProgram = getTrainingStartDate(user);
          if (startProgram > new Date()) setNotification(failed('El usuario aun no inicia su entrenamiento'));
          else history.push(`/dashboard/training-user/${user.id}`);
        },
      });
    }

    if (!user.programActive) options.push({ label: 'Activar programa', handler: () => handleActive(user.id) });
    if (user.surveyAnswered) options.push({ label: 'Ver encuesta', handler: () => setSurveyActive(user.id) });
    if (user.programActive)
      options.push({
        label: user.advicer ? 'Cambiar asesor' : 'Asignar asesor',
        handler: () => setEditingAdvicer(user.id),
      });
    return options;
  };

  // fetch data when componnet is mounted
  useFetch(handleFetch);

  return (
    <UsersView
      bodyMeasurements={bodyMeasurements}
      onBodyMeasurementsClose={() => setBodyMeasurements(null)}
      onHistoryPaymentClose={() => setHistoryPaymentsActive(null)}
      historyPaymentsActive={historyPaymentsActive}
      onSurveyClose={() => setSurveyActive(false)}
      onEdittingAdvicerClose={() => setEditingAdvicer(null)}
      loading={loading}
      users={usersFiltered}
      keyWords={keyWords}
      surveyActive={surveyActive}
      getOptions={handleGetOptions}
      onKeywordChange={setKeywords}
      edittingAdvicer={editingAdvicer}
      onReloadUsers={handleFetch}
    />
  );
};

export default Users;
