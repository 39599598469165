import { CircularProgress } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { func, string } from 'prop-types';
import React, { useState } from 'react';
import programUserModel from '../../../../../../core/models/programUsers';
import useFetch from '../../../../../../hooks/useFetch';
import styles from './styles.module.css';

const SurveyModal = (props) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  useFetch(async () => {
    setLoading(true);
    const response = await programUserModel.getSingle(props.userId);
    setData(response.survey);
    setLoading(false);
  }, [props.userId]);

  return (
    <div className={styles.fullWrapper}>
      <div className={styles.card}>
        <Close className={styles.close} onClick={props.onClose} />
        <div className={styles.cardBody}>
          {loading && <CircularProgress />}
          {!loading && (
            <ul>
              {data.map((currentSirvey, index) => (
                <li key={index}>
                  <div className={styles.question}>{currentSirvey.question}</div>
                  <div className={styles.answer}>{currentSirvey.answer}</div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

SurveyModal.propTypes = {
  userId: string.isRequired,
  onClose: func.isRequired,
};

export default SurveyModal;
