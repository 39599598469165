import { Box } from '@material-ui/core';
import { any, bool, func, object, string } from 'prop-types';
import React, { useEffect } from 'react';
import AlternativeButton from 'src/components/alternativeButton';
import Picture from 'src/components/picture';
import formatPrice from 'src/helpers/formatPrice';
import styles from './styles.module.css';

const Summary = (props) => {
  useEffect(() => {
    if (props.renderPaypal) {
      const target = document.getElementById('paypal_tarjet');
      if (target) {
        target.innerHTML = '';
        window.paypal.Buttons(props.paypalConfig).render('#paypal_tarjet');
      }
    }
  }, [props.renderPaypal, props.paypalConfig]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.group}>
        <div className={styles.productWrapper}>
          <div className={styles.imageProdutWRapper}>
            <Picture height={100} className={styles.imageProdut} src={props.productData.picture} />
          </div>
          <div className={styles.title}>{props.productData.title}</div>
        </div>
      </div>

      {!props.hidePrices && (
        <div className={styles.group}>
          <div className={styles.genericFlex} style={{ marginTop: '1em' }}>
            <div className={`${styles.genericTitle} ${styles.gray}`} style={{ marginBottom: '0px' }}>
              Un solo pago
            </div>
            <div className={`${styles.genericTitle}`} style={{ marginBottom: '0px' }}>
              <Box fontSize="1.3em">${formatPrice(props.productData.price)}</Box>
            </div>
          </div>
          <div className={styles.genericFlex} style={{ marginTop: '1em' }}>
            <div className={`${styles.genericTitle} ${styles.gray}`} style={{ marginBottom: '0px' }}>
              Pago semestral
            </div>
            <div className={`${styles.genericTitle}`} style={{ marginBottom: '0px' }}>
              <Box fontSize="1.3em">${formatPrice(props.productData.semesterPrice)}</Box>
            </div>
          </div>
          <div className={styles.genericFlex} style={{ marginTop: '1em' }}>
            <div className={`${styles.genericTitle} ${styles.gray}`} style={{ marginBottom: '0px' }}>
              Pago mensual
            </div>
            <div className={`${styles.genericTitle}`} style={{ marginBottom: '0px' }}>
              <Box fontSize="1.3em">${formatPrice(props.productData.monthlyPrice)}</Box>
            </div>
          </div>
          <div className={styles.genericFlex} style={{ marginTop: '1em' }}>
            <div className={`${styles.genericTitle} ${styles.gray}`} style={{ marginBottom: '0px' }}>
              Inscripción
            </div>
            <div className={`${styles.genericTitle}`} style={{ marginBottom: '0px' }}>
              <Box fontSize="1.3em">${formatPrice(props.productData.inscription)}</Box>
            </div>
          </div>
          <Box mt="1em" fontSize="1.2em" color="var(--gray" style={{ marginTop: '1em' }}>
            *En el siguiente paso seleccionarás tu modo de pago.
          </Box>
        </div>
      )}

      {props.hidePrices && (
        <div className={styles.group}>
          <div className={styles.genericFlex}>
            <div className={`${styles.genericTitle} ${styles.gray}`} style={{ marginBottom: '0px' }}>
              Inscripción
            </div>
            <div className={`${styles.genericTitle}`} style={{ marginBottom: '0px' }}>
              <Box fontSize="1.4em">${formatPrice(props.productData.inscription)}</Box>
            </div>
          </div>
          <div className={styles.genericFlex} style={{ marginTop: '1em' }}>
            <div className={`${styles.genericTitle} ${styles.gray}`} style={{ marginBottom: '0px' }}>
              Total
            </div>
            <div className={`${styles.genericTitle}`} style={{ marginBottom: '0px' }}>
              <Box fontSize="1.4em">${formatPrice(props.total)}</Box>
            </div>
          </div>
        </div>
      )}

      <div className={styles.group}>
        <div className={styles.genericFlex}>
          <Box width="100%">
            <AlternativeButton className={styles.buttton} onClick={props.onBack}>
              Regresar
            </AlternativeButton>
          </Box>
          <Box paddingRight="1.5em" />
          <div className={styles.buttonPaypalWrapper}>
            {props.renderPaypal && <div className={styles.paypalTarget} id="paypal_tarjet" />}
            <AlternativeButton className={styles.buttton} filled onClick={props.onAcept}>
              {props.buttonText}
            </AlternativeButton>
          </div>
        </div>
      </div>
    </div>
  );
};

Summary.propTypes = {
  productData: object.isRequired,
  onAcept: func.isRequired,
  onBack: func.isRequired,
  paypalConfig: object.isRequired,
  renderPaypal: bool.isRequired,
  buttonText: string.isRequired,
  total: any.isRequired,
  hidePrices: bool.isRequired,
};

export default Summary;
