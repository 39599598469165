/* eslint-disable no-nested-ternary */
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AuthRoute from 'src/components/authRoute';
import { useSession } from 'src/modules/session';
import LayoutDashboard from './components/layout';
import CreateAdviser from './pages/createAdviser';
import createProduct from './pages/createProduct';
import ProductList from './pages/producsList';
import AdviserList from './pages/adviserList';
import shippingCost from './pages/shippingCost';
import ShippingCostEditor from './pages/shippingCostEditor';
import Sales from './pages/salesList';
import SaleDetail from './pages/saleDetail';
import CreateEvent from './pages/createEvent';
import EventsList from './pages/eventsList';
import MessagesPage from './pages/messages';
import Communities from './pages/communities';
import ComunityEditor from './pages/comunityEditor';
import Testimonials from './pages/testimonials';
import TestimonialEditor from './pages/TestimonialEditor';
import ProgramDetail from './pages/programDetail';
import ActivateCount from './pages/activateAccount';
import Users from './pages/users';
import UserDetail from './pages/userDetail';
import AdvicersUsers from './pages/advicerUsers';
import TrainingView from './pages/trainingView';
import TipsEditor from './pages/tipsEditor';
import Tips from './pages/tips';
import GroupChatPage from './pages/groupChat';
import InitiationQuestions from './pages/initiationQuestions';
import TutorialsByImplement from './pages/TutorialsByImplement';
import TutorialsAdvicer from './pages/tutorialsAdvicer';
import TrainingUser from './pages/trainingUser';
import TrainingUserDetail from './pages/trainingUser/userTrainingDetail';
import ProgramList from './pages/programList';

function Dashboard() {
  const { data } = useSession();

  // handle redirects
  if (!data) return <Redirect to="/home" />;

  return (
    <LayoutDashboard>
      <Switch>
        <AuthRoute userAuthorized="advicer" path="/dashboard/advicer-users" exact component={AdvicersUsers} />
        <AuthRoute userAuthorized="advicer" path="/dashboard/group-chat" exact component={GroupChatPage} />
        <AuthRoute userAuthorized="advicer" path="/dashboard/tutorials" exact component={TutorialsAdvicer} />
        <AuthRoute userAuthorized="advicer" path="/dashboard/user-detail/:userId" exact component={UserDetail} />
        <AuthRoute
          userAuthorized="advicer"
          path="/dashboard/users-advicer/questions/:userId"
          exact
          component={InitiationQuestions}
        />

        <AuthRoute userAuthorized="admin" path="/dashboard/group-chat-admin" exact component={GroupChatPage} />
        <AuthRoute userAuthorized="admin" path="/dashboard/create-product" exact component={createProduct} />
        <AuthRoute userAuthorized="admin" path="/dashboard/create-advicer" exact component={CreateAdviser} />
        <AuthRoute userAuthorized="admin" path="/dashboard/products" exact component={ProductList} />
        <AuthRoute userAuthorized="admin" path="/dashboard/advisers" exact component={AdviserList} />
        <AuthRoute userAuthorized="admin" path="/dashboard/shipping-cost" exact component={shippingCost} />
        <AuthRoute userAuthorized="admin" path="/dashboard/shipping-cost/editor" exact component={ShippingCostEditor} />
        <AuthRoute userAuthorized="admin" path="/dashboard/sales" exact component={Sales} />
        <AuthRoute userAuthorized="admin" path="/dashboard/sales/:id" exact component={SaleDetail} />
        <AuthRoute userAuthorized="admin" path="/dashboard/create-event" exact component={CreateEvent} />
        <AuthRoute userAuthorized="admin" path="/dashboard/events" exact component={EventsList} />
        <AuthRoute userAuthorized="admin" path="/dashboard/messages" exact component={MessagesPage} />
        <AuthRoute userAuthorized="admin" path="/dashboard/communities" exact component={Communities} />
        <AuthRoute userAuthorized="admin" path="/dashboard/communities/editor" exact component={ComunityEditor} />
        <AuthRoute userAuthorized="admin" path="/dashboard/testimonials" exact component={Testimonials} />
        <AuthRoute userAuthorized="admin" path="/dashboard/testimonials/editor" exact component={TestimonialEditor} />

        <AuthRoute userAuthorized="admin" path="/dashboard/program-detail/:programId" exact component={ProgramDetail} />
        <AuthRoute userAuthorized="admin" path="/dashboard/program-list" exact component={ProgramList} />

        <AuthRoute userAuthorized="admin" path="/dashboard/activate-acount/:id" exact component={ActivateCount} />
        <AuthRoute userAuthorized="admin" path="/dashboard/users" exact component={Users} />
        <AuthRoute userAuthorized="admin" path="/dashboard/users/:userId" exact component={UserDetail} />
        <AuthRoute userAuthorized="admin" path="/dashboard/training-view" exact component={TrainingView} />
        <AuthRoute
          userAuthorized="admin"
          path="/dashboard/tutorials-by-implement"
          exact
          component={TutorialsByImplement}
        />
        <AuthRoute userAuthorized="admin" path="/dashboard/tips-editor" exact component={TipsEditor} />
        <AuthRoute userAuthorized="admin" path="/dashboard/tips" exact component={Tips} />
        <AuthRoute userAuthorized="admin" path="/dashboard/training-user/:userId" exact component={TrainingUser} />

        <AuthRoute
          userAuthorized="admin"
          path="/dashboard/training-user/:userId/:day"
          exact
          component={TrainingUserDetail}
        />
        <AuthRoute
          userAuthorized="admin"
          path="/dashboard/users/questions/:userId"
          exact
          component={InitiationQuestions}
        />
        <Route>
          <Redirect
            to={
              data.type === 'admin' ? '/dashboard/products' : data.type === 'advicer' ? '/dashboard/advicer-users' : '/'
            }
          />
        </Route>
      </Switch>
    </LayoutDashboard>
  );
}

export default Dashboard;
