import database from 'src/core/database';
import formatObect from '../../helpers/formatObject';
import { failed, success } from '../../helpers/response';
import storage from '../storage';

const getListCommunities = async () => {
  const list = await database.getList('communities', null, ['title', 'asc']).next();
  return list;
};

export const createNewCommunity = async (values) => {
  const allows = ['title', 'description', 'phone', 'email', 'whatsappNumber', 'web'];
  const secureData = formatObect(values, allows);
  const data = await database.create('communities', secureData);
  return data ? success('Publicacion creada correctamente', data) : failed();
};

export const updateCommunity = async (id, data) => {
  const allows = ['title', 'description', 'phone', 'email', 'picture', 'whatsappNumber', 'web'];
  const secureData = formatObect(data, allows);
  const status = await database.update(`/communities/${id}`, secureData);
  return status ? success('Publicacion creada correctamente') : failed();
};

export const deleteCommunity = async (comunity) => {
  const { picture = '' } = comunity;
  await storage.deletePicture(picture);
  const status = await database.remove(`communities/${comunity.id}`);
  return status ? success('Publicacion eliminada correctamente') : failed();
};

export default {
  getList: getListCommunities,
  update: updateCommunity,
  create: createNewCommunity,
  delete: deleteCommunity,
};
