/* eslint-disable no-loop-func */
import { useState } from 'react';
import { defaultShippingCost } from '../constants';
import shipping from '../core/models/shipping';
import useFetch from './useFetch';

const useShippingCost = (postalCode = '') => {
  const [list, setList] = useState([]);
  let shippingCost = 0;

  useFetch(async () => {
    console.log('handleFetch');
    const items = await shipping.getList();
    setList(items);
  });

  if (postalCode.toString().length === 5) {
    let currentPostalCode = postalCode || '';
    while (currentPostalCode.toString().length && !shippingCost) {
      const matched = list.find((item) => item.cp.toString() === currentPostalCode.toString());
      if (matched) shippingCost = matched.price;
      currentPostalCode = currentPostalCode.substring(0, currentPostalCode.length - 1);
    }
  }

  return shippingCost || defaultShippingCost;
};

export default useShippingCost;
