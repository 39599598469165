// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_picture__1_TDF {\n  width: 100%;\n  height: auto;\n  margin-bottom: -5px;\n}", "",{"version":3,"sources":["webpack://src/components/privateCover/styles.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;AACrB","sourcesContent":[".picture {\n  width: 100%;\n  height: auto;\n  margin-bottom: -5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"picture": "styles_picture__1_TDF"
};
export default ___CSS_LOADER_EXPORT___;
