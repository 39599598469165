// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_picture__1BNcc {\n  width: 5em;\n  height: 5em;\n  object-fit: cover;\n  border-radius: 50%;\n  margin-right: 1em;\n}\n.styles_wrapper__3zIn_ {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}", "",{"version":3,"sources":["webpack://src/pages/myProfile/components/advicer/styles.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,WAAW;EACX,iBAAiB;EACjB,kBAAkB;EAClB,iBAAiB;AACnB;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB","sourcesContent":[".picture {\n  width: 5em;\n  height: 5em;\n  object-fit: cover;\n  border-radius: 50%;\n  margin-right: 1em;\n}\n.wrapper {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"picture": "styles_picture__1BNcc",
	"wrapper": "styles_wrapper__3zIn_"
};
export default ___CSS_LOADER_EXPORT___;
