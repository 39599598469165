import { subCategoriesByCategory } from 'src/constants';
import mapObjects from '../../helpers/mapObjects';

const formatSimpleFilter = (source, type) =>
  mapObjects(source, (value, keyname) => ({
    label: value,
    value: keyname,
    type,
  }));

const recursiveComputeValue = (options, composeValue = {}) =>
  options.map((option) => {
    const localOption = { ...option };
    localOption.calculatedValue = {
      ...composeValue,
      [localOption.type]: localOption.value,
    };
    if (localOption.options) {
      localOption.options = recursiveComputeValue(localOption.options, localOption.calculatedValue);
    }
    return localOption;
  });

const FIltersCrud = [
  {
    type: 'category',
    label: 'Todos',
    value: null,
  },
  {
    type: 'category',
    label: 'Programas deportivos',
    value: 'sportsPrograms',
    // options: formatSimpleFilter(subCategoriesByCategory.sportsPrograms, 'subCategory'),
  },
  {
    type: 'category',
    label: 'Ropa deportiva',
    value: 'sportswear',
    options: [
      {
        type: 'gender',
        label: 'hombre',
        value: 'male',
        options: formatSimpleFilter(subCategoriesByCategory.sportswear, 'subCategory'),
      },
      {
        type: 'gender',
        label: 'Mujer',
        value: 'female',
        options: formatSimpleFilter(subCategoriesByCategory.sportswear, 'subCategory'),
      },
    ],
  },
  {
    type: 'category',
    label: 'Artículos sobre pedido',
    value: 'itemsOnRequest',
    options: [
      {
        type: 'gender',
        label: 'hombre',
        value: 'male',
        options: formatSimpleFilter(subCategoriesByCategory.sportswear, 'subCategory'),
      },
      {
        type: 'gender',
        label: 'Mujer',
        value: 'female',
        options: formatSimpleFilter(subCategoriesByCategory.sportswear, 'subCategory'),
      },
    ],
  },
  {
    type: 'category',
    label: 'Cursos y conferencias',
    value: 'coursesAndConferences',
  },
  {
    type: 'category',
    label: 'Eventos y actividades',
    value: 'eventsAndActivities',
  },
  {
    type: 'category',
    label: 'Otros',
    value: 'others',
  },
];

const FIlters = recursiveComputeValue(FIltersCrud);

export default FIlters;
