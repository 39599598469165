import { useState } from 'react';
import { createImplement } from '../core/models/program';
import useNotification from '../modules/notifications/useNotification';

/**
 * @param {Object} params
 * @param {func?} params.onSuccess
 */
const useUploadProgramModel = (params = {}) => {
  const setNotification = useNotification();
  const [loading, setLoading] = useState(false);

  const handleFileSelect = async (file) => {
    setLoading(true);
    const response = await createImplement(params.programId, file);
    if (response.success && params.onSuccess) await params.onSuccess();
    setNotification(response);
    setLoading(false);
  };

  return {
    loading,
    fileSelect: handleFileSelect,
  };
};

export default useUploadProgramModel;
