// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_wrapperMaster__2B0Rg {\r\n  padding: 2em;\r\n}", "",{"version":3,"sources":["webpack://src/pages/dashboard/pages/producsList/styles.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd","sourcesContent":[".wrapperMaster {\r\n  padding: 2em;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapperMaster": "styles_wrapperMaster__2B0Rg"
};
export default ___CSS_LOADER_EXPORT___;
