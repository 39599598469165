/* eslint-disable no-unused-vars */
import { array } from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { stripHtml } from 'string-strip-html';
import Picture from '../../../../components/picture';
import limitText from '../../../../helpers/limitText';
import styles from './styles.module.css';

const List = (props) => (
  <div className={styles.listWrapper}>
    {props.items.map((currentItem) => (
      <div className={styles.item} key={currentItem.id}>
        <div className={styles.title}>{currentItem.title}</div>
        <Picture src={currentItem.cover} className={styles.picture} height="60" />
        <p className={styles.paragraph}>{limitText(stripHtml(currentItem.body).result, 500)}</p>
        <Link to={`/tips/${currentItem.id}`} className={styles.link}>
          Continuar leyendo
        </Link>
      </div>
    ))}
  </div>
);

List.propTypes = {
  items: array.isRequired,
};

export default List;
