import React, { useState } from 'react';
import useForm from 'src/hooks/useForm';
import { useSession } from 'src/modules/session';
import { completeSurvey } from '../../../../core/models/users';
import useErrorMessage from '../../../../hooks/useErrorMessage';
import { getValidators } from './funtions';
import RegisterView from './surveyView';

const Survey = () => {
  const { data, refreshSession } = useSession();
  const [currentStep, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const { getInputProps, validateInputs, values } = useForm();
  const { errorMessage, setErrorMessage } = useErrorMessage();

  const handleStepOneNext = () => {
    const { firstErrorMessage } = validateInputs(getValidators());
    if (firstErrorMessage) setErrorMessage(firstErrorMessage);
    else setStep((prevStep) => prevStep + 1);
  };

  const handleStepTwoAcept = async () => {
    setLoading(true);
    const response = await completeSurvey(data.id, values);
    if (response.error) {
      setErrorMessage(response.message);
      setLoading(false);
    } else {
      await refreshSession();
    }
  };

  return (
    <RegisterView
      loading={loading}
      currentStep={currentStep}
      getInputProps={getInputProps}
      onStepOneNext={handleStepOneNext}
      errorMessage={errorMessage}
      onStepTwoAcept={handleStepTwoAcept}
    />
  );
};

export default Survey;
