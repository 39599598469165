import React, { Fragment } from 'react';
import { func, object } from 'prop-types';
import { Box, Collapse } from '@material-ui/core';
import styles from './styles.module.css';
import filters from '../../filtetersComposed';

const Filters = (props) => {
  const computeShowFilterSection = (computedValueFromOption) => {
    for (const type in computedValueFromOption) {
      if (Object.hasOwnProperty.call(computedValueFromOption, type)) {
        if (computedValueFromOption[type] !== props.value[type]) return false;
      }
    }
    return true;
  };

  return (
    <div className={styles.menuWrapper}>
      <Box marginBottom="1em" clone color="#000" fontWeight="600">
        <div className={styles.title}>FILTRAR POR</div>
      </Box>
      {filters.map((filter) => (
        <Fragment key={filter.label}>
          <div
            className={`${styles.option} ${
              computeShowFilterSection(filter.calculatedValue) ? styles.optionSelected : ''
            }`}
            onClick={() => props.onChange(filter.calculatedValue)}
            role="button"
          >
            {filter.label}
          </div>
          {filter.options && (
            <>
              <Collapse in={computeShowFilterSection(filter.calculatedValue)}>
                <div className={styles.block}>
                  {filter.options.map((secondFilter) => (
                    <Fragment key={secondFilter.label}>
                      <div
                        className={`${styles.option} ${
                          computeShowFilterSection(secondFilter.calculatedValue) ? styles.optionSelected : ''
                        }`}
                        onClick={() => props.onChange(secondFilter.calculatedValue)}
                        role="button"
                      >
                        {secondFilter.label}
                      </div>
                      {secondFilter.options && (
                        <Collapse in={computeShowFilterSection(secondFilter.calculatedValue)}>
                          <div className={styles.block}>
                            {secondFilter.options.map((thirdFilter) => (
                              <div
                                key={thirdFilter.label}
                                className={`${styles.option} ${
                                  computeShowFilterSection(thirdFilter.calculatedValue) ? styles.optionSelected : ''
                                }`}
                                onClick={() => props.onChange(thirdFilter.calculatedValue)}
                                role="button"
                              >
                                {thirdFilter.label}
                              </div>
                            ))}
                          </div>
                        </Collapse>
                      )}
                    </Fragment>
                  ))}
                </div>
              </Collapse>
            </>
          )}
        </Fragment>
      ))}
    </div>
  );
};

Filters.propTypes = {
  onChange: func.isRequired,
  value: object.isRequired,
};

export default Filters;
