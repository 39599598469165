import { bool, func, string } from 'prop-types';
import React from 'react';
import Input from 'src/components/inputs';
import { Link } from 'react-router-dom';
import ButtonAdmin from '../button';
import styles from './styles.module.css';

const HeaderForLists = (props) => (
  <div className={styles.wrapper}>
    <div className={styles.titleWrapper}>
      <div className={styles.title}>{props.title}</div>
      {!!props.buttonRedirect && (
        <Link to={props.buttonRedirect}>
          <ButtonAdmin primary>Crear nuevo</ButtonAdmin>
        </Link>
      )}
    </div>
    {!props.searcherDisabled && (
      <Input
        className={styles.input}
        value={props.value}
        onChange={(e) => props.onChange(e.target.value)}
        placeholder="Buscar"
      />
    )}
  </div>
);

HeaderForLists.defaultProps = {
  buttonRedirect: null,
  value: null,
  onChange: null,
  searcherDisabled: false,
};

HeaderForLists.propTypes = {
  title: string.isRequired,
  value: string,
  onChange: func,
  buttonRedirect: string,
  searcherDisabled: bool,
};

export default HeaderForLists;
