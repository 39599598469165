import { Box, useMediaQuery } from '@material-ui/core';
import { ArrowBackIos, ArrowForwardIos, ShoppingCart } from '@material-ui/icons';
import { func, number, array, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import AlternativeButton from '../../components/alternativeButton';
import Breadcrumb from '../../components/breadcrumb';
import Container from '../../components/container';
import Picture from '../../components/picture';
import FormatPrice from '../../helpers/formatPrice';
import styles from './styles.module.css';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const ProductDetailView = (props) => {
  const [currentPictureIndex, setCurrentPictureIndex] = useState(0);
  const isMobile = useMediaQuery('(max-width:800px)');
  const history = useHistory();

  useEffect(() => {
    // const element = document.getElementById(`thumb-id-${currentPictureIndex}`);
    // element.scrollLeft({ behavior: 'smooth' });
  }, [currentPictureIndex]);

  const handleNext = () => {
    const indexToShow = currentPictureIndex + 1 <= props.pictures.length - 1 ? currentPictureIndex + 1 : 0;
    setCurrentPictureIndex(indexToShow);
  };

  const handleBack = () => {
    const indexToShow = currentPictureIndex ? currentPictureIndex - 1 : props.pictures.length - 1;
    setCurrentPictureIndex(indexToShow);
  };

  const handleKeepBuyying = () => history.replace('/articulos');

  return (
    <>
      <Breadcrumb values={props.breadCrumbs} />
      <Container small>
        <div className={styles.flexWrapper}>
          {isMobile && (
            <div
              className={styles.group}
              style={{
                width: '100%',
                borderBottom: 'none',
                marginBottom: 0,
                paddingBottom: '1.5em',
              }}
            >
              <div className={styles.flexGeneric}>
                <div className={styles.title}>{props.title}</div>
                <Box paddingRight="2em" />
                <div className={styles.price}>
                  ${FormatPrice(props.price)}
                  <span>MXN</span>
                </div>
              </div>
            </div>
          )}
          <div className={styles.imageWrapper}>
            <div className={styles.currentyImageWRapper}>
              <ArrowForwardIos
                className={`${styles.buttonControl} ${styles.buttonControlRight}`}
                onClick={handleNext}
              />
              <ArrowBackIos className={`${styles.buttonControl} ${styles.buttonControlLeft}`} onClick={handleBack} />
              <AutoPlaySwipeableViews
                index={currentPictureIndex}
                onChangeIndex={setCurrentPictureIndex}
                enableMouseEvents
                disableLazyLoading
                interval={13000}
              >
                {props.pictures.map((pic) => (
                  <Picture key={pic} src={pic} className={styles.picture} height={80} />
                ))}
              </AutoPlaySwipeableViews>
            </div>

            <div className={styles.thumbWrapperLimited}>
              <div className={styles.thumbWrapper}>
                {props.pictures.map((curremtPic, index) => (
                  <Picture
                    onClick={() => setCurrentPictureIndex(index)}
                    id={`thumb-id-${index}`}
                    height={80}
                    src={curremtPic}
                    key={curremtPic}
                    className={`${styles.thumb} ${index === currentPictureIndex ? styles.pictureSelected : ''}`}
                  />
                ))}
              </div>
            </div>
          </div>

          <div className={styles.dataWrapper}>
            {!isMobile && (
              <div className={styles.group}>
                <div className={styles.flexGeneric}>
                  <div className={styles.title}>{props.title}</div>
                  <Box paddingRight="2em" />
                  <div className={styles.price}>
                    ${FormatPrice(props.price)}
                    <span>MXN</span>
                  </div>
                </div>
              </div>
            )}
            <div className={styles.group}>
              <div className={styles.describe}>DESCRIPCIÓN</div>
              <div className={styles.description}>{props.description}</div>
            </div>
            <div className={styles.group}>
              <div className={styles.flexGeneric}>
                <div className={styles.describe}>CANTIDAD</div>
                <div className={styles.quantityWrapper}>
                  <div
                    className={styles.quantityControl}
                    role="button"
                    onClick={() => props.onQuantityChange(props.quantity - 1)}
                  >
                    -
                  </div>
                  <div className={styles.quantityVal}>{props.quantity}</div>
                  <div
                    className={styles.quantityControl}
                    role="button"
                    onClick={() => props.onQuantityChange(props.quantity + 1)}
                  >
                    +
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.group} style={{ borderBottom: 'none' }}>
              <div className={styles.flexGeneric}>
                <AlternativeButton className={styles.button} onClick={handleKeepBuyying}>
                  SEGUIR COMPRANDO
                </AlternativeButton>
                <AlternativeButton className={styles.button} filled onClick={props.onAddTocart}>
                  <div className={styles.flexGeneric}>
                    <ShoppingCart className={styles.icon} />
                    AGREGAR A LA BOLSA
                  </div>
                </AlternativeButton>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

ProductDetailView.propTypes = {
  pictures: array.isRequired,
  title: string.isRequired,
  description: string.isRequired,
  onQuantityChange: func.isRequired,
  quantity: number.isRequired,
  price: number.isRequired,
  onAddTocart: func.isRequired,
  breadCrumbs: array.isRequired,
};

export default ProductDetailView;
