import { MoreVert } from '@material-ui/icons';
import { array, func } from 'prop-types';
import React from 'react';
import Options from 'src/components/options';
import Picture from 'src/components/picture';
import { TipsCategory } from 'src/constants';
import styles from './styles.module.css';

const TipsList = (props) => (
  <div className={styles.list}>
    {props.items.map((currentItem) => (
      <div className={styles.itemWrapper} key={currentItem.id}>
        <div className={styles.itemCard}>
          <Picture src={currentItem.cover} height={70} className={styles.cover} />
          <div className={styles.dataWrapper}>
            <div className={styles.title}>{currentItem.title}</div>
            <div className={styles.category}>{TipsCategory[currentItem.category]}</div>
          </div>
          <Options className={styles.options} optionsList={props.getOptions(currentItem)}>
            <MoreVert />
          </Options>
        </div>
      </div>
    ))}
  </div>
);

TipsList.propTypes = {
  items: array.isRequired,
  getOptions: func.isRequired,
};

export default TipsList;
