import { Box } from '@material-ui/core';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Loading from 'src/components/loading';
import programModel from 'src/core/models/program';
import useFetch from 'src/hooks/useFetch';
import queryString from 'query-string';
import Weekly from '../../../training/components/weekly';
import TraiiningDetail from './components/detail';
import styles from './styles.module.css';

const TrainingView = () => {
  const { search } = useLocation();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [currentView, setCurretView] = useState(null);

  const { implementId, programId } = queryString.parse(search);

  const handleView = (weekIndex, dayIndex) => setCurretView({ weekIndex, dayIndex });

  // fetch iniital data
  useFetch(async () => {
    const responseImplement = await programModel.getSingleImpletement(programId, implementId);
    setData(responseImplement.payload);
    setLoading(false);
  });

  const getTrainingDetailProps = () => ({
    dayData: data[currentView.weekIndex].days[currentView.dayIndex],
    dayName: data[currentView.weekIndex].days[currentView.dayIndex].label,
    weekNumber: currentView.weekIndex + 1,
  });

  return (
    <div className={styles.limiter}>
      <div>
        <>
          {!!currentView && <TraiiningDetail {...getTrainingDetailProps()} onClose={() => setCurretView(null)} />}
          {loading && <Loading message="Cargando programa" />}
          {!loading && (
            <>
              {data.map((week, weekIndex) => (
                <Box marginBottom="1em" fontSize=".9em" key={weekIndex}>
                  <Weekly
                    weekNumber={weekIndex + 1}
                    objetive={week.objetive}
                    title={week.title}
                    days={week.days}
                    isCurrentWeek
                    disabledCompleteds
                    onView={(dayIndex) => handleView(weekIndex, dayIndex)}
                  />
                </Box>
              ))}
            </>
          )}
        </>
      </div>
    </div>
  );
};

export default TrainingView;
