import React, { useState } from 'react';
import { arrayOf, bool, string, func } from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { ArrowBackIos, ArrowForwardIos, Close } from '@material-ui/icons';
import styles from './styles.module.css';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const ModalPictures = (props) => {
  const [currentPictureIndex, setCurrentPictureIndex] = useState(0);

  const handleNext = () => {
    const indexToShow = currentPictureIndex + 1 <= props.pictures.length - 1 ? currentPictureIndex + 1 : 0;
    setCurrentPictureIndex(indexToShow);
  };

  const handleBack = () => {
    const indexToShow = currentPictureIndex ? currentPictureIndex - 1 : props.pictures.length - 1;
    setCurrentPictureIndex(indexToShow);
  };

  if (!props.open) return null;

  return (
    <div className={styles.fulllwidth}>
      <div className={styles.currentyImageWRapper}>
        <ArrowForwardIos className={`${styles.buttonControl} ${styles.buttonControlRight}`} onClick={handleNext} />
        <ArrowBackIos className={`${styles.buttonControl} ${styles.buttonControlLeft}`} onClick={handleBack} />
        <Close className={styles.close} onClick={props.onClose} />
        <AutoPlaySwipeableViews
          index={currentPictureIndex}
          onChangeIndex={setCurrentPictureIndex}
          enableMouseEvents
          disableLazyLoading
          interval={13000}
          autoPlay={false}
        >
          {props.pictures.map((pic) => (
            <img key={pic} src={pic} className={styles.picture} alt="some img" />
          ))}
        </AutoPlaySwipeableViews>
      </div>
    </div>
  );
};

ModalPictures.propTypes = {
  pictures: arrayOf(string).isRequired,
  open: bool.isRequired,
  onClose: func.isRequired,
};

export default ModalPictures;
