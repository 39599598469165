/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import Loading from 'src/components/loading';
import EmptyContent from 'src/components/emptyContent';
import advicerModel from 'src/core/models/advisers';
import useFetch from 'src/hooks/useFetch';
import { useHistory } from 'react-router';
import AdvicerListBody from './components/advicerList';
import styles from './styles.module.css';
import useAlert from '../../../../modules/alert/useAlert';
import useNotification from '../../../../modules/notifications/useNotification';
import HeaderForLists from '../../components/headerForLists';
import UsersByAdvicer from './components/usersPerAdvicerModal';

const AdvicerList = () => {
  const setAlert = useAlert();
  const setNotification = useNotification();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [advisers, setAdvicers] = useState([]);
  const [keywords, setKeywords] = useState('');
  const [usersByAdvicerModalStatus, setUsersByAdvicerModalStatus] = useState(null);

  let advicersFiltered = advisers;

  // filter by name
  if (keywords) {
    advicersFiltered = advisers.filter((adviver) => {
      const normalizedKeywords = keywords.toString().toLowerCase();
      const nameNormalized = adviver.name.toString().toLowerCase();
      return nameNormalized.includes(normalizedKeywords);
    });
  }

  const handleFetchData = async () => {
    const response = await advicerModel.getList();
    setAdvicers(response);
    setLoading(false);
  };

  useFetch(handleFetchData);

  const getOptions = (item) => [
    {
      label: 'Eliminar',
      handler: () => {
        setAlert({
          title: '¿Seguro quieres eliminar este asesor?',
          message: 'Despues de ejecutar esta acción no podras recuperar la información',
          action: async () => {
            const response = await advicerModel.deleteAdvicer(item.id);
            console.log('response');
            await handleFetchData();
            setNotification(response);
          },
        });
      },
    },
    {
      label: 'Editar',
      handler: () => history.push({ pathname: '/dashboard/create-advicer', state: item }),
    },
    {
      label: 'Ver usuarios',
      handler: () => setUsersByAdvicerModalStatus(item.id),
    },
  ];

  return (
    <div className={styles.wrapperMaster}>
      {usersByAdvicerModalStatus && (
        <UsersByAdvicer advicerId={usersByAdvicerModalStatus} onClose={() => setUsersByAdvicerModalStatus(false)} />
      )}
      <HeaderForLists
        title="Asesores"
        value={keywords}
        onChange={setKeywords}
        buttonRedirect="/dashboard/create-advicer"
      />
      {loading && <Loading />}
      {!loading && !!advicersFiltered.length && <AdvicerListBody items={advicersFiltered} getOptions={getOptions} />}
      {!loading && !advicersFiltered.length && (
        <EmptyContent message={keywords ? 'No encontramos resultados' : 'Aún no tienes publicaciones'} />
      )}
    </div>
  );
};

export default AdvicerList;
