import { Box } from '@material-ui/core';
import { MoreVert, Star, Publish } from '@material-ui/icons';
import { array, bool, func } from 'prop-types';
import React from 'react';
import Options from 'src/components/options';
import Card from 'src/components/card';
import composeClassNames from 'src/helpers/composeClassNames';
import { toLargeStringDate } from 'src/helpers/dates';
import styles from './styles.module.css';

const ProgramHistory = ({ loading, items, getOptions }) => (
  <>
    {(!loading || !!items.length) && (
      <div>
        <Box fontWeight="600" marginBottom="1em">
          Historial de actualizaciones
        </Box>
        <Card className={styles.card}>
          {!!items.length && (
            <>
              <div className={composeClassNames(styles.tableRow, styles.tableRowHeader)}>
                <div className={styles.tableCellStatus}>Estado</div>
                <div className={styles.tableCelldate}>Fecha</div>
                <div className={styles.tableCellOptions} />
              </div>

              {items.map((item) => (
                <div className={styles.tableRow} key={item.id}>
                  <div
                    className={composeClassNames(
                      styles.tableCellStatus,
                      item.isActive ? styles.tableCellStatusActive : '',
                    )}
                  >
                    {item.isActive ? <Star /> : <Publish />}
                    {item.isActive ? 'Activo' : 'Implementado'}
                  </div>
                  <div className={styles.tableCelldate}>{toLargeStringDate(item.createdAt)}</div>
                  <div className={styles.tableCellOptions}>
                    <Options optionsList={getOptions(item)} className={styles.options}>
                      <MoreVert />
                    </Options>
                  </div>
                </div>
              ))}
            </>
          )}

          {!items.length && !loading && (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="4em" padding="2em" color="gray">
              Aún no se ha cargado el programa
            </Box>
          )}
        </Card>
        <Box marginTop="1em" />
      </div>
    )}

    {loading && !items.length && (
      <Card className={styles.card}>
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="8em">
          Cargando historial
        </Box>
      </Card>
    )}
  </>
);

ProgramHistory.propTypes = {
  loading: bool.isRequired,
  items: array.isRequired,
  getOptions: func.isRequired,
};

export default ProgramHistory;
