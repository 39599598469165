import React, { useEffect, useState } from 'react';
import tipsSrc from 'src/assets/tips.jpg';
import tipsmSrc from 'src/assets/tipsm.jpg';
import fotterSrc from 'src/assets/asesorfooter.jpg';
import fotterMSrc from 'src/assets/asesores-footer-poltri.jpg';
import { Box } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import CoverFooterPage from '../../components/coverFooterPage';
import CoverPage from '../../components/coverPage';
import Footer from '../../components/footer';
import Header from '../../components/header';
import styles from './styles.module.css';
import Container from '../../components/container';
import Aside from './components/aside';
import Loading from '../../components/loading';
import EmptyContent from '../../components/emptyContent';
import useFetch from '../../hooks/useFetch';
import { getTipsList } from '../../core/models/tips';
import List from './components/list';
import Detail from './components/detail';

const Tips = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [currentCategory, setCurrentCategory] = useState('ALL');
  const { id = null } = useParams(); // if is null render list

  useFetch(async () => {
    const response = await getTipsList();
    setItems(response);
    setLoading(false);
  });

  // filter by category
  let itemsFiltered = [...items];
  if (currentCategory && currentCategory !== 'ALL') {
    itemsFiltered = itemsFiltered.filter((currentItem) => currentItem.category === currentCategory);
  }

  const handleCategoryChange = (newCategory) => {
    if (id) history.replace('/tips');
    setCurrentCategory(newCategory);
    setTimeout(() => document.getElementById('listTarget').scrollIntoView({ behavior: 'auto' }), 10);
  };

  const handleGetRecetPosts = () => {
    if (items.length <= 3) return null;
    return items.slice(0, 3);
  };

  // remove category when show post detail
  useEffect(() => {
    setCurrentCategory(!id ? 'ALL' : null);
  }, [id]);

  return (
    <>
      <Header overlap />
      <CoverPage content="Tips" picture={tipsSrc} mobilePicture={tipsmSrc} />
      <Container small>
        <div className={styles.flexWrapper} id="listTarget">
          <div className={styles.body}>
            {loading && (
              <Box marginTop="-7em">
                <Loading message="Cargando tips" />
              </Box>
            )}
            {!loading && !itemsFiltered.length && (
              <Box marginTop="-7em">
                <EmptyContent message={!currentCategory ? 'Aún no hay tips' : 'No encontramos resultados'} />
              </Box>
            )}
            {!loading && !!itemsFiltered.length && !id && <List items={itemsFiltered} />}
            {!loading && !!items.length && !!id && <Detail item={items.find((i) => i.id === id)} />}
          </div>
          <div className={styles.aside}>
            <Aside
              onCategoryChange={handleCategoryChange}
              category={currentCategory}
              recentItems={handleGetRecetPosts()}
            />
          </div>
        </div>
      </Container>
      <CoverFooterPage
        picture={fotterSrc}
        pictureMobile={fotterMSrc}
        content="EL DOLOR DE HOY ES EL BENEFICIO DE MAÑANA"
      />
      <Footer />
    </>
  );
};

export default Tips;
