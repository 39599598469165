import React from 'react';
import programaSrc from 'src/assets/programa.jpg';
import adornmentSrc from 'src/assets/adornment.svg';
import { Link } from 'react-router-dom';
import Button from '../../../../components/button';
import Container from '../../../../components/container';
import styles from './styles.module.css';

const Program = () => (
  <div className={styles.fullWrapper}>
    <Container disabledVerticalPadding>
      <div className={styles.body}>
        <div className={styles.dividerWrapper}>
          <div className={styles.elemenDivided}>
            <img src={programaSrc} alt="programa" className={styles.picture} />
          </div>
          <div className={`${styles.elemenDivided} ${styles.dataWrapper}`}>
            <div className={styles.title}>
              SIEMPRE PARECE IMPOSIBLE HASTA QUE SE HACE
              {/* Conoce los programas de Feminne Life, donde te acompañaremos todos los días */}
            </div>
            <div className={styles.subtitle}>
              {/* Comunícate con tu asesor todos los días y notarás paso a paso el cambio en todo tu cuerpo. Recupera tu
              movilidad, fuerza y resistencia en este programa donde poco a poco te llevamos al siguiente nivel. Feminne
              Life existe para demostrarte que tu cuerpo es lo más importante que tienes. */}
              Conoce nuestros programas de entrenamiento
            </div>
            <Link to="/programa-de-entrenamiento">
              <Button className={styles.button}>VER PROGRAMAS</Button>
            </Link>
          </div>
        </div>
      </div>
    </Container>
    <img src={adornmentSrc} alt="adornment" className={styles.addornment} />
  </div>
);

export default Program;
