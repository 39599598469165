import React from 'react';
import { Link } from 'react-router-dom';
import ciclesrc from 'src/assets/1.jpg';
import tennisSrc from 'src/assets/2.jpg';
import tshirtSrc from 'src/assets/3.jpg';
import pantsSrc from 'src/assets/4.jpg';
import Container from 'src/components/container';
import Picture from 'src/components/picture';
import styles from './styles.module.css';

const Categories = () => (
  <Container disabledVerticalPadding>
    <div className={styles.body}>
      <div className={styles.title}>BOUTIQUE</div>
      <div className={styles.subtitle}>Nuestros productos</div>
      <div className={styles.gridWrapper}>
        <div className={styles.cardWrapper}>
          <Link
            // to={{ pathname: '/articulos', state: { category: 'sportswear', subCategory: 'set' } }}
            to={{ pathname: '/articulos' }}
            className={styles.card}
          >
            <Picture className={styles.picture} src={ciclesrc} />
            {/* <div className={styles.categoryName}>Chamarra impermeable</div> */}
          </Link>
        </div>
        <div className={styles.cardWrapper}>
          <Link
            // to={{ pathname: '/articulos', state: { category: 'sportswear', subCategory: 'sportsShoes' } }}
            to={{ pathname: '/articulos' }}
            className={styles.card}
          >
            <Picture className={styles.picture} src={tennisSrc} />
            {/* <div className={styles.categoryName}>Ciclismo</div> */}
          </Link>
        </div>
        <div className={styles.cardWrapper}>
          <Link
            // to={{ pathname: '/articulos', state: { category: 'sportswear', subCategory: 'tShirt' } }}
            to={{ pathname: '/articulos' }}
            className={styles.card}
          >
            <Picture className={styles.picture} src={tshirtSrc} />
            {/* <div className={styles.categoryName}>Speed suit</div> */}
          </Link>
        </div>
        <div className={styles.cardWrapper}>
          <Link
            // to={{ pathname: '/articulos', state: { category: 'sportswear', subCategory: 'pants' } }}
            to={{ pathname: '/articulos' }}
            className={styles.card}
          >
            <Picture className={styles.picture} src={pantsSrc} />
            {/* <div className={styles.categoryName}>Tag dryft</div> */}
          </Link>
        </div>
      </div>
    </div>
  </Container>
);

export default Categories;
