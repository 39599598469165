export const failed = (message = 'Error, algo salió mal') => ({
  error: true,
  success: false,
  status: 'error',
  message,
});

export const success = (message = 'operacion exitosa', data = {}) => ({
  error: false,
  success: true,
  status: 'success',
  data,
  message,
});

const response = { failed, success };

export default response;
