import formatObect from '../../helpers/formatObject';
import response from '../../helpers/response';
import database from '../database';
import storage from '../storage';

const createProduct = async (values) => {
  const secureData = {
    title: values.title,
    price: parseFloat(values.price),
    category: values.category,
    description: values.description,
    subCategory: values.subCategory || null,
    gender: values.gender || null,
    isEnabled: true,
    quantity: Number.parseInt(values.quantity, 10),
  };
  const data = await database.create('products', secureData);
  if (!data)
    return {
      status: 'error',
      message: 'Algo salio mal',
    };
  return {
    status: 'success',
    message: 'operacion exitosa',
    data,
  };
};

export const updateProduct = async (id, newValues) => {
  const allows = [
    'title',
    'price',
    'category',
    'subCategory',
    'description',
    'gender',
    'picture',
    'pictures',
    'isEnabled',
    'quantity',
  ];
  const secureData = formatObect(newValues, allows, true);
  // parse integers
  if (secureData.price) secureData.price = parseFloat(secureData.price);
  if (secureData.quantity) secureData.quantity = parseInt(secureData.quantity, 10);

  const status = await database.update(`products/${id}`, secureData);
  if (!status) return response.failed();
  return response.success();
};

export const getAll = async ({ onlyInStock = false } = {}) => {
  let producs = await database.getList('products', null, ['createdAt', 'desc'], null).next();
  if (onlyInStock) {
    producs = producs.filter((currentElement) => currentElement.quantity > 0);
  }
  return producs;
};

export const deleteProduct = async (productId) => {
  const { pictures = [], picture = '' } = await database.getDocument(`products/${productId}`);
  const filesToDelete = [...pictures, picture];
  await Promise.all(filesToDelete.map((currentFile) => storage.deletePicture(currentFile)));
  const status = await database.remove(`products/${productId}`);
  return status ? response.success('El producto se eliminó correctamente') : response.failed();
};

export const getProduct = async (productId) => {
  const data = await database.getDocument(`/products/${productId}`);
  return data;
};

export default {
  deleteProduct,
  createProduct,
  updateProduct,
  getAll,
  getProduct,
};
