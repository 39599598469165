export const input = (errorMessage = 'Algunos campos nos son válidos') => {
  const validator = (inputValue) => {
    if (!inputValue) return errorMessage;
    if (inputValue.toString().length < 1) return errorMessage;
    return false;
  };
  return validator;
};

export const phone = (errorMessage = 'El número de teléfono no es válido') => {
  const validator = (inputValue) => {
    if (!inputValue) return errorMessage;
    if (inputValue.toString().length < 10) return errorMessage;
    return null;
  };
  return validator;
};

export const email = (errorMessage = 'El correo no es válido') => {
  const validator = (inputValue) => {
    const isValid = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(inputValue);
    return isValid ? null : errorMessage;
  };
  return validator;
};

export const password = (errorMessage = 'La contraseña debe tener almenos 8 caracteres') => {
  const validator = (inputValue) => {
    if (!inputValue) return errorMessage;
    if (inputValue.toString().length < 8) return errorMessage;
    return null;
  };
  return validator;
};

export const number = (errorMessage = 'El precio no es válido') => {
  const validator = (inputValue) => {
    if (!inputValue) return errorMessage;
    if (Number.isNaN(Number.parseFloat(inputValue))) return errorMessage;
    if (!parseFloat(inputValue)) return errorMessage;
    return false;
  };
  return validator;
};

const validaotors = {
  input,
  phone,
  password,
  email,
  number,
};

export default validaotors;
