/* eslint-disable no-unused-vars */
import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import purchases from 'src/core/models/purchases';
import openPayModel from 'src/core/models/openpay';
import { Redirect, useLocation } from 'react-router-dom';
import useForm from '../../hooks/useForm';
import useAlert from '../../modules/alert/useAlert';
import View from './view';
import inputValidators from './inputValidators';
import useErrorMessage from '../../hooks/useErrorMessage';
import useLoading from '../../modules/loading/useLoading';
import validaotors from '../../helpers/getValidators';

const Checkout = () => {
  const { state } = useLocation();
  const productData = state || {};
  const [activeStep, setActiveStep] = useState(0);
  const error = useErrorMessage();
  const form = useForm({ formPay: 'price' });
  const { inscription } = productData;
  const setAlert = useAlert();
  const history = useHistory();
  const setLoading = useLoading();

  // calculate prices
  const subTotal = productData ? productData[form.values.formPay] : 0;
  const total = subTotal + productData.inscription;

  // methods
  const handlePaymentApprov = async (orderId = null, reference = null) => {
    const { data } = await purchases.savePurchase({
      digital: true,
      formPay: form.values.formPay,
      userInfo: form.values,
      items: [{ ...productData, quantity: 1 }],
      status: 'pending',
      orderId,
      total,
      subTotal,
      inscription,
      reference,
    });
    setLoading(false);
    history.replace(`/compra-exitosa/${data.id}`);
  };

  const handleDelete = () =>
    setAlert({
      title: '¿Seguro quieres eliminar este producto de tu carrito?',
      action: () => history.push('/articulos'),
    });

  const handlePayWithStore = async () => {
    setLoading(true);
    const response = await openPayModel.payWithStore({
      deviceId: window.OpenPay.deviceData.setup(),
      email: form.values.email,
      name: `${form.values.name} ${form.values.lastName}`,
      total,
    });
    setLoading(false);
    if (response.error) {
      error.setErrorMessage('Error algo salió mal');
      setActiveStep(0);
    } else {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      await handlePaymentApprov(response.data.id, response.data.payment_method.reference);
    }
  };

  const handlePayWithSpei = async () => {
    setLoading(true);
    const response = await openPayModel.payWithSpei({
      deviceId: window.OpenPay.deviceData.setup(),
      email: form.values.email,
      name: `${form.values.name} ${form.values.lastName}`,
      total,
    });
    setLoading(false);
    if (response.error) {
      error.setErrorMessage('Error algo salió mal');
      setActiveStep(0);
    } else {
      await handlePaymentApprov(response.data.id);
    }
  };

  const handlePayWithCard = async () => {
    setLoading(true);
    const response = await openPayModel.payWithCard({
      cardCode: form.values.cardCode,
      cardMonth: form.values.cardMonth,
      cardNumber: form.values.cardNumber,
      cardName: form.values.cardName,
      cardYear: form.values.cardYear,
      deviceId: window.OpenPay.deviceData.setup(),
      email: form.values.email,
      name: `${form.values.name} ${form.values.lastName}`,
      total,
    });
    setLoading(false);
    if (response.error) {
      error.setErrorMessage(response.message);
      setActiveStep(0);
    } else {
      await handlePaymentApprov(response.data.id);
    }
  };

  const handlePayManual = async () => {
    setLoading(true);
    await handlePaymentApprov();
  };

  const generalInfoValidator = () => {
    const { firstErrorMessage } = form.validateInputs(inputValidators.generalInfoValidator);
    if (!firstErrorMessage) return true;
    error.setErrorMessage(firstErrorMessage);
    return false;
  };

  const validateMethoPay = () => {
    const { firstErrorMessage } = form.validateInputs({ methodPay: validaotors.input('Selecciona un método de pago') });
    if (!firstErrorMessage) return true;
    error.setErrorMessage(firstErrorMessage);
    return false;
  };

  const validateCardForm = () => {
    const { firstErrorMessage } = form.validateInputs(inputValidators.cardFormValidator);
    if (!firstErrorMessage) return true;
    error.setErrorMessage(firstErrorMessage);
    return false;
  };

  const handleAcept = () => {
    if (activeStep === 0 && generalInfoValidator()) setActiveStep(1);
    if (activeStep === 1 && validateMethoPay() && form.values.methodPay === 'store') handlePayWithStore();
    if (activeStep === 1 && validateMethoPay() && form.values.methodPay === 'spei') handlePayWithSpei();
    if (activeStep === 1 && validateMethoPay() && form.values.methodPay === 'card') setActiveStep(3);
    if (activeStep === 1 && validateMethoPay() && form.values.methodPay === 'manual') handlePayManual(3);
    if (activeStep === 3 && form.values.methodPay === 'card' && validateCardForm()) handlePayWithCard();
  };

  const handleBack = () => {
    if (activeStep === 0) history.push('/articulos');
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleGetButtonText = () => {
    if (activeStep === 2) return 'PAGAR';
    if (activeStep === 1 && form.values.methodPay === 'paypal') return 'Pagar con PayPal';
    if (activeStep === 1 && form.values.methodPay === 'manual') return 'Generar orden de pago';
    if (activeStep === 1 && (form.values.methodPay === 'spei' || form.values.methodPay === 'store'))
      return 'Generar orden de pago';
    return 'Siguiente';
  };

  const paypalConfig = useMemo(
    () => ({
      createOrder: (_data, actions) => {
        setLoading(true);
        return actions.order.create({ purchase_units: [{ amount: { value: total } }] });
      },
      onApprove: async (_data, actions) => {
        const { id } = await actions.order.capture();
        await handlePaymentApprov(id);
      },
      onCancel: () => setLoading(false),
    }),
    [total, form.values],
  );

  if (!state) return <Redirect to="/articulos" />;

  return (
    <View
      activeStep={activeStep}
      productData={productData}
      subTotal={subTotal}
      inscription={inscription}
      total={total}
      errorMessage={error.errorMessage}
      values={form.values}
      buttonText={handleGetButtonText()}
      paypalConfig={paypalConfig}
      onDelete={handleDelete}
      getInputProps={form.getInputProps}
      onAcept={handleAcept}
      onBack={handleBack}
    />
  );
};

export default Checkout;
