// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_input__iLabs {\r\n  width: 100%;\r\n  box-sizing: border-box;\r\n  outline: none;\r\n  border: 1px solid #ccc;\r\n  border-radius: .5em;\r\n  height: 2.8em;\r\n  padding: .5em .5em;\r\n  display: flex;\r\n  align-items: center;\r\n}\r\n.styles_input__iLabs::-webkit-input-placeholder {\r\n  font-size: 1em;\r\n}\r\n.styles_input__iLabs:-ms-input-placeholder {\r\n  font-size: 1em;\r\n}\r\n.styles_input__iLabs::-ms-input-placeholder {\r\n  font-size: 1em;\r\n}\r\n.styles_input__iLabs::placeholder {\r\n  font-size: 1em;\r\n}", "",{"version":3,"sources":["webpack://src/components/inputs/styles.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,sBAAsB;EACtB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,aAAa;EACb,kBAAkB;EAClB,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,cAAc;AAChB;AAFA;EACE,cAAc;AAChB;AAFA;EACE,cAAc;AAChB;AAFA;EACE,cAAc;AAChB","sourcesContent":[".input {\r\n  width: 100%;\r\n  box-sizing: border-box;\r\n  outline: none;\r\n  border: 1px solid #ccc;\r\n  border-radius: .5em;\r\n  height: 2.8em;\r\n  padding: .5em .5em;\r\n  display: flex;\r\n  align-items: center;\r\n}\r\n.input::placeholder {\r\n  font-size: 1em;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": "styles_input__iLabs"
};
export default ___CSS_LOADER_EXPORT___;
