// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_wrapper__27dzl {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  margin-left: 1em;\n}\n.styles_wrapper__27dzl svg {\n  font-size: 2em;\n  cursor: pointer;\n}\n.styles_selected__1eLM1 {\n  color: var(--cyan);\n}", "",{"version":3,"sources":["webpack://src/pages/dashboard/pages/salesList/components/gridController/styles.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;EACnB,gBAAgB;AAClB;AACA;EACE,cAAc;EACd,eAAe;AACjB;AACA;EACE,kBAAkB;AACpB","sourcesContent":[".wrapper {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  margin-left: 1em;\n}\n.wrapper svg {\n  font-size: 2em;\n  cursor: pointer;\n}\n.selected {\n  color: var(--cyan);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "styles_wrapper__27dzl",
	"selected": "styles_selected__1eLM1"
};
export default ___CSS_LOADER_EXPORT___;
