// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_numberWrapper__loK7s {\r\n  background-color: #80808069;\r\n  width: 1.5em;\r\n  height: 1.5em;\r\n  font-size: .9em;\r\n  border-radius: 50%;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  font-weight: bold;\r\n  color: var(--black);\r\n}\r\n.styles_numberWrapperCOmpleted__2oSUy {\r\n  background-color: cyan!important;\r\n  color: #fff;\r\n}\r\n.styles_check__3a_-e {\r\n  font-size: 1.3em!important;\r\n}\r\n.styles_checkCompleted__3Bwju {\r\n  color: #fff;\r\n}\r\n.styles_label__30Cq8 {\r\n  font-size: 1em!important;\r\n}", "",{"version":3,"sources":["webpack://src/components/stepper/styles.module.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,YAAY;EACZ,aAAa;EACb,eAAe;EACf,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,iBAAiB;EACjB,mBAAmB;AACrB;AACA;EACE,gCAAgC;EAChC,WAAW;AACb;AACA;EACE,0BAA0B;AAC5B;AACA;EACE,WAAW;AACb;AACA;EACE,wBAAwB;AAC1B","sourcesContent":[".numberWrapper {\r\n  background-color: #80808069;\r\n  width: 1.5em;\r\n  height: 1.5em;\r\n  font-size: .9em;\r\n  border-radius: 50%;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  font-weight: bold;\r\n  color: var(--black);\r\n}\r\n.numberWrapperCOmpleted {\r\n  background-color: cyan!important;\r\n  color: #fff;\r\n}\r\n.check {\r\n  font-size: 1.3em!important;\r\n}\r\n.checkCompleted {\r\n  color: #fff;\r\n}\r\n.label {\r\n  font-size: 1em!important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"numberWrapper": "styles_numberWrapper__loK7s",
	"numberWrapperCOmpleted": "styles_numberWrapperCOmpleted__2oSUy",
	"check": "styles_check__3a_-e",
	"checkCompleted": "styles_checkCompleted__3Bwju",
	"label": "styles_label__30Cq8"
};
export default ___CSS_LOADER_EXPORT___;
