// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_menuWrapper__3mVrc {\n  font-size: 1.2em;\n  color: var(--gray);\n}\n.styles_option__J4XgA {\n  padding: .5em 0em;\n  cursor: pointer;\n}\n.styles_block__11e4S {\n  margin-left: 1em;\n}\n.styles_optionSelected__3_fVa {\n  color: #000;\n}", "",{"version":3,"sources":["webpack://src/pages/productsList/components/filters/styles.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;AACA;EACE,iBAAiB;EACjB,eAAe;AACjB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,WAAW;AACb","sourcesContent":[".menuWrapper {\n  font-size: 1.2em;\n  color: var(--gray);\n}\n.option {\n  padding: .5em 0em;\n  cursor: pointer;\n}\n.block {\n  margin-left: 1em;\n}\n.optionSelected {\n  color: #000;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menuWrapper": "styles_menuWrapper__3mVrc",
	"option": "styles_option__J4XgA",
	"block": "styles_block__11e4S",
	"optionSelected": "styles_optionSelected__3_fVa"
};
export default ___CSS_LOADER_EXPORT___;
