import { useMediaQuery } from '@material-ui/core';
import { string } from 'prop-types';
import React from 'react';
import pictureSrc from 'src/assets/private/cover.png';
import pictureMSrc from 'src/assets/private/coverm.png';
import styles from './styles.module.css';

const PrivateCover = ({ backgroundColor }) => {
  const isMobile = useMediaQuery('(max-width:600px)');

  const inlineStyle = {};
  if (backgroundColor) inlineStyle.backgroundColor = backgroundColor;

  return (
    <img src={isMobile ? pictureMSrc : pictureSrc} className={styles.picture} alt="cover private" style={inlineStyle} />
  );
};

PrivateCover.defaultProps = {
  backgroundColor: '',
};

PrivateCover.propTypes = {
  backgroundColor: string,
};

export default PrivateCover;
