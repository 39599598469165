// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_flexWrapper__3qAKz {\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-start;\n}\n.styles_body__1Ahic {\n  width: 60%;\n}\n.styles_aside__s0ZDW {\n  width: 30%;\n  position: -webkit-sticky;\n  position: sticky;\n  top: 3em;\n}\n\n@media screen and (max-width:1000px) {\n  .styles_flexWrapper__3qAKz {\n    font-size: .9em;\n  }\n  .styles_body__1Ahic {\n    width: 70%;\n  }\n  .styles_aside__s0ZDW {\n    width: 25%;\n  }\n}\n\n@media screen and (max-width:800px) {\n  .styles_flexWrapper__3qAKz {\n    font-size: .8em;\n  }\n}\n\n@media screen and (max-width:800px) {\n  .styles_aside__s0ZDW {\n    display: none;\n  }\n  .styles_body__1Ahic {\n    margin: auto;\n    width: 90%;\n  }\n}\n\n@media screen and (max-width:600px) {\n  .styles_flexWrapper__3qAKz {\n    font-size: .7em;\n  }\n}", "",{"version":3,"sources":["webpack://src/pages/tips/styles.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,uBAAuB;AACzB;AACA;EACE,UAAU;AACZ;AACA;EACE,UAAU;EACV,wBAAgB;EAAhB,gBAAgB;EAChB,QAAQ;AACV;;AAEA;EACE;IACE,eAAe;EACjB;EACA;IACE,UAAU;EACZ;EACA;IACE,UAAU;EACZ;AACF;;AAEA;EACE;IACE,eAAe;EACjB;AACF;;AAEA;EACE;IACE,aAAa;EACf;EACA;IACE,YAAY;IACZ,UAAU;EACZ;AACF;;AAEA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":[".flexWrapper {\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-start;\n}\n.body {\n  width: 60%;\n}\n.aside {\n  width: 30%;\n  position: sticky;\n  top: 3em;\n}\n\n@media screen and (max-width:1000px) {\n  .flexWrapper {\n    font-size: .9em;\n  }\n  .body {\n    width: 70%;\n  }\n  .aside {\n    width: 25%;\n  }\n}\n\n@media screen and (max-width:800px) {\n  .flexWrapper {\n    font-size: .8em;\n  }\n}\n\n@media screen and (max-width:800px) {\n  .aside {\n    display: none;\n  }\n  .body {\n    margin: auto;\n    width: 90%;\n  }\n}\n\n@media screen and (max-width:600px) {\n  .flexWrapper {\n    font-size: .7em;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"flexWrapper": "styles_flexWrapper__3qAKz",
	"body": "styles_body__1Ahic",
	"aside": "styles_aside__s0ZDW"
};
export default ___CSS_LOADER_EXPORT___;
