import { Box } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { array, oneOfType, string, object, func } from 'prop-types';
import React from 'react';
import Dropzone from 'src/components/dropzone';
import ErrorMessage from '../../../../../../components/errorMessage';
import getFilePreviewUrl from '../../../../../../helpers/normalizeImagesUrl';
import styles from './styles.module.css';

const Multimedia = (props) => (
  <div className={styles.wrapper}>
    <ErrorMessage message={props.errorMessage} />
    <Box textAlign="center" fontSize="1.2em">
      Imagen principal
    </Box>
    <Box margin="1em 0em">
      {!props.picture && (
        <Dropzone className={styles.dropzone} onChange={props.onPictureSelect}>
          Seleccionar imagen
        </Dropzone>
      )}
      {!!props.picture && (
        <div className={styles.pictureListContainer}>
          <div className={styles.pictureWrapper}>
            <div className={styles.deleteIconWrapper} onClick={() => props.onPictureDelete()} role="button">
              <Delete className={styles.deleteIcon} />
            </div>
            <img className={styles.image} src={getFilePreviewUrl(props.picture)} alt="ima" />
          </div>
        </div>
      )}
    </Box>
    <Box textAlign="center" fontSize="1.2em">
      Imágenes
    </Box>
    <Box margin="1em 0em">
      <Dropzone className={styles.dropzone} multiple onChange={props.onPicturesSelect}>
        Seleccionar imágenes
      </Dropzone>
    </Box>
    {!!props.pictures && !!props.pictures.length && (
      <div className={styles.pictureListContainer}>
        {props.pictures.map((currentPic) => (
          <div className={styles.pictureWrapper} key={typeof currentPic === 'string' ? currentPic : currentPic.name}>
            <div className={styles.deleteIconWrapper}>
              <Delete className={styles.deleteIcon} onClick={() => props.onPicturesDelete(currentPic)} />
            </div>
            <img
              className={styles.image}
              src={getFilePreviewUrl(currentPic)}
              alt={typeof currentPic === 'string' ? currentPic : currentPic.name}
            />
          </div>
        ))}
      </div>
    )}
  </div>
);

Multimedia.defaultProps = {
  picture: null,
  errorMessage: '',
};

Multimedia.propTypes = {
  onPictureDelete: func.isRequired,
  onPicturesDelete: func.isRequired,
  onPictureSelect: func.isRequired,
  onPicturesSelect: func.isRequired,
  pictures: array.isRequired,
  picture: oneOfType([string, object]),
  errorMessage: string,
};

export default Multimedia;
