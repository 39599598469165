// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_optionsWrapper__Iu92a {\r\n  padding: 1em 0em;\r\n}\r\n.styles_menu__3ateR {\r\n  margin-top: 2em;\r\n}\r\n.styles_optionList__1-Hv0 {\r\n  padding: .3em 1.5em;\r\n  cursor: pointer;\r\n}\r\n.styles_optionList__1-Hv0:hover {\r\n  background-color: var(--cyan);\r\n  color: #fff;\r\n}", "",{"version":3,"sources":["webpack://src/components/options/styles.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;AACA;EACE,eAAe;AACjB;AACA;EACE,mBAAmB;EACnB,eAAe;AACjB;AACA;EACE,6BAA6B;EAC7B,WAAW;AACb","sourcesContent":[".optionsWrapper {\r\n  padding: 1em 0em;\r\n}\r\n.menu {\r\n  margin-top: 2em;\r\n}\r\n.optionList {\r\n  padding: .3em 1.5em;\r\n  cursor: pointer;\r\n}\r\n.optionList:hover {\r\n  background-color: var(--cyan);\r\n  color: #fff;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"optionsWrapper": "styles_optionsWrapper__Iu92a",
	"menu": "styles_menu__3ateR",
	"optionList": "styles_optionList__1-Hv0"
};
export default ___CSS_LOADER_EXPORT___;
