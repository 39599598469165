import { Box } from '@material-ui/core';
import { string } from 'prop-types';
import React, { useState } from 'react';
import advicerModel from 'src/core/models/advisers';
import useFetch from '../../../../hooks/useFetch';
import styles from './styles.module.css';

const Adviver = (props) => {
  const [advicer, setAdviver] = useState(null);

  // fetch initialData
  useFetch(async () => {
    const responseData = await advicerModel.getOne(props.id);
    setAdviver(responseData);
  });

  if (!advicer) return null;

  return (
    <div className={styles.wrapper}>
      <img className={styles.picture} src={advicer.picture} alt="user-avatar" />
      <Box>
        <Box fontWeight="600" marginBottom=".5em">
          {advicer.name}
        </Box>
        <Box fontWeight="normal">{advicer.phone}</Box>
        <Box fontWeight="normal">{advicer.email}</Box>
      </Box>
    </div>
  );
};

Adviver.propTypes = {
  id: string.isRequired,
};

export default Adviver;
