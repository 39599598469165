import { element, oneOfType, string, array, any } from 'prop-types';
import React from 'react';
import Lottie from 'react-lottie';
import Container from 'src/components/container';
import aminationPayload from 'src/assets/shoes.json';
import styles from './styles.module.css';

const Waiting = (props) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: aminationPayload,
  };

  return (
    <div className={styles.fullWrapper}>
      <Container extraSmall>
        <div className={styles.title}>{props.children}</div>
        <Lottie width="8em" options={defaultOptions} />
        {!!props.caption && props.caption}
      </Container>
    </div>
  );
};

Waiting.defaultProps = {
  caption: null,
};

Waiting.propTypes = {
  children: oneOfType([array, element, string]).isRequired,
  caption: any,
};

export default Waiting;
