import { Box, useMediaQuery } from '@material-ui/core';
import { PhotoLibrary, PlayCircleFilled } from '@material-ui/icons';
import { arrayOf, instanceOf, number, oneOfType, string } from 'prop-types';
import React, { useState } from 'react';
import Picture from 'src/components/picture';
import AlternativeButton from 'src/components/alternativeButton';
import ModalPictures from 'src/components/modalPictures';
import { toLargeStringDate } from 'src/helpers/dates';
import styles from './styles.module.css';
import formatPrice from '../../../../helpers/formatPrice';
import { usePlayer } from '../../../../modules/youtubePlayer';

const PropductCard = (props) => {
  const isMboile = useMediaQuery('(max-width:600px)');
  const [isModalPicturesOpen, setIsModalPicturesOpen] = useState(false);
  const player = usePlayer();

  const handleModalPicturesOpen = () => setIsModalPicturesOpen(true);
  const handleModalPicturesclose = () => setIsModalPicturesOpen(false);

  const currentDate = new Date();
  currentDate.setHours(0);
  currentDate.setMinutes(0);
  currentDate.setSeconds(0);
  currentDate.setMilliseconds(0);

  const eventDate = new Date(props.date);
  eventDate.setHours(0);
  eventDate.setMinutes(0);
  eventDate.setSeconds(0);
  eventDate.setMilliseconds(0);

  const showPrice = eventDate.getTime() >= currentDate.getTime();

  return (
    <>
      <ModalPictures
        pictures={[props.picture, ...props.pictures]}
        open={isModalPicturesOpen}
        onClose={handleModalPicturesclose}
      />
      <div className={styles.wrapper}>
        {isMboile && (
          <Box flexGrow="1" fontSize="1.2em">
            {!!props.currentView && (
              <Box marginBottom=".5em" color="var(--rose)">
                {props.currentView === 'next' ? 'Proximo Evento' : 'Evento Pasado'}
              </Box>
            )}
            <div className={styles.title}>{props.title}</div>
            {!!props.price && !!showPrice && <div className={styles.title}>Precio: $ {formatPrice(props.price)}</div>}
            {!props.price && !!showPrice && <div className={styles.title}>Precio: Gratis</div>}
            <div className={styles.date}>{toLargeStringDate(props.date)}</div>
          </Box>
        )}
        <Picture src={props.picture} height={isMboile ? 50 : 145} className={styles.picture} />
        <div className={styles.data}>
          {!isMboile && (
            <>
              {!!props.currentView && (
                <Box marginBottom=".5em" color="var(--rose)">
                  {props.currentView === 'next' ? 'Proximo Evento' : 'Evento Pasado'}
                </Box>
              )}
              <div className={styles.title}>{props.title}</div>
              {!!props.price && !!showPrice && <div className={styles.title}>Precio: $ {formatPrice(props.price)}</div>}
              {!props.price && !!showPrice && <div className={styles.title}>Precio: Gratis</div>}
              <div className={styles.date}>{toLargeStringDate(props.date)}</div>
            </>
          )}
          <div className={styles.description}>{props.description}</div>
          <Box display="flex">
            <AlternativeButton id={styles.button} onClick={handleModalPicturesOpen}>
              <>
                <PhotoLibrary />
                Ver Fotos
              </>
            </AlternativeButton>
            {!!props.youtube && (
              <>
                <Box paddingRight="1em" />
                <AlternativeButton id={styles.button} onClick={() => player.play(props.youtube)}>
                  <>
                    <PlayCircleFilled />
                    Ver video
                  </>
                </AlternativeButton>
              </>
            )}
          </Box>
        </div>
      </div>
    </>
  );
};

PropductCard.defaultProps = {
  price: '',
  youtube: null,
  currentView: null,
};

PropductCard.propTypes = {
  title: string.isRequired,
  picture: string.isRequired,
  description: string.isRequired,
  pictures: arrayOf(string).isRequired,
  date: instanceOf(Date).isRequired,
  price: oneOfType([number, string]),
  youtube: string,
  currentView: string,
};

export default PropductCard;
