import { Box } from '@material-ui/core';
import { TipsCategory } from 'src/constants';
import React from 'react';
import { array, func, string } from 'prop-types';
import { useHistory } from 'react-router-dom';
import mapObjects from '../../../../helpers/mapObjects';
import styles from './styles.module.css';
import composeClassNames from '../../../../helpers/composeClassNames';

const Aside = (props) => {
  const history = useHistory();

  const handleSelectPost = (postId) => {
    history.push(`/tips/${postId}`);
  };

  return (
    <div>
      <div className={styles.group}>
        <Box fontSize="1.3em" color="var(--rose)" fontWeight="bold">
          CATEGORÍAS
        </Box>
        <div
          className={composeClassNames(styles.listItem, props.category === 'ALL' ? styles.listItemSelected : '')}
          onClick={() => props.onCategoryChange('ALL')}
          role="button"
        >
          Todos
        </div>
        {mapObjects(TipsCategory, (value, key) => (
          <div
            className={composeClassNames(styles.listItem, props.category === key ? styles.listItemSelected : '')}
            key={key}
            onClick={() => props.onCategoryChange(key)}
            role="button"
          >
            {value}
          </div>
        ))}
      </div>
      {props.recentItems && (
        <div className={styles.group}>
          <Box fontSize="1.3em" color="var(--rose)" fontWeight="bold">
            ENTRADAS RECIENTES
          </Box>
          {props.recentItems.map((item) => (
            <div className={styles.listItem} key={item.id} role="button" onClick={() => handleSelectPost(item.id)}>
              {item.title}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

Aside.defaultProps = {
  category: null,
  recentItems: null,
};

Aside.propTypes = {
  onCategoryChange: func.isRequired,
  category: string,
  recentItems: array,
};

export default Aside;
