import React, { useEffect, useState } from 'react';
import adornmentSrc from 'src/assets/adornment.svg';
import training from 'src/helpers/training';
import { toLargeStringDate } from 'src/helpers/dates';
import { Box } from '@material-ui/core';
import PrivateCover from 'src/components/privateCover';
import Header from 'src/components/header';
import PrivateFooter from 'src/components/privateFooter';
import Loading from 'src/components/loading';
import useFetch from 'src/hooks/useFetch';
import programUserModel from 'src/core/models/programUsers';
import { useSession } from 'src/modules/session';
import TrainingBody from './components/trainingBody';
import Controls from './components/controls';
import Waiting from './components/waiting';
import Title from './components/title';
import ButtonAdmin from '../dashboard/components/button';
import { useCalculatePaymentAlert } from '../../modules/paymentAlert';

const Training = () => {
  const session = useSession();
  const recalculateAlertPayment = useCalculatePaymentAlert();
  const [userdata, setUserdata] = useState({});
  const [loading, setLoading] = useState(true);
  const [isBloqued, setIsBlocked] = useState(false);

  useFetch(async () => {
    const data = await programUserModel.getSingle(session.data.id);
    setUserdata(data);
    setLoading(false);
  });

  // compute is bloked
  useEffect(() => {
    if (userdata) {
      if (userdata.programActive && userdata.paysHistory) {
        const nextPayment = userdata.paysHistory.find((payment) => payment.status === 'pending');
        if (nextPayment) {
          const { limitPay } = nextPayment;
          limitPay.setHours(0);
          limitPay.setMinutes(0);
          limitPay.setSeconds(0);
          limitPay.setMilliseconds(0);

          const currentDate = new Date();

          currentDate.setHours(0);
          currentDate.setMinutes(0);
          currentDate.setSeconds(0);
          currentDate.setMilliseconds(0);
          if (currentDate.getTime() > limitPay.getTime()) setIsBlocked(true);
        }
      }
    }
  }, [userdata]);

  const currentDate = new Date();

  const startTrainingDate = new Date(training.getTrainingStartDate(userdata));

  return (
    <div>
      <Header />
      <PrivateCover />
      <Controls />
      {loading && <Loading message="Cargando entrenamiento" />}
      {!loading && !userdata.programActive && (
        <Waiting>
          Estamos activando tu <br />
          programa de entenamiento.
        </Waiting>
      )}

      {!loading && userdata.programActive && training.getTrainingStartDate(userdata) > currentDate && (
        <Waiting>
          Tu entrenamiento inicia el <br />
          <Box fontWeight="bold" color="var(--rose)">
            {toLargeStringDate(startTrainingDate)}
          </Box>
        </Waiting>
      )}

      {!loading && !!userdata.programActive && training.getTrainingStartDate(userdata) <= currentDate && (
        <>
          {!isBloqued && (
            <>
              <Title title="PROGRAMA DE INICIACIÓN DEPORTIVA" />
              <TrainingBody />
              <img src={adornmentSrc} alt="adornment" width="100%" />
            </>
          )}
          {isBloqued && (
            <Waiting>
              El acceso a tu entrenamiento <br />
              ha sido bloqueado por falta de pago
              <br />
              <br />
              <Box fontSize=".7em">
                <ButtonAdmin onClick={recalculateAlertPayment}>Pagar ahora</ButtonAdmin>
              </Box>
            </Waiting>
          )}
        </>
      )}

      <PrivateFooter />
    </div>
  );
};

export default Training;
