// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_fullWidthWRapper__2hI54 {\n  background: var(--rose);\n  background: linear-gradient(240deg, var(--cyan) 0%, var(--rose) 70%);\n}\n.styles_subHeader__1NOcT {\n  display: flex;\n  justify-content: space-between;\n  padding: 1.3em 0em;\n  font-size: 2em;\n  color: #fff;\n  font-weight: 800;\n}\n@media screen and (max-width:1000px) {\n  .styles_fullWidthWRapper__2hI54 {\n    font-size: .8em;\n  }\n}\n@media screen and (max-width:600px) {\n  .styles_fullWidthWRapper__2hI54 {\n    font-size: .5em;\n  }\n}", "",{"version":3,"sources":["webpack://src/pages/eventsAndActivities/components/subHeader/styles.module.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,oEAAoE;AACtE;AACA;EACE,aAAa;EACb,8BAA8B;EAC9B,kBAAkB;EAClB,cAAc;EACd,WAAW;EACX,gBAAgB;AAClB;AACA;EACE;IACE,eAAe;EACjB;AACF;AACA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":[".fullWidthWRapper {\n  background: var(--rose);\n  background: linear-gradient(240deg, var(--cyan) 0%, var(--rose) 70%);\n}\n.subHeader {\n  display: flex;\n  justify-content: space-between;\n  padding: 1.3em 0em;\n  font-size: 2em;\n  color: #fff;\n  font-weight: 800;\n}\n@media screen and (max-width:1000px) {\n  .fullWidthWRapper {\n    font-size: .8em;\n  }\n}\n@media screen and (max-width:600px) {\n  .fullWidthWRapper {\n    font-size: .5em;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fullWidthWRapper": "styles_fullWidthWRapper__2hI54",
	"subHeader": "styles_subHeader__1NOcT"
};
export default ___CSS_LOADER_EXPORT___;
