/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import useFetch from 'src/hooks/useFetch';
import advisers from 'src/core/models/advisers';
import { useSession } from 'src/modules/session';
import Loading from 'src/components/loading';
import EmptyContent from 'src/components/emptyContent';
import { useHistory } from 'react-router-dom';
import useProgramList from 'src/logic/programList';
import styles from './style.module.css';
import HeaderForLists from '../../components/headerForLists';
import UserCard from './components/userCard';
import ChatModal from './components/chatModal';
import training, { getCurrentWeek } from '../../../../helpers/training';
import programModel from '../../../../core/models/program';
import TraiiningDetail from '../trainingView/components/detail';
import useNotification from '../../../../modules/notifications/useNotification';
import { failed } from '../../../../helpers/response';
import SurveyModal from '../users/components/surveyModal';
import BodyMeasurementsModal from '../users/components/bodyMeasurementsModal&';

const AdvicersUsers = () => {
  const history = useHistory();
  const setNotification = useNotification();
  const session = useSession();
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [trainingData, setTrainingData] = useState([]);
  const [keywords, setKeywords] = useState('');
  const [currentUserSelected, setCurrentUserSelected] = useState(null);
  const [isChatOpen, setChatOpen] = useState(false);
  const [surveyActive, setSurveyActive] = useState(false);
  const [bodyMeasurements, setBodyMeasurements] = useState(false);
  const [currentTraining, setCurrentTraining] = useState(null);
  const { programList } = useProgramList();

  // filter by keywords
  let usersFiltered = [...users];
  if (keywords) {
    usersFiltered = usersFiltered.filter((currentUser) =>
      currentUser.name.toLowerCase().includes(keywords.toString().toLowerCase()),
    );
  }

  /**
   * fetch users list
   */
  useFetch(async () => {
    const responseUsers = await advisers.getUsersByAdvicer(session.data.id);
    setUsers(responseUsers);
    setLoading(false);
  });

  useFetch(async () => {
    if (currentUserSelected && users && users.length) {
      const currentUser = users.find((i) => i.id === currentUserSelected);
      const responseImplement = await programModel.getActiveImplement(currentUser.programId || 'main');
      setTrainingData(responseImplement.payload);
    }
  }, [currentUserSelected, users]);

  /**
   * open trainig detail
   * @param {number} dayIndex
   */
  const handleOpenTraining = (dayIndex) => {
    const currentUser = users.find((i) => i.id === currentUserSelected);
    if (currentUser && training.getTrainingStartDate(currentUser) < new Date()) {
      const weekIndex = getCurrentWeek(currentUser) - 1;
      setCurrentTraining({ weekIndex, dayIndex });
    } else {
      setNotification(failed('Este usuario aun no inicia su entrenamiento'));
    }
  };

  /**
   * transforms program id into program title
   * @param {{}} userData user´s data
   * @returns {string} prohram title
   */
  const getProgram = (userData) => {
    if (!programList || !programList.length) return 'Cargando programa';
    const programId = userData.programId || 'main';
    const userProgram = programList.find((program) => program.id === programId);
    return userProgram.title;
  };

  /**
   * format training detail
   */
  const getTrainingDetailProps = () => ({
    dayData: trainingData[currentTraining.weekIndex].days[currentTraining.dayIndex],
    dayName: trainingData[currentTraining.weekIndex].days[currentTraining.dayIndex].label,
    weekNumber: currentTraining.weekIndex + 1,
  });

  const getOptionList = (userData) => {
    const payload = [
      {
        label: 'ir al chat',
        handler: () => {
          setCurrentUserSelected(userData.id);
          setChatOpen(true);
        },
      },
      {
        label: 'Ver dealles',
        handler: () => history.push(`/dashboard/user-detail/${userData.id}`),
      },
      {
        label: 'Medidas de cuerpo',
        handler: () => setBodyMeasurements(userData.id),
      },
      {
        label: 'Historial deportivo',
        handler: () => history.push(`/dashboard/users-advicer/questions/${userData.id}`),
      },
    ];
    if (userData.surveyAnswered) payload.push({ label: 'Ver encuesta', handler: () => setSurveyActive(userData.id) });
    return payload;
  };

  return (
    <div className={styles.fullWrapper}>
      {surveyActive && <SurveyModal userId={surveyActive} onClose={() => setSurveyActive(false)} />}
      {!loading && !!users.length && (
        <>
          {isChatOpen && (
            <ChatModal
              userId={currentUserSelected}
              onClose={() => setChatOpen(false)}
              onOpenTraining={handleOpenTraining}
            />
          )}

          {!!currentTraining && (
            <TraiiningDetail {...getTrainingDetailProps()} onClose={() => setCurrentTraining(null)} />
          )}

          {bodyMeasurements && (
            <BodyMeasurementsModal userId={bodyMeasurements} onClose={() => setBodyMeasurements(false)} />
          )}

          <HeaderForLists title="Usuarios" value={keywords} onChange={setKeywords} />
          <div className={styles.list}>
            {usersFiltered.map((currentUser) => (
              <div className={styles.userWrapper} key={currentUser.id}>
                <UserCard
                  name={currentUser.name}
                  id={currentUser.id}
                  picture={currentUser.picture}
                  options={getOptionList(currentUser)}
                  currentWeek={getCurrentWeek(currentUser)}
                  program={getProgram(currentUser)}
                />
              </div>
            ))}
          </div>
        </>
      )}

      {loading && <Loading message="Cargando usuarios" />}

      {!loading && !usersFiltered.length && (
        <EmptyContent message={!keywords ? 'Aún no tienes usuarios asignados' : 'No encontramos resultados'} />
      )}
    </div>
  );
};

export default AdvicersUsers;
