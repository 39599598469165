export const computeGeneralInfoValidators = () => {
  const rules = {
    title: (value) => (!value || value.toString().length < 5 ? 'El título es requerido' : false),
    description: (value) => (!value || value.toString().length < 5 ? 'La descripcion es requerida' : false),
    category: (value) => (!value ? 'La categoria es requerida' : false),
    price: (value) => {
      if (!value) return 'El precio no es válido';
      if (Number.isNaN(Number.parseFloat(value, 10))) return 'El precio no es válido';
      return false;
    },
    quantity: (value) => {
      if (!value) return 'La existencia es requerida';
      if (Number.isNaN(Number.parseFloat(value, 10))) return 'La existencia es requerida';
      return false;
    },
    gender: (value) => (!value ? 'El genero es requerido' : false),
  };

  return rules;
};

export const multimediaRules = {
  picture: (value) => (!value ? 'La imágen principal es requerido' : false),
  pictures: (values) => (!values || !values.length ? 'Agrega almenos 2 imágenes' : false),
};

export const imagesVaidator = () => {};
