import { array } from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowForwardIos } from '@material-ui/icons';
import { Box } from '@material-ui/core';
import Button from '../../../../components/button';
import Container from '../../../../../../components/container';
import { formsPay, payStatus } from '../../../../../../constants';
import { toStringDate } from '../../../../../../helpers/dates';
import styles from './styles.module.css';

const List = (props) => (
  <Container extraSmall className={styles.container}>
    <div className={styles.card}>
      {props.items.map((item) => (
        <div className={styles.row}>
          <div className={styles.cellId}>
            {item.id}
            {!!item.userInfo.formPay && (
              <Box marginTop=".5em" color="var(--rose)" fontWeight="bold" fontSize=".9em">
                {formsPay[item.userInfo.formPay]}
              </Box>
            )}
          </div>
          <div className={styles.cellDate}>{toStringDate(item.createdAt)}</div>
          <div className={styles.cellName}>
            {item.userInfo.name}
            <div className={styles.alternativeDetailAt700}>
              <div className={item.status === 'approved' ? styles.statusAproved : styles.statusPending}>
                {payStatus[item.status]}
              </div>
            </div>
          </div>
          <div className={styles.cellStaus}>
            <div className={item.status === 'approved' ? styles.statusAproved : styles.statusPending}>
              {payStatus[item.status]}
            </div>
          </div>
          <div className={styles.cellDetail}>
            <Link to={`/dashboard/sales/${item.id}`}>
              <Button primary id={styles.button}>
                Ver detalles
              </Button>
              <ArrowForwardIos className={styles.detailMobileArrow} />
            </Link>
          </div>
        </div>
      ))}
    </div>
  </Container>
);

List.propTypes = {
  items: array.isRequired,
};

export default List;
