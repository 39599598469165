/* eslint-disable no-unreachable */
import React from 'react';
import { useParams } from 'react-router-dom';
import TrainingDetail from '../../../../trainingDetail';

const TrainingUserDetail = () => {
  const { userId, day } = useParams();

  return <TrainingDetail userId={userId} day={day} />;
};

export default TrainingUserDetail;
