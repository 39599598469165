import { array, node, object, oneOfType, string } from 'prop-types';
import React from 'react';
import styles from './styles.module.css';

const Container = ({ children, className }) => (
  <section className={`${styles.container} ${className}`}>{children}</section>
);

Container.defaultProps = {
  className: '',
};

Container.propTypes = {
  children: oneOfType([string, array, object, node]).isRequired,
  className: string,
};

export default Container;
