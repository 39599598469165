import formatObect from '../../helpers/formatObject';
import response from '../../helpers/response';
import database from '../database';
import storage from '../storage';

export const createEvent = async (values) => {
  const secureData = {
    title: values.title,
    description: values.description,
    date: values.date,
    price: Number.parseFloat(values.price, 10),
    youtube: values.youtube || null,
  };

  const data = await database.create('events', secureData);

  if (!data) return response.failed('Algo salio mal');
  return response.success('operacion exitosa', data);
};

export const updateEvent = async (id, newValues) => {
  const allows = ['title', 'description', 'date', 'picture', 'pictures', 'price', 'youtube'];
  const secureData = formatObect(newValues, allows);
  if (secureData.price) secureData.price = Number.parseFloat(secureData.price, 10);
  const status = await database.update(`events/${id}`, secureData);
  if (!status) return response.failed();
  return response.success();
};

export const getEventsList = async () => {
  const list = await database.getList('events', null, ['date', 'asc'], null).next();
  return list;
};

export const deleteEvent = async (event) => {
  const { pictures, picture } = event;
  const filesToDelete = [...pictures, picture];
  await Promise.all(filesToDelete.map((currentFile) => storage.deletePicture(currentFile)));
  const isSuccess = await database.remove(`events/${event.id}`);
  if (isSuccess) return response.success();
  return response.failed();
};

export default {
  create: createEvent,
  update: updateEvent,
  delete: deleteEvent,
};
