import { useState, useEffect } from 'react';

const useErrorMessage = () => {
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (errorMessage) {
      const timerId = setTimeout(() => {
        setErrorMessage('');
      }, 7000);
      return () => {
        clearTimeout(timerId);
      };
    }
    return undefined;
  }, [errorMessage]);

  return {
    errorMessage,
    setErrorMessage,
  };
};

export default useErrorMessage;
