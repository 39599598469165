import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { createNewCommunity, updateCommunity } from '../core/models/community';
import storage from '../core/storage';
import formatObect from '../helpers/formatObject';
import validaotors from '../helpers/getValidators';
import { success } from '../helpers/response';
import useNotification from '../modules/notifications/useNotification';
import useErrorMessage from './useErrorMessage';
import useForm from './useForm';

const computeInitialFormState = (state) => {
  if (!state) return {};
  const allows = ['title', 'email', 'phone', 'description', 'picture', 'whatsappNumber', 'web'];
  return formatObect(state, allows);
};

const useCommunityEditor = (state) => {
  const setNotification = useNotification();
  const history = useHistory();
  const { errorMessage, setErrorMessage } = useErrorMessage();
  const [currentStep, setStep] = useState(0);
  const [loading, setLoading] = useState(false);

  const { getInputProps, validateInputs, setValues, values } = useForm(computeInitialFormState(state));

  const handlePictureSelect = (picture) => {
    setValues((prevVals) => ({ ...prevVals, picture }));
  };

  const handlePictureDelete = () => setValues((prevVals) => ({ ...prevVals, picture: null }));

  const handleValidateInputs = () => {
    const { firstErrorMessage } = validateInputs({
      title: validaotors.input('El título es requerido'),
      email: validaotors.email(),
      phone: validaotors.phone(),
      whatsappNumber: validaotors.phone('El número para whatsapp es inválido'),
      description: validaotors.input('La descripción no es válida'),
      picture: (val) => (!val ? 'La imagen es requerida' : false),
    });
    if (!firstErrorMessage) return true;
    setErrorMessage(firstErrorMessage);
    return false;
  };

  const handleUpload = async () => {
    setLoading(true);
    const { picture, ...comunityData } = values;
    const { data } = await createNewCommunity(comunityData);
    const pictureUrl = await storage.uploadPicture(values.picture, `comunity/${data.id}`);
    await updateCommunity(data.id, { picture: pictureUrl });
    history.push('/dashboard/communities');
    setNotification(success('Publicacion creada correctamente'));
  };

  const handleUpdate = async () => {
    setLoading(true);
    let pictureUrl = values.picture;
    if (typeof pictureUrl !== 'string') {
      await storage.deletePicture(state.picture);
      pictureUrl = await storage.uploadPicture(values.picture, `comunity/${state.id}`);
    }
    await updateCommunity(state.id, { ...values, picture: pictureUrl });
    history.push('/dashboard/communities');
    setNotification(success('Publicacion actualizado correctamente'));
    return null;
  };

  const handleAcept = () => {
    if (!state && handleValidateInputs()) handleUpload();
    if (state && handleValidateInputs()) handleUpdate();
  };

  const handleBack = () => setStep((prevStep) => prevStep - 1);

  return {
    back: handleBack,
    acept: handleAcept,
    deletePicture: handlePictureDelete,
    selectPicture: handlePictureSelect,
    getInputProps,
    errorMessage,
    loading,
    currentStep,
    values,
  };
};

export default useCommunityEditor;
