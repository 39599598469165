/* eslint-disable no-param-reassign */
import database from 'src/core/database';
import { failed, success } from 'src/helpers/response';
import formatObect from '../../helpers/formatObject';
import xlsxParser from '../../helpers/xlsxParser';
import { deletePicture, uploadFile, uploadPicture } from '../storage';

const collection = 'program';

export const createNewEmptyProgram = async (programInitialId) => {
  const data = {
    inscription: 0,
    monthlyPrice: 0,
    picture: 0,
    price: 0,
    semesterPrice: 0,
    title: 'THE TITLE OF A NEW TRAINING PROGRAM',
  };
  const result = await database.create(`${collection}`, data, programInitialId);
  if (result) return success('Programa creado correctamente');
  return failed('Error al crear el programa');
};

export const getProgramList = async () => {
  const list = await database.getList(`${collection}`, null, ['createdAt', 'asc'], null).next();
  return list;
};

/**
 * create a progm impleent
 * @param {Object} filexlsx
 * @returns operation status
 */
export const createImplement = async (programId, file) => {
  const { program, tutorial } = await xlsxParser(file);
  const urlFile = await uploadFile(file, `programImplements/${programId}`);
  const data = { isActive: false, payload: program, urlFile, tutorial };
  const response = await database.create(`${collection}/${programId}/implements`, data);
  if (response) return success('Implementación creado correctamente', response);
  return failed('Error al guardar la nueva implementacion');
};

/**
 * Delete program, only can delete a inactive program;
 * @param {String} id
 * @returns
 */
export const deleteImplement = async (programId, implementId) => {
  const data = await database.getDocument(`${collection}/${programId}/implements/${implementId}`);
  if (data.isActive) return failed('No se puede eliminar un programa activo');
  if (data.urlFile) await deletePicture(data.urlFile);
  const status = await database.remove(`${collection}/${programId}/implements/${implementId}`);
  return status ? success('Programa eliminado correctamente') : failed('Error al eliminar el programa');
};

/**
 * switch active program
 * @param {String} id
 * @returns operation status
 */
export const activeImplement = async (programId, implementId) => {
  await database.updateList(`${collection}/${programId}/implements`, [['isActive', '==', true]], { isActive: false });
  await database.update(`${collection}/${programId}/implements/${implementId}`, { isActive: true });
  return success('Programa actualizado correctamente');
};

/**
 * get list of all programs
 * @returns list
 */
export const getImplementsList = async (programId) => {
  const list = await database
    .getList(`${collection}/${programId}/implements`, null, ['createdAt', 'desc'], null)
    .next();
  return list;
};

export const updateInfo = async (programId, data) => {
  const { picture, ...info } = formatObect(data, [
    'description',
    'picture',
    'title',
    'price',
    'monthlyPrice',
    'semesterPrice',
    'inscription',
  ]);
  let prevData = await database.getDocument(`${collection}/${programId}`);

  // create initial data if not exist
  if (!prevData) {
    await database.create(collection, {}, programId);
    prevData = await database.getDocument(`${collection}/${programId}`);
  }

  if (!!picture && picture instanceof File) {
    if (prevData.picture) await deletePicture(prevData.picture);
    const pictureUrl = await uploadPicture(picture, `program/${programId}`);
    await database.update(`${collection}/${programId}`, { picture: pictureUrl });
  }

  // parse prices
  if (info.price) info.price = Number.parseFloat(info.price);
  if (info.monthlyPrice) info.monthlyPrice = Number.parseFloat(info.monthlyPrice);
  if (info.semesterPrice) info.semesterPrice = Number.parseFloat(info.semesterPrice);
  if (info.inscription) info.inscription = Number.parseFloat(info.inscription);

  await database.update(`${collection}/${programId}`, info);
  return success('Programa actualizado correctamente');
};

export const getInfo = async (programId) => {
  const data = await database.getDocument(`${collection}/${programId}`);
  return data;
};

export const getActiveImplement = async (programId) => {
  try {
    const list = await database
      .getList(`${collection}/${programId}/implements`, null, null, [['isActive', '==', true]])
      .next();
    return list[0];
  } catch (error) {
    return null;
  }
};

export const getAsProduct = async () => {
  let programList = await getProgramList();
  await Promise.all(
    programList.map(async (program) => {
      const hasActiveImplement = await getActiveImplement(program.id);
      program.hasActiveImplement = !!hasActiveImplement;
    }),
  );
  programList = programList.filter((program) => program.hasActiveImplement && !!program.showAtStore);
  return programList.map((program) => ({ ...program, category: 'sportsPrograms' }));
};

export const getSingleImpletement = async (programId, implementId) => {
  const data = await database.getDocument(`/program/${programId}/implements/${implementId}`);
  return data;
};

export const getTutorialsOfCurrentImplement = async (programId) => {
  try {
    const { tutorial } = await getActiveImplement(programId);
    return tutorial.map((current) => ({
      section: current.sectionTitle,
      video: current.url ? current.url.replace('https://youtu.be/', '') : null,
      title: current.title,
    }));
  } catch (error) {
    return [];
  }
};

export const getTutorialsByImplementId = async (programId, implementId) => {
  try {
    const { tutorial } = await getSingleImpletement(programId, implementId);
    return tutorial.map((current) => ({
      section: current.sectionTitle,
      video: current.url ? current.url.replace('https://youtu.be/', '') : null,
      title: current.title,
    }));
  } catch (error) {
    return [];
  }
};

export const showAtTheStore = async (programId, value) => {
  try {
    await database.update(`${collection}/${programId}`, { showAtStore: value });
    return success('Programa actualizado correctamente');
  } catch (error) {
    return failed('Error al actualizar intetalo nuevamente');
  }
};

const programModel = {
  createImplement,
  deleteImplement,
  activeImplement,
  getImplementsList,
  updateInfo,
  getInfo,
  getActiveImplement,
  getAsProduct,
  getSingleImpletement,
  getTutorialsOfCurrentImplement,
  getTutorialsByImplementId,
  showAtTheStore,
};

export default programModel;
