// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_titleWrapper__1uwfO {\n  background: #fff;\n  border-bottom: .5em solid #f1f1f1;\n  padding: 1.5em 1em;\n}\n.styles_members__qsgxt {\n  background: #fff;\n  padding: 1em;\n  max-height: 70vh;\n  overflow: scroll;\n}\n.styles_memberRow__3r2dt {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  padding: .8em 0em;\n  border-bottom: 2px solid #e1e1e1;\n}\n.styles_memberRow__3r2dt:last-of-type {\n  border-bottom: none\n}\n.styles_userAvatar__iJvR- {\n  width: 3.3em;\n  height: 3.3em;\n  border-radius: 50%;\n  object-fit: cover;\n  object-position: center;\n  margin-right: 1em;\n}\n.styles_userName__LTjVX {\n  font-size: 1.1em;\n  font-weight: 600;\n}", "",{"version":3,"sources":["webpack://src/components/groupChat/components/members/styles.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,iCAAiC;EACjC,kBAAkB;AACpB;AACA;EACE,gBAAgB;EAChB,YAAY;EACZ,gBAAgB;EAChB,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;EACnB,iBAAiB;EACjB,gCAAgC;AAClC;AACA;EACE;AACF;AACA;EACE,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,iBAAiB;EACjB,uBAAuB;EACvB,iBAAiB;AACnB;AACA;EACE,gBAAgB;EAChB,gBAAgB;AAClB","sourcesContent":[".titleWrapper {\n  background: #fff;\n  border-bottom: .5em solid #f1f1f1;\n  padding: 1.5em 1em;\n}\n.members {\n  background: #fff;\n  padding: 1em;\n  max-height: 70vh;\n  overflow: scroll;\n}\n.memberRow {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  padding: .8em 0em;\n  border-bottom: 2px solid #e1e1e1;\n}\n.memberRow:last-of-type {\n  border-bottom: none\n}\n.userAvatar {\n  width: 3.3em;\n  height: 3.3em;\n  border-radius: 50%;\n  object-fit: cover;\n  object-position: center;\n  margin-right: 1em;\n}\n.userName {\n  font-size: 1.1em;\n  font-weight: 600;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titleWrapper": "styles_titleWrapper__1uwfO",
	"members": "styles_members__qsgxt",
	"memberRow": "styles_memberRow__3r2dt",
	"userAvatar": "styles_userAvatar__iJvR-",
	"userName": "styles_userName__LTjVX"
};
export default ___CSS_LOADER_EXPORT___;
