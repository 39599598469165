// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_listItem__33jus {\n  font-size: 1.25em;\n  padding: .8em;\n  border-bottom: 1px solid #e1e1e1;\n  cursor: pointer;\n}\n.styles_listItemSelected__r-mzO {\n  font-weight: bold;\n}\n.styles_group__3lq2C {\n  margin-bottom: 3em;\n}", "",{"version":3,"sources":["webpack://src/pages/tips/components/aside/styles.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,aAAa;EACb,gCAAgC;EAChC,eAAe;AACjB;AACA;EACE,iBAAiB;AACnB;AACA;EACE,kBAAkB;AACpB","sourcesContent":[".listItem {\n  font-size: 1.25em;\n  padding: .8em;\n  border-bottom: 1px solid #e1e1e1;\n  cursor: pointer;\n}\n.listItemSelected {\n  font-weight: bold;\n}\n.group {\n  margin-bottom: 3em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listItem": "styles_listItem__33jus",
	"listItemSelected": "styles_listItemSelected__r-mzO",
	"group": "styles_group__3lq2C"
};
export default ___CSS_LOADER_EXPORT___;
