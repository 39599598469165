import React from 'react';
import GroupChat from 'src/components/groupChat';
import chatIcon from 'src/assets/chat.png';
import styles from './styles.module.css';

const GroupChatPage = () => (
  <div className={styles.fullWrapper}>
    <div className={styles.title}>
      <img src={chatIcon} alt="chat icon" />
      Chat del grupo
    </div>
    <div className={styles.limiter}>
      <GroupChat />
    </div>
  </div>
);

export default GroupChatPage;
