import { Box } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { oneOfType, string, object, func } from 'prop-types';
import React from 'react';
import Dropzone from 'src/components/dropzone';
import ErrorMessage from '../../../../../../components/errorMessage';
import getFilePreviewUrl from '../../../../../../helpers/normalizeImagesUrl';
import styles from './styles.module.css';

const Multimedia = (props) => (
  <div className={styles.wrapper}>
    <ErrorMessage message={props.errorMessage} />
    <Box textAlign="center" fontSize="1.2em">
      FOTO
    </Box>
    <Box margin="1em 0em" padding="5em 0px">
      {!props.picture && (
        <Dropzone className={styles.dropzone} onChange={props.onPictureSelect}>
          Seleccionar imagen
        </Dropzone>
      )}
      {!!props.picture && (
        <div className={styles.pictureListContainer}>
          <div className={styles.pictureWrapper}>
            <div className={styles.deleteIconWrapper} onClick={() => props.onPictureDelete()} role="button">
              <Delete className={styles.deleteIcon} />
            </div>
            <img className={styles.image} src={getFilePreviewUrl(props.picture)} alt="ima" />
          </div>
        </div>
      )}
    </Box>
  </div>
);

Multimedia.defaultProps = {
  picture: null,
  errorMessage: '',
};

Multimedia.propTypes = {
  onPictureDelete: func.isRequired,
  onPictureSelect: func.isRequired,
  picture: oneOfType([string, object]),
  errorMessage: string,
};

export default Multimedia;
