// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_title__BQcOO {\n  text-transform: uppercase;\n}\n.styles_option__U9sfv {\n  cursor: pointer;\n  padding-bottom: .5em;\n  border-bottom: 2px solid var(--rose);\n}\n.styles_arrow__1M-3I {\n  font-size: 2em!important;\n  margin-left: .5em;\n}", "",{"version":3,"sources":["webpack://src/pages/dashboard/pages/tutorialsAdvicer/components/programSelector/styles.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;AACA;EACE,eAAe;EACf,oBAAoB;EACpB,oCAAoC;AACtC;AACA;EACE,wBAAwB;EACxB,iBAAiB;AACnB","sourcesContent":[".title {\n  text-transform: uppercase;\n}\n.option {\n  cursor: pointer;\n  padding-bottom: .5em;\n  border-bottom: 2px solid var(--rose);\n}\n.arrow {\n  font-size: 2em!important;\n  margin-left: .5em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "styles_title__BQcOO",
	"option": "styles_option__U9sfv",
	"arrow": "styles_arrow__1M-3I"
};
export default ___CSS_LOADER_EXPORT___;
