import validaotors from 'src/helpers/getValidators';

export const generalInfoRules = {
  title: (value) => (!value || value.toString().length < 5 ? 'El título es requerido' : false),
  price: (value) => {
    const prevValidator = validaotors.number();
    if (value === '0' || value === 0 || value === '') return false;
    return prevValidator(value);
  },
  description: (value) => (!value || value.toString().length < 5 ? 'La descripcion es requerida' : false),
  date: validaotors.input('La fecha es requerida'),
  youtube: (value) => {
    if (!!value && !value.toString().includes('http')) return 'El link del video no es válido';
    return false;
  },
};

export const multimediaRules = {
  picture: (value) => (!value ? 'La imágen principal es requerido' : false),
  pictures: (values) => (!values || !values.length ? 'Agrega almenos 2 imágenes' : false),
};

const rules = { generalInfoRules, multimediaRules };

export default rules;
