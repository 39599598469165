import React, { useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import purchaseModel, { setAccountActivated } from 'src/core/models/purchases';
import useFetch from 'src/hooks/useFetch';
import useForm from 'src/hooks/useForm';
import useErrorMessage from 'src/hooks/useErrorMessage';
import programUsers from 'src/core/models/programUsers';
import useNotification from 'src/modules/notifications/useNotification';
import qs from 'query-string';
import { transformUserInfoForForm, getRules, filterDataForEditing } from './functions';
import View from './view';

const ActivateAccount = () => {
  const { search } = useLocation();
  const history = useHistory();
  const setNotifications = useNotification();
  const { errorMessage, setErrorMessage } = useErrorMessage();
  const { id } = useParams();
  const { getInputProps, setValues, validateInputs, values } = useForm({}, getRules());
  const [loading, setLoading] = useState(true);
  const [formPay, setFormPay] = useState(null);
  const [price, setPrice] = useState(null); // formSelected
  const [date, setDate] = useState(null);
  const [programId, setProgramId] = useState(null);

  const query = qs.parse(search) || {};
  const { edit = false } = query;
  const isEditing = !!edit;

  const handleFetchFroActivateAccount = async () => {
    const { userInfo, formPay: formPayResponse, subTotal, createdAt, items } = await purchaseModel.getSingle(id);
    setProgramId(items[0].id || 'main');
    setFormPay(formPayResponse);
    setPrice(subTotal);
    setValues(transformUserInfoForForm(userInfo));
    setDate(createdAt);
    setLoading(false);
  };

  const handleFetchForUpdateAccount = async () => {
    const userPrevData = await programUsers.getSingle(id);
    console.log('userPrevData', userPrevData);
    setValues(filterDataForEditing(userPrevData));
    setLoading(false);
  };

  useFetch(isEditing ? handleFetchForUpdateAccount : handleFetchFroActivateAccount);

  const handleSave = async () => {
    const { firstErrorMessage } = validateInputs();
    if (firstErrorMessage) return setErrorMessage(firstErrorMessage);
    setLoading(true);
    const response = await programUsers.create({ ...values, programId, formPay, price, date });

    if (response.success) {
      await setAccountActivated(id);
      history.replace('/dashboard/users');
      setNotifications(response);
    }
    if (response.error) {
      setLoading(false);
      setErrorMessage(response.message);
    }
    return null;
  };

  const handleUpdate = async () => {
    const { firstErrorMessage } = validateInputs();
    if (firstErrorMessage) return setErrorMessage(firstErrorMessage);

    setLoading(true);
    const response = await programUsers.updateByAdmin(id, values);

    if (response.success) {
      history.replace('/dashboard/users');
      setNotifications(response);
    }
    if (response.error) {
      setLoading(false);
      setErrorMessage(response.message);
    }
    return null;
  };

  return (
    <View
      isEditing={isEditing}
      getInputProps={getInputProps}
      loading={loading}
      onSave={!isEditing ? handleSave : handleUpdate}
      errorMessage={errorMessage}
    />
  );
};

export default ActivateAccount;
