import React from 'react';
import { Link } from 'react-router-dom';
import { array } from 'prop-types';
import styles from './styles.module.css';
import { formsPay, payStatus } from '../../../../../../constants';
import { toLargeStringDate } from '../../../../../../helpers/dates';
import Button from '../../../../components/button';

const GridView = (props) => (
  <div className={styles.list}>
    {props.items.map((item, index) => (
      <div className={styles.productWrapper} key={index}>
        <div className={styles.productCard}>
          {item.digital && <div className={styles.caption}>Programa</div>}
          <div className={styles.date}>{toLargeStringDate(item.createdAt)}</div>
          <div className={styles.group}>
            <div className={styles.describe}>ORDER ID</div>
            <div className={styles.val}>{item.id}</div>
          </div>
          <div className={styles.group}>
            <div className={styles.describe}>NOMBRE</div>
            <div className={styles.val}>{item.userInfo.name}</div>
          </div>
          {item.userInfo.formPay && (
            <div className={styles.group}>
              <div className={styles.describe}>Forma de pago</div>
              <div className={styles.val}>{formsPay[item.userInfo.formPay]}</div>
            </div>
          )}
          <div className={styles.group}>
            <div className={styles.describe}>ESTADO DE PAGO</div>
            <div className={item.status === 'approved' ? styles.statusAproved : styles.statusPending}>
              {payStatus[item.status]}
            </div>
          </div>
          <div className={styles.group}>
            <Link to={`/dashboard/sales/${item.id}`}>
              <Button primary id={styles.button}>
                Ver detalles
              </Button>
            </Link>
          </div>
        </div>
      </div>
    ))}
  </div>
);

GridView.propTypes = {
  items: array.isRequired,
};

export default GridView;
