import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router';
import productModel from 'src/core/models/product';
import Footer from '../../components/footer';
import Header from '../../components/header';
import Loading from '../../components/loading';
import useFetch from '../../hooks/useFetch';
import View from './view';
import useNotification from '../../modules/notifications/useNotification';
import response from '../../helpers/response';
import { allSubcategories, categories } from '../../constants';
import useCart from '../../modules/cart/useCart';

const ProductDetail = () => {
  const setNotification = useNotification();
  const { productId } = useParams();
  const history = useHistory();
  const [quantity, setQuantity] = useState(1);
  const [product, setProduct] = useState({});
  const [loading, setLoading] = useState({});
  const { methods } = useCart();

  const handleQuantityChange = (value) => {
    if (value >= 1 && value <= product.quantity) setQuantity(value);
  };

  useFetch(async () => {
    const productData = await productModel.getProduct(productId);
    if (productData) {
      setProduct(productData);
      setLoading(false);
    } else {
      history.replace('/');
    }
  });

  const handleAddToCart = () => {
    methods.addToCart(product, quantity);
    setQuantity(1);
    setNotification(response.success('Producto agregado al carrito correctamente'));
  };

  const gandleGetBradCrumbs = () => {
    if (loading) return [];
    const crumbs = [];
    if (product.category)
      crumbs.push({
        label: categories[product.category],
      });
    if (product.subCategory)
      crumbs.push({
        label: allSubcategories[product.subCategory],
      });
    return crumbs;
  };

  return (
    <>
      <Header grayOptions />
      {loading && <Loading />}
      {!loading && (
        <View
          quantity={quantity}
          pictures={[product.picture, ...product.pictures]}
          title={product.title}
          description={product.description}
          onQuantityChange={handleQuantityChange}
          price={product.price}
          onAddTocart={handleAddToCart}
          breadCrumbs={gandleGetBradCrumbs()}
        />
      )}
      <Footer />
    </>
  );
};

export default ProductDetail;
