import { Box, ButtonBase } from '@material-ui/core';
import { func } from 'prop-types';
import React from 'react';
import styles from './styles.module.css';

const DaySelectorModal = (props) => (
  <div className={styles.fullWrapper}>
    <div className={styles.body}>
      <Box color="var(--cyan)">Selecciona el día</Box>
      <Box marginTop="2em">
        <ButtonBase onClick={() => props.onSelect(0)} className={styles.day}>
          Lunes
        </ButtonBase>
        <ButtonBase onClick={() => props.onSelect(1)} className={styles.day}>
          Martes
        </ButtonBase>
        <ButtonBase onClick={() => props.onSelect(2)} className={styles.day}>
          Miércoles
        </ButtonBase>
        <ButtonBase onClick={() => props.onSelect(3)} className={styles.day}>
          Jueves
        </ButtonBase>
        <ButtonBase onClick={() => props.onSelect(4)} className={styles.day}>
          Viernes
        </ButtonBase>
        <ButtonBase onClick={() => props.onSelect(5)} className={styles.day}>
          Sábado
        </ButtonBase>
      </Box>
    </div>
  </div>
);

DaySelectorModal.propTypes = {
  onSelect: func.isRequired,
};

export default DaySelectorModal;
