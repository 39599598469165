import validators from 'src/helpers/getValidators';

const { validateCardNumber, validateCVC, validateExpiry } = window.OpenPay.card;

export const generalInfoValidator = {
  email: validators.email(),
  name: validators.input('El nombre no es válido'),
  lastName: validators.input('Los apellidos no son válidos'),
  streetAndNumber: validators.input('calle y numero inválido'),
  suburb: validators.input('La colonia no es válida'),
  city: validators.input('La ciudad no es válida'),
  state: validators.input('El estado no es válido'),
  phone: (value = '') => {
    const errorMessage = 'El numero de teléfono no es valido';
    const validator = validators.number();
    const error = validator(value);
    if (error) return errorMessage;
    if (value.toString().length !== 10) return errorMessage;
    return null;
  },
  postalCode: (value = '') => {
    const errorMessage = 'Código postal inválido';
    const validator = validators.number();
    const error = validator(value);
    if (error) return errorMessage;
    if (value.toString().length !== 5) return errorMessage;
    return null;
  },
};

export const cardFormValidator = {
  cardName: validators.input('El nombre no es válido'),
  cardNumber: (val) => {
    if (!validateCardNumber(val)) return 'El número de tarjeta no es válido';
    return false;
  },
  cardMonth: (_val, values) => {
    if (!validateExpiry(values.cardMonth, values.cardYear)) return 'La fecha de vencimiento no es válida';
    return false;
  },
  cardYear: (_val, values) => {
    if (!validateExpiry(values.cardMonth, values.cardYear)) return 'La fecha de vencimiento no es válida';
    return false;
  },
  cardCode: (value) => {
    if (!validateCVC(value)) return 'El código de seguridad no es válido';
    return false;
  },
};

export default {
  generalInfoValidator,
  cardFormValidator,
};
