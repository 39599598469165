import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import testimonialModel from 'src/core/models/testimonials';
import createPartialStateUpdater from '../helpers/setPartialState';
import useAlert from '../modules/alert/useAlert';
import useNotification from '../modules/notifications/useNotification';
import useFetch from './useFetch';

/**
 * testimonial list
 * @param {Object} config
 * @param {Number} config.limit
 */
const useTestimonialLits = (config = {}) => {
  const setAlert = useAlert();
  const setNotification = useNotification();
  const history = useHistory();
  const [state, setState] = useState({
    loading: true,
    items: [],
    keywords: '',
  });

  // aply filters
  let filteredItems = state.items;
  if (filteredItems) {
    const keywordsNormalized = state.keywords.toString().toLowerCase();
    filteredItems = filteredItems.filter((item) => {
      const nameNormalized = item.name.toString().toLowerCase();
      return nameNormalized.includes(keywordsNormalized);
    });
  }

  const setComposeState = createPartialStateUpdater(setState);

  const handleFetch = async () => {
    setComposeState({ loading: true });
    const items = await testimonialModel.getList(config.limit);
    setComposeState({ loading: false, items });
  };

  const handleSetKeywords = (value) => setComposeState({ keywords: value });

  const handleGetOptions = (item) => [
    {
      label: 'Eliminar',
      handler: () => {
        setAlert({
          title: '¿Seguro quieres eliminar este testimonio?',
          message: 'Despues de ejecutar esta acción no podras recuperar la información',
          action: async () => {
            const response = await testimonialModel.delete(item);
            await handleFetch();
            setNotification(response);
          },
        });
      },
    },
    {
      label: 'Editar',
      handler: () =>
        history.push({
          pathname: '/dashboard/testimonials/editor',
          state: item,
        }),
    },
  ];

  useFetch(handleFetch);

  return {
    loading: state.loading,
    items: filteredItems,
    keywords: state.keywords,
    getOptions: handleGetOptions,
    setKeywords: handleSetKeywords,
  };
};

export default useTestimonialLits;
