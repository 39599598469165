// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_wrapper__s0FEB {\n  padding: .5em;\n  background-color: #fff;\n  border-bottom: .3em solid var(--rose);\n}\n\n.styles_data__20DB9 {\n  margin-top: 1em;\n  font-size: 1.2em;\n  line-height: 1.4em;\n}\n.styles_title__3m4-Q {\n  font-weight: 800;\n  text-transform: uppercase;\n  margin-bottom: .4em;\n  font-size: 1.1em;\n}\n.styles_videoWraper__2tB9D {\n  width: 100;\n  position: relative;\n}\n.styles_shadow__3xNms {\n  width: 100%;\n  height: 100%;\n  position: absolute;\n  z-index: 1;\n  cursor: pointer;\n}", "",{"version":3,"sources":["webpack://src/pages/tutorials/components/tutorialCard/styles.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,qCAAqC;AACvC;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,kBAAkB;AACpB;AACA;EACE,gBAAgB;EAChB,yBAAyB;EACzB,mBAAmB;EACnB,gBAAgB;AAClB;AACA;EACE,UAAU;EACV,kBAAkB;AACpB;AACA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,UAAU;EACV,eAAe;AACjB","sourcesContent":[".wrapper {\n  padding: .5em;\n  background-color: #fff;\n  border-bottom: .3em solid var(--rose);\n}\n\n.data {\n  margin-top: 1em;\n  font-size: 1.2em;\n  line-height: 1.4em;\n}\n.title {\n  font-weight: 800;\n  text-transform: uppercase;\n  margin-bottom: .4em;\n  font-size: 1.1em;\n}\n.videoWraper {\n  width: 100;\n  position: relative;\n}\n.shadow {\n  width: 100%;\n  height: 100%;\n  position: absolute;\n  z-index: 1;\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "styles_wrapper__s0FEB",
	"data": "styles_data__20DB9",
	"title": "styles_title__3m4-Q",
	"videoWraper": "styles_videoWraper__2tB9D",
	"shadow": "styles_shadow__3xNms"
};
export default ___CSS_LOADER_EXPORT___;
