export const binaryOptiopns = {
  yes: 'Si',
  no: 'No',
};

export const objetveToDoExercise = {
  key1: 'Otro',
  key2: 'Indicación medica',
  key3: 'Crear hábitos saludables',
  key4: 'Perder peso',
  key5: 'Mejorar la condición fisica',
  key6: 'Mejorar la movilidad',
  key7: 'Dormir mejor',
  key8: 'Por gusto',
};

export const exerciceDid = {
  key3: 'En la infancia',
  key4: 'En la adolescencia',
  key5: 'Una vez lo intente',
  key6: 'Otro',
};

export const makeDesist = {
  key1: 'No me gusta',
  key2: 'No soy disciplinado',
  key3: 'Los ejercicios son difíciles',
  key4: 'Los ejercicios no son adecuados para mi',
  key5: 'no me siento motivado',
};

export const workType = {
  pasive: 'Pasivo',
  active: 'Activo',
};

export const workDuration = {
  key1: 'Entre 1 y 4 horas',
  key2: 'Entre 4 y 6 horas',
  key3: 'Entre 6 y 8 horas',
  key5: 'Mas de 8 horas',
};

export const workDurationSitting = {
  key1: 'Entre 1 y 4 horas',
  key2: 'Entre 4 y 6 horas',
  key3: 'Entre 6 y 8 horas',
  key5: 'Mas de 8 horas',
};

export const numberDaysWorking = {
  key1: '5 dias',
  key2: '6 dias',
};

export const numberOfMeals = {
  1: '1',
  2: '2',
  3: '3',
  4: 'Mas de 3',
};

export const numberOfSnacks = {
  1: '1',
  2: '2',
  3: 'Ninguna',
};

export const numberOfDaysEatingAtHome = {
  1: 'Ninguna',
  2: 'De 1 a 3',
  3: 'De 3 a 6',
  4: 'Los 7 dias de la semana',
};

export const digestionDisorder = {
  1: 'Colitis',
  2: 'Gastritis',
  3: 'Estreñimiento',
  4: 'Otro',
};

export const NumberHoursSleeping = {
  1: 'de 4 a 6 horas',
  2: 'de 6 a 8 horas',
  3: 'mas de 8 horas',
};

export const sufferInsomnia = {
  1: 'poco',
  2: 'Regularmente',
  3: 'Nunca',
};

export const whoCanDoExercise = {
  1: 'Personas que solo piensan en su cuerpo',
  2: 'Solo los fuertes y veloces',
  3: 'Solo los delgados',
  4: 'Todas las personas',
};

export const whatUnlinkeDoExersice = {
  1: 'Es cansado',
  2: 'Sudar',
  3: 'Que duela',
  4: 'Es aburrido',
};

export const objetiveAtEndRutine = {
  1: 'Bajar de peso',
  2: 'Mejorar la salud',
  3: 'Tener condicion',
  4: 'Hacer un deporte',
  5: 'Correr una carrera',
  6: 'Caminar en el cerro',
  7: 'Convivir con mi familia',
  8: 'otro',
};

const questionsConstants = {
  binaryOptiopns,
  objetveToDoExercise,
  exerciceDid,
  makeDesist,
  workType,
  workDuration,
  workDurationSitting,
  numberDaysWorking,
  numberOfMeals,
  numberOfSnacks,
  numberOfDaysEatingAtHome,
  digestionDisorder,
  NumberHoursSleeping,
  sufferInsomnia,
  whoCanDoExercise,
  whatUnlinkeDoExersice,
  objetiveAtEndRutine,
};

export default questionsConstants;
