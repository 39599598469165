// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_lim__2T_Hh {\n  width: 55%;\n  margin: auto;\n}\n\n@media screen and (max-width:1200px) {\n  .styles_lim__2T_Hh {\n    width: 65%;\n  }\n}\n\n@media screen and (max-width:1000px) {\n  .styles_lim__2T_Hh {\n    width: 70%;\n  }\n}\n\n@media screen and (max-width:800px) {\n  .styles_lim__2T_Hh {\n    width: 85%;\n  }\n}\n\n@media screen and (max-width:500px) {\n  .styles_lim__2T_Hh {\n    width: 95%;\n  }\n}", "",{"version":3,"sources":["webpack://src/pages/trainingDetail/styles.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,YAAY;AACd;;AAEA;EACE;IACE,UAAU;EACZ;AACF;;AAEA;EACE;IACE,UAAU;EACZ;AACF;;AAEA;EACE;IACE,UAAU;EACZ;AACF;;AAEA;EACE;IACE,UAAU;EACZ;AACF","sourcesContent":[".lim {\n  width: 55%;\n  margin: auto;\n}\n\n@media screen and (max-width:1200px) {\n  .lim {\n    width: 65%;\n  }\n}\n\n@media screen and (max-width:1000px) {\n  .lim {\n    width: 70%;\n  }\n}\n\n@media screen and (max-width:800px) {\n  .lim {\n    width: 85%;\n  }\n}\n\n@media screen and (max-width:500px) {\n  .lim {\n    width: 95%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"lim": "styles_lim__2T_Hh"
};
export default ___CSS_LOADER_EXPORT___;
