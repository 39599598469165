import { Box } from '@material-ui/core';
import React from 'react';
import programaSrc from 'src/assets/iampoltri.png';
import Container from '../../../../components/container';
import styles from './styles.module.css';

const Program = () => (
  <div className={styles.fullWrapper}>
    <Container disabledVerticalPadding>
      <div className={styles.body}>
        <div className={styles.dividerWrapper}>
          <div className={styles.elemenDivided}>
            <img src={programaSrc} alt="programa" className={styles.picture} />
          </div>
          <div className={`${styles.elemenDivided} ${styles.dataWrapper}`}>
            <div className={styles.title}>
              <span className={styles.rose}>TRASCIENDE</span>
              <span> TRANSFORMA</span>
              <span className={styles.rose}> INSPIRA </span>
              <br />
              <span>
                SANA Y <span className={styles.rose}>VIVE</span>
              </span>
            </div>
            <div className={styles.subtitle}>
              Es un programa que te lleva paso a paso, de una manera amigable desarrollando técnica y condición física.
            </div>
            <div className={styles.subtitle}>Características del Programa:</div>
            <div className={styles.subtitle}>
              <Box component="ul" paddingTop="0px" paddingBottom="0px" margin="0">
                <li>Inicio desde lo mas básico</li>
                <li>Programa muy amigable y sin sufrimiento</li>
                <li>Seguimiento a tus actividades.</li>
                <li>Asesoría personalizada.</li>
                <li>Tu escoges el horario y lugar para tus sesiones</li>
                <li>Asesoría en alimentación, descanso y equipo deportivo</li>
                <li>90% en línea, con apoyo de nuestra plataforma.</li>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </Container>
  </div>
);

export default Program;
