/* eslint-disable no-unused-vars */
import { Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { onMessagesChange } from '../../../../core/models/groupChat';
import composeClassNames from '../../../../helpers/composeClassNames';
import { useSession } from '../../../../modules/session';
import styles from './styles.module.css';
import { toStringTime, toLargeStringDate } from '../../../../helpers/dates';

const Chat = () => {
  const session = useSession();
  const [messages, setMessages] = useState([]);

  // initialize realtime messages
  useEffect(() => {
    const unsubscribe = onMessagesChange(session.data.id, (messages_) => {
      setMessages(messages_);
    });
    return unsubscribe;
  }, []);

  // scroll into last message
  useEffect(() => {
    setTimeout(() => {
      try {
        const div = document.getElementById('messageWrapper');
        div.scrollTo({ top: div.scrollHeight - div.clientHeight, behavior: 'smooth' });
      } catch (error) {
        console.log(error);
      }
    }, 100);
  }, [messages.length]);

  return (
    <div className={styles.wrapper} id="messageWrapper">
      {messages.map((message) => (
        <div className={composeClassNames(styles.messageCard, message.isOwned ? '' : styles.messagesOtherPersom)}>
          <img src={message.picture} alt="avatar" className={styles.userAvatar} />
          <div>
            <Box fontWeight="bold" color="var(--rose)" fontSize=".9em">
              {message.name}
            </Box>
            {message.message && <Box className={styles.messageBody}>{message.message}</Box>}
            {message.image && <img src={message.image} className={styles.imageMessage} alt="some" />}
            <Box fontSize=".8em" color="gray">
              {toLargeStringDate(message.date)}
              {` `}
              {toStringTime(message.date)}
              {` `}
              hrs.
            </Box>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Chat;
