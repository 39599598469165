import { element } from 'prop-types';
import React, { useEffect, useState } from 'react';
import context from './context';
import ViewNotifications from './view';

const Provider = ({ children }) => {
  const [state, setState] = useState({
    status: 'success',
    message: '',
  });

  /**
   * set notification
   * @param {Object} argument
   * @param {String} argument.status
   * @param {String} argument.message
   */
  const handleSetNotification = ({ status = '', message = '' }) => setState({ status, message });

  useEffect(() => {
    const timerId = setTimeout(() => handleSetNotification({ status: state.status, message: '' }), 10000);
    return () => clearTimeout(timerId);
  }, [state.status, state.message]);

  window.handleSetNotification = handleSetNotification;

  return (
    <context.Provider value={handleSetNotification}>
      <>
        {children}
        <ViewNotifications status={state.status} message={state.message} />
      </>
    </context.Provider>
  );
};

Provider.propTypes = {
  children: element.isRequired,
};

export default Provider;
