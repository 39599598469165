import PropTypes from 'prop-types';
import { Email, Language, Phone, WhatsApp } from '@material-ui/icons';
import React from 'react';
import { Box } from '@material-ui/core';
import styles from './styles.module.css';
import Picture from '../../../../components/picture';

const CommunityCard = (props) => (
  <div className={styles.card}>
    <Picture src={props.picture} className={styles.picture} height={80} />
    <div className={styles.data}>
      <h2 className={styles.title}>{props.title}</h2>
      <p className={styles.description}>{props.description}</p>
      <div className={styles.row}>
        <Email /> {props.email}
      </div>
      <div className={styles.row}>
        <Phone /> {props.phone}
      </div>
      <div className={styles.row}>
        <Box
          target="_blank"
          href={`https://api.whatsapp.com/send?phone=521${props.whatsapp}`}
          rel="noreferrer"
          display="flex"
          alignItems="center"
          component="a"
          className={styles.whatsappLink}
        >
          <WhatsApp /> Whatsapp
        </Box>
      </div>
      {props.web && (
        <div className={styles.row}>
          <Box
            target="_blank"
            href={props.web}
            rel="noreferrer"
            display="flex"
            alignItems="center"
            component="a"
            className={styles.whatsappLink}
          >
            <Language /> Sitio web
          </Box>
        </div>
      )}
    </div>
  </div>
);

CommunityCard.defaultProps = {
  web: null,
};

CommunityCard.propTypes = {
  description: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  picture: PropTypes.string.isRequired,
  whatsapp: PropTypes.string.isRequired,
  web: PropTypes.string,
};

export default CommunityCard;
