// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_modal__FY47z {\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  outline: none;\r\n}\r\n.styles_card__t3x2v {\r\n  outline: none;\r\n  background-color: #fff;\r\n  width: 30em;\r\n  min-height: 20em;\r\n  padding: 2em;\r\n  box-sizing: border-box;\r\n  border-radius: 1em;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  flex-direction: column;\r\n}\r\n@media screen and (max-width:600px) {\r\n  .styles_card__t3x2v {\r\n    width: 90%;\r\n    max-width: 400px;\r\n    padding: 1em;\r\n    min-height: 16em;\r\n  }\r\n}", "",{"version":3,"sources":["webpack://src/modules/alert/styles.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,gBAAgB;EAChB,YAAY;EACZ,sBAAsB;EACtB,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;AACxB;AACA;EACE;IACE,UAAU;IACV,gBAAgB;IAChB,YAAY;IACZ,gBAAgB;EAClB;AACF","sourcesContent":[".modal {\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  outline: none;\r\n}\r\n.card {\r\n  outline: none;\r\n  background-color: #fff;\r\n  width: 30em;\r\n  min-height: 20em;\r\n  padding: 2em;\r\n  box-sizing: border-box;\r\n  border-radius: 1em;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  flex-direction: column;\r\n}\r\n@media screen and (max-width:600px) {\r\n  .card {\r\n    width: 90%;\r\n    max-width: 400px;\r\n    padding: 1em;\r\n    min-height: 16em;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "styles_modal__FY47z",
	"card": "styles_card__t3x2v"
};
export default ___CSS_LOADER_EXPORT___;
