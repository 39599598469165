import React from 'react';
import CoverPage from 'src/components/coverPage';
import pictureHeaderSrc from 'src/assets/program_header.jpg';
import pictureHeaderMSrc from 'src/assets/programas-slide-poltri.jpg';
import footerprogramSrc from 'src/assets/footerprogram.jpg';
import footerprogramMSrc from 'src/assets/programam.jpg';
import { Box } from '@material-ui/core';
import Menu from './components/menu';
import ProgramLanding from './components/program';
import Grid from './components/grid';
import WhoIsThisPRogramFor from './components/whiIsThisProgramFor';
import Modules from './components/modules';
import CoverFooterPage from '../../components/coverFooterPage';
import Footer from '../../components/footer';
import Header from '../../components/header';

const Program = () => (
  <>
    <Header overlap />
    <CoverPage
      picture={pictureHeaderSrc}
      mobilePicture={pictureHeaderMSrc}
      content={
        <>
          Conoce los programas <br /> que tenemos para ti <br />
          <Box fontSize=".5em" color="var(--rose)" marginTop=".5em">
            Principiantes / Carrera / Triatlon
          </Box>
        </>
      }
    />
    <Menu />
    <ProgramLanding />
    <Grid />
    <WhoIsThisPRogramFor />
    <Modules />
    <CoverFooterPage
      content="EL DOLOR DE HOY ES EL BENEFICIO DE MAÑANA"
      picture={footerprogramSrc}
      pictureMobile={footerprogramMSrc}
    />
    <Footer />
  </>
);

export default Program;
