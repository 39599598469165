import { useMediaQuery } from '@material-ui/core';
import { array, element, object, oneOfType, string } from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import Button from 'src/components/button';
import Container from 'src/components/container';
import styles from './styles.module.css';

const CoverFooterPage = ({ content, picture, pictureMobile }) => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const pictureCalculated = isMobile && pictureMobile ? pictureMobile : picture;

  return (
    <div className={styles.fullWrapper}>
      <img src={pictureCalculated} alt="motivation" className={styles.picture} />
      <Container className={styles.container} disabledVerticalPadding>
        <div className={styles.body}>
          <div className={styles.title}>{content}</div>
          <Link to={{ pathname: '/articulos', state: { category: 'sportsPrograms' } }}>
            <Button className={styles.button}>COMPRAR PROGRAMA</Button>
          </Link>
        </div>
      </Container>
    </div>
  );
};

CoverFooterPage.defaultProps = {
  pictureMobile: '',
};

CoverFooterPage.propTypes = {
  content: oneOfType([string, element, array, object]).isRequired,
  picture: string.isRequired,
  pictureMobile: string,
};

export default CoverFooterPage;
