// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_card__2Uy57 {\r\n  background-color: #fff;\r\n  height: 100%;\r\n  padding: 1em;\r\n  border-radius: .5em;\r\n  box-sizing: border-box;\r\n  box-shadow: 5px 5px 20px #e1e1e1;\r\n  line-height: 1.5em;\r\n  position: relative;\r\n}\r\n.styles_row__1C0zm {\r\n  margin-bottom: .1em;\r\n  font-weight: 600;\r\n}\r\n.styles_row__1C0zm span {\r\n  color: gray;\r\n  margin-right: .5em;\r\n}\r\n.styles_message__1sWvG {\r\n  margin-top: 1em;\r\n}\r\n.styles_caption__fNU_4 {\r\n  font-weight: 600;\r\n  color: gray;\r\n}\r\n.styles_deleteIcon__1Smnf {\r\n  font-size: 1.6em;\r\n  position: absolute;\r\n  top: .5em;\r\n  right: .5em;\r\n  color: var(--rose);\r\n  cursor: pointer;\r\n}", "",{"version":3,"sources":["webpack://src/pages/dashboard/pages/messages/components/messageCard/styles.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,YAAY;EACZ,YAAY;EACZ,mBAAmB;EACnB,sBAAsB;EACtB,gCAAgC;EAChC,kBAAkB;EAClB,kBAAkB;AACpB;AACA;EACE,mBAAmB;EACnB,gBAAgB;AAClB;AACA;EACE,WAAW;EACX,kBAAkB;AACpB;AACA;EACE,eAAe;AACjB;AACA;EACE,gBAAgB;EAChB,WAAW;AACb;AACA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,kBAAkB;EAClB,eAAe;AACjB","sourcesContent":[".card {\r\n  background-color: #fff;\r\n  height: 100%;\r\n  padding: 1em;\r\n  border-radius: .5em;\r\n  box-sizing: border-box;\r\n  box-shadow: 5px 5px 20px #e1e1e1;\r\n  line-height: 1.5em;\r\n  position: relative;\r\n}\r\n.row {\r\n  margin-bottom: .1em;\r\n  font-weight: 600;\r\n}\r\n.row span {\r\n  color: gray;\r\n  margin-right: .5em;\r\n}\r\n.message {\r\n  margin-top: 1em;\r\n}\r\n.caption {\r\n  font-weight: 600;\r\n  color: gray;\r\n}\r\n.deleteIcon {\r\n  font-size: 1.6em;\r\n  position: absolute;\r\n  top: .5em;\r\n  right: .5em;\r\n  color: var(--rose);\r\n  cursor: pointer;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "styles_card__2Uy57",
	"row": "styles_row__1C0zm",
	"message": "styles_message__1sWvG",
	"caption": "styles_caption__fNU_4",
	"deleteIcon": "styles_deleteIcon__1Smnf"
};
export default ___CSS_LOADER_EXPORT___;
