/* eslint-disable react/no-unused-prop-types */
import { Box, ButtonBase, CircularProgress } from '@material-ui/core';
import { func, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import ChatView from 'src/components/chat';
import chatModel, { setNotificationsViewed } from 'src/core/models/chat';
import programUserModel from 'src/core/models/programUsers';
import useFetch from 'src/hooks/useFetch';
import { useSession } from 'src/modules/session';
import userSrc from 'src/assets/user.png';
import normalizeImagesUrl from 'src/helpers/normalizeImagesUrl';
import { Close } from '@material-ui/icons';
import styles from './styles.module.css';
import DaySelectorModal from '../daySelectorModal';
import useHideMessages from '../../../../../../hooks/useHideMessages';

const Chat = (props) => {
  const session = useSession();
  const [loading, setLoading] = useState(true);
  const [userData, setUserdata] = useState();
  const [chatId, setChatId] = useState(null);
  const [messages, setMessages] = useState([]);
  const [daySelecetorOpen, setDaySelectorOpne] = useState(false);
  const hide = useHideMessages();

  // fetch initial data
  useFetch(async () => {
    const responseUserData = await programUserModel.getSingle(props.userId);
    const responseChatId = await chatModel.getChatId(session.data.id, props.userId);
    setUserdata(responseUserData);
    setChatId(responseChatId);
    setLoading(false);
  }, [props.userId]);

  const formtMessage = (message) => ({
    ...message,
    isOwn: message.userId === session.data.id,
    picture: message.userId === session.data.id ? session.data.picture || userSrc : userData.picture || userSrc,
    name: message.userId === session.data.id ? 'Tú' : userData.name.split(' ')[0],
  });

  // iitialize realtime messages
  useEffect(() => {
    let unsubscribe;
    if (!loading && !!userData && !!chatId) {
      unsubscribe = chatModel.onMessagesChange(chatId, (currentMessages) => {
        setMessages(currentMessages.map(formtMessage));
        setNotificationsViewed(chatId, 'advicer');
      });
    }
    return unsubscribe;
  }, [chatId, props.userId, userData, loading]);

  const handleNewMessage = async (message) => {
    if (message instanceof File) {
      setMessages((prevVals) => [
        ...prevVals,
        {
          date: new Date(),
          userId: session.data.id,
          content: normalizeImagesUrl(message),
          isOwn: true,
          picture: session.data.picture || userSrc,
          name: 'Tú',
        },
      ]);
    }
    await chatModel.newMessage(chatId, session.data.id, message, 'user');
  };

  const handleDaySelected = (dayIndex) => {
    setDaySelectorOpne(false);
    props.onOpenTraining(dayIndex);
  };

  return (
    <>
      <>{daySelecetorOpen && <DaySelectorModal onSelect={handleDaySelected} />}</>
      <div className={styles.fullWrapper}>
        <div className={styles.card}>
          <Close className={styles.closeButton} onClick={props.onClose} />
          {!loading && (
            <div className={styles.cardBody}>
              <Box marginBottom="1em">
                <ButtonBase onClick={() => setDaySelectorOpne(true)} className={styles.button}>
                  Ver entrenamiento
                </ButtonBase>
              </Box>
              {!messages.length && <Box className={styles.emptyMessages}>Aún no hay mensajes</Box>}
              <ChatView messages={messages} onNewMessage={handleNewMessage} hideMessages={hide} />
            </div>
          )}
          {loading && <CircularProgress />}
        </div>
      </div>
    </>
  );
};

Chat.propTypes = {
  userId: string.isRequired,
  onClose: func.isRequired,
  onOpenTraining: func.isRequired,
};

export default Chat;
