// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_item__1A8ox {\n  margin-bottom: 4em;\n}\n.styles_picture__14xce {\n  width: 100%;\n}\n.styles_title__Dra4p {\n  font-size: 2em;\n  font-weight: 600;\n  color: var(--rose);\n  margin-bottom: 1em;\n}\n.styles_paragraph__itzlf {\n  line-height: 1.4em;\n  font-size: 1.3em;\n}\n.styles_link__3PWV4 {\n  font-size: 1.2em;\n  color: var(--cyan)!important;\n  text-decoration: underline!important;\n  font-weight: 600;\n  cursor: pointer;\n}\n@media screen and (max-width:600px) {\n  .styles_title__Dra4p {\n    font-size: 1.8em;\n  }\n}", "",{"version":3,"sources":["webpack://src/pages/tips/components/list/styles.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;AACA;EACE,WAAW;AACb;AACA;EACE,cAAc;EACd,gBAAgB;EAChB,kBAAkB;EAClB,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,gBAAgB;EAChB,4BAA4B;EAC5B,oCAAoC;EACpC,gBAAgB;EAChB,eAAe;AACjB;AACA;EACE;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".item {\n  margin-bottom: 4em;\n}\n.picture {\n  width: 100%;\n}\n.title {\n  font-size: 2em;\n  font-weight: 600;\n  color: var(--rose);\n  margin-bottom: 1em;\n}\n.paragraph {\n  line-height: 1.4em;\n  font-size: 1.3em;\n}\n.link {\n  font-size: 1.2em;\n  color: var(--cyan)!important;\n  text-decoration: underline!important;\n  font-weight: 600;\n  cursor: pointer;\n}\n@media screen and (max-width:600px) {\n  .title {\n    font-size: 1.8em;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": "styles_item__1A8ox",
	"picture": "styles_picture__14xce",
	"title": "styles_title__Dra4p",
	"paragraph": "styles_paragraph__itzlf",
	"link": "styles_link__3PWV4"
};
export default ___CSS_LOADER_EXPORT___;
