import React from 'react';
import { Step, StepLabel, Stepper as StepperMaterial } from '@material-ui/core';
import { number, array, bool } from 'prop-types';
import { Check } from '@material-ui/icons';
import styles from './styles.module.css';

const StepIcon = ({ icon, completed, active }) => (
  <div
    className={`${styles.numberWrapper} 
      ${completed || active ? styles.numberWrapperCOmpleted : ''}`}
  >
    {completed ? (
      <Check
        className={`${styles.check} 
          ${completed ? styles.checkCompleted : ''}`}
      />
    ) : (
      icon
    )}
  </div>
);

StepIcon.propTypes = {
  icon: number.isRequired,
  completed: bool.isRequired,
  active: bool.isRequired,
};

const Stepper = ({ steps, activeStep }) => (
  <StepperMaterial alternativeLabel activeStep={activeStep}>
    {steps.map((label) => (
      <Step key={label} className={styles.step}>
        <StepLabel StepIconComponent={StepIcon} classes={{ label: styles.label }}>
          {label}
        </StepLabel>
      </Step>
    ))}
  </StepperMaterial>
);

Stepper.propTypes = {
  steps: array.isRequired,
  activeStep: number.isRequired,
};

export default Stepper;
