import { Box, Collapse, useMediaQuery } from '@material-ui/core';
import { ExpandMore, QueryBuilder, AccessTime, ExpandLess, Check } from '@material-ui/icons';
import { array, bool, func, number, string } from 'prop-types';
import React, { useState, Fragment, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import composeClassNames from 'src/helpers/composeClassNames';
import styles from './styles.module.css';

const Weekly = (props) => {
  const history = useHistory();
  const hideDesktopDate = useMediaQuery('(max-width:750px)');
  const [indexDayExpanded, setIndexDayExpanded] = useState(null);
  const currentDayNumber = new Date().getDay() - 1; // monday == 0

  const isLastStand = useMemo(() => {
    const currentDate = new Date();
    if (currentDayNumber === -1 && currentDate.getHours() < 16) return true;
    return false;
  }, [currentDayNumber]);

  const handleDayExpand = (index) => () => {
    if ((indexDayExpanded || indexDayExpanded === 0) && index === indexDayExpanded) setIndexDayExpanded(null);
    else setIndexDayExpanded(index);
  };

  const handleView = (dayIndex) => () => {
    if (props.onView) props.onView(dayIndex);
    else history.push(`/private/training/${dayIndex}`);
  };

  return (
    <div className={styles.wrapper}>
      {!hideDesktopDate && (
        <div className={styles.date}>
          <Box fontSize="1.2em">SEM</Box>
          <Box fontSize="2em">{props.weekNumber}</Box>
        </div>
      )}

      <div className={styles.cardBody}>
        {hideDesktopDate && (
          <Box display="flex" alignItems="center" marginBottom="1em" fontWeight="bold" color="var(--gray)">
            <Box fontSize="1.2em" color="var(--cyan)" fontWeight="normal" marginRight=".5em">
              SEM
            </Box>
            <Box fontSize="1.8em">{props.weekNumber}</Box>
          </Box>
        )}

        <div className={styles.cardHeadWrapper}>
          <div className={styles.cardHeadData}>
            <div className={styles.caption}>50 minutos de trabajo</div>
            <Box display="flex" justifyContent="space-between" marginBottom=".5em" alignItems="center">
              <div className={styles.title}>{props.title}</div>
              <div className={styles.buttonWrapper}>
                <div className={styles.buttonPending}>
                  <QueryBuilder className={styles.clockIcon} />
                  <div>PENDIENTE</div>
                </div>
              </div>
            </Box>
            <Box fontSize="1.2em" lineHeight="1.5em">
              {props.objetive}
            </Box>
          </div>
        </div>

        {props.isCurrentWeek && (
          <div className={styles.list}>
            {props.days.map((day, dayIndex) => (
              <Fragment key={dayIndex}>
                <div className={styles.row} onClick={handleDayExpand(dayIndex)} role="button">
                  <div className={styles.rowTitle}>{day.label}</div>
                  <Box display="flex">
                    {!props.disabledCompleteds && currentDayNumber <= dayIndex && !isLastStand && (
                      <AccessTime className={styles.dayStatusIcon} />
                    )}
                    {!props.disabledCompleteds && (currentDayNumber > dayIndex || !!isLastStand) && (
                      <Check className={composeClassNames(styles.dayStatusIcon, styles.finishedIcon)} />
                    )}
                    {indexDayExpanded !== dayIndex && <ExpandMore onClick={() => setIndexDayExpanded(dayIndex)} />}
                    {indexDayExpanded === dayIndex && <ExpandLess onClick={() => setIndexDayExpanded(null)} />}
                  </Box>
                </div>
                <Collapse in={indexDayExpanded === dayIndex}>
                  <div className={styles.excercices}>
                    {day.types.map((type = {}) => {
                      const element = [];
                      const exercices = type.exercices || [];
                      exercices.forEach((singleExcercice = {}) => {
                        if (singleExcercice.type === 'title') {
                          element.push(<div key={element.length + 1}>{singleExcercice.value}</div>);
                        }
                      });
                      return element;
                    })}
                    <div onClick={handleView(dayIndex)} className={styles.link} role="button">
                      Ver entrenamiento
                    </div>
                  </div>
                </Collapse>
              </Fragment>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

Weekly.defaultProps = {
  days: [],
  isCurrentWeek: false,
  disabledCompleteds: false,
  onView: null,
};

Weekly.propTypes = {
  title: string.isRequired,
  weekNumber: number.isRequired,
  objetive: string.isRequired,
  days: array,
  isCurrentWeek: bool,
  disabledCompleteds: bool,
  onView: func,
};

export default Weekly;
