// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_header__18VXj {\n  z-index: 2;\n  top: 0px;\n}\n.styles_headerBody__1NQ1Q {\n  padding: 1em 0em;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n.styles_logo__3Hqu6 {\n  height: 2.4em;\n}\n.styles_icon__3_JdC {\n  width: 2.2em!important;\n}\n.styles_iconMaterial__3aBdY {\n  font-size: 2em!important;\n  color: var(--rose)!important;\n  margin-left: .2em;\n}\n.styles_action__3pADb {\n  display: flex;\n  align-items: center;\n}\n.styles_numberItemsOnCart__K51hy {\n  background-color: var(--cyan);\n  position: absolute;\n  font-size: .9em;\n  width: 1.2em;\n  height: 1.2em;\n  border-radius: 50%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  color: #fff;\n  right: 0;\n  top: 0;\n}", "",{"version":3,"sources":["webpack://src/components/header/components/mobile/styles.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,QAAQ;AACV;AACA;EACE,gBAAgB;EAChB,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;AACA;EACE,aAAa;AACf;AACA;EACE,sBAAsB;AACxB;AACA;EACE,wBAAwB;EACxB,4BAA4B;EAC5B,iBAAiB;AACnB;AACA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,6BAA6B;EAC7B,kBAAkB;EAClB,eAAe;EACf,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;EACX,QAAQ;EACR,MAAM;AACR","sourcesContent":[".header {\n  z-index: 2;\n  top: 0px;\n}\n.headerBody {\n  padding: 1em 0em;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n.logo {\n  height: 2.4em;\n}\n.icon {\n  width: 2.2em!important;\n}\n.iconMaterial {\n  font-size: 2em!important;\n  color: var(--rose)!important;\n  margin-left: .2em;\n}\n.action {\n  display: flex;\n  align-items: center;\n}\n.numberItemsOnCart {\n  background-color: var(--cyan);\n  position: absolute;\n  font-size: .9em;\n  width: 1.2em;\n  height: 1.2em;\n  border-radius: 50%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  color: #fff;\n  right: 0;\n  top: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "styles_header__18VXj",
	"headerBody": "styles_headerBody__1NQ1Q",
	"logo": "styles_logo__3Hqu6",
	"icon": "styles_icon__3_JdC",
	"iconMaterial": "styles_iconMaterial__3aBdY",
	"action": "styles_action__3pADb",
	"numberItemsOnCart": "styles_numberItemsOnCart__K51hy"
};
export default ___CSS_LOADER_EXPORT___;
