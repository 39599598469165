// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_modal__2H9Vd {\r\n  width: 100vw;\r\n  min-height: 100vh;\r\n  outline: none;\r\n  background-color: rgb(0 0 0 / 87%);\r\n}\r\n.styles_body__rjUJ0 {\r\n  width: 100%;\r\n  padding-left: 2em;\r\n  box-sizing: border-box;\r\n  margin-top: 2em;\r\n}\r\n.styles_wrapper__1QZPG {\r\n  outline: none;\r\n  flex-grow: 1;\r\n  min-height: 100vh;\r\n  justify-content: center;\r\n  align-items: flex-start;\r\n  position: relative;\r\n  width: 100%;\r\n}\r\n\r\n.styles_link__cr_gv {\r\n  color: var(--cyan)!important;\r\n  display: block;\r\n  font-size: 1em;\r\n  padding: 1.2em 0em;\r\n  font-weight: 600;\r\n  display: flex;\r\n  align-items: center;\r\n}\r\n\r\n.styles_logOutImg__2xaR6 {\r\n  width: 1.5em;\r\n  margin-right: 1em;\r\n}", "",{"version":3,"sources":["webpack://src/modules/menuPrivate/styles.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,iBAAiB;EACjB,aAAa;EACb,kCAAkC;AACpC;AACA;EACE,WAAW;EACX,iBAAiB;EACjB,sBAAsB;EACtB,eAAe;AACjB;AACA;EACE,aAAa;EACb,YAAY;EACZ,iBAAiB;EACjB,uBAAuB;EACvB,uBAAuB;EACvB,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,4BAA4B;EAC5B,cAAc;EACd,cAAc;EACd,kBAAkB;EAClB,gBAAgB;EAChB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,iBAAiB;AACnB","sourcesContent":[".modal {\r\n  width: 100vw;\r\n  min-height: 100vh;\r\n  outline: none;\r\n  background-color: rgb(0 0 0 / 87%);\r\n}\r\n.body {\r\n  width: 100%;\r\n  padding-left: 2em;\r\n  box-sizing: border-box;\r\n  margin-top: 2em;\r\n}\r\n.wrapper {\r\n  outline: none;\r\n  flex-grow: 1;\r\n  min-height: 100vh;\r\n  justify-content: center;\r\n  align-items: flex-start;\r\n  position: relative;\r\n  width: 100%;\r\n}\r\n\r\n.link {\r\n  color: var(--cyan)!important;\r\n  display: block;\r\n  font-size: 1em;\r\n  padding: 1.2em 0em;\r\n  font-weight: 600;\r\n  display: flex;\r\n  align-items: center;\r\n}\r\n\r\n.logOutImg {\r\n  width: 1.5em;\r\n  margin-right: 1em;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "styles_modal__2H9Vd",
	"body": "styles_body__rjUJ0",
	"wrapper": "styles_wrapper__1QZPG",
	"link": "styles_link__cr_gv",
	"logOutImg": "styles_logOutImg__2xaR6"
};
export default ___CSS_LOADER_EXPORT___;
