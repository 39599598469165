// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_limiter__1BsQD {\n  width: 60%;\n  margin: 2em auto;\n}\n", "",{"version":3,"sources":["webpack://src/pages/dashboard/pages/programDetail/styles.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,gBAAgB;AAClB","sourcesContent":[".limiter {\n  width: 60%;\n  margin: 2em auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"limiter": "styles_limiter__1BsQD"
};
export default ___CSS_LOADER_EXPORT___;
