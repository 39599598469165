import firebase from 'firebase/app';
import mapObjects from '../../helpers/mapObjects';
import response from '../../helpers/response';
import database from '../database';

const path = 'shippingCost/main';

const getList = async () => {
  const data = await database.getDocument(path);
  if (!data) return [];
  delete data.id;
  delete data.updatedAt;
  delete data.createdAt;
  return mapObjects(data, (value, keyname) => ({ ...value, cp: keyname }));
};

export const saveSippingCost = async ({ comment = '', price, postalCode }) => {
  const data = await database.getDocument(path);
  if (!!data && !!data[postalCode]) return response.failed('El código postal ya se encuentra registrado');
  if (!data) await database.create('shippingCost', {}, 'main');
  if (!parseInt(postalCode, 10)) return response.failed('Código postal no válido');
  await database.update(path, {
    [postalCode]: { comment, price: parseFloat(price) },
  });

  return response.success('Código postal registrado correctamente');
};

export const updateShippingCost = async ({ comment = '', price, postalCode }) => {
  if (!parseInt(postalCode, 10)) return response.failed('Código postal no válido');
  await database.update(path, {
    [postalCode]: { comment, price: parseFloat(price) },
  });
  return response.success('Código postal actualizado correctamente');
};

export const deleteShippingCost = async (postalCode) => {
  await database.update(path, {
    [postalCode]: firebase.firestore.FieldValue.delete(),
  });
  return response.success('Código postal eliminado correctamente');
};

const shipping = {
  getList,
  saveSippingCost,
};

export default shipping;
