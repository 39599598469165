export const runtime = process.env.REACT_APP_ENV;

console.log(`---------- welcome to ${runtime} ----------`);

const firebaseConfigList = {
  development: {
    apiKey: 'AIzaSyCWW6toyjJHy8_endDegfGrYBICKCkS16g',
    authDomain: 'poltri.firebaseapp.com',
    projectId: 'poltri',
    storageBucket: 'poltri.appspot.com',
    messagingSenderId: '232865623748',
    appId: '1:232865623748:web:0174df59d8f5864265e391',
  },
  test: {
    apiKey: 'AIzaSyCWW6toyjJHy8_endDegfGrYBICKCkS16g',
    authDomain: 'poltri.firebaseapp.com',
    projectId: 'poltri',
    storageBucket: 'poltri.appspot.com',
    messagingSenderId: '232865623748',
    appId: '1:232865623748:web:0174df59d8f5864265e391',
  },
  production: {
    apiKey: 'AIzaSyCZJSwI2T_p9fRsHtRl7BcBJHAOC1vOh1Q',
    authDomain: 'poltri-production.firebaseapp.com',
    projectId: 'poltri-production',
    storageBucket: 'poltri-production.appspot.com',
    messagingSenderId: '259946954962',
    appId: '1:259946954962:web:e124edf8575762b221ad0b',
  },
};

const openPayCredentialList = {
  development: {
    publickKey: 'pk_31a1308eb1414628a76786743bfda67f',
    id: 'mc0vma0i8xxlafccvhr5',
  },
  test: {
    publickKey: 'pk_31a1308eb1414628a76786743bfda67f',
    id: 'mc0vma0i8xxlafccvhr5',
  },
  production: {},
};

const openpaydashboardUrlList = {
  test: 'https://sandbox-dashboard.openpay.mx',
  development: 'https://sandbox-dashboard.openpay.mx',
  production: 'https://dashboard.openpay.mx',
};

const paypalClientIdList = {
  development: 'AaSWKyX_04IYi1ka8A_Qxr-xddBNxbn_K0fzZukxjYHN1BR01dv6wykgLV_zoBL16adglxM6ixmC2jps',
  test: 'AaSWKyX_04IYi1ka8A_Qxr-xddBNxbn_K0fzZukxjYHN1BR01dv6wykgLV_zoBL16adglxM6ixmC2jps',
  production: 'AT90mERyE1jXIOILw9VEsjajtKR3Z2dIkIU60SuA63gsqhFO3MK5nJ9GI7bshWTX3XC-_RW_Dd4Ug6Nd',
};

export const firebaseConfig = firebaseConfigList[runtime];
export const paypalClientId = paypalClientIdList[runtime];
export const openPayCredential = openPayCredentialList[runtime];
export const openPayDashboardPath = openpaydashboardUrlList[runtime];
