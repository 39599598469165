// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_wrapper__2eNcz {\n  background-color: #fff;\n  padding: 3em;\n}\n.styles_body__fjMjD {\n  display: flex;\n  justify-content: center;\n}\n.styles_textarea__17vAc {\n  height: 6em;\n  min-height: 6em;\n  font-size: .9em;\n  line-height: 1.5em;\n}\n.styles_actionsWrapper__2V29v {\n  padding: 0em 1em;\n  color: var(--gray);\n}\n\n.styles_icon__32NpU {\n  font-size: 1.6em!important;\n  cursor: pointer;\n}\n.styles_iconSend__sWUGF {\n  cursor: pointer;\n  font-size: 1.6em!important;\n  color: var(--rose);\n  margin-top: .5em;\n  transform: rotate(-40deg);\n}\n@media screen and (max-width:600px) {\n  .styles_wrapper__2eNcz {\n    font-size: 1.2em;\n    padding: 1em;\n  }\n}", "",{"version":3,"sources":["webpack://src/components/groupChat/components/form/styles.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,YAAY;AACd;AACA;EACE,aAAa;EACb,uBAAuB;AACzB;AACA;EACE,WAAW;EACX,eAAe;EACf,eAAe;EACf,kBAAkB;AACpB;AACA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,0BAA0B;EAC1B,eAAe;AACjB;AACA;EACE,eAAe;EACf,0BAA0B;EAC1B,kBAAkB;EAClB,gBAAgB;EAChB,yBAAyB;AAC3B;AACA;EACE;IACE,gBAAgB;IAChB,YAAY;EACd;AACF","sourcesContent":[".wrapper {\n  background-color: #fff;\n  padding: 3em;\n}\n.body {\n  display: flex;\n  justify-content: center;\n}\n.textarea {\n  height: 6em;\n  min-height: 6em;\n  font-size: .9em;\n  line-height: 1.5em;\n}\n.actionsWrapper {\n  padding: 0em 1em;\n  color: var(--gray);\n}\n\n.icon {\n  font-size: 1.6em!important;\n  cursor: pointer;\n}\n.iconSend {\n  cursor: pointer;\n  font-size: 1.6em!important;\n  color: var(--rose);\n  margin-top: .5em;\n  transform: rotate(-40deg);\n}\n@media screen and (max-width:600px) {\n  .wrapper {\n    font-size: 1.2em;\n    padding: 1em;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "styles_wrapper__2eNcz",
	"body": "styles_body__fjMjD",
	"textarea": "styles_textarea__17vAc",
	"actionsWrapper": "styles_actionsWrapper__2V29v",
	"icon": "styles_icon__32NpU",
	"iconSend": "styles_iconSend__sWUGF"
};
export default ___CSS_LOADER_EXPORT___;
