import React, { createContext, useContext, useEffect, useState } from 'react';
import { element } from 'prop-types';
import { onUserChangeState } from 'src/core/models/auth';
import users from 'src/core/models/users';

const contextInterface = {
  data: { name: '', id: '', type: '' },
  setSession: () => {},
  refreshSession: () => {},
};

const context = createContext(contextInterface);

export const useSession = () => useContext(context);

// H E L P E R S
const getSessionFromLocalStorage = () => {
  try {
    const session = localStorage.getItem('SESSION');
    if (!session) return null;
    return JSON.parse(session) || null;
  } catch (error) {
    return null;
  }
};

const setSessionToLocalStorage = (currentSession) => {
  try {
    if (!currentSession) localStorage.removeItem('SESSION');
    localStorage.setItem('SESSION', JSON.stringify(currentSession));
  } catch (error) {
    console.log('error');
  }
};

export const SessionProvider = ({ children }) => {
  const [state, setState] = useState(getSessionFromLocalStorage());

  const handleSetSession = (newSessionValue) => {
    setState(newSessionValue);
    setSessionToLocalStorage(newSessionValue);
  };

  // subscribe session state from firebase
  useEffect(() => {
    onUserChangeState((sessionFromFirebase) => {
      handleSetSession(sessionFromFirebase);
    });
  }, []);

  const handleRefreshSession = async () => {
    const userData = await users.getSingle(state.id);
    handleSetSession(userData);
  };

  const contextValue = {
    data: state,
    setSession: handleSetSession,
    refreshSession: handleRefreshSession,
  };

  return <context.Provider value={contextValue}>{children}</context.Provider>;
};

SessionProvider.propTypes = {
  children: element.isRequired,
};

export default {
  provider: SessionProvider,
};
