import PropTypes from 'prop-types';
import { Box, CircularProgress } from '@material-ui/core';
import React from 'react';
import { colors } from '../../constants';
import styles from './styles.module.css';

const Loading = (props) => (
  <div className={styles.wrapper}>
    <Box textAlign="center" color={colors.cyan} marginBottom="4em">
      {props.message}
    </Box>
    <CircularProgress />
  </div>
);

Loading.defaultProps = {
  message: 'Cargando datos...',
};

Loading.propTypes = {
  message: PropTypes.string,
};

export default Loading;
